import { useMemo } from "react";
import Select from "../ui/Select";

const SelectCategorias = ({
  onChange,
  categoriaOpen,
  categoria,
  deporte,
  getCategorias,
  isExterno,
  label = "",
  error,
  placeholder = "Selecciona una categoría",
  isClearable,
  isSearchable,
}) => {
  const onChangeCategoria = (categoria) => {
    const { value = null } = categoria || {};
    onChange?.(value);
  };
  const Abierta = useMemo(() => getCategorias.find((c) => c === "Abierta"), [getCategorias]);
  const categoriaA = useMemo(() => {
    return [
      {
        key: categoria,
        value: categoria,
        label: categoria,
      },
      { key: "Abierta", value: "Abierta", label: "Abierta" },
    ];
  }, [categoria]);

  if (deporte === "padel" || isExterno)
    return (
      <Select
        isSearchable={isSearchable}
        isClearable={isClearable}
        placeholder={placeholder}
        label={label}
        value={categoriaOpen}
        options={getCategorias.map((c) => ({ key: c, value: c, label: c }))}
        onChange={onChangeCategoria}
        name="categoriaOpen"
        error={error}
        containerStyle={{paddingBottom:0}}
      />
    );
  if (Abierta)
    return (
      <Select
        isSearchable={isSearchable}
        isClearable={isClearable}
        label={label}
        placeholder={placeholder}
        value={categoriaOpen}
        options={categoriaA}
        name="categoriaOpen"
        onChange={onChangeCategoria}
        error={error}
        containerStyle={{paddingBottom:0}}
      />
    );
  return <span style={{ marginTop: 12 }}>{categoria}</span>;
};

export default SelectCategorias;
