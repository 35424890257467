import { useCallback, useEffect, useState, useMemo } from "react";
import "../../styles/SingleCalendar.css";
import { colors } from "../../costants/colors";
import classNames from "classnames";
import moment from "moment";
export const TimeInput = (props) => {
  const { label, value, onChange, min, max } = props;
  const [time, setTime] = useState({
    fulltime: 0,
    horas: 12,
    minutos: 0,
    AmPm: true,
  });
  useEffect(() => {
    if (value !== null && value !== undefined && time.fulltime - (time.fulltime % 60) !== value)
      auxSetTime(ChangeTime("reset", value), true);
  }, [value]); //eslint-disable-line
  const getHora = (value) => {
    var hour = Math.trunc(value >= 720 ? (value - 720) / 60 : value / 60);
    return hour === 0 ? 12 : hour;
  };
  const getMinutos = (value) => {
    return value >= 720 ? (value - 720) % 60 : value % 60;
  };
  const getAmPm = (value) => {
    return value < 720;
  };
  const auxSetTime = (value, noExternalChange) => {
    if (value.fulltime !== time.fulltime) {
      setTime(value);
      if (!noExternalChange) {
        onChange({ value: value.fulltime });
      }
    }
  };
  const ChangeTime = (name, value, prevValue) => {
    var AuxPrevValue = prevValue ? prevValue : {}; //eslint-disable-line
    var valuesub = 0;
    if (name === "tiempo") {
      valuesub = time.fulltime + value;
      if (Math.abs(value) === 720) {
        // if(valuesub>=24*60) valuesub = 24*60-Math.abs(valuesub)
        // else if(valuesub<0) valuesub = 24*60-Math.abs(valuesub)
      }
    } else valuesub = value;
    var auxMin = min ? min : 0;
    var auxMax = max ? max : 24 * 60 - 30;
    if (auxMin < auxMax) {
      const auxValuesub = valuesub;
      if (auxValuesub < auxMin)
        valuesub =
          Math.abs(value) === 60 && auxMax === 24 * 60 - 30 && auxValuesub === -60
            ? auxMax - 30
            : auxMax;
      if (auxValuesub > auxMax)
        valuesub =
          Math.abs(value) === 60 && auxMin === 0 && auxValuesub === 24 * 60 ? auxMin + 30 : auxMin;
    } else {
      if (valuesub < 0) valuesub = 24 * 60 - 30;
      if (valuesub > 24 * 60 - 1) valuesub = 0;
    }
    valuesub = {
      fulltime: valuesub,
      horas: getHora(valuesub),
      minutos: getMinutos(valuesub),
      AmPm: getAmPm(valuesub),
    };

    return valuesub.fulltime === time.fulltime ? time : valuesub;
  };
  return (
    <div
      className={classNames(
        "TimeInput-input-container",
        "TimeInput-padding",
        { "TimeInput-input-container-am": time.AmPm },
        { "TimeInput-input-container-pm": !time.AmPm }
      )}
    >
      <div style={{ textAlign: "center" }}>{label}</div>
      <div
        style={{
          display: "grid",
          justifyContent: "spaceEvenly",
          gridTemplateColumns: "45% min-content 45%",
          width: "100%",
          alignItems: "center",
          // justifyContent: "center",
          fontSize: "20px",
        }}
      >
        <div
          className={classNames(
            "TimeInput-field",
            { "TimeInput-field-am": time.AmPm },
            { "TimeInput-field-pm": !time.AmPm }
          )}
        >
          <div
            className={classNames(
              "TimeInput-fields-change",
              { "TimeInput-fields-change-am": time.AmPm },
              { "TimeInput-fields-change-pm": !time.AmPm }
            )}
            onClick={() => auxSetTime(ChangeTime("tiempo", 60))}
          >
            +
          </div>
          <div className="TimeInput-fields-label">{time.horas}</div>
          <div
            className={classNames(
              "TimeInput-fields-change",
              { "TimeInput-fields-change-am": time.AmPm },
              { "TimeInput-fields-change-pm": !time.AmPm }
            )}
            onClick={() => auxSetTime(ChangeTime("tiempo", -60))}
          >
            -
          </div>
        </div>
        <div style={{ margin: "0 3px" }}>:</div>
        <div
          className={classNames(
            "TimeInput-field",
            { "TimeInput-field-am": time.AmPm },
            { "TimeInput-field-pm": !time.AmPm }
          )}
        >
          <div
            className={classNames(
              "TimeInput-fields-change",
              { "TimeInput-fields-change-am": time.AmPm },
              { "TimeInput-fields-change-pm": !time.AmPm }
            )}
            onClick={() => auxSetTime(ChangeTime("tiempo", 30))}
          >
            +
          </div>
          <div className="TimeInput-fields-label">{time.minutos}</div>
          <div
            className={classNames(
              "TimeInput-fields-change",
              { "TimeInput-fields-change-am": time.AmPm },
              { "TimeInput-fields-change-pm": !time.AmPm }
            )}
            onClick={() => auxSetTime(ChangeTime("tiempo", -30))}
          >
            -
          </div>
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          width: "100%",
        }}
      >
        <div
          className={classNames("TimeInput-AmPm", {
            "TimeInput-AmPm-Selected": time.AmPm,
          })}
          onClick={() => auxSetTime(ChangeTime("tiempo", -720))}
        >
          AM
        </div>
        <div
          className={classNames("TimeInput-AmPm", {
            "TimeInput-AmPm-Selected": !time.AmPm,
          })}
          onClick={() => auxSetTime(ChangeTime("tiempo", 720))}
        >
          PM
        </div>
      </div>
      <div></div>
    </div>
  );
};

export const SingleAgend = (props) => {
  const { selectedDay, courtPrice, onchange } = props;
  const [Inicio, setInicio] = useState(
    selectedDay.date ? selectedDay.date.getHours() * 60 + selectedDay.date.getMinutes() : 0
  );
  const [fin, setFin] = useState(
    selectedDay.date ? selectedDay.date.getHours() * 60 + selectedDay.date.getMinutes() + 60 : 0
  );
  const CalculeDuration = () => {
    if (fin >= Inicio) return (fin - Inicio) / 60;
    else return (fin - Inicio) / 60 + 24;
  };
  useEffect(() => {
    if (selectedDay.date) {
      var newTime = selectedDay.date.getHours() * 60;
      if (selectedDay.date.getMinutes() >= 30) newTime += 60;
      else newTime += 30;
      if (newTime !== Inicio) setInicio(newTime);
      setFin(newTime + 60);
    }
  }, [selectedDay]); //eslint-disable-line
  useEffect(() => {
    var duracion = CalculeDuration();
    var dateStart = new Date(
      selectedDay?.date?.getFullYear(),
      selectedDay?.date?.getMonth(),
      selectedDay?.date?.getDate()
    );
    var dateEnd = new Date(
      selectedDay?.date?.getFullYear(),
      selectedDay?.date?.getMonth(),
      selectedDay?.date?.getDate()
    );
    dateStart.setTime(dateStart.getTime() + Inicio * 60 * 1000);
    dateEnd.setTime(dateEnd.getTime() + (Inicio + duracion * 60) * 60 * 1000);
    dateStart = dateStart.toISOString();
    dateEnd = dateEnd.toISOString();

    onchange({
      day: { start: dateStart, end: dateEnd },
      duracion: duracion,
      total: duracion * courtPrice,
    });
  }, [Inicio, fin]); //eslint-disable-line
  return (
    <div className="SingleAgend">
      <TimeInput
        label="Inicio"
        value={Inicio}
        min={
          selectedDay.date ? selectedDay.date.getHours() * 60 + selectedDay.date.getMinutes() : 0
        }
        onChange={(event) => {
          if (Inicio !== event.value) setInicio(event.value);
        }}
      ></TimeInput>
      <TimeInput
        label="Fin"
        value={fin}
        onChange={(event) => {
          if (fin !== event.value) setFin(event.value);
        }}
      ></TimeInput>
    </div>
  );
};

export const SingleCalendar = (props) => {
  const { selectedDay, courtPrice, Total, onchange } = props;
  useEffect(() => {
    if (selectedDay?.date) {
      onchange({
        day: { start: selectedDay?.date ? selectedDay.date : new Date() },
      });
    }
  }, [selectedDay]); //eslint-disable-line
  return (
    <div className="SingleCalendar">
      <div className="SingleCalendar-firstregion">
        <CircleDay day={selectedDay} />
        <div>
          {Total && (
            <>
              <div>Total</div>
              <div>{Math.trunc(Total)}</div>
            </>
          )}
          {/* <img className={'SingleCalendar-divImg'} src={moon} />
          <img className={'SingleCalendar-divImg'} src={sun} /> */}
        </div>
      </div>
      <SingleAgend selectedDay={selectedDay} courtPrice={courtPrice} onchange={onchange} />
    </div>
  );
};
export const CircleDay = (props) => {
  const { day, SetselectedDay, isSelected, onlyRead, showNumberDay, small } = props;
  const getD = useCallback(() => {
    const weekDays = {
      Monday: "L",
      Tuesday: "M",
      Wednesday: "M",
      Thursday: "J",
      Friday: "V",
      Saturday: "S",
      Sunday: "D",
    };
    return weekDays[day?.name] || "";
  }, [day?.name]);
  return (
    <div
      className="court-days-width court-days"
      style={{
        backgroundColor: day
          ? day?.disponibility
            ? colors.Verde.hover
            : "#999999"
          : "transparent",
        color:
          isSelected && !onlyRead
            ? day?.disponibility
              ? "white"
              : "#2c0ae7"
            : day?.disponibility
            ? "#002688"
            : "#4d6090",
        boxShadow: isSelected && !onlyRead ? "rgb(32 156 17 / 12%) 0px 0px 0px 3px inset" : "none",
        outlineOffset: 1,
        transition: "all 0.5s ease 0s",
      }}
      onClick={() => {
        try {
          if (!onlyRead) SetselectedDay(day);
        } catch (e) {}
      }}
    >
      {(day?.date || showNumberDay) && (
        <p
          style={{
            width: "100%",
            textAlign: "center",
            fontWeight: "bold",
            margin: "auto",
            position: "absolute",
            bottom: -2,
            fontSize: "calc(0.3rem + 0.8vh)",
            height: "calc(0.7rem + 0.8vh)",
            background: "linear-gradient(180deg, #ffffff00 0%, #6c6c6cbd" || "#dadada94",
            color: day?.disponibility ? "#e8e8e8" : "#f3f3f3",
          }}
        >
          {showNumberDay ? getD() : day?.date?.getDate()}
        </p>
      )}
      <p
        className="court-days-contenido"
        style={{
          fontFamily: "sans-serif",
          height: showNumberDay ? "100%" : "justifyContent",
          top: small ? -5 : 0,
        }}
      >
        {showNumberDay ? day?.date?.getDate() : getD()}
      </p>
    </div>
  );
};

// new calendar

export const ConteinerDay = (props) => {
  const { day, SetselectedDay, isSelected, onlyRead, showNumberDay, small } = props;
  const getD = useCallback(() => {
    const weekDays = {
      Monday: "L",
      Tuesday: "M",
      Wednesday: "M",
      Thursday: "J",
      Friday: "V",
      Saturday: "S",
      Sunday: "D",
    };
    return weekDays[day?.name] || "";
  }, [day?.name]);
  const headerDay = useMemo(
    () => (showNumberDay ? getD() : day?.date?.getDate()),
    [day?.date, getD, showNumberDay]
  );
  const bodyDay = useMemo(
    () => (showNumberDay ? day?.date?.getDate() : getD()),
    [day?.date, getD, showNumberDay]
  );
  const selectedAndRead = isSelected && !onlyRead;
  return (
    <div>
      <div
        className="court-days-width tw-bg-transparent tw-outline-offset-1 tw-transition-all tw-pb-4"
        style={{ backgroundColor: "transparent" }}
        onClick={() => !onlyRead && SetselectedDay?.(day)}
      >
        {(day?.date || showNumberDay) && (
          <p
            className={classNames(
              "tw-w-full tw-text-center tw-font-bold tw-top-0.5 tw-text-lg tw-text-gray-600",
              {
                "tw-h-full": showNumberDay,
                "tw-h-fit": !showNumberDay,
              }
            )}
          >
            {headerDay}
          </p>
        )}
      </div>
      <div
        className={classNames(
          "conteiner-day tw-text-Turquesa tw-overflow-hidden tw-relative tw-rounded-full tw-font-bold tw-outline-offset-1 tw-transition-all",
          {
            "tw-scale-125 tw-transform tw-translate-y-0 hover:tw-bg-opacity-75": isSelected,
            "hover:tw-bg-gray-100": !isSelected,
            "tw-bg-Verde-TenisCTA": day?.disponibility && selectedAndRead,
            "tw-bg-gray-400 tw-text-gray-500":
              day && (!day?.disponibility || onlyRead) && !isSelected,
            "tw-bg-transparent tw-text-transparent": !day,
          }
        )}
        style={{
          boxShadow:
            isSelected && !onlyRead ? "rgb(32 156 17 / 12%) 0px 0px 0px 3px inset" : "none",
          top: small ? -15 : -16,
          width: "30px",
        }}
        onClick={() => !onlyRead && SetselectedDay?.(day)}
      >
        <p
          className="new-court-days-contenido tw-mt-2"
          style={{
            fontFamily: "calc(0.3rem + 0.8vh)",
            height: "calc(0.7rem + 0.8vh)",
            top: small ? -15 : -5,
            fontSize: "15px",
          }}
        >
          {bodyDay}
        </p>
      </div>
    </div>
  );
};

//
export const SingleWeek = ({ week, showNumbDay, onlyRead, selectedDay, setselectedDay, small }) => {
  const Week = useMemo(() => {
    let startDay = moment().startOf("isoWeek");
    let name = startDay.format("dddd");
    const weekDays = {
      [name]: 0,
      [moment(startDay).add(1, "day").format("dddd")]: 1,
      [moment(startDay).add(2, "day").format("dddd")]: 2,
      [moment(startDay).add(3, "day").format("dddd")]: 3,
      [moment(startDay).add(4, "day").format("dddd")]: 4,
      [moment(startDay).add(5, "day").format("dddd")]: 5,
      [moment(startDay).add(6, "day").format("dddd")]: 6,
    };
    let arrayWeek = [];
    if (Array.isArray(week)) arrayWeek = week;
    else if (typeof week === "object") arrayWeek = Object.values(week);
    return arrayWeek.sort((a, b) => weekDays[a.name] - weekDays[b.name]);
  }, [week]);
  return (
    <div
      className={classNames("court-disponibility", {
        "court-disponibility-small": small,
      })}
      style={{ width: "100%" }}
    >
      {Week.map((day) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          key={day.name}
        >
          <CircleDay
            day={day}
            SetselectedDay={setselectedDay}
            isSelected={selectedDay?.date === day?.date}
            showNumberDay={showNumbDay}
            onlyRead={onlyRead}
            small={small}
          />
        </div>
      ))}
    </div>
  );
};
