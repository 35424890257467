import { lazy, Suspense, useEffect, useState } from "react";
import Marker from "./Marker";
const GoogleMapReact = lazy(() => import("google-map-react"));
const defaultLat = -29.93225;
const defaultLng = -71.246173;
const Map = ({
  lat = defaultLat,
  lng = defaultLng,
  zoom = 15,
  disable,
  handleChildInteraction,
  text = "Mi marcador",
  Mark,
  children,
  draggable = true,
  defaultCenter = { lat: defaultLat, lng: defaultLng },
}) => {
  const [coords, setCoords] = useState(defaultCenter);
  useEffect(() => {
    const newLat = parseFloat(lat || defaultLat);
    const newLng = parseFloat(lng || defaultLng);
    setCoords({ lat: newLat || defaultLat, lng: newLng || defaultLng });
  }, [lat, lng]);
  const onCircleInteraction = (childKey, childProps, mouse) => {
    if (!disable) setCoords({ lat: mouse.lat, lng: mouse.lng });
  };
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyA0dzT6rTDdpQShxirA07ikNrKdG2deQ6s",
        }}
        draggable={draggable}
        defaultCenter={defaultCenter}
        center={coords}
        defaultZoom={15}
        zoom={zoom}
        onChildMouseDown={onCircleInteraction}
        onChildMouseMove={onCircleInteraction}
        onChildMouseUp={handleChildInteraction}
      >
        {children ? (
          children
        ) : (
          <Marker
            key={coords.lat}
            lat={coords.lat}
            lng={coords.lng}
            text={text}
          >
            {Mark}
          </Marker>
        )}
      </GoogleMapReact>
    </Suspense>
  );
};

export default Map;
