import {
    HEADER_SELECTED,
    IS_LOGIN_OPEN,
    LOGIN_SECTION,
} from '../actions/header';

const headerState = (
    state = {
        selected: 'inicio',
        isLoginopen: false,
        loginSection: '',
        eventId: ''
    },
    action
) => {
    switch (action.type) {
        case HEADER_SELECTED:
            // console.log(action.eventInfo)
            return {
                ...state,
                selected: action.selected,
                eventId: action.eventInfo
            }
        case IS_LOGIN_OPEN:
            return {
                ...state,
                isLoginopen: action.isLoginopen
            }
        case LOGIN_SECTION:
            return {
                ...state,
                loginSection: action.loginSection
            }
        default:
            return state;
    }
}

export default headerState;