import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

//styles
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getAllPlayers, defaultUpdatePlayer } from "../../actions/jugador";
import { getAllNewRanking } from "../../actions/ranking";
import Title from '../ui/Title';
import TabsHeader from "../ui/TabsHeader";

//Components
import MatchAdmin from "./MatchAdmin";
import RenderRank from "./RenderRank";
import TableRank from "./TableRank";
import TableMobileRank from "./TableMobileRank";
import ModalEdit from "./ModalEdit";

//Json
import TypeRanking from "../../costants/ranking/TypeRanking.json";

function mapStateToProps(state) {
    return {
        users: state.jugador.players,
        ranking: state.ranking.ranking,
        jugador: state.jugador,
        playersFetched: state.jugador.playersFetched,
        info: state.auth.info
    }
}

const Ranking = (props) => {
    const navigate = useNavigate();
    const { users = [], info } = props;
    const { playerUpdated } = props.jugador;
    const [filterUsers, setFilterUser] = useState('');
    const [filterCountries, setFilterCountries] = useState('');
    const [filterForCategory, setFilterCategoria] = useState('');
    const [filterType, setFilterType] = useState('-MzWVRTdWFjFvJoWQcXL');
    const dispatch = useDispatch();
    const mobile = useMediaQuery("(max-width: 420px)");
    const [selectOption, setSelectOption] = useState('Ranking')
    const menu = ['Match', "Ranking"];
    const { tipo_usuario } = info
    const [showEditRank, setShowEditRank] = useState(false);
    const [selectedUser, setSelectedUser] = useState(false);
    const [, setLoadingSuccess] = useState(false);
    const [rankingTenisUp, setRankingTenisUp] = useState([])
    const [rankingAsociacion, setRankingAsociacion] = useState([])

    useEffect(() => {
        dispatch(getAllPlayers())
        dispatch(getAllNewRanking())
    }, [dispatch])

    useEffect(() => {
        setRankingTenisUp(props.ranking?.filter((r) => r?.tipoRanking === "-MzWVRTdWFjFvJoWQcXL") || [])
        setRankingAsociacion(props.ranking?.filter((r) => r?.tipoRanking === "-MzWVgm4M8WokUfSDeK_"))
    }, [props.ranking])

    useEffect(() => {
        if (playerUpdated) {
            setLoadingSuccess(true);
            setTimeout(() => {
                setLoadingSuccess(false);
                dispatch(getAllPlayers());
                dispatch(defaultUpdatePlayer());
            }, 2500);
        }
    }, [dispatch, playerUpdated]);

    const usersFiltered = useMemo(() => {
        let userRanking = []
        users?.filter((usuario) => usuario?.settings).map((user) => {
            let rankingTenis = rankingTenisUp?.find(f => f.uidPlayer === user.uid)
            let rankingAso = rankingAsociacion?.find(f => f.uidPlayer === user.uid)
            return userRanking.push({
                id: user?.uid,
                nombre: user?.settings?.nombre,
                pais: user?.settings?.nacionalidad,
                edad: user?.settings?.fechaNacimiento,
                categoria: user?.settings?.categoria,
                posicionTenisUp: rankingTenis?.position,
                posicionAsociacion: rankingAso?.position,
                puntajeTenisUp: rankingTenis?.puntaje === undefined ? 0 : rankingTenis?.puntaje,
                tipoRankingTenisUp: rankingTenis?.tipoRanking,
                puntajeAsociacion: rankingAso?.puntaje === undefined ? 0 : rankingAso?.puntaje,
                tipoRankingAsociacion: rankingAso?.tipoRanking,
                region: user?.region || user?.preferencias?.region,
                imagen: user.settings.imagen,
                imagen_100x100: user.settings.imagen_100x100,
                imagen_200x200: user.settings.imagen_200x200,
                imagen_400x400: user.settings.imagen_400x400,
                imagen_800x800: user.settings.imagen_800x800,
            })
        })
        return userRanking || []
    }, [users, rankingTenisUp, rankingAsociacion])

    const filter = useMemo(() => {
        const nameFilter = filterUsers?.toLowerCase() || "";
        return usersFiltered.filter(({ nombre, pais, categoria, region }) => {
            return (!filterCountries || pais === filterCountries) &&
                (!filterForCategory || categoria === filterForCategory) &&
                (!nameFilter || nombre?.toLowerCase()?.includes(nameFilter)) &&
                ((filterType === "-MzWVgm4M8WokUfSDeK_" && region === "Región de Coquimbo" && pais === "CHL")  || filterType !== "-MzWVgm4M8WokUfSDeK_")
        })
            .sort((a, b) => {
                if (filterType === "-MzWVRTdWFjFvJoWQcXL") {
                    if (a.puntajeTenisUp < b.puntajeTenisUp) return 1
                    if (a.puntajeTenisUp > b.puntajeTenisUp) return -1
                } else if (filterType === "-MzWVgm4M8WokUfSDeK_") {
                    if (a.puntajeAsociacion < b.puntajeAsociacion) return 1
                    if (a.puntajeAsociacion > b.puntajeAsociacion) return -1
                }
                return 0
            })
    }, [usersFiltered, filterCountries, filterForCategory, filterUsers, filterType])

    function goBack() {
        navigate("/explore");
    }

    // function getEdad(edad) {
    //     var nacimiento = getFecha(edad);
    //     var ahora = new Date();
    //     var birthday = new Date(ahora.getFullYear(), nacimiento.getMonth(), nacimiento.getDate());

    //     if (ahora >= birthday) return ahora.getFullYear() - nacimiento.getFullYear();
    //     else return ahora.getFullYear() - nacimiento.getFullYear() - 1;
    // }

    // function getFecha(fecha) {
    //     var datosFecha = fecha.split("/");
    //     var objFecha = new Date(datosFecha[2], datosFecha[1] - 1, datosFecha[0]);
    //     return objFecha;
    // }

    const rankTable = useMemo(() => {
        if (mobile) {
            return (
                <div
                    style={{
                        padding: "20px 20px",
                        margin: 0,
                    }}
                    className="LargeCard LargeCard-items"
                >
                    {filter?.map((user) => (
                        <TableMobileRank
                            filterType={filterType}
                            player={user}
                            container={false}
                            tipo_usuario={tipo_usuario}
                            setShowEditRank={setShowEditRank}
                            setSelectedUser={setSelectedUser}
                        />
                    ))}
                </div>
            )
        } else {
            return (
                <TableRank
                    mobile={mobile}
                    filterUsers={filterUsers}
                    setFilterUser={setFilterUser}
                    filterCountries={filterCountries}
                    setFilterCountries={setFilterCountries}
                    filterForCategory={filterForCategory}
                    setFilterCategoria={setFilterCategoria}
                    filterType={filterType}
                    setFilterType={setFilterType}
                    TypeRanking={TypeRanking}
                    tipo_usuario={tipo_usuario}
                    filter={filter}
                    setShowEditRank={setShowEditRank}
                    setSelectedUser={setSelectedUser}
                />
            )
        }
    }, [filter, filterCountries, filterForCategory, filterType, filterUsers, mobile, tipo_usuario])

    const renderOptions = useMemo(() => {
        let options = []
        const menuOptions = {
            Match:
                <MatchAdmin
                    users={users}
                    mobile={mobile}
                />,
            Ranking:
                <RenderRank
                    mobile={mobile}
                    filterUsers={filterUsers}
                    setFilterUser={setFilterUser}
                    filterCountries={filterCountries}
                    setFilterCountries={setFilterCountries}
                    filterForCategory={filterForCategory}
                    setFilterCategoria={setFilterCategoria}
                    filterType={filterType}
                    setFilterType={setFilterType}
                    TypeRanking={TypeRanking}
                    renderTable={rankTable}
                />
        }
        options = menuOptions[selectOption]
        return options
    }, [filterCountries, filterForCategory, filterType, filterUsers, mobile, selectOption, users, rankTable])

    const modalUsers = () => {
        return (
            <Modal
                isOpen={showEditRank}
                style={!mobile ? customStylesTenisUp : ModalMobile}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => setShowEditRank(false)}
            >
                <ModalEdit
                    selectedUser={selectedUser}
                    filterType={filterType}
                    setShowEditRank={setShowEditRank}
                    mobile={mobile}
                />
            </Modal>
        );
    }

    return (
        <div
            className="main-container-activado"
            style={{ backgroundColor: "white", overflow: "hidden", paddingRight: 0 }}
        >
            <div
                style={{
                    paddingInline: mobile && 12,
                    width: "100%",
                }}
            >
                <Title
                    goBack={goBack}
                    title="Ranking Tenis Up"
                    containerStyles={{
                        fontSize: 32,
                        zIndex: mobile && 10,
                        backgroundColor: "white",
                        height: mobile ? 70 : 60,
                        position: mobile && "sticky",
                        top: mobile && 0,
                        paddingBottom: mobile && 10,
                    }}
                />
            </div>
            <div className="container-upload">
                <div className="container-selectOption">
                    <TabsHeader menuOptions={tipo_usuario !== "admin" ? 'Ranking' : menu} onChange={setSelectOption} value={selectOption} />
                </div>
                {renderOptions}
                {modalUsers()}
            </div>
        </div>
    );
}

//Estilos Modal
const customStylesTenisUp = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "none",
        width: "25%",
        overflow: 'hidden'
    }, overlay: {
        backgroundColor: "rgba(0,0,0,0.75)",
        zIndex: 100,
    },
};

const ModalMobile = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        border: "none",
        width: "100vw",
        height: "100%",
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    overlay: {
        backgroundColor: "rgba(0,0,0,0.75)",
        zIndex: 12,
    },
};



export default connect(mapStateToProps)(Ranking);