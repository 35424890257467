import { Suspense, useEffect } from "react";
//redux
import { connect, useDispatch } from "react-redux";

//components
import Dashboard from "../components/dashboard/Dashboard";
import "../../src/styles/Loading.css";
//styles
import { Outlet, useLocation } from "react-router-dom";

import Loading from "./Loading";

import { changeMenuSelected } from "../actions/menu";
import { useUser } from "../context/UserContext";
function mapStateToProps(state) {
  return {
    events: state.events.events,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    isRegistered: state.register.isRegistered,
    selected: state.menu.selected,
    isUpdatingClub: state.club.isUpdatingClub,
  };
}

const DashboardPage = () => {
  //Efecto que se ejecuta al inicio (como ComponentDidMount)
  const dispatch = useDispatch();
  const { user } = useUser();
  const { pathname } = useLocation();
  useEffect(() => {
    dispatch(changeMenuSelected(pathname?.replace("/", "")));
  }, [pathname, dispatch]);

  if (user) {
    return (
      <Dashboard
        component={
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        }
      />
    );
  } else return <Loading />;
};

export default connect(mapStateToProps)(DashboardPage);
