import {
  RESET_EVENTS,
  //Crear Evento
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAILURE,

  //Obtener todos los eventos
  GET_MY_EVENTS_REQUEST,
  GET_MY_EVENTS_SUCCESS,
  GET_MY_EVENTS_FAILURE,
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,

  //Obtener un Evento
  GET_SINGLE_EVENT_REQUEST,
  GET_SINGLE_EVENT_SUCCESS,
  GET_SINGLE_EVENT_FAILURE,
  CLEAR_SINGLE_EVENT,

  //Actualizar un Evento
  UPDATE_EVENT_DEFAULT,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  UPDATE_EVENT_REQUEST,

  //Eliminar un Evento
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
  GET_TRANSACTIONS_EVENTS_REQUEST,
  GET_TRANSACTIONS_EVENTS_SUCCESS,
  GET_TRANSACTIONS_EVENTS_FAILURE,

  //Obtener imagenes torneo
  GET_IMAGES_REQUEST,
  GET_IMAGES_SUCCESS,
  GET_IMAGES_FAILURE,

  //Eliminar imagen 
  DELETE_IMAGE_REQUEST,
  DELETE_IMAGE_SUCCESS,
  DELETE_IMAGE_FAILURE,
} from "../actions/events";

const eventsState = (
  state = {
    //creacion de eventos
    isCreatingEvent: false,
    eventCreated: false,
    createEventError: false,

    //Eliminar evento
    isDeleteEvent: false,
    eventDeleted: false,
    eventDeletedError: false,

    //obtencion de mis eventos
    isFetchingMyEvents: false,
    myEventsFetched: false,
    fetchMyEventsError: false,
    myEvents: [],

    //obtencion de eventos
    isFetchingEvents: false,
    eventsFetched: false,
    fetchEventsError: false,
    events: [],

    //Obtencion de 1 solo evento
    isFetchingSingleEvent: false,
    singleEventFetched: false,
    fetchSingleEventError: false,
    event: {},

    //Editando un evento
    isUpdatingEvent: false,
    updateEventError: false,
    eventUpdated: false,

    //transacciones de eventos
    isFetchingTransactionsEvent: false,
    eventsTransactionsFetched: false,
    fetchEventsTransactionsError: false,
    eventsTransactions: [],

    //Obtener imagenes torneo
    isFetchingImagesEvent: false,
    eventsImagesFetched: false,
    fetchEventsImageError: false,
  },
  action
) => {
  switch (action.type) {
    case RESET_EVENTS:
      return {
        eventCreated: false,
        eventUpdated: false,
        isUpdatingEvent: false,
        updateEventError: false,
        isCreatingEvent: false,
        createEventError: false,
        event: {},
      };
    //imagenes torneo
    case GET_IMAGES_REQUEST:
      return {
        ...state,
        isFetchingImagesEvents: true,
        fetchEventsImagesError: false,
      };
    case GET_IMAGES_SUCCESS:
      return {
        ...state,
        isFetchingImagesEvents: false,
        eventsImagesFetched: true,
      };
    case GET_IMAGES_FAILURE:
      return {
        ...state,
        isFetchingImagesEvents: false,
        fetchImagesEventsError: true,
      };

    //Eliminar Imagen del torneo
    case DELETE_IMAGE_REQUEST:
      return {
        ...state,
        isDeletingImage: true,
        createImageError: false,
      };
    case DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        isDeletingImage: false,
        deleteImageError: false,
        imageDeleted: true,
      };
    case DELETE_IMAGE_FAILURE:
      return {
        ...state,
        isCreatingImage: false,
        imageDeletedError: true,
      };

    //transacciones de eventos
    case GET_TRANSACTIONS_EVENTS_REQUEST:
      return {
        ...state,
        isFetchingTransactionsEvent: true,
        eventsTransactionsFetched: false,
        fetchEventsTransactionsError: false,
      };
    case GET_TRANSACTIONS_EVENTS_SUCCESS:
      return {
        ...state,
        isFetchingTransactionsEvent: false,
        eventsTransactionsFetched: true,
        fetchEventsTransactionsError: false,
        eventsTransactions: action.events,
      };
    case GET_TRANSACTIONS_EVENTS_FAILURE:
      return {
        ...state,
        isFetchingTransactionsEvent: false,
        eventsTransactionsFetched: false,
        fetchEventsTransactionsError: true,
      };

    //Crear de eventos
    case CREATE_EVENT_REQUEST:
      return {
        ...state,
        isCreatingEvent: true,
        createEventError: false,
      };
    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        isCreatingEvent: false,
        createEventError: false,
        eventCreated: true,
      };
    case CREATE_EVENT_FAILURE:
      return {
        ...state,
        isCreatingEvent: false,
        createEventError: true,
      };

    //Eliminar Evento
    case DELETE_EVENT_REQUEST:
      return {
        ...state,
        isDeletingEvent: true,
        createEventError: false,
      };
    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        isDeletingEvent: false,
        deleteEventError: false,
        eventDeleted: true,
      };
    case DELETE_EVENT_FAILURE:
      return {
        ...state,
        isCreatingEvent: false,
        eventDeletedError: true,
      };

    //Obtencion de mis eventos
    case GET_MY_EVENTS_REQUEST:
      return {
        ...state,
        isFetchingMyEvents: true,
        fetchMyEventsError: false,
      };
    case GET_MY_EVENTS_SUCCESS:
      return {
        ...state,
        isFetchingMyEvents: false,
        myEventsFetched: true,
        myEvents: action.events,
      };
    case GET_MY_EVENTS_FAILURE:
      return {
        ...state,
        isFetchingMyEvents: false,
        fetchMyEventsError: true,
      };

    //Obtencion de todos los eventos
    case GET_EVENTS_REQUEST:
      return {
        ...state,
        isFetchingEvents: true,
        fetchEventsError: false,
      };
    case GET_EVENTS_SUCCESS:
      action.events = action.events.map((event) => {
        return {
          ...event,
          actionType: "event",
        };
      });
      return {
        ...state,
        isFetchingEvents: false,
        eventsFetched: true,
        events: action.events,
      };
    case GET_EVENTS_FAILURE:
      return {
        ...state,
        isFetchingEvents: false,
        fetchEventsError: true,
      };

    //Obtencion de un solo evento
    case GET_SINGLE_EVENT_REQUEST:
      return {
        ...state,
        isFetchingSingleEvent: true,
        fetchSingleEventError: false,
      };
    case GET_SINGLE_EVENT_SUCCESS:
      return {
        ...state,
        isFetchingSingleEvent: false,
        singleEventFetched: true,
        event: action.event,
      };
    case GET_SINGLE_EVENT_FAILURE:
      return {
        ...state,
        isFetchingSingleEvent: false,
        singleEventFetched: false,
        fetchSingleEventError: true,
        event: {},
      };
    case CLEAR_SINGLE_EVENT:
      return {
        ...state,
        isFetchingSingleEvent: false,
        singleEventFetched: false,
        fetchSingleEventError: false,
        event: {},
      };

    //Editar un evento
    case UPDATE_EVENT_DEFAULT:
      return {
        ...state,
        isUpdatingEvent: false,
        eventUpdated: false,
      };

    case UPDATE_EVENT_REQUEST:
      return {
        ...state,
        isFetchingEvents: true,
        fetchEventsError: false,
        eventUpdated: false,
      };
    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        isUpdatingEvent: false,
        updateEventError: false,
        eventUpdated: true,
      };
    case UPDATE_EVENT_FAILURE:
      return {
        ...state,
        isUpdatingEvent: false,
        updateEventError: true,
      };
    default:
      return state;
  }
};

export default eventsState;
