import { useState } from "react";
import { useNavigate } from "react-router-dom";

//Actions
import { recoverPassword } from "../actions/auth";

//Redux
import { connect } from "react-redux";

import useMediaQuery from '@material-ui/core/useMediaQuery';

import {  FaArrowCircleLeft } from "react-icons/fa";

//Card
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

//image
import logo from '../assets/logo-blanco.png';

//Styles
import "../styles/RecuperarContraseña.css";


//Alerta
import swal from "@sweetalert/with-react"


function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated,
    registerClubError: state.club.registerClubError,
    errorRegister: state.club.error,
    errorLogin: state.auth.error
  };
}

const RecoverPassword = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const mobile = useMediaQuery('(max-width: 420px)');
  const navigate = useNavigate();

  function goBack() {
    navigate("/explore");
  }

  const alertInfo = () => {
    swal({
      title: "Se te envió un email a tu correo",
      text: "Ingresa al link que te enviamos para restablecer tu contraseña",
      icon: "success",
      button: {
        text: "OK",
        value: "done"
      }
    })
      .then((value) => {
        switch (value) {
          case "done":
            window.location = "/";
            break
          default: break;
        }
      })
  }

  const disabledButton = () => {
    if (email === "") {
      return false;
    } else {
      return true;
    }
  }

  var EmailReg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line

  const handleRecoverPassword = () => {
    const { dispatch } = props;
    dispatch(recoverPassword(email))
      .then(() => {
        alertInfo();
      })
      .catch(error => {
        if (error.message) {
          swal({
            title: "El correo ingresado no existe",
            text: "El correo ingresado no está registrado en nuestro sistema",
            icon: "error"
          })
        }
        if (error.message) {
          if (EmailReg.exec(email) === false || EmailReg.exec(email) === null) {
            swal({
              title: "El correo ingresado no es válido",
              text: "Se debe ingresar un formato de correo válido",
              icon: "warning"
            })
          }
        }
      })

  }

  return (
    <div className={classes.content}>
      <Card className={classes.root}>
        {mobile && (
          <div onClick={() => goBack()} className="button-back" style={{ zIndex: 0 }}>
            <FaArrowCircleLeft
              className="button-back-style"
            />
          </div>
        )}
        <CardMedia
          style={{ objectFit: "contain", backgroundColor: "#234591", height: 100 }}
          component="img"
          image={logo}
          alt="Contemplative Reptile"
          title="Contemplative Reptile"
        />

        <CardContent
          className={classes.cardcontent}
        >
          <Typography gutterBottom variant="h5" component="h2">
            Recuperar Contraseña
          </Typography>
          <Typography
            className={classes.cardtypography1}
            component="h2">
            Te enviaremos un email con instrucciones sobre cómo restablecer tu contraseña.
          </Typography>
          <Typography
            className={classes.cardtypography2}
            component="h2">
            Escribe tu correo electrónico
          </Typography>
          <div className="restore-password-input" style={{ marginBottom: 10 }}>
            <input
              name="email"
              type="email"
              className="restore-password-intext"
              placeholder="nombre@ejemplo.com"
              onChange={(event) => setEmail(event.target.value)}
              value={email}>
            </input>
          </div>
        </CardContent>
        <CardActions>
          <button
            className="recover-button"
            style={!disabledButton() ? { opacity: 0.5, transform: 'none' } : { opacity: 1 }}
            onClick={() => handleRecoverPassword()}
            disabled={!disabledButton()}
          >
            Enviar un email</button>
        </CardActions>
      </Card>
    </div>
  )
}

export default connect(mapStateToProps)(RecoverPassword)

const useStyles = makeStyles({
  root: {
    width: "60%",
    boxShadow: "10px 10px 22px 6px #928D8D",
    height: "65%"
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "-webkit-center",
    backgroundColor: "#f5f6fa",
    height: "100vh"
  },
  cardcontent: {
    height: "60%",
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
    alignItems: "center"
  },
  cardtypography1: {
    width: "50%"
  },
  cardtypography2: {
    marginTop: "30px"
  },
  "@media (max-width: 480px)": {
    root: {
      width: "100%",
      height: "100%",
      borderRadius: "0px"
    },
    cardcontent: {
      height: "75%",
      display: "flex",
      flexDirection: "column",
      placeContent: "center",
      alignItems: "center"
    },
    cardtypography1: {
      width: "100%",
      textAlign: "justify",
    },
    cardtypography2: {
      marginTop: "30px",
      marginBottom: "1rem",
      marginLeft: "10px",
      alignSelf: "flex-start"
    }
  },
  "@media (max-height: 568px) and (max-width: 320px)":{
    root: {
      width: "100%",
      height: "100%",
      borderRadius: "0px"
    },
    cardcontent: {
      height: "72%",
      display: "flex",
      flexDirection: "column",
      placeContent: "center",
      alignItems: "center",
      overflow: "auto"
    },
    cardtypography1: {
      width: "100%",
      textAlign: "justify",
    },
    cardtypography2: {
      marginTop: "30px",
      marginBottom: "1rem",
      marginLeft: "10px",
      alignSelf: "flex-start"
    }
  }

  
})
  ;