import { useEffect, useState } from "react";
import { MdPlace } from "react-icons/md";
const defaultLat = -29.93225;
const defaultLng = -71.246173;
const Marker = ({
  lat = defaultLat,
  lng = defaultLng,
  text = "Mi marcador",
  children,
}) => {
  const [coords, setCoords] = useState({ lat: defaultLat, lng: defaultLng });
  useEffect(() => {
    setCoords({
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    });
  }, [lat, lng]);
  return (
    <div
      lat={coords.lat}
      lng={coords.lng}
      text={text}
      style={{
        width: "fit-content",
        height: "fit-content",
        transform: "translate(-50%, -50%)",
      }}
    >
      {children ? children : <MdPlace size={48} color="#d43535" />}
    </div>
  );
};

export default Marker;
