function Padel({ size, color="#fff" }) {
  const style = { fill: color }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.74 67.74" width={size || 25}>
      <polygon
        style={style}
        points="17.67 28.99 19.68 47.57 17.23 53.28 14.89 52.28 11.23 60.84 17.73 63.62 25.61 45.2 23.29 44.26 22.1 34.55 17.67 28.99"
      />
      <polygon
        style={style}
        points="42.83 39.75 28.01 51.13 25.57 56.84 27.92 57.85 24.26 66.41 16.83 63.23 24.7 44.81 27.91 46.23 35.75 40.38 42.83 39.75"
      />
      <path
        style={style}
        d="M43.3,4.06C33.69-.06,24.46-.64,19.71,10.47c-3,7-3.15,16.89-.95,23,1.4,3.9,5.53,6.34,8.68,7.69,3.93,1.68,8.57,2.27,12.77.37C46.29,38.79,51.71,32,54.8,25.37,59.91,14.43,52.92,8.17,43.3,4.06ZM27,27.56,23.33,26l1.59-3.71,3.7,1.59ZM30,20.72l-3.7-1.58,1.58-3.71L31.55,17Zm3-6.81-3.7-1.58,1.58-3.71,3.71,1.59Zm1,16.62-3.7-1.59,1.58-3.7,3.71,1.58Zm2.93-6.84L33.19,22.1l1.59-3.7L38.48,20Zm3-6.81-3.71-1.59,1.59-3.7,3.7,1.58Zm1.16,16.68L37.35,32l1.59-3.7,3.7,1.58ZM44,26.72l-3.7-1.59,1.58-3.7L45.57,23Zm3-6.81-3.7-1.59,1.58-3.7,3.71,1.58Z"
      />
    </svg>
  )
}

export default Padel
