import { useCallback, useMemo, useState } from "react";
import { IoAddCircle } from "react-icons/io5";
import { MdSettings } from "react-icons/md";
import { cuposTorneo } from "../../costants/courtOptions";
import Delete from "../../icons/Delete";
import { getTotalQuotes } from "../../services/Utils";
import Input from "../ui/Input";
import Select from "../ui/Select";
import Check from "../ui/Check";
import { SportcloseContainer } from "./styles/SportSelectModalStyle";
import { BsFilter } from "react-icons/bs";
import { colors } from "../../costants/colors";
import RangeInput from "../ui/RangeInput";
import "../../styles/CrearTorneo.css";
import { useMediaQuery } from "@material-ui/core";

const TenisCategory = [
  { name: "1°", label: "1° (Primera)" },
  { name: "2°", label: "2° (Segunda)" },
  { name: "3°", label: "3° (Tercera)" },
  { name: "4°", label: "4° (Cuarta)" },
  { name: "Novicio", label: "Novicio" },
  { name: "Honor", label: "Honor" },
  { name: "Escalafón", label: "Escalafón" },
  { name: "Abierta", label: "Abierta" },
];
const PadelCategory = [];
const ModalCupos = ({ tipo, close, cupos, setCupos }) => {
  const getTotalQuotas = useCallback(() => getTotalQuotes(cupos), [cupos]);
  const mobile = useMediaQuery("(max-width: 475px)");
  const category = useMemo(() => {
    if (tipo === "padel") return PadelCategory;
    return TenisCategory;
  }, [tipo]);
  const setStatesVacioQuotas = () => {
    const TempCupos = {};
    Object.keys(cupos).forEach((c) => (TempCupos[c] = ""));
    setCupos(TempCupos);
  };
  const [newCategory, setNewCategory] = useState({ name: "", cupos: "" });
  const [showSettings, setShowSettings] = useState(-1);
  const [dynamicCategory, setDynamicCategory] = useState([
    ...category,
    ...Object.keys(cupos)
      .map((c) => ({ name: c, label: c }))
      .filter((c) => !category.find((cc) => cc.name === c.name)),
  ]);
  const className = "content-form scroll content-form-" + tipo;

  const RemoveCategory = (i = 0, name) => {
    if (i < dynamicCategory.length)
      setDynamicCategory((data) => {
        data.splice(i, 1);
        return [...data];
      });
    if (cupos.hasOwnProperty(name)) {
      delete cupos[name];
      setCupos({ ...cupos });
    }
    setShowSettings(-1);
  };
  const setCuposIncategory = ({ target: { name, value } }, param) => {
    const newCupos = { ...cupos };
    if (!newCupos[name]) newCupos[name] = {};
    newCupos[name][param || "cupos"] = value;
    setCupos(newCupos);
  };
  const setEdadIncategory = ([min, max], name, param) => {
    const newCupos = { ...cupos };
    if (!newCupos[name]) newCupos[name] = {};
    newCupos[name][param || "cupos"] = `${min}-${max}`;
    setCupos(newCupos);
  };
  const AddCategory = ({ name, cupos = "" }) => {
    if (name && newCategory.name.toLowerCase() !== "total") {
      setDynamicCategory((data) => [...data, { name, label: name }]);
      setNewCategory({ name: "", cupos: "" });
      setCupos((data) => ({ ...data, [name]: { cupos } }));
    }
  };
  return (
    <div className="form-ModalCupos">
      <div className="title-form-cupos" style={{ top: 0, backgroundColor: "white" }}>
        <h1>Elige la cantidad de cupos para cada categoría</h1>
      </div>
      <SportcloseContainer style={{ padding: 4, backgroundColor: colors.Verde.TenisCTA }}>
        <BsFilter style={{ cursor: "pointer" }} size={25} />
      </SportcloseContainer>
      <div className={className}>
        {(tipo === "padel" ? dynamicCategory : category).map(({ label, name }, i) => (
          <div key={i} style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexFlow: mobile && "wrap",
                justifyContent: "flex-end",
                position: "relative",
              }}
            >
              <Select
                label={label}
                name={name}
                onChange={({ value }) =>
                  setCuposIncategory({ target: { name: name, value: value } })
                }
                value={cupos[name]?.cupos || ""}
                placeholder="Seleccione la cantidad de cupos"
                options={cuposTorneo}
              />
              {tipo === "padel" && (
                <div style={{ position: "absolute", top: 0, right: 7 }}>
                  <Delete onClick={() => RemoveCategory(i, name)} style={{ cursor: "pointer" }} />
                  <MdSettings
                    onClick={() => setShowSettings((val) => (i === val ? -1 : i))}
                    style={{ cursor: "pointer" }}
                    size={20}
                    color={colors.Verde.hover}
                  />
                </div>
              )}
            </div>
            {showSettings === i && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  gap: !mobile && 20,
                  flexDirection: mobile && "column",
                }}
              >
                <div>
                  <label className="custom-input-label">Sexo</label>
                  <div style={{ display: "flex", gap: 12 }}>
                    <Check
                      size="medium"
                      square
                      label="Hombre"
                      value="hombre"
                      name={name}
                      type="radio"
                      checked={cupos[name]?.genero === "hombre"}
                      onChange={(e) => setCuposIncategory(e, "genero")}
                    />
                    <Check
                      size="medium"
                      square
                      label="Mujer"
                      value="mujer"
                      name={name}
                      type="radio"
                      checked={cupos[name]?.genero === "mujer"}
                      onChange={(e) => setCuposIncategory(e, "genero")}
                    />
                    <Check
                      size="medium"
                      square
                      label="Todos"
                      value="all"
                      name={name}
                      type="radio"
                      checked={!cupos[name]?.genero || cupos[name]?.genero === "all"}
                      onChange={(e) => setCuposIncategory(e, "genero")}
                    />
                  </div>
                </div>
                <div style={{ width: "100%", maxWidth: 300 }}>
                  <RangeInput
                    onChange={(value) => setEdadIncategory(value, name, "edad")}
                    label="Edad"
                    valueMin={cupos[name]?.edad?.split("-")?.[0]}
                    valueMax={cupos[name]?.edad?.split("-")?.[1]}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
        {tipo === "padel" && (
          <div style={{ marginTop: 5, position: "relative", width: "100%" }}>
            <div
              style={{
                position: "absolute",
                boxShadow: "0 -7px 0px 0.5px #e1e1e1ab",
                width: "100%",
              }}
            />
          </div>
        )}
        {tipo === "padel" && (
          <div className="padel-list">
            <Input
              placeholder="Nueva categoría"
              name="newCategory"
              value={newCategory.name}
              onChange={({ target }) =>
                setNewCategory((value) => ({ ...value, name: target.value }))
              }
              error={newCategory.name.toLowerCase() === "total" && "Nombre no permitido"}
            />
            <div>
              <Select
                name={newCategory}
                onChange={({ value: newV }) =>
                  setNewCategory((value) => ({ ...value, cupos: newV }))
                }
                value={newCategory.cupos}
                options={cuposTorneo}
                placeholder="Seleccione la cantidad de cupos"
              />
            </div>
            <IoAddCircle
              size={25}
              onClick={() => AddCategory(newCategory)}
              color={colors.Verde.hover}
              style={{ placeSelf: mobile && "flex-end" }}
            />
          </div>
        )}
        <div className="form-column-1">
          <label className="form-content-label">Total:</label>
          <div style={{ alignSelf: "center" }}> {getTotalQuotas()} Cupos</div>
        </div>
      </div>
      {tipo === "padel" ? (
        <div
          className="torneo-btn_form torneo-btn_form-no-grid-colum torneo-event-preferencias"
          style={{ bottom: 15, backgroundColor: "white" }}
        >
          <button className="torneo-cancelar-event-boton" onClick={() => setStatesVacioQuotas()}>
            Limpiar
          </button>
          <button
            className="torneo-crear-event-boton"
            onClick={() => close()}
            style={{
              background: "rgb(189, 218, 43)",
            }}
          >
            Añadir Cupos
          </button>
        </div>
      ) : (
        <div
          className="torneo-btn_form_tennis torneo-btn_form-no-grid-colum torneo-event-preferencias"
          style={{ bottom: 15, backgroundColor: "white", marginTop: "15px" }}
        >
          <button className="torneo-cancelar-event-boton" onClick={() => setStatesVacioQuotas()}>
            Limpiar
          </button>
          <button
            className="torneo-crear-event-boton"
            onClick={() => close()}
            style={{
              background: "rgb(189, 218, 43)",
            }}
          >
            Añadir Cupos
          </button>
        </div>
      )}
    </div>
  );
};

export default ModalCupos;
