import { useState } from "react"
import ModalVideo from 'react-modal-video'

//Styles
import 'react-modal-video/scss/modal-video.scss';
import "../styles/Videos.css"

//Icono
import { AiFillPlayCircle } from "react-icons/ai";

//Images
import foto from "../assets/sponsor/comunidad.png"
import foto1 from "../assets/Videos/foto1.jpg"
import foto2 from "../assets/Videos/foto2.jpg"
import foto3 from "../assets/Videos/foto3.jpg"
import foto4 from "../assets/Videos/foto4.jpg"
import foto5 from "../assets/Videos/foto5.jpg"
import foto6 from "../assets/Videos/foto6.jpg"

const VideosForm = () => {
    const [isOpen, setOpen] = useState()

    const ModalVideos = () => {
        switch (isOpen) {
            case "creaciontorneo":
                return (
                    <ModalVideo
                        autoplay
                        channel='youtube'
                        isOpen={isOpen}
                        videoId="2p6yFXKH3rM"
                        onClose={() => setOpen(false)}
                    />
                )
            case "inscripciontorneo":
                return (
                    <ModalVideo
                        autoplay
                        channel='youtube'
                        isOpen={isOpen}
                        videoId="cTWY6gDV_d0"
                        onClose={() => setOpen(false)}
                    />
                )
            case "web":
                return (
                    <ModalVideo
                        autoplay
                        channel='youtube'
                        isOpen={isOpen}
                        videoId="jTBnT3eeBY8"
                        onClose={() => setOpen(false)}
                    />
                )
            case "configperfilApp":
                return (
                    <ModalVideo
                        autoplay
                        channel='youtube'
                        isOpen={isOpen}
                        videoId="q4KkRd9VkoE"
                        onClose={() => setOpen(false)}
                    />
                )
            case "mapaApp":
                return (
                    <ModalVideo
                        autoplay
                        channel='youtube'
                        isOpen={isOpen}
                        videoId="Go75AU4ePUo"
                        onClose={() => setOpen(false)}
                    />
                )
            case "matchApp":
                return (
                    <ModalVideo
                        autoplay
                        channel='youtube'
                        isOpen={isOpen}
                        videoId="Ry0RLvNxYiI"
                        onClose={() => setOpen(false)}
                    />
                )
            case "idiomaApp":
                return (
                    <ModalVideo
                        autoplay
                        channel='youtube'
                        isOpen={isOpen}
                        videoId="Jyl2DB4q0Ik"
                        onClose={() => setOpen(false)}
                    />
                );
            default:
            break;
        }
    }


    return (
        <div>
            <div style={{ marginTop: 20 }} className="videos-web-title">
                <h3>Tutoriales Web</h3>
            </div>
            <div className="container">
                <div className="card">
                    <img className="img-card" alt="foto-torneo" src={foto2} onClick={() => setOpen("creaciontorneo")} />
                    <div
                        style={{
                            position: "absolute", top: 114,
                            left: 112,
                            zIndex: 1
                        }}
                    >
                        <AiFillPlayCircle
                            color="white"
                            size='100' cursor="pointer"
                            onClick={() => setOpen("creaciontorneo")}
                        />
                    </div>
                    <h4 className="titulo-card-video">Creacion de torneo</h4>
                    <p className="parrafo-video">Aprende como se realizan la creación de los torneos.</p>
                </div>
                <div className="card">
                    <img className="img-card" alt="foto-web" src={foto6} onClick={() => setOpen("web")} />
                    <div
                        style={{
                            position: "absolute", top: 114,
                            left: 112,
                            zIndex: 1
                        }}
                    >
                        <AiFillPlayCircle
                            color="white"
                            size='100' cursor="pointer"
                            onClick={() => setOpen("web")}
                        />
                    </div>
                    <h4 className="titulo-card-video">Tutorial web</h4>
                    <p className="parrafo-video">Revisa un tutorial completo de nuestra pagina web.</p>
                </div>
                <div className="card">
                    <img className="img-card" alt="foto-inscripcion" src={foto4} onClick={() => setOpen("inscripciontorneo")} />
                    <div
                        style={{
                            position: "absolute", top: 114,
                            left: 112,
                            zIndex: 1
                        }}
                    >
                        <AiFillPlayCircle
                            color="white"
                            size='100' cursor="pointer"
                            onClick={() => setOpen("inscripciontorneo")}
                        />
                    </div>
                    <h4 className="titulo-card-video">Inscripcion a torneo</h4>
                    <p className="parrafo-video">Aca te enseñamos a inscribirte a los torneo!</p>
                </div>
                <div className="videos-web-title">
                    <h3>Tutoriales APP</h3>
                </div>
                <div className="container">
                    <div className="card">
                        <img className="img-card" alt="foto-configurar-perfil" src={foto1} onClick={() => setOpen("configperfilApp")} />
                        <div
                            style={{
                                position: "absolute", top: 114,
                                left: 112,
                                zIndex: 1
                            }}
                        >
                            <AiFillPlayCircle
                                color="white"
                                size='100' cursor="pointer"
                                onClick={() => setOpen("configperfilApp")}
                            />
                        </div>
                        <h4 className="titulo-card-video">Configuracion de perfil</h4>
                        <p className="parrafo-video">Configura tu perfil en la aplicación.</p>
                    </div>
                    <div className="card">
                        <img className="img-card" alt="foto-mapa-app" src={foto5} onClick={() => setOpen("mapaApp")} />
                        <div
                            style={{
                                position: "absolute", top: 114,
                                left: 112,
                                zIndex: 1
                            }}
                        >
                            <AiFillPlayCircle
                                color="white"
                                size='100' cursor="pointer"
                                onClick={() => setOpen("mapaApp")}
                            />
                        </div>
                        <h4 className="titulo-card-video">Mapa tenis-up</h4>
                        <p className="parrafo-video">Revisa el mapa de torneo, canchas y clubes en la aplicación.</p>
                    </div>
                    <div className="card">
                        <img className="img-card" alt="foto-match-app" src={foto} onClick={() => setOpen("matchApp")} />
                        <div
                            style={{
                                position: "absolute", top: 114,
                                left: 112,
                                zIndex: 1
                            }}
                        >
                            <AiFillPlayCircle
                                color="white"
                                size='100' cursor="pointer"
                                onClick={() => setOpen("matchApp")}
                            />
                        </div>
                        <h4 className="titulo-card-video">Match app</h4>
                        <p className="parrafo-video">Consigue hacer match en nuestra aplicación</p>
                    </div>
                    <div className="card">
                        <img className="img-card" alt="foto-idioma-app" src={foto3} onClick={() => setOpen("idiomaApp")} />
                        <div
                            style={{
                                position: "absolute", top: 114,
                                left: 112,
                                zIndex: 1
                            }}
                        >
                            <AiFillPlayCircle
                                color="white"
                                size='100' cursor="pointer"
                                onClick={() => setOpen("idiomaApp")}
                            />
                        </div>
                        <h4 className="titulo-card-video">Idiomas disponibles</h4>
                        <p className="parrafo-video">Revisa los idiomas disponibles en nuestra aplicación movil.</p>
                    </div>
                </div>
            </div>
            {ModalVideos()}
        </div>
    )
}



export default VideosForm;