
import {
    GET_MENU_ACTIVE,
    GET_MENU_PASIVE,
    MENU_SELECTED,
} from '../actions/menu';

const menuState = (
    state = {
        mode: 'desactivado',
        selected: 'explore',
    },
    action
) => {
    switch (action.type) {
        case GET_MENU_ACTIVE:
            return {
                ...state,
                mode: 'activado',
            }
        case GET_MENU_PASIVE:
            return {
                ...state,
                mode: 'desactivado',
            }
        case MENU_SELECTED:
            return {
                ...state,
                selected: action.selected,
            }
        default:
            return state;
    }
}

export default menuState;