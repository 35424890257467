import { useState, useEffect } from "react";

const useContador = ({ isRuning, timeEnd = 2000, totalCount = 10000, interval = 50, show }) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let useContador = 0;

    setCounter(useContador);
    let totalSteps = timeEnd / interval;
    const incremento = totalCount / totalSteps;
    let InvervalInstance = null;
    if (isRuning) {
      InvervalInstance = setInterval(() => {
        useContador += incremento;
        setCounter(Math.ceil(useContador));

        if (useContador >= totalCount) {
          useContador = totalCount;
          clearInterval(InvervalInstance);
          setCounter(Math.ceil(useContador));
        }
      }, interval);
    }

    return () => {
      if (InvervalInstance) {
        clearInterval(InvervalInstance);
      }
    };
  }, [interval, timeEnd, totalCount, isRuning, show]);

  return {counter};
};

export default useContador;
