import { db } from "../firebase/firebase";

import {
  //Registrar Club
  REGISTER_CLUB_REQUEST,
  REGISTER_CLUB_SUCCESS,
  REGISTER_CLUB_FAILURE,

  //Crear Club
  CREATE_CLUB_REQUEST,
  CREATE_CLUB_SUCCESS,
  CREATE_CLUB_FAILURE,

  //Buscar Club
  GET_CLUBS_REQUEST,
  GET_CLUBS_SUCCESS,
  GET_CLUBS_FAILURE,

  //Buscar un Club
  GET_SINGLE_CLUB_REQUEST,
  GET_SINGLE_CLUB_SUCCESS,
  GET_SINGLE_CLUB_FAILURE,
  CLEAR_SINGLE_CLUB,

  //Eliminar Club
  DELETE_CLUB_REQUEST,
  DELETE_CLUB_SUCCESS,
  DELETE_CLUB_FAILURE,

  //Editar Club
  UPDATE_CLUB_DEFAULT,
  UPDATE_CLUB_REQUEST,
  UPDATE_CLUB_SUCCESS,
  UPDATE_CLUB_FAILURE,

  //Crear un servicio
  CREATE_SERVICE_REQUEST,
  CREATE_SERVICE_SUCCESS,
  CREATE_SERVICE_FAILURE,

  //Editar un servicio
  UPDATE_SERVICE_DEFAULT,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAILURE,

  //Eliminar Service
  DELETE_SERVICE_REQUEST,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAILURE,

  //Crear una cancha
  CREATE_COURT_REQUEST,
  CREATE_COURT_SUCCESS,
  CREATE_COURT_FAILURE,

  //Editar una cancha
  UPDATE_COURT_DEFAULT,
  UPDATE_COURT_REQUEST,
  UPDATE_COURT_SUCCESS,
  UPDATE_COURT_FAILURE,

  //Eliminar Cancha
  DELETE_COURT_REQUEST,
  DELETE_COURT_SUCCESS,
  DELETE_COURT_FAILURE,

  //Limpieza de estado
  CLEAR_COURT,
  CLEAR_SERVICE,

  //Obtener todas las canchas
  GET_COURTS_REQUEST,
  GET_COURTS_SUCCESS,
  GET_COURTS_FAILURE,

  //Obtener una cancha
  GET_SINGLE_COURT_REQUEST,
  GET_SINGLE_COURT_SUCCESS,
  GET_SINGLE_COURT_FAILURE,
  GET_SINGLE_COURT_DEFAULT,
  UPDATE_SINGLE_COURT_WEEK,
  // CANCEL_COURTRENT_REQUEST,
  // CANCEL_COURTRENT_SUCESS,
  // CANCEL_COURTRENT_FAILURE,
} from "../actions/clubs";

const ClubState = (
  state = {
    //Creación de Club
    isRegisteringClub: false,
    clubRegisted: false,
    registerClubError: false,

    //Creación de Club
    isCreatingClub: false,
    clubCreated: false,
    createClubError: false,

    //Obtener un Club
    isFetchingSingleClub: false,
    singleClubFetched: false,
    fetchSingleClubError: false,
    club: {},

    //Obtener todos Los Clubes
    isFetchingClubs: false,
    clubsFetched: false,
    fetchClubsError: false,
    clubs: [],

    //Editar un Club
    isUpdatingClub: false,
    updateClubError: false,
    clubUpdated: false,

    //Eliminar un club
    isDeletingClub: false,
    clubDeleted: false,
    deleteClubError: false,

    //Crear un servicio
    isCreatingService: false,
    serviceCreated: false,
    createServiceError: false,

    //Editar un servicio
    isUpdatingService: false,
    serviceUpdated: false,
    updateServiceError: false,

    //Eliminar un servicio
    isDeleteService: false,
    serviceDeleted: false,
    deleteServiceError: false,

    //Crear una cancha
    isCreatingCourt: false,
    courtCreated: false,
    createCourtError: false,

    //Editar una cancha
    isUpdatingCourt: false,
    updateCourtError: false,
    courtUpdated: false,

    //Eliminar una Cancha
    isDeleteCourt: false,
    courtDeleted: false,
    deleteCourtError: false,

    //Obtención de canchas
    isFetchingCourts: false,
    courtsFetched: false,
    fetchCourtsError: false,
    courts: [],

    //Obtención de 1 cancha
    isFetchingSingleCourt: false,
    singleCourtFetched: false,
    fetchSingleEventError: false,
    court: {},
  },
  action
) => {
  switch (action.type) {
    //Registro de Clubes
    case REGISTER_CLUB_REQUEST:
      return {
        ...state,
        isRegiseringClub: true,
        registerClubError: false,
      };
    case REGISTER_CLUB_SUCCESS:
      return {
        ...state,
        isRegisteringClub: false,
        clubRegistered: true,
        registerClubError: false,
      };
    case REGISTER_CLUB_FAILURE:
      return {
        ...state,
        isRegisteringClub: false,
        registerClubError: true,
        error: action.error,
      };

    //Creacion de Clubes
    case CREATE_CLUB_REQUEST:
      return {
        ...state,
        isCreatingClub: true,
        createClubError: false,
      };
    case CREATE_CLUB_SUCCESS:
      return {
        ...state,
        isCreatingClub: false,
        clubCreated: true,
        createClubError: false,
      };
    case CREATE_CLUB_FAILURE:
      return {
        ...state,
        clubCreated: false,
        createClubError: true,
      };

    //Busqueda de un Club
    case GET_SINGLE_CLUB_REQUEST:
      return {
        ...state,
        isFetchingSingleClub: true,
        singleClubFetched: false,
      };
    case GET_SINGLE_CLUB_SUCCESS:
      return {
        ...state,
        isFetchingSingleClub: false,
        singleClubFetched: true,
        club: action.club,
      };
    case GET_SINGLE_CLUB_FAILURE:
      return {
        ...state,
        isFetchingSingleClub: false,
        fetchSingleClubError: true,
      };

    //Busqueda de todos los Clubes
    case GET_CLUBS_REQUEST:
      return {
        ...state,
        isFetchingClubs: true,
        fetchClubError: false,
      };
    case GET_CLUBS_SUCCESS:
      let clubs = action.clubs?.map((club) => {
        return {
          ...club,
          actionType: "club",
        };
      });
      return {
        ...state,
        isFetchingClubs: false,
        clubsFetched: true,
        clubs: clubs,
      };
    case GET_CLUBS_FAILURE:
      return {
        ...state,
        clubsFetched: false,
        fetchClubsError: true,
      };

    //Actualizando un Club
    case UPDATE_CLUB_DEFAULT:
      return {
        ...state,
        isUpdatingClub: false,
        clubUpdated: false,
      };
    case UPDATE_CLUB_REQUEST:
      return {
        ...state,
        isUpdatingClub: true,
        clubUpdated: false,
      };
    case UPDATE_CLUB_SUCCESS:
      return {
        ...state,
        isUpdatingClub: false,
        clubUpdated: true,
        updateClubError: false,
      };
    case UPDATE_CLUB_FAILURE:
      return {
        ...state,
        clubUpdated: false,
        updateClubError: true,
      };

    //Eliminado un Club
    case DELETE_CLUB_REQUEST:
      return {
        ...state,
        isDeletingClub: true,
        clubDeleted: false,
      };
    case DELETE_CLUB_SUCCESS:
      return {
        ...state,
        isDeletingClub: false,
        clubDeleted: true,
        deleteClubError: false,
      };
    case DELETE_CLUB_FAILURE:
      return {
        ...state,
        clubDeleted: false,
        deleteClubError: true,
      };

    //Creación de canchas
    case CREATE_COURT_REQUEST:
      return {
        ...state,
        isCreatingCourt: true,
        createCourtError: false,
      };
    case CREATE_COURT_SUCCESS:
      return {
        ...state,
        isCreatingCourt: false,
        courtCreated: true,
        createCourtError: false,
      };
    case CREATE_COURT_FAILURE:
      return {
        ...state,
        courtCreated: false,
        createCourtError: true,
      };

    //Actualización de canchas
    case UPDATE_COURT_DEFAULT:
      return {
        state,
        isUpdatingCourt: false,
        courtUpdated: false,
      };
    case UPDATE_COURT_REQUEST:
      return {
        ...state,
        isUpdatingCourt: true,
        courtUpdated: false,
      };
    case UPDATE_COURT_SUCCESS:
      return {
        ...state,
        isUpdatingCourt: false,
        courtUpdated: true,
        updateCourtError: false,
      };
    case UPDATE_COURT_FAILURE:
      return {
        ...state,
        courtUpdated: false,
        updateCourtError: true,
      };

    //Eliminacion de canchas
    case DELETE_COURT_REQUEST:
      return {
        ...state,
        isDeleteCourt: true,
        courtDeleted: false,
      };
    case DELETE_COURT_SUCCESS:
      return {
        ...state,
        isDeleteCourt: false,
        courtDeleted: true,
        deleteCourtError: false,
      };
    case DELETE_COURT_FAILURE:
      return {
        ...state,
        courtDeleted: false,
        deleteCourtError: true,
      };

    //Obtención de las canchas
    case GET_COURTS_REQUEST:
      return {
        ...state,
        isFetchingCourts: true,
        fetchCourtsError: false,
      };
    case GET_COURTS_SUCCESS:
      action.courts = action.courts.map((court) => {
        return {
          ...court,
          actionType: "court",
        };
      });
      return {
        ...state,
        isFetchingCourts: false,
        courtsFetched: true,
        courts: action.courts,
      };
    case GET_COURTS_FAILURE:
      return {
        ...state,
        isFetchingCourts: false,
        fetchCourtsError: true,
      };

    //Obtención de 1 cancha
    case GET_SINGLE_COURT_REQUEST:
      return {
        ...state,
        isFetchingSingleCourt: true,
        fetchSingleClubError: false,
      };
    case UPDATE_SINGLE_COURT_WEEK:
      return {
        ...state,
        court: { ...action.court },
      };
    case GET_SINGLE_COURT_SUCCESS:
      return {
        ...state,
        isFetchingSingleCourt: false,
        singleCourtFetched: true,
        court: action.court,
      };

    case GET_SINGLE_COURT_DEFAULT:
      return {
        ...state,
        isFetchingSingleCourt: false,
        singleCourtFetched: false,
        court: [],
      };
    case GET_SINGLE_COURT_FAILURE:
      return {
        ...state,
        isFetchingSingleCourt: false,
        singleCourtFetched: false,
        fetchSingleClubError: true,
      };

    //Creación de servicios
    case CREATE_SERVICE_REQUEST:
      return {
        ...state,
        isCreatingService: true,
        createServiceError: false,
      };
    case CREATE_SERVICE_SUCCESS:
      return {
        ...state,
        isCreatingService: false,
        serviceCreated: true,
        createServiceError: false,
      };
    case CREATE_SERVICE_FAILURE:
      return {
        ...state,
        serviceCreated: false,
        createServiceError: true,
      };

    //Actualizacion de servicios
    case UPDATE_SERVICE_DEFAULT:
      return {
        state,
        isUpdatingService: false,
        serviceUpdated: false,
      };
    case UPDATE_SERVICE_REQUEST:
      return {
        ...state,
        isUpdatingService: true,
        serviceUpdated: false,
      };
    case UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        isUpdatingService: false,
        serviceUpdated: true,
        updateServiceError: false,
      };
    case UPDATE_SERVICE_FAILURE:
      return {
        ...state,
        serviceUpdated: false,
        updateServiceError: true,
      };

    //Eliminacion de servicios
    case DELETE_SERVICE_REQUEST:
      return {
        ...state,
        isDeleteService: true,
        serviceDeleted: false,
      };
    case DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        isDeleteService: false,
        serviceDeleted: true,
        deleteServiceError: false,
      };
    case DELETE_SERVICE_FAILURE:
      return {
        ...state,
        serviceDeleted: false,
        deleteServiceError: true,
      };
    //Limpieza de estados
    case CLEAR_COURT:
      return {
        ...state,
        isDeleteCourt: false,
        courtDeleted: false,
        deleteCourtError: false,

        //Update
        isUpdatingCourt: false,
        courtUpdated: false,
        updateCourtError: false,

        //Create
        isCreatingCourt: false,
        courtCreated: false,
        createCourtError: false,
      };
    case CLEAR_SERVICE:
      return {
        ...state,
        isDeleteService: false,
        serviceDeleted: false,
        deleteServiceError: false,

        //Update
        isUpdatingService: false,
        serviceUpdated: false,
        updateServiceError: false,

        //Create
        isCreatingService: false,
        serviceCreated: false,
        createServiceError: false,
      };
    case CLEAR_SINGLE_CLUB:
      return {
        ...state,
        isFetchingSingleClub: false,
        singleClubFetched: false,
        fetchSingleClubError: false,
        club: {},
      };

    default:
      return state;
  }
};

export const getMyTransactions = async (keyCourt) => {
  let transactions = [];
  let today = new Date();
  let after_days = new Date();
  after_days.setDate(today.getDate() + 15);
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);

  try {
    await db
      .ref(`transactions/canchas`)
      .orderByChild("key_court")
      .equalTo(keyCourt)
      .once("value", (snapshot) => {
        let data = Object.values(snapshot.val());
        transactions = data
          .filter(
            (data) =>
              new Date(data?.date?.start) > today && new Date(data?.date?.start) <= after_days
          )
          .map((data) => ({
            date: data.date,
            duration: data.duration,
          }));
      });
    return transactions;
  } catch (error) {
    console.log("error" + error);
  }
};

export default ClubState;
