import { Bracket } from 'react-brackets';
import {
  updateSets,
  updateTitleGroup,
} from '../../services/tournament/playersTournament.service';
import SetItems from './SetItems';
import { useMemo, useEffect, useState } from 'react';
import EditSets from './EditSets';
import RoundRobind from './RoundRobind';
import { FaPencilAlt } from 'react-icons/fa';
import TitleBracketModal from './TitleBracketModal';
import classNames from 'classnames';

const Tournaments = ({
  data,
  setGenerate,
  TiposCuadro,
  activetab,
  roundsWithoutRankingPoints,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditRoundOpen, setIsEditRoundOpen] = useState(false);
  const [teamData, setTeamData] = useState(null);
  const [roundData, setRoundData] = useState(null);
  const [sets, setSets] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const EDRounds = useMemo(
    () =>
      data?.rounds?.filter(
        (round) => round.typeRound === TiposCuadro.ED.value
      ) || [],
    [TiposCuadro.ED.value, data?.rounds]
  );

  const updateScoreHandle = async (idRound, teamData, sets) => {
    setGenerate(false);
    setIsSaving(true);
    const { update } = await updateSets({
      idCuadro: data.idCuadro,
      idRound,
      teamData,
      sets,
    });
    setIsSaving(false);
    closeModal();
    setGenerate(update || false);
  };
  const updateGroupTitleHandle = async (idRound, { title, rankingPoints }) => {
    setGenerate(false);
    setIsSaving(true);
    const { update } = await updateTitleGroup({
      idCuadro: data.idCuadro,
      idRound,
      title,
      rankingPoints,
    });
    setIsSaving(false);
    closeModal();
    setGenerate(update || false);
    if (update) closeModalRound();
  };

  const updateData = async (sets) => {
    if (!roundData || !teamData || !sets) return;
    await updateScoreHandle(roundData?.id, teamData, sets);
  };
  const updateTitleRound = async ({ title, rankingPoints }) => {
    if (!roundData || !title || !rankingPoints) return;
    await updateGroupTitleHandle(roundData?.id, { title, rankingPoints });
  };
  const type = data?.type?.value;

  const openModal = (round, team) => {
    setIsOpen(true);
    setSets(round.sets?.[team?.id] || null);
    setTeamData(team);
    setRoundData(round);
  };
  const closeModal = () => {
    setIsOpen(false);
    setSets(null);
    setTeamData(null);
    setRoundData(null);
  };
  const openModalRound = (data, round) => {
    setIsEditRoundOpen(data);
    setRoundData(round);
  };
  const closeModalRound = () => {
    setIsEditRoundOpen(false);
    setRoundData(null);
  };
  const [currentTab, setCurrentTab] = useState(0);
  useEffect(() => {
    if (activetab === TiposCuadro.ED.value) setCurrentTab(0);
  }, [TiposCuadro.ED.value, activetab]);

  if (!data?.rounds) {
    return (
      <div className="tw-h-fit tw-min-h-full tw-flex tw-items-center tw-font-medium tw-m-0 tw-w-full tw-relative tw-justify-center">
        No hay cuadro creado
      </div>
    );
  }
  if (!TiposCuadro[type]) return <></>;
  return (
    <>
      <EditSets
        isOpen={isOpen}
        onClose={closeModal}
        data={teamData}
        setsData={sets}
        isSaving={isSaving}
        updateData={updateData}
      />
      <TitleBracketModal
        isOpen={!!isEditRoundOpen}
        onClose={closeModalRound}
        data={isEditRoundOpen}
        isSaving={isSaving}
        updateData={updateTitleRound}
      />
      {type === TiposCuadro.RR.value && activetab === TiposCuadro.RR.value && (
        <RoundRobind cuadro={data} handleClick={openModal} />
      )}
      {activetab === TiposCuadro.ED.value && (
        <Bracket
          rounds={EDRounds}
          mobileBreakpoint={0}
          swipeableProps={{
            enableMouseEvents: true,
            animateHeight: true,
            index: currentTab,
            onChangeIndex: setCurrentTab,
            containerStyle: { width: '100%' },
          }}
          roundTitleComponent={(title, index) => (
            <TitleBracket
              title={title}
              invalid={
                roundsWithoutRankingPoints?.[EDRounds[index]?.id] &&
                !EDRounds[index].rankingPoints
              }
              handleClick={() => {
                openModalRound(
                  {
                    title,
                    rankingPoints: EDRounds[index].rankingPoints,
                  },
                  EDRounds[index]
                );
              }}
            />
          )}
          bracketClassName="tw-p-3 tw-w-full tw-h-full tw-object-cover"
          renderSeedComponent={(values) => (
            <SetItems
              {...values}
              finish={data.finish}
              handleClick={(team) =>
                openModal(EDRounds?.[values.roundIndex], team)
              }
              round={EDRounds?.[values.roundIndex]}
              updateScoreHandle={(idTeam, score) =>
                updateScoreHandle(
                  EDRounds?.[values.roundIndex]?.id,
                  idTeam,
                  score
                )
              }
            />
          )}
        />
      )}
    </>
  );
};

export default Tournaments;

const TitleBracket = ({ title, handleClick, invalid }) => {
  return (
    <div
      role="button"
      className={classNames(
        'tw-px-4 tw-z-10 tw-pb-3 tw-bg-slate-900 tw-bg-opacity-10 hover:tw-bg-slate-600 hover:tw-bg-opacity-10',
        {
          'tw-bg-red-500 tw-bg-opacity-80 hover:tw-bg-red-600 hover:tw-bg-opacity-80':
            invalid,
        }
      )}
      onClick={handleClick}
      title={invalid ? 'No se ha asignado puntos de ranking' : ''}
    >
      <div
        className={classNames(
          'tw-text-white tw-border-b-white tw-border-b-2 tw-border-solid tw-font-bold tw-flex tw-justify-between tw-items-end'
        )}
      >
        {title}
        <div
          role="button"
          className="tw-rounded-full tw-w-5 tw-h-5 tw-flex tw-justify-center tw-items-center tw-font-extrabold tw-bg-transparent tw-text-white "
          onClick={handleClick}
        >
          <FaPencilAlt size={16} style={{ marginRight: 4 }} />
        </div>
      </div>
    </div>
  );
};
