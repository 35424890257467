import { useState, useEffect} from "react";
import { useParams, useLocation } from "react-router";
import { status, approvedOrRejected } from "../services/transactions/Transaction.service";
import styled from "styled-components";

//Imagenes
import tick from "../assets/tick.png"
import failed from "../assets/failed-icon.jpg"
import LogoTennisUp from "../assets/logo-tenisup.png"

import { connect } from "react-redux";
import moment from "moment";

function mapStateToProps(state) {
  return {
    mode: state.menu.mode,
    club: state.club.club,
    court: state.club.court,
  };
}

const Paid = () => {
    /*=============================================
                      TOKEN PARAM
      =============================================*/
    const { token } = useParams();
    const search = useLocation().search;
    const type = new URLSearchParams(search).get('type');
    const Redirect = () => {
        window.location = "/explore";
      };
  /*=============================================
                               Hook
       =============================================*/
  const [responseTitle, setResponseTitle] = useState("");
  const [voucher, setVoucher] = useState({
    title: "",
    buy_order: 0,
    amount: 0,
    card_detail: 0,
    transaction_date: null,
    payment_type_code: "",
    installments_number: 0,
  });
  /*=============================================
                     EXECUTE SERVICE
      =============================================*/

  const receiveStatusValues = async () => {
    const responseStatus = await status(token, type);
    const title = await approvedOrRejected(responseStatus.response_code);
    setResponseTitle(title);
    setVoucher({
      title: title,
      buy_order: responseStatus.buy_order,
      amount: responseStatus.amount,
      card_detail: responseStatus.card_detail,
      transaction_date: moment(responseStatus.transaction_date).format("DD/MM/YYYY"),
      payment_type_code: responseStatus.payment_type_code,
      installments_number: responseStatus.installments_number,
    });
  };

  useEffect(() => {
    receiveStatusValues();
  }, []); // eslint-disable-line

  const renderContent = () => {
    if (responseTitle === "Transacción Aprobada") {
      return (
        <div className="shadow-lg">
          <div className="info-transaction">
            <h2>Transacción realizada correctamente!</h2>
          </div>
          <div className="info-container-paid-success">
            <img src={tick} className="sucess-paid" alt="Logo del Club" />
            <div className="info-content-paid">
              <div className="info-paid-tittle">
                <h2>Detalles de compra</h2>
              </div>
              <label className="info-paid">
                Orden de Compra N°: <b>{voucher.buy_order}</b>
              </label>
              <label className="info-paid">
                Monto: <b>${voucher.amount}</b>
              </label>
              <label className="info-paid">
                Número de Tarjeta:{" "}
                <b>********{voucher.card_detail.card_number}</b>
              </label>
              <label className="info-paid">
                Fecha de Pago: <b>{voucher.transaction_date}</b>
              </label>
              <label className="info-paid">
                Tipo de Pago: <b>{voucher.payment_type_code}</b>
              </label>
              <label className="info-paid">
                N° Cuotas: <b>{voucher.installments_number}</b>
              </label>
            </div>
            <div className="btn-content-success">
              <button className="btn-paid-success" onClick={() => Redirect()}>
                Volver al menú principal
              </button>
            </div>
            <div className="logo-end-success">
              <img src={LogoTennisUp} alt="logo" />
            </div>
          </div>
        </div>
      );
    } else if (responseTitle === "Transacción Rechazada") {
      return (
        <div className="shadow-lg">
          <div className="info-transaction">
            <h2>Transacción reachazada!</h2>
          </div>
          <div className="info-container-paid-failed">
            <img src={failed} className="sucess-paid" alt="Logo del Club" />
            <div className="info-paid-tittle-failed">
              <h2>
                Lo sentimos, el método de pago ingresado es incorrecto o no
                cuenta con el saldo suficiente.
              </h2>
            </div>
            <div className="btn-content-failed">
              <button className="btn-paid-failed" onClick={() => Redirect()}>
                Volver al menú principal
              </button>

              <div className="logo-end-failed">
                <img src={LogoTennisUp} alt="logo"/>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return <LoadingContainer>{renderContent()}</LoadingContainer>;
}

export default connect(mapStateToProps)(Paid)

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
