// Íconos
import chile from "../assets/paises/chile.png";
import brazil from "../assets/paises/brazil.png";
import argentina from "../assets/paises/argentina.png";
import colombia from "../assets/paises/colombia.png";
import england from "../assets/paises/england.png";
import eeuu from "../assets/paises/eeuu.png";
import france from "../assets/paises/france.png";
import peru from "../assets/paises/peru.png";
import venezuela from "../assets/paises/venezuela.png";
import bolivia from "../assets/paises/bolivia.png";
import uruguay from "../assets/paises/uruguay.png";
import paraguay from "../assets/paises/paraguay.png";
import costarica from "../assets/paises/costa-rica.png";
import mexico from "../assets/paises/mexico.png";
import spain from "../assets/paises/españa.png";

export const paisesOptions = [
    {
      nombre: "Chile",
      codigo: "CHL",
      img: chile,
    },
    {
      nombre: "Chile",
      codigo: "CHI",
      img: chile,
    },
    {
      nombre: "Peru",
      codigo: "PER",
      img: peru,
    },
    {
      nombre: "Argentina",
      codigo: "ARG",
      img: argentina,
    },
    {
      nombre: "Brasil",
      codigo: "BRA",
      img: brazil,
    },
    {
      nombre: "Colombia",
      codigo: "COL",
      img: colombia,
    },
    {
      nombre: "Venezuela",
      codigo: "VEN",
      img: venezuela,
    },
    {
      nombre: "Bolivia",
      codigo: "BOL",
      img: bolivia,
    },
    {
      nombre: "Uruguay",
      codigo: "URY",
      img: uruguay,
    },
    {
      nombre: "Paraguay",
      codigo: "PRY",
      img: paraguay,
    },
    {
      nombre: "Costa Rica",
      codigo: "CRI",
      img: costarica,
    },
    {
      nombre: "México",
      codigo: "MEX",
      img: mexico,
    },
    {
      nombre: "USA",
      codigo: "USA",
      img: eeuu,
    },
    {
      nombre: "Francia",
      codigo: "FRA",
      img: france,
    },
    {
      nombre: "España",
      codigo: "ESP",
      img: spain,
    },
    {
      nombre: "Inglaterra",
      codigo: "ENG",
      img: england,
    },
  ];