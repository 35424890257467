import { Component } from 'react'


import eventos from '../../../assets/instrucciones/eventos.jpeg'

import '../../../styles/Eventos.css'



export default class Eventos extends Component {
    render() {
        return (
            <div className='event1-content-land'>
                <div className='event1-text-container'>
                    <h1>Encuentra eventos!</h1>
                    <p>Podrás buscar eventos e inscribirte a ellos a través de la aplicación, atento a las fechas 
                    de inscripción!</p>
                </div>
                <div className='event1-screenshots-container'>
                    <img alt="evento" className='event1-screenshots' src={eventos} />
                </div>
            </div>
        )
    }
}