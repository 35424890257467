import { ref, onValue} from "firebase/database";
import { db } from "../firebase/firebase";

// Obtencion ranking
export const GET_TYPERANK_REQUEST = "GET_TYPERANK_REQUEST";
export const GET_TYPERANK_SUCCESS = "GET_TYPERANK_SUCCESS";
export const GET_TYPERANK_FAILURE = "GET_TYPERANK_FAILURE";

//RANKING

const requestGetTypeRank = () => {
    return {
      type: GET_TYPERANK_REQUEST,
    };
  };

  const recieveGetTypeRank = (typeRank) => {
    return {
      type: GET_TYPERANK_SUCCESS,
      typeRank,
    };
  };

  const errorGetTypeRank = () => {
    return {
      type: GET_TYPERANK_FAILURE,
    };
  };

  export const getTypeRanking = () => (dispatch) => {
    //Se establece el estado como 'obteniendo todos los eventos'
    dispatch(requestGetTypeRank());

    let typeRank = [];

    //obtener todos los eventos mediante firebase
    onValue(
      ref(db, "tipos_ranking/"),
      (snapshot) => {
        typeRank = Object.values(snapshot.val());
        dispatch(recieveGetTypeRank(typeRank));
      },
      () => {
        dispatch(errorGetTypeRank());
      },
      { onlyOnce: true }
    );
  };