import { ref, update, remove, onValue } from "firebase/database";
import { db } from "../firebase/firebase";
import { uploadImage } from "../services/images/images.service";


export const GET_SINGLE_PLAYER = "GET_SINGLE_PLAYER";
//Obtención de todos los Jugadores
export const GET_PLAYERS_REQUEST = "GET_ALL_PLAYERS_REQUEST";
export const GET_PLAYERS_SUCCESS = "GET_ALL_PLAYERS_SUCCESS";
export const GET_PLAYERS_FAILURE = "GET_ALL_PLAYERS_FAILURE";

//Eliminar un Jugador
export const DELETE_PLAYER_REQUEST = "DELETE_PLAYER_REQUEST";
export const DELETE_PLAYER_SUCCESS = "DELETE_PLAYER_SUCCESS";
export const DELETE_PLAYER_FAILURE = "DELETE_PLAYER_FAILURE";

//Actualizar un Jugador
export const UPDATE_PLAYER_DEFAULT = "UPDATE_PLAYER_DEFAULT";
export const UPDATE_PLAYER_REQUEST = "UPDATE_PLAYER_REQUEST";
export const UPDATE_PLAYER_SUCCESS = "UPDATE_PLAYER_SUCCESS";
export const UPDATE_PLAYER_FAILURE = "UPDATE_PLAYER_FAILURE";

//Actualizar estadisticas 
export const UPDATE_STATISTICS_PLAYER_DEFAULT = "UPDATE_STATISTICS_PLAYER_DEFAULT";
export const UPDATE_STATISTICS_PLAYER_WIN = "UPDATE_STATISTICS_PLAYER_WIN";
export const UPDATE_STATISTICS_PLAYER_LOSS = "UPDATE_STATISTICS_PLAYER_LOSS";


//Actualizar estadisticas 
export const CREATE_MATCH_REQUEST = "CREATE_MATCH_REQUEST";
export const CREATE_MATCH_SUCCESS = "CREATE_MATCH_SUCESS";
export const CREATE_MATCH_FAILURE = "CREATE_MATCH_FAILURE";

export const getSinglePlayerType = (user) => {
  return {
    type: GET_SINGLE_PLAYER,
    user,
  };
};
//Obtención de jugadores
const requestGetPlayers = () => {
  return {
    type: GET_PLAYERS_REQUEST,
  };
};

const recieveGetPlayers = (players) => {
  return {
    type: GET_PLAYERS_SUCCESS,
    players,
  };
};

const errorGetPlayers = () => {
  return {
    type: GET_PLAYERS_FAILURE,
  };
};

//Eliminacion de un jugador

const requestDeletePlayer = () => {
  return {
    type: DELETE_PLAYER_REQUEST,
  };
};

const recieveDeletePlayer = () => {
  return {
    type: DELETE_PLAYER_SUCCESS,
  };
};

const errorDeletePlayer = () => {
  return {
    type: DELETE_PLAYER_FAILURE,
  };
};

// Actualización de un Jugador
export const defaultUpdatePlayer = () => {
  return {
    type: UPDATE_PLAYER_DEFAULT,
  };
};

const requestUpdatePlayer = () => {
  return {
    type: UPDATE_PLAYER_REQUEST,
  };
};
const recieveUpdatePlayer = () => {
  return {
    type: UPDATE_PLAYER_SUCCESS,
  };
};

const errorFailurePlayer = () => {
  return {
    type: UPDATE_PLAYER_FAILURE,
  };
};

// ACTUALIZACION DE ESTADISTICAS DEL JUGADOR

const requestUpdatePlayerWin = () => {
  return {
    type: UPDATE_STATISTICS_PLAYER_WIN
  };
};

const requestUpdatePlayerLoss = () => {
  return {
    type: UPDATE_STATISTICS_PLAYER_LOSS
  };
};



// creacion de match 
const requestCreateMatch = () => {
  return {
    type: CREATE_MATCH_REQUEST,
  };
};

const recieveCreateMatch = () => {
  return {
    type: CREATE_MATCH_SUCCESS,
  };
};

const errorCreateMatch = () => {
  return {
    type: CREATE_MATCH_FAILURE,
  };
};




export const editPlayer = (settings, UID, imagen) => async (dispatch) => {
  dispatch(requestUpdatePlayer()); //
  try {
    if (imagen) {
      delete settings.imagen;
      uploadImage(`usuarios/${UID}.jpg`, imagen, `users/${UID}/settings/imagen`);
    }
    await update(ref(db, `users/${UID}/settings/`), settings);
    dispatch(recieveUpdatePlayer()); //
    console.log("success");
  } catch (error) {
    console.log(error);
    dispatch(errorFailurePlayer()); // Si hubo exito se establece el estado como falla
  }
};





export const editPlayerStatistics = (data, UID, selectPuntaje) => async (dispatch) => {
  dispatch(requestUpdatePlayer());
  let puntajeUser = 0
  // let rankingUser = 0
  try {
    onValue(
      ref(db, `users/${UID}`),
      (snapshot) => {
        let user = (snapshot.val())
        puntajeUser = user.estadisticas.puntaje;
      },
    );
    if (puntajeUser !== undefined) {
      if (selectPuntaje === "Ganador") {
        let win = {
          puntaje: puntajeUser + 15
        }
        await update(ref(db, `users/${UID}/estadisticas/`), win);
        dispatch(requestUpdatePlayerWin()); //
        console.log("success");
      } if (selectPuntaje === "Derrota") {
        let lose = {
          puntaje: puntajeUser - 5
        }
        await update(ref(db, `users/${UID}/estadisticas/`), lose);
        dispatch(requestUpdatePlayerLoss()); //
        console.log("success");
      }
    } else {
      await update(ref(db, `users/${UID}/estadisticas/`), data);
      dispatch(recieveUpdatePlayer()); //
      console.log("success");
    }
  } catch (error) {
    console.log(error)
    dispatch(errorFailurePlayer())
  }
};


export const matchPlayer = (user1, user2) => async (dispatch) => {
  dispatch(requestCreateMatch());
  try {
    let id_usuario = user2.uid
    let data = {
      imagen: user2.settings.imagen,
      nombre: user2.settings.nombre,
      location: user2.ciudad || "Coquimbo",
      origin: "sent",
      status: "accepted",
      type: "match",
      uid: id_usuario
    }
    await update(ref(db, `activity/${user1}/${id_usuario}`), data);
    dispatch(recieveCreateMatch());
    console.log("success")
  } catch (error) {
    dispatch(errorCreateMatch());
    console.log(error)
  }
}



export const deletePlayer = (uid) => async (dispatch) => {
  //Se establece el estado como 'evento eliminado'
  dispatch(requestDeletePlayer()); // Si hubo exito se establece el estado como exitoso
  try {
    await remove(ref(db, `users/${uid}`));
    dispatch(recieveDeletePlayer());
  } catch (error) {
    dispatch(errorDeletePlayer()); // Si hubo exito se establece el estado como falla
    console.log(error);
  }
};

export const getAllPlayers = () => (dispatch) => {
  //Se establece el estado como 'obteniendo todos los eventos'
  dispatch(requestGetPlayers());

  let users = [];

  //obtener todos los eventos mediante firebase
  onValue(
    ref(db, "users/"),
    (snapshot) => {
      users = Object.values(snapshot.val());
      dispatch(recieveGetPlayers(users));
    },
    () => {
      dispatch(errorGetPlayers());
    },
    { onlyOnce: true }
  );
};

export const getAllPlayersOn = () => (dispatch) => {
  //Se establece el estado como 'obteniendo todos los eventos'
  dispatch(requestGetPlayers());

  let users = [];
  //obtener todos los eventos mediante firebase
  onValue(
    ref(db, "users/"),
    (snapshot) => {
      users = Object.values(snapshot.val()) || [];
      dispatch(recieveGetPlayers(users));
    },
    () => {
      dispatch(errorGetPlayers());
    }
  );
};
