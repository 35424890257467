import React, { useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../components/ui/Button';
import Modal from '../../components/ui/Modal';
import { RiCloseLine } from 'react-icons/ri';
import UserImage from '../../components/user/UserImage';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import Select from '../../components/ui/Select';
import Loading from '../Loading';
import { getNumberList } from '../../services/Utils';
import { AiFillFile } from 'react-icons/ai';

const CuadroRankingDrager = ({
  handleClose,
  isOpen,
  data,
  handleCreate,
  isSaving,
}) => {
  const seeds = useMemo(() => data?.rounds?.[0]?.seeds || [], [data?.rounds]);
  const [items, setItems] = useState([]);
  const [maxPlayer, setMaxPlayer] = useState(2);
  const scrollRef = useRef();
  useEffect(() => {
    let players = [];
    seeds?.forEach((seed) => {
      if (seed?.teams)
        players = [
          ...players,
          ...(seed?.teams.map((team) => ({
            ...team,
            priorityTeam: seed.teams.length,
          })) || []),
        ];
    });
    setItems(
      players
        .filter((p) => !p.isBye)
        .sort((a, b) => {
          return (
            a.priorityTeam - b.priorityTeam || b.priorityTeam - a.priorityTeam
          );
        })
    );
    setMaxPlayer(players.length / 2);
  }, [seeds]);
  const maxPlayerList = useMemo(
    () => getNumberList(2, items?.length - 1 || 0) || [],
    [items?.length]
  );

  useEffect(() => {
    let timmerOff;
    if (isOpen) {
      timmerOff = setTimeout(() => (scrollRef.current.scrollTop = 0), 10);
    }
    return () => timmerOff && clearTimeout(timmerOff);
  }, [isOpen]);

  const grid = 8;

  const getItemStyle = (draggableStyle, isDragging, maxSelected) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    backgroundColor: !isDragging && maxSelected && '#e8f3f7bf',
    ...draggableStyle,
    left: 28,
    top: (draggableStyle.top || 0) - 40,
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);
  };
  return (
    <Modal
      onClose={() => !isSaving && handleClose?.()}
      show={isOpen}
      width={576}
    >
      <div
        className="tw-flex tw-flex-col tw-my-4 tw-px-7 scroll tw-h-full"
        ref={scrollRef}
      >
        {isSaving && (
          <div className="tw-w-full tw-h-full tw-absolute tw-z-30 tw-flex tw-items-center tw-justify-center tw-bg-gray-600 tw-bg-opacity-25 tw-right-0 tw-top-0">
            <Loading label="Guardando" />
          </div>
        )}
        <div className="tw-sticky tw-top-0 tw-text-Turquesa tw-font-bold tw-text-2xl tw-mb-3 tw-bg-white tw-z-20">
          <div className="tw-flex tw-h-12 tw-items-center tw-justify-between">
            <h1 className="tw-w-full tw-text-center">
              Cuadro de Jugadores / partidos
            </h1>
            <div className="tw-flex tw-h-12 tw-items-center tw-justify-between tw-text-Turquesa tw-font-bold tw-text-2xl tw-gap-10">
              <RiCloseLine
                size={30}
                onClick={handleClose}
                className="tw-cursor-pointer"
              />
            </div>
          </div>
          <div className="tw-text-gray-500 tw-font-normal tw-flex tw-justify-center tw-flex-wrap">
            <h1 className="tw-w-full tw-text-center tw-mb-3 tw-text-sm tw-font-normal tw-max-w-sm">
              Ordena el ranking de los jugadores para determinar quiénes pasarán
              a la siguiente fase deslizando la carta del jugador
            </h1>
            <div className="tw-flex tw-items-center tw-gap-2 tw-justify-between tw-w-full">
              <div className="tw-max-w-xs tw-bg-white tw-w-32 tw-pt-3">
                <Select
                  placeholder="Cantidad de jugadores"
                  label=""
                  name="tipo"
                  selectStylesOff
                  onChange={(max) => setMaxPlayer(max.value)}
                  value={maxPlayer}
                  selectClass="tw-border-Verde-TenisCTA tw-border-solid tw-border-2 tw-rounded tw-text-sm tw-w-full tw-h-10 tw-flex tw-items-center"
                  options={maxPlayerList}
                  containerStylesOff
                />
              </div>
              <Button
                type="button"
                className="tw-py-2"
                onClick={() =>
                  handleCreate?.(
                    items.slice(0, maxPlayer),
                    data?.rounds?.[0].id,
                    maxPlayer
                  )
                }
              >
                <div className="tw-flex tw-items-center tw-text-sm tw-font-semibold">
                  <AiFillFile size={16} style={{ marginRight: 4 }} />
                  Crear
                </div>
              </Button>
            </div>
          </div>
        </div>
        {items.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  className="tw-w-full tw-flex tw-flex-col tw-items-center"
                  ref={provided.innerRef}
                >
                  {items.map((player, index) => (
                    <Draggable
                      key={player.id}
                      draggableId={player.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classNames(
                            'tw-flex tw-mb-2 tw-rounded-md tw-shadow-md tw-items-center tw-w-full tw-max-w-md tw-h-24',
                            {
                              'tw-bg-Verde-TenisCTA tw-bg-opacity-20':
                                snapshot.isDragging,
                              'tw-bg-green-700 tw-bg-opacity-5':
                                !snapshot.isDragging && maxPlayer > index,
                            }
                          )}
                          style={getItemStyle(provided.draggableProps.style, snapshot.isDragging, maxPlayer > index)}
                        >
                          <div
                            className="tw-w-15 tw-mr-2 tw-relative"
                            title={`posición en el ranking: ${
                              player.ranking || 0
                            }`}
                          >
                            <UserImage
                              uuid={player.id}
                              size={200}
                              className="tw-w-15 tw-h-15"
                              editSize
                            />

                            <div
                              style={{ fontSize: 10 }}
                              className="tw-text-xs tw-w-6 tw-h-6 tw-bg-slate-700 tw-bg-opacity-75 tw-rounded-full tw-absolute tw--right-2 tw--top-2 tw-flex tw-items-center tw-justify-center tw-text-white"
                            >
                              {player.ranking}
                            </div>
                          </div>
                          <span className="tw-flex-1 tw-text-Turquesa">
                            {player?.name}
                          </span>
                          <div className="tw-font-bold tw-flex tw-flex-col tw-items-center tw-justify-cente tw-text-Turquesa">
                            PG - PP
                            <div className="tw-flex tw-justify-center">
                              {player.ganados || 0}-{player.perdidos || 0}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
    </Modal>
  );
};

export default CuadroRankingDrager;
