import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { changeMenuSelected } from '../actions/menu';
import { useNavigate, useParams } from 'react-router-dom';
import { getEventsTransaction, getSingleEvent } from '../actions/events';
import perfil from '../assets/perfil.png';
import ticket from '../assets/ticket-tenisup.png';
import '../styles/Inscritos.css';
import styled from 'styled-components';
import { MdSearch, MdEdit, MdDelete } from 'react-icons/md';
import { set_eventReplace } from '../actions/inscription';
import Select from '../components/ui/Select';
import Input from '../components/ui/Input';
import AddPlayerModal from './inscritos/AddPlayerModal';
import { getAllPlayersOn } from '../actions/jugador';
import {
  deleteInscription,
  editInscription,
} from '../services/transactions/Transaction.service';
import useCupos from '../hooks/useCupos';
import toast from 'react-hot-toast';
import SelectCategorias from '../components/events/SelectCategorias';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Title from '../components/ui/Title';
// import classNames from "classnames";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { saveInscriptionCSV } from '../services/events/Inscriptions.service';
import { useUser } from '../context/UserContext';
import Modal from '../components/ui/Modal';
import Button from '../components/ui/Button';

function mapStateToProps(state) {
  return {
    isFetchingSingleEvent: state.events.isFetchingSingleEvent,
    singleEventFetched: state.events.singleEventFetched,
    fetchSingleEventError: state.events.fetchSingleEventError,
    event: state.events.event,
    eventReplace: state.register.eventReplace,

    //cancel registration
    isCancelingRegister: state.register.isCancelingRegister,
    isRegisterCanceled: state.register.isRegisterCanceled,
    errorCancelingRegister: state.register.errorCancelingRegister,

    users: state.jugador.players,

    isFetchingTransactionsEvent: state.events.isFetchingTransactionsEvent,
    eventsTransactionsFetched: state.events.eventsTransactionsFetched,
    fetchEventsTransactionsError: state.events.fetchEventsTransactionsError,
    eventsTransactions: state.events.eventsTransactions,
  };
}

function Inscritos(props) {
  const {
    dispatch,
    event,
    eventsTransactions,
    users = [],
    eventReplace,
  } = props;
  const { isAdmin, user } = useUser();
  const { deporte } = event?.categoria || { deporte: 'tenis' };
  const externos = useMemo(
    () => Object.values(event.externos || {}),
    [event.externos]
  );
  const { isDual } = event;
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectUser, setSelectUser] = useState({ inscrito: {}, details: {} });
  const [, setIsAdding] = useState('Menu Principal');
  const mobile = useMediaQuery('(max-width: 780px)');
  const fileInputRef = useRef(null);

  const {
    getCategorias,
    categoria,
    inscritosList,
    inscritoName,
    getInscritobyiD,
  } = useCupos({
    deporte,
    settings: selectUser.details?.settings,
    event,
    eventReplace: eventReplace,
    myEvents: selectUser.details?.torneos,
  });

  const [categoriaOpen, setCategoriaOpen] = useState(categoria);

  useEffect(() => {
    const resetValues = () => {
      setSelectUser({ details: {}, inscrito: {} });
      dispatch(set_eventReplace(''));
    };

    if (!showEdit) resetValues();
  }, [showEdit, dispatch]);
  const { eventId } = useParams('eventId');

  useEffect(() => {
    dispatch(set_eventReplace(inscritoName || ''));
    setCategoriaOpen(inscritoName);
  }, [inscritoName, dispatch]);
  useEffect(() => {
    if (users?.length === 0) {
      dispatch(getAllPlayersOn());
    }
  }, [dispatch, users]);

  const categoriaSelect = useMemo(() => {
    const onChangeCategoria = ({ value }) => {
      setCategoriaOpen(value);
      dispatch(set_eventReplace(value));
    };
    if (deporte === 'padel' || selectUser.inscrito?.isExterno)
      return (
        <Select
          placeholder="Selecciona una categoría"
          value={categoriaOpen}
          options={getCategorias.map((c) => ({ key: c, value: c, label: c }))}
          onChange={onChangeCategoria}
          name="categoriaOpen"
        />
      );
    if (event.cupos?.Abierta) {
      const categoriaA = [
        { key: 'Abierta', value: 'Abierta', label: 'Abierta' },
      ];
      let categoriaTenis =
        selectUser.details?.settings?.perfiljuego.tenis.categoria;
      if (categoriaTenis) {
        categoriaA.push({
          key: categoriaTenis + 'Categoria',
          value: categoriaTenis,
          label: categoriaTenis,
        });
      }
      return (
        <Select
          placeholder="Selecciona una categoría"
          value={categoriaOpen}
          options={categoriaA}
          name="categoriaOpen"
          onChange={onChangeCategoria}
        />
      );
    }
    return <span style={{ marginTop: 12 }}>{categoria}</span>;
  }, [
    categoriaOpen,
    event.cupos?.Abierta,
    selectUser,
    deporte,
    getCategorias,
    dispatch,
    categoria,
  ]);

  const navigate = useNavigate();
  const [usersForImport, setUsersForImport] = useState([]);

  useEffect(() => {
    if (eventId) {
      dispatch(getSingleEvent(eventId));
      dispatch(getEventsTransaction(eventId));
    }
  }, [dispatch, eventId]);
  const [inscritos, setinscritos] = useState([]);
  const [filtro, setfiltro] = useState({
    sort: 'nombre',
    filters: { categoria: '', payment_method: '', nombre: '' },
  });
  function goBack() {
    navigate('/event/' + eventId);
    dispatch(changeMenuSelected('/explore'));
  }

  useEffect(() => {
    setIsAdding('Menu Principal');
  }, []);

  useEffect(() => {
    setinscritos(
      Object.entries(event.inscritos || {}).reduce((a, b) => {
        const [key, value] = b;
        a = a.concat(
          Object.entries(value || {}).map(([uid, value2]) => {
            return {
              ...value2,
              categoria: key,
              uid,
              voucher: eventsTransactions?.find((et) => et.uid === uid),
            };
          })
        );
        return a;
      }, []) || []
    );
  }, [event, eventsTransactions]);
  const filterData = () => {
    var auxInscritos = [...inscritos];
    auxInscritos = auxInscritos
      .filter(
        (i) =>
          (i.categoria?.toLowerCase() ===
            filtro.filters.categoria?.toLowerCase() ||
            !filtro.filters.categoria) &&
          (i.payment_method?.toLowerCase() ===
            filtro.filters.payment_method?.toLowerCase() ||
            !filtro.filters.payment_method) &&
          (i.nombre
            ?.toLowerCase()
            .includes(filtro.filters.nombre?.toLowerCase()) ||
            !filtro.filters.nombre)
      )
      .sort((a, b) => {
        if (b[filtro.sort] === undefined && a[filtro.sort] !== undefined)
          return -1;
        if (a[filtro.sort] === undefined && b[filtro.sort] !== undefined)
          return 1;
        if (a[filtro.sort] < b[filtro.sort]) return -1;
        if (a[filtro.sort] > b[filtro.sort]) return 1;
        return 0;
      });
    return auxInscritos;
  };

  const handleAdd = () => {
    setShowAdd(true);
  };

  const editPlayer = () => {
    editInscription({
      inscrito: selectUser.inscrito,
      categoria: categoriaOpen,
      EventId: event.id,
    });
    toast.success('Se ha actualizado correctamente');
    setShowEdit(false);
  };

  const getCategory = useCallback(() => {
    var cupos = Object.keys(event?.cupos || []);
    return cupos;
  }, [event]);

  const getCuposAvailables = (available, total) => {
    return total - available;
  };

  const filterSinglePlayers = (players, event, control) => {
    const playerSingleResult = [];
    let error = null;
    players.map((item) => {
      let categories =
        item[4].indexOf('"') === -1
          ? item[4].split(',')
          : item[4].split('"')[1].split(',');

      let formatUsers = {
        id: item[0],
        name: item[1],
        lastName: item[2],
        phoneNumber: item[3],
        category: [],
        email: item[5],
        dualId: item[6]?.split('\r')[0],
      };
      categories.map((item) => {
        let isCategory = getCategory().indexOf(item);
        let inscritoCategory =
          event?.inscritos === undefined ? null : event?.inscritos[item];
        let isAvailableCategory = event?.inscritos
          ? getCuposAvailables(
              inscritoCategory ? Object.keys(inscritoCategory)?.length : null,
              control[item]
            )
          : control[item];

        if (isCategory !== -1 && isAvailableCategory > 0) {
          formatUsers.category.push(item);
          control[item] = control[item] - 1;
        } else {
          error =
            'Usuario no agregado, Algunas categorías están llenas o no existen';
        }
        return null;
      });
      if (formatUsers.category.length !== 0) {
        playerSingleResult.push(formatUsers);
      }
      return null;
    });
    error
      ? toast.error(error)
      : toast.success('Agregado todos los usuarios con exito');
    return { playerSingleResult };
  };

  const sortPlayers = (playerList) => {
    let playersListSorted = [];

    playerList.map((item, _, list) => {
      let isPlayerListSorted = playersListSorted.find(
        (player) => parseInt(player[0]) === parseInt(item[0])
      );

      if (!isPlayerListSorted) {
        const team = list.find(
          (player) => parseInt(player[0]) === parseInt(item[6].split('\r'))
        );
        if (team) {
          playersListSorted.push([...team], [...item]);
        }
      }
      return null;
    });

    return playersListSorted;
  };

  const filterDualPlayers = (players, event, control) => {
    let playerDualResult = [];
    let error = null;
    sortPlayers(players).map((item) => {
      const categories =
        item[4].indexOf('"') === -1
          ? item[4].split(',')
          : item[4].split('"')[1].split(',');
      let formatUsers = {
        id: item[0],
        name: item[1],
        lastName: item[2],
        phoneNumber: item[3],
        category: [],
        email: item[5],
        dualId: item[6]?.split('\r')[0],
      };

      let team = players.find(
        (player) =>
          player[0] === formatUsers.dualId &&
          player[6]?.split('\r')[0] === formatUsers.id
      );
      if (team) {
        team = {
          id: team[0],
          name: team[1],
          lastName: team[2],
          phoneNumber: team[3],
          category:
            team[4].indexOf('"') === -1
              ? team[4].split(',')
              : team[4].split('"')[1].split(','),
          email: team[5],
          dualId: team[6]?.split('\r')[0],
        };

        categories.map((itemCategory, index) => {
          let isCategory = getCategory().indexOf(itemCategory);
          let inscritoCategory =
            event?.inscritos === undefined
              ? null
              : event?.inscritos[itemCategory];
          let isAvailableCategory = event?.inscritos
            ? getCuposAvailables(
                inscritoCategory ? Object.keys(inscritoCategory)?.length : null,
                control[itemCategory]
              )
            : control[itemCategory];

          if (
            isCategory !== -1 &&
            isAvailableCategory > 0 &&
            team.category[index] === itemCategory
          ) {
            formatUsers.category.push(itemCategory);
            control[itemCategory] = control[itemCategory] - 1;
          } else {
            error =
              'Usuario no agregado, Algunas categorías están llenas o no existen';
          }
          return null;
        });

        if (formatUsers.category.length > 0) {
          playerDualResult.push(formatUsers);
        }
      } else {
        error = 'Error al asignar id del equipo';
      }
      return null;
    });

    error
      ? toast.error(error)
      : toast.success('Agregado todos los usuarios con exito');

    return { playerDualResult };
  };

  async function onChange(eventFile) {
    var file = eventFile.target.files[0];
    var reader = new FileReader();
    var users = '';
    var control = Object.assign({}, event.cupos);

    reader.onload = await function (e) {
      // The file's text will be printed here
      users = e.target.result;
      users = users.split('\n');
      users = users.filter((item) => item !== '');
      users.map((item, index) => {
        users[index] = item.split(';');
        return true;
      });
      users = users.filter((item) => item[0] !== '' || item[0] === undefined);
      if (event.isDual) {
        const { playerDualResult } = filterDualPlayers(users, event, control);
        setUsersForImport(playerDualResult);
      } else {
        const { playerSingleResult } = filterSinglePlayers(
          users,
          event,
          control
        );
        setUsersForImport(playerSingleResult);
      }
    };
    if (file) {
      reader.readAsText(file);
    }
  }

  const saveInscriptions = async () => {
    await saveInscriptionCSV(usersForImport, eventId, event.isDual).then(() => {
      setUsersForImport([]);
    });
  };

  const handleEditModal = async (inscrito) => {
    if (!inscrito) return;
    const { isExterno } = inscrito;
    const usersList = isExterno ? externos : users;
    if (usersList.length === 0) return;
    const user = usersList.find((u) => u.uid === inscrito.uid);
    if (user) {
      user.torneos = isExterno
        ? Object.values(inscritosList || {}).filter(
            (inscritoL) => inscritoL.uid === inscrito.uid
          )
        : Object.values(user.torneos || {});
    }
    setSelectUser({ inscrito: { ...inscrito }, details: { ...user } });
    setShowEdit(true);
  };

  const handleDeleteModal = (inscritos) => {
    setSelectUser({ inscrito: inscritos, details: null });
    setShowDelete(true);
  };

  const cancelRegistration = async () => {
    const {
      event: { id },
    } = props;
    if (id && selectUser.inscrito) {
      setShowDelete(false);
      const data = await deleteInscription({
        userID: selectUser.inscrito.uid,
        EventId: id,
        categoria: selectUser.inscrito.categoria,
      });
      if (data) {
        toast.success('Se ha eliminado correctamente');
      } else {
        toast.error('Ha ocurrido un error.');
      }
    }
  };

  const closeModal = () => {
    if (showAdd) {
      setShowAdd(false);
      setIsAdding('Menu Principal');
    }
    if (showEdit) {
      setShowEdit(false);
    }
    if (showDelete) {
      setShowDelete(false);
    }
  };

  const valueFilters = {
    categoria: ['Novicio', '4°', '3°', '2°', '1°', 'Honor', 'Escalafón'],
    payment_method: ['webpay', 'efectivo'],
  };
  const headerhandle = (sort) => {
    setfiltro((value) => ({ ...value, sort }));
  };

  return (
    <div
      className="main-container-activado"
      style={{ backgroundColor: 'white' }}
    >
      <div style={{ position: 'relative' }} className="scroll">
        <div
          style={{
            paddingInline: mobile && 12,
            width: '100%',
          }}
        >
          <Title
            goBack={goBack}
            title="Inscritos en el Evento"
            containerStyles={{
              fontSize: 32,
              zIndex: mobile && 10,
              backgroundColor: 'white',
              height: mobile ? 70 : 60,
              position: mobile && 'sticky',
              top: mobile && 0,
              paddingBottom: mobile && 10,
            }}
          />
        </div>
        <div
          className="inscritos-filtros-container inscritos-main-buttons"
          style={{ right: 0, top: 0 }}
        >
          {(isAdmin || user?.uid === event.owner) && (
            <>
              {usersForImport.length > 0 && (
                <>
                  <Button1
                    style={{
                      width: 100,
                      color: '#fff',
                      backgroundColor: '#AAC130',
                    }}
                    onClick={saveInscriptions}
                  >
                    Guardar
                  </Button1>
                  <Button1
                    style={{
                      width: 100,
                      color: '#fff',
                      backgroundColor: '#d02822',
                    }}
                    onClick={() => setUsersForImport([])}
                  >
                    Borrar
                  </Button1>
                </>
              )}
              {usersForImport.length === 0 && (
                <Button1 style={{ width: 132 }} onClick={() => handleAdd()}>
                  Añadir Jugador
                </Button1>
              )}
              <label className="labelImport">
                <input
                  className="toolsButtons"
                  type="file"
                  accept=".csv"
                  onChange={onChange}
                  onClick={(e) => (e.target.value = null)}
                  ref={fileInputRef}
                />
                Importar CSV
              </label>
            </>
          )}
        </div>
        <div className="inscritos-filtros-container" style={{ gap: 12 }}>
          <div style={{ width: 'none' }}>
            <Input
              full={mobile}
              placeholder="Filtrar por nombre"
              onChange={(event) =>
                setfiltro({
                  ...filtro,
                  filters: { ...filtro.filters, nombre: event.target.value },
                })
              }
              value={filtro.filters.nombre}
              Icon={
                <MdSearch
                  color="#C9C9E3"
                  size="20"
                  style={{ marginLeft: 16, marginRight: 16 }}
                />
              }
              labelStyles={{ margin: 0 }}
              containerStyles={{ padding: 0 }}
            />
          </div>
          <div style={{ maxWidth: !mobile && 220, width: '100%' }}>
            <SelectCategorias
              categoriaOpen={filtro.filters.categoria}
              onChange={(value) =>
                setfiltro((filtro) => ({
                  ...filtro,
                  filters: { ...filtro.filters, categoria: value },
                }))
              }
              isClearable
              deporte={deporte}
              getCategorias={getCategorias}
              isExterno
            />
          </div>

          {(isAdmin || user?.uid === event.owner) && (
            <div style={{ maxWidth: !mobile && 220, width: '100%' }}>
              <Select
                isSearchable
                isClearable
                placeholder="Método de pago"
                value={filtro.filters.payment_method}
                onChange={(payment) => {
                  const { value = null } = payment || {};
                  setfiltro((filtro) => ({
                    ...filtro,
                    filters: { ...filtro.filters, payment_method: value },
                  }));
                }}
                options={valueFilters.payment_method.map((c) => ({
                  key: c,
                  value: c,
                  label: c,
                }))}
                containerStyle={{ paddingBottom: 0 }}
              ></Select>
            </div>
          )}
        </div>
        {usersForImport.length > 0 && (
          <div className={'for-save'}>Usuarios por Guardar</div>
        )}
        {usersForImport.length !== 0 && (
          <div className="scroll">
            <Table
              aria-label="simple table"
              style={{ borderCollapse: 'collapse' }}
            >
              <TableHead className="Inscritos-header">
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell />
                  <TableCell>Nombre</TableCell>
                  <TableCell>Categoría</TableCell>
                  <TableCell>Email</TableCell>
                  {event.isDual && <TableCell>Equipo</TableCell>}
                  <TableCell>Método de pago</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="Inscritos-body">
                {usersForImport?.map((inscrito, index) => (
                  <TableRow
                    key={
                      inscrito.uid + ' ' + inscrito.categoria + Math.random()
                    }
                  >
                    <TableCell width={36}>{index + 1}</TableCell>
                    <TableCell width={36} style={{ padding: 0 }}>
                      <img
                        alt="imagen de usuario"
                        className="user-img"
                        src={inscrito.imagen ? inscrito.imagen : perfil}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="inscription-name">{`${inscrito.name} ${inscrito.lastName}`}</div>
                    </TableCell>
                    <TableCell>
                      {inscrito.category.map(
                        (item, index) =>
                          `${item} ${
                            index < inscrito.category.length - 1 ? ', ' : ''
                          }`
                      )}
                    </TableCell>
                    <TableCell>{inscrito.email}</TableCell>
                    {event.isDual && (
                      <TableCell>{`${
                        usersForImport.find(
                          (player) => player.id === inscrito.dualId
                        )?.name || ''
                      }`}</TableCell>
                    )}
                    {(isAdmin || user?.uid === event.owner) && (
                      <TableCell>
                        <ColorTypePayment payment={'efectivo'} />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}

        {usersForImport.length === 0 && (
          <div className="scroll">
            <Table
              aria-label="simple table"
              style={{ borderCollapse: 'collapse' }}
            >
              <TableHead className="Inscritos-header">
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell />
                  <TableCell onClick={() => headerhandle('nombre')}>
                    Nombre
                  </TableCell>
                  <TableCell />
                  <TableCell onClick={() => headerhandle('categoria')}>
                    Categoría
                  </TableCell>
                  <TableCell onClick={() => headerhandle('payment_method')}>
                    Método de pago
                  </TableCell>
                  {event.isDual && <TableCell>Equipo</TableCell>}
                  {(isAdmin || user?.uid === event.owner) && (
                    <TableCell>Acciones</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody className="Inscritos-body">
                {filterData()?.map((inscrito, index) => (
                  <TableRow key={inscrito.uid + ' ' + inscrito.categoria}>
                    <TableCell width={36}>{index + 1}</TableCell>
                    <TableCell width={36} style={{ padding: 0 }}>
                      <img
                        alt="imagen de usuario"
                        className="user-img"
                        src={inscrito.imagen ? inscrito.imagen : perfil}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="inscription-name">{inscrito.nombre}</div>
                    </TableCell>
                    <TableCell width={50}>
                      {inscrito.isExterno ? (
                        <div />
                      ) : (
                        <img
                          width={50}
                          style={{ maxWidth: 'none' }}
                          alt="TenisUp"
                          src={ticket}
                        />
                      )}
                    </TableCell>
                    <TableCell>{inscrito.categoria}</TableCell>
                    {(isAdmin || user?.uid === event.owner) && (
                      <TableCell>
                        <ColorTypePayment payment={inscrito.payment_method} />
                      </TableCell>
                    )}
                    {event.isDual && (
                      <TableCell>{inscrito.equipo.nombre}</TableCell>
                    )}
                    {(isAdmin || user?.uid === event.owner) && (
                      <TableCell>
                        <div className="tw-flex tw-flex-row">
                          <MdEdit
                            color="#333"
                            size="20"
                            onClick={() => handleEditModal(inscrito)}
                          />
                          <MdDelete
                            color="#333"
                            size="20"
                            onClick={() => handleDeleteModal(inscrito)}
                          />
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
        <AddPlayerModal
          show={showAdd}
          closeModal={closeModal}
          data={{
            users,
            event,
            eventReplace: eventReplace,
            set_eventReplace,
            deporte: deporte,
            isDual: isDual,
            categoria: eventReplace,
            handleChangeCategoria: (value) => {
              setCategoriaOpen(value);
              dispatch(set_eventReplace(value));
            },
            id: event.id,
            showModal: setShowAdd,
            inscritosList: inscritosList,
            getInscritobyiD: getInscritobyiD,
          }}
        />
        <Modal width={580} show={showDelete} onClose={closeModal}>
          <div className="tw-h-full tw-p-5 tw-px-7 tw-flex tw-item-center tw-flex-col">
            <Title
              textCenter
              goBack={() => setShowDelete(false)}
              title={
                <div className="md:tw-text-3xl tw-text-lg tw-px-10">
                  Eliminar Usuario
                </div>
              }
            />
            <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center">
              <div className="mensaje">
                <h5> ¿Estás seguro de eliminar al usuario del evento? </h5>
              </div>

              <div className="modal-body" style={{ display: 'flex', gap: 12 }}>
                <Button
                  className="tw-w-36 tw-self-center"
                  onClick={() => setShowDelete(false)}
                  buttonType="secundary"
                >
                  Cancelar
                </Button>

                <Button
                  className="tw-w-36 tw-self-center"
                  onClick={() => cancelRegistration()}
                >
                  Eliminar
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal width={580} show={showEdit} onClose={closeModal}>
          <div className="tw-h-full tw-p-5 tw-px-7 tw-flex tw-item-center tw-flex-col">
            <Title
              textCenter
              goBack={() => setShowEdit(false)}
              title={
                <div className="md:tw-text-3xl tw-text-lg tw-px-10">
                  Editar Inscripción del Usuario
                </div>
              }
            />
            <div className="config-perfil-form-column">{categoriaSelect}</div>
            <Button
              className="tw-w-48 tw-self-center"
              onClick={() => editPlayer()}
            >
              Editar
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
}

const ColorTypePayment = (props) => {
  const { payment } = props;
  const color = (payment) => {
    const colores = {
      webpay: '#ae81ff',
      efectivo: '#66d9ef',
    };
    return colores[payment] || 'gray';
  };
  return (
    <div className="Inscritos-paymenttype" style={{ color: color(payment) }}>
      {payment}
    </div>
  );
};

const Button1 = styled.button`
  position: relative;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background-color: #dcec00;
  color: #2085b7;
  font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: 'right';
  max-width: 200px;
  font-size: 14px;
  cursor: pointer;
`;
//Estilos Modal

export default connect(mapStateToProps)(Inscritos);
