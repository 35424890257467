import React from "react";
import { MdSearch } from "react-icons/md";
import Select from "../../ui/Select";
import Input from "../../ui/Input";

const genderOptions = [
  { label: "Hombre", value: "Hombre", key: "Hombre" },
  { label: "Mujer", value: "Mujer", key: "Mujer" },
];

const categoryOptions = [
  { value: "Novicio", key: "Novicio", label: "Novicio" },
  { value: "4°", key: "4°", label: "Cuarta" },
  { value: "3°", key: "3°", label: "Tercera" },
  { value: "2°", key: "2°", label: "Segunda" },
  { value: "1°", key: "1°", label: "Primera" },
  { value: "Honor", key: "Honor", label: "Honor" },
];

const Countryoptions = [
  { value: "CHL", key: "CHL", label: "Chile" },
  { value: "PER", key: "PER", label: "Peru" },
  { value: "ARG", key: "ARG", label: "Argentina" },
  { value: "BRA", key: "BRA", label: "Brasil" },
  { value: "COL", key: "COL", label: "Colombia" },
  { value: "VEN", key: "VEN", label: "Venezuela" },
  { value: "BOL", key: "BOL", label: "Bolivia" },
  { value: "URY", key: "URY", label: "Uruguay" },
  { value: "PRY", key: "PRY", label: "Paraguay" },
  { value: "CRI", key: "CRI", label: "Costa Rica" },
  { value: "MEX", key: "MEX", label: "México" },
  { value: "USA", key: "USA", label: "Estados unidos" },
  { value: "FRA", key: "FRA", label: "Francia" },
  { value: "ESP", key: "ESP", label: "España" },
  { value: "ENG", key: "ENG", label: "Inglaterra" },
];

const FiltrosJugadores = ({
  filterGenero,
  setFilterGenero,
  filterCategoria,
  setFilterCategoria,
  filterCountries,
  setFilterCountries,
  setSearchString,
  searchString,
}) => {
  const containerClass = "tw-flex-1 tw-basis-60";
  return (
    <div className="tw-flex tw-flex-wrap tw-gap-x-5 tw-px-6 tw-pt-4 tw-bg-white">
      <Select
        name="categoriaOpen"
        containerStylesOff
        value={filterGenero}
        placeholder="Selecciona un género"
        onChange={(event) => setFilterGenero(event?.value || "")}
        options={genderOptions}
        isClearable
        isSearchable
        containerClass={containerClass}
      />
      <Select
        containerStylesOff
        value={filterCategoria}
        placeholder="Selecciona una categoría"
        onChange={(event) => setFilterCategoria(event?.value || "")}
        options={categoryOptions}
        isClearable
        containerClass={containerClass}
      />
      <Select
        containerStylesOff
        value={filterCountries}
        placeholder="Selecciona un país"
        onChange={(event) => setFilterCountries(event?.value || "")}
        options={Countryoptions}
        isClearable
        containerClass={containerClass}
      />
      <Input
        containerStylesOff
        full
        value={searchString}
        placeholder="Filtrar por nombre"
        onChange={(event) => setSearchString(event.target.value)}
        Icon={<MdSearch color="#C9C9E3" size="20" style={{ marginLeft: 10 }} />}
        containerClass={containerClass}
      />
    </div>
  );
};

export default FiltrosJugadores;
