import { useState, useEffect, Suspense, useMemo, lazy } from "react";
import "../styles/MapEvents.css";
import DetailEvents from "../components/events/DetailEvent";
import Rodal from "rodal";
// Estilos
import "swiper/css";
import "rodal/lib/rodal.css";
//Iconos
import { GiTrophyCup, GiTennisCourt, GiGreekTemple } from "react-icons/gi";
import logo from "../assets/logo.png";
import { FaArrowCircleLeft } from "react-icons/fa";

//Redux
import { connect, useDispatch } from "react-redux";
import { getAllEvents } from "../actions/events";
import {
  getAllClubs,
  getAllCourts,
  getSingleCourt,
  GET_SINGLE_CLUB_SUCCESS,
} from "../actions/clubs";
import { GET_SINGLE_EVENT_SUCCESS } from "../actions/events";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useUser } from "../context/UserContext";
import classNames from "classnames";
import { useResizeDetector } from "react-resize-detector";
import PerfilClub from "../components/dashboard/PerfilClub";

//Mapa
import PreviewCourt from "../components/mapa/PreviewCourt";
import Marker from "../components/mapa/Marker";
import Map from "../components/mapa/Map";
import FilterBtn from "../components/mapa/mapEvents/FilterBtn";
import moment from 'moment'

const SliderFilter = lazy(() => import("../components/mapa/SliderFilter"));
const RenderEvent = lazy(() => import("../components/mapa/mapEvents/RenderEvent"));
const RenderClub = lazy(() => import("../components/mapa/mapEvents/RenderClub"));
const RenderCourt = lazy(() => import("../components/mapa/mapEvents/RenderCourt"));
// Card
const defaultLat = -29.93225;
const defaultLng = -71.246173;

const REGIONS = [
  { value: "XV", label: "Arica y Parinacota", lat: -18.4833, lng: -70.3333 },
  { value: "I", label: "Tarapacá", lat: -20.2167, lng: -70.15 },
  { value: "II", label: "Antofagasta", lat: -23.65, lng: -70.4 },
  { value: "III", label: "Atacama", lat: -27.3664, lng: -70.3331 },
  { value: "IV", label: "Coquimbo", lat: -29.95332, lng: -71.33947 },
  { value: "V", label: "Valparaíso", lat: -33.05, lng: -71.6167 },
  { value: "MET", label: "Metropolitana", lat: -33.47269, lng: -70.64724 },
  { value: "VII", label: "Maule", lat: -35.5, lng: -71.5 },
  { value: "VIII", label: "Biobío", lat: -37.0, lng: -72.5 },
  { value: "IX", label: "La Araucanía", lat: -38.7333, lng: -72.6667 },
  { value: "X", label: "Los Lagos", lat: -41.75, lng: -73.0 },
  { value: "XI", label: "Aysen", lat: -45.98869, lng: -73.77348 },
  { value: "VI", label: "O'Higgins", lat: -48.48623, lng: -72.91059 },
  { value: "XII", label: "Magallanes", lat: -53.1667, lng: -70.9333 },
  { value: "XVI", label: "Ñuble", lat: -36.7226, lng: -71.7622 },
  { value: "XIV", label: "Los Ríos", lat: -40.231, lng: -72.3311 },
];

function mapStateToProps(state) {
  return {
    events: state.events.events,
    eventsFetched: state.events.eventsFetched,
    event: state.events.event,
    club: state.club.club,
    clubsFetched: state.club.clubsFetched,
    clubs: state.club.clubs,
    court: state.club.court,
    courts: state.club.courts,
  };
}
const searchBy = ["Todo", "Eventos", "Clubes", "Canchas"];
const searchIn = ["Chile"];

const MapEvents = (props) => {
  const [params] = useSearchParams();
  const selectID = params.get("selectID")
  const idURL = params.get("id");
  const typeURL = params.get("type");
  const regionURL = params.get("location")
  const dateUrl = params.get("date")
  const sportURL = params.get("sport")
  //SET primero nombre del atributo y luego el valor original
  // params.set("type","Todo");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clubs, setClubs] = useState([]);
  const [events, setEvents] = useState([]);
  const [courts, setCourts] = useState([]);
  //filtro
  const [active, setActive] = useState(["Todo", "Chile"]);
  const [showMap, setShowMap] = useState(false);
  //Modal
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  //Mapa
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [zoom, setZoom] = useState(12);
  const { width, ref } = useResizeDetector();
  //PreviewCard
  const [showCard, setShowCard] = useState(false);
  const { isPlayer, isNotPlayer, tipo_usuario } = useUser();
  //Método Visualización Modal
  const handleModal = (item, e) => {
    e?.preventDefault?.();
    if (item.id) {
      setSelectedItem(item);
      setShowModal(true);
      dispatch({
        type: GET_SINGLE_EVENT_SUCCESS,
        event: item,
      });
    } else if (item.settings) {
      setSelectedItem(item);
      setShowModal(true);
      dispatch({
        type: GET_SINGLE_CLUB_SUCCESS,
        club: item,
      });
    } else if (item.key) {
      dispatch(getSingleCourt(item.club_uid, item.key, () => {
        setSelectedItem(item);
        setShowModal(true);
      }));
    }
  };

  //Método Previsualización Card
  const handlePreviewCard = (item) => {
    setShowCard(true);
    setLat(item.lat);
    setLng(item.lng);
    setSelectedItem(item);
    if (item.id) {
      dispatch({
        type: GET_SINGLE_EVENT_SUCCESS,
        event: item,
      });
    } else if (item.settings) {
      dispatch({
        type: GET_SINGLE_CLUB_SUCCESS,
        club: item,
      });
    } else if (item.key) {
      dispatch(getSingleCourt(item.club_uid, item.key));
    }
  };

  //Previsualización Card
  const renderPreviewCard = (item) => {
    const { actionType, lat, lng } = selectedItem;
    const newLat = parseFloat(lat || defaultLat);
    const newLng = parseFloat(lng || defaultLng);
    const show =
      showCard && (actionType === "court" || actionType === "event" || actionType === "club");
    if (!show) return <></>;
    let Component;
    if (actionType === "court") Component = RenderCourt;
    if (actionType === "event") Component = RenderEvent;
    if (actionType === "club") Component = RenderClub;
    return (
      <div lat={newLat} lng={newLng} className="tw-translate-x-4 tw-translate-y-4">
        {Component && (
          <Component
            item={selectedItem}
            handleClick={() => setShowCard(false)}
            handleClickModal={handleModal}
            isPlayer={isPlayer}
            isNotPlayer={isNotPlayer}
            closeBtn
          />
        )}
      </div>
    );
  };

  const renderModal = () => {
    const { actionType } = selectedItem;
    return (
      <Rodal
        width={1024}
        height={900}
        visible={showModal}
        showCloseButton={false}
        showMask
        closeMaskOnClick
        onClose={() => {
          setShowModal(false);
          setShowMap(false);
        }}
        customStyles={{
          width: "100%",
          maxWidth: 1024,
          padding: 0,
          height: "fit-content",
        }}
      >
        {showModal && (
          <div
            className={classNames(
              "tw-max-w-screen-lg tw-w-full tw-border-none tw-bg-white tw-rounded tw-outline-none tw-max-h-screen tw-flex tw-flex-col, tw-min-h-screen"
            )}
          >
            {actionType === "event" && (
              <DetailEvents
                eventID={selectedItem.id}
                customBack={() => {
                  setShowModal(false);
                  setShowMap(false);
                }}
              />
            )}
            {actionType === "club" && (
              <PerfilClub
                handleClose={() => {
                  setShowModal(false);
                  setShowMap(false);
                }}
                handleOpenModals={() => { }}
              />
            )}
            {actionType === "court" && (
              <div
                className="tw-flex tw-flex-col tw-w-full scroll tw-p-5"
                style={{
                  overflowX: "hidden",
                }}
              >
                <PreviewCourt
                  showModal={showModal}
                  setShowModal={() => {
                    setShowModal(false);
                    setShowMap(false);
                  }}
                  courtID={selectedItem.uid}
                />
              </div>
            )}
          </div>
        )}
      </Rodal>
    );
  };
  
  useEffect(() => {
    if (typeURL) {
      setActive([typeURL, "Chile"])
    }
  }, [typeURL])

  const findRegion = useMemo(() => REGIONS.find(e => e.label === regionURL), [regionURL])
  useEffect(() => {
    if (findRegion) {
      setLat(findRegion.lat)
      setLng(findRegion.lng)
      setZoom(8)
    }
  }, [findRegion])

  

  useEffect(() => {
    dispatch(getAllClubs());
    dispatch(getAllCourts());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getAllEvents(tipo_usuario));
  }, [tipo_usuario, dispatch]);

  useEffect(() => {
    setEvents(props.events?.map((event) => ({ ...event, uid: event.id })) || []);
    setClubs(
      props.clubs
        ?.filter((club) => club?.settings)
        .map((club) => ({ ...club, lat: club.settings.lat, lng: club.settings.lng, region: club.settings.region })) || []
    );
    setCourts(props.courts?.map((court) => ({ ...court, uid: court.key })) || []);
  }, [props.events, props.clubs, props.courts]);

  const findSportEvent = useMemo(() => events.find(e => e.categoria?.deporte === sportURL), [events, sportURL])
  const findSportCourt = useMemo(() => courts.find(e => e.type === sportURL), [courts, sportURL])

  const returnFilters = useMemo(() => {
    
    let items = [];
    const typesAction = {
      Canchas: courts,
      Eventos: events,
      Clubes: clubs
    }
    
    items = typesAction[active[0]] || [...events, ...clubs, ...courts];
    return items.filter(({ region, fechaInicio, actionType, categoria, type }) => {
        return region?.includes(findRegion?.label || "") && 
        (!dateUrl || moment(fechaInicio).isSameOrAfter(dateUrl)) && 
        (categoria?.deporte.includes(findSportEvent?.categoria?.deporte || "") || actionType !== "event") &&
        (type?.includes(findSportCourt?.type || "") || actionType !== "court")
      })
      .sort((a,b) => {
        if (a.uid === idURL) return -1;
        if (a.fechaInicio && b.fechaInicio){
          if (moment(a.fechaInicio).isBefore(b.fechaInicio)) return -1
          if (moment(b.fechaInicio).isBefore(a.fechaInicio)) return 1
          if (a.categoria?.deporte) {
            if (a.categoria?.deporte === sportURL) return -1
          }
          if (a.type) {
            if (a.type === sportURL) return -1
          }
          return 0
        }
        return 1
      })

  }, [events, active, clubs, courts, idURL, findRegion, dateUrl, findSportEvent, findSportCourt, sportURL]);

  const findLastSearch = returnFilters.find(e => e.uid === selectID)

  useEffect(() => {
    if (findLastSearch) {
      setSelectedItem(findLastSearch)
      setShowModal(true)
    }
  }, [findLastSearch])

  useEffect(() => {
    const findSearch = returnFilters.find(item => {

      const { actionType } = item

      const typesAction = {
        court: item.key,
        event: item.id,
        club: item.uid
      }
      return (typesAction[actionType] || "") === idURL
    })

    if (findSearch) {
      setLat(findSearch.lat)
      setLng(findSearch.lng)
      setSelectedItem(findSearch)
      setShowCard(true)
      setZoom(12)
    }
  }, [returnFilters, idURL])

  const setCords = (lat = defaultLat, lng = defaultLng, zoom, e) => {
    setLat(lat + 0.0001);
    setLng(lng + 0.0001);
    setTimeout(() => {
      setLat(lat);
      setLng(lng);
    }, 20);
    setZoom(zoom);
    e.stopPropagation();
    setShowMap(true);
  };

  const renderIcons = () => {
    return returnFilters.map((item, idx) => {
      const { actionType } = item;
      let key = "marker-" + item.id;
      let text = "Eventos";
      let Icon = GiTrophyCup;
      const newLat = parseFloat(item.lat || defaultLat);
      const newLng = parseFloat(item.lng || defaultLng);
      if (actionType === "club") {
        key = "marker-" + item.uid + idx;
        text = "Clubes";
        Icon = GiGreekTemple;
      } else if (actionType === "court") {
        key = "marker-" + item.key + idx;
        text = "Canchas";
        Icon = GiTennisCourt;
      }
      return (
        <Marker key={key} lat={newLat} lng={newLng} text={text}>
          <Icon
            color="white"
            className="icon-custom-torneo"
            onClick={() => handlePreviewCard(item)}
          />
        </Marker>
      );
    });
  };
  function goBack() {
    navigate("/");
  }

  return (
    <div className="tw-grid tw-h-screen tw-w-full tw-rounded tw-shadow tw-text-left tw-bg-white tw-grid-rows-2-auto-fr tw-overflow-hidden tw-relative">
      <div
        className="tw-bottom-20 tw-z-20 tw-fixed tw-h-0 tw-flex tw-justify-center md:tw-hidden"
        style={{ width: width, right: 0 }}
      >
        <div className="tw-w-fit">
          <div
            className={classNames(
              "info-map-row-2-title tw-transition-transform tw-cursor-pointer",
              {
                "tw-scale-75": !showMap,
              }
            )}
            onClick={() => setShowMap((v) => !v)}
          >
            <h2>Mapa</h2>
            <img className="info-logo" src={logo} alt="tennis-up" />
          </div>
        </div>
      </div>
      <div className="tw-min-w-full tw-px-5 tw-pt-2">
        <FaArrowCircleLeft
          className="tw-text-Turquesa tw-w-12 tw-h-12 tw-cursor-pointer"
          onClick={() => goBack()}
        />
      </div>

      <div className="tw-flex tw-flex-wrap tw-h-full tw-w-full tw-grid-rows-1 tw-overflow-auto tw-overflow-x-hidden">
        <div className="tw-flex tw-flex-col tw-h-full tw-flex-1 tw-basis-96">
          <div className="tw-px-5">
            <h1 className="tw-font-semibold tw-text-lg xs:tw-text-2xl">
              Encuentra eventos y recintos tenísticos cerca de tí
            </h1>
          </div>
          <div className="tw-flex tw-flex-col tw-pl-5 tw-mb-5">
            <div className="info-map-search-type">
              <h3 className="tw-text-xs tw-mb-1">¿Que deseas buscar?</h3>
              <div className="tw-flex tw-gap-2">
                {searchBy.map((search, index) => (
                  <FilterBtn
                    handleClick={() => {
                      setActive([search, active[1]]);
                      setZoom(12);
                      setShowCard(false);
                    }}
                    key={"info-btn" + index}
                    text={search}
                    active={active[0] === search}
                  />
                ))}
              </div>
            </div>
            <div className="info-map-search-location">
              <h3 className="tw-text-xs tw-mb-1">Lugar</h3>
              <div className="tw-grid tw-grid-cols-2-auto-fr tw-w-full tw-gap-2">
                {searchIn.map((search, index) => (
                  <FilterBtn
                    handleClick={() => {
                      setActive([active[0], search]);
                      setShowCard(false);
                      setZoom(5);
                    }}
                    key={"info-btn-country-" + index}
                    text={search}
                    active={active[1] === search}
                  />
                ))}
                <Suspense fallback={null}>
                  <div className="tw-w-full tw-overflow-hidden">
                    <SliderFilter
                      list={REGIONS}
                      nameList="regionsBtn"
                      handleClick={(e, region) => {
                        setCords(region.lat, region.lng, 8, e);
                        setShowCard(false);
                      }}
                      activefunction={(region) => lat === region.lat && lng === region.lng}
                    />
                  </div>
                </Suspense>
              </div>
            </div>
          </div>
          <Suspense fallback={null}>
            <div className="info-map-location tw-justify-center scroll">
              {returnFilters.map((item, idx) => {
                const { actionType } = item;
                let Component;
                let key;
                if (actionType === "court") {
                  Component = RenderCourt;
                  key = item.key + idx;
                }
                if (actionType === "event") {
                  Component = RenderEvent;
                  key = item.id + idx;
                }
                if (actionType === "club") {
                  Component = RenderClub;
                  key = item.uid + idx;
                }
                return (
                  <div className="tw-w-72" key={key}>
                    {Component && (
                      <Component
                        item={item}
                        handleClick={setCords}
                        handleClickModal={handleModal}
                        isPlayer={isPlayer}
                        isNotPlayer={isNotPlayer}
                      />
                    )}
                  </div>
                );
              })}
              <div className="tw-pb-11 tw-w-full"></div>
            </div>
          </Suspense>
        </div>
        <div
          ref={ref}
          className={classNames(
            "info-map-row-2 tw-flex-1 tw-grow-max tw-basis-96 md:tw-h-full md:tw-relative tw-absolute tw-bottom-0 tw-w-full tw-z-10 md:tw-z-auto tw-transition-all",
            {
              "tw-h-full": showMap,
              "tw-h-0": !showMap,
            }
          )}
        >
          <Suspense fallback={null}>
            <div className="tw-w-full tw-transition-all tw-h-full">
              <Map lat={lat || -29.90241} lng={lng || -71.25159} zoom={zoom} disable>
                {renderIcons()}
                {renderPreviewCard()}
              </Map>
            </div>
          </Suspense>
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default connect(mapStateToProps)(MapEvents);
