import { memo, useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import "../../styles/SingleWeekCalendar.css";
import moment from "moment";
import { useMemo } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import HeaderWeek from "./HeaderWeek";
import BodyWeek from "./BodyWeek";

const SingleWeekCalendar = ({
  week,
  selectedDay,
  setWeek,
  setselectedDay,
  UpdateData,
  price,
  isClub,
  numberDay = 7,
  start = moment(new Date()),
  containerScrollID,
  staticWeek = false,
}) => {
  const [isSeleccionando, setIsSeleccionando] = useState(false);
  const ref = useRef();
  const mobile = useMediaQuery("(max-width: 620px)");

  const days = useMemo(() => {
    let startDay = staticWeek
      ? moment(start).startOf("isoWeek")
      : moment(start);
    const tempDays = [];
    for (let index = 0; index < numberDay; index++) {
      let name = startDay.format("dddd");
      if (startDay.isSameOrBefore(start)) {
        const date = moment(start).format("YYYY-MM-DD");
        Object.entries(week?.[name]?.hour || {}).forEach(([hKey, hValue]) => {
          const date1 = moment(
            moment(date + " " + hKey, "YYYY-MM-DD HH:mm").format(
              "YYYY-MM-DD HH:mm"
            )
          );
          const date2 = moment(start).format("YYYY-MM-DD HH:mm");
          if (!isClub && moment(date1).isSameOrBefore(date2)) {
            week[name].hour[hKey] = 0;
          }
        });
      }
      tempDays.push({
        name,
        disponibility: week?.[name]?.disponibility ?? true,
        date: !staticWeek && startDay.toDate(),
        selectables: isClub,
        hours: week?.[name]?.hour || {},
      });
      startDay.add(1, "day");
    }
    return tempDays;
  }, [numberDay, start, isClub, week, staticWeek]);
  const bodyDays = useMemo(() => {
    if (!mobile) return days;
    const day = days.find((day) => day.name === selectedDay.name);
    return [day];
  }, [days, mobile, selectedDay]);

  const colormap = useMemo(
    () => [
      {
        visible: true,
        label: "Disponible",
        className: "disponible",
        labelStyles: { backgroundColor: "#1275a5" },
      },
      {
        visible: true,
        label: "No disponible",
        className: "nodisponible",
        labelStyles: { backgroundColor: "#efefef" },
      },
      {
        visible: !isClub,
        label: "Ocupada",
        className: "courtocupada",
        labelStyles: { backgroundColor: "#d44517" },
      },
      {
        visible: !isClub,
        label: "Seleccinado",
        className: "ds-selected disponible",
      },
    ],
    [isClub]
  );

  const CalculeHours = useCallback(
    (items) => {
      const groupClass = selectedGroup.current;
      if (groupClass) {
        items = items.filter((el) => el.classList.contains(groupClass));
      }
      setIsSeleccionando(false);
      let auxWeek = { ...week };
      var dateObj = {};
      var dates = items?.map((i) => {
        let dayName = i.id.split("--")[0];
        let hour = i.id.split("--")[1];
        dateObj[i.id] = auxWeek[dayName].hour?.[hour] === 1 ? 0 : 1;
        if (isClub) {
          if (!auxWeek[dayName].hour) auxWeek[dayName].hour = {};
          auxWeek[dayName].hour = {
            ...auxWeek[dayName].hour,
            [hour]: dateObj[i.id],
          };
        }
        return {
          dayName,
          day: auxWeek[dayName],
          hour,
        };
      });
      if (isClub) {
        setWeek(auxWeek);
      }
      if (!isClub) {
        const compareTimezones = (tz1, tz2, format) =>
          moment(tz1, format).format("x") - moment(tz2, format).format("x");

        dates = dates.sort((a, b) => compareTimezones(a.hour, b.hour, "HH:mm"));
        if (!dates.length > 0) {
          UpdateData({
            day: { start: null, end: null },
            duracion: 0,
            total: 0,
          });
          return;
        }
        var Inicio = Object.keys(dates[0]?.day?.hour).findIndex(
          (h) => h === dates[0]?.hour
        );
        if (Inicio) Inicio = Inicio / 2;
        var Fin = Object.keys(dates[dates.length - 1]?.day?.hour).findIndex(
          (h) => h === dates[dates.length - 1]?.hour
        );
        if (Fin) Fin = Fin / 2 + 0.5;
        let dateStart = moment(
          dates[0].dayName + " " + dates[0].hour,
          "dddd HH:mm"
        ).toDate();
        if (
          moment(week[dates[dates.length - 1].dayName].date).isAfter(dateStart)
        ) {
          dateStart = moment(dateStart).add(7, "day").toDate();
        }
        if (!isNaN(dateStart)) {
          let dateEnd = moment(
            dates[dates.length - 1].dayName +
              " " +
              dates[dates.length - 1].hour,
            "dddd HH:mm"
          )
            .add(30, "minute")
            .toDate();
          if (
            moment(week[dates[dates.length - 1].dayName].date).isAfter(dateEnd)
          ) {
            dateEnd = moment(dateEnd).add(7, "day").toDate();
          }
          const duracion = moment(dateEnd).diff(dateStart, "minute") / 60;
          dateStart = dateStart.toISOString();
          dateEnd = dateEnd.toISOString();

          // formato a utilizar
          UpdateData({
            day: { start: dateStart, end: dateEnd },
            duracion: duracion,
            total: duracion * price,
          });
        } else {
          selectedGroup.current = "";
        }
      }
    },
    [UpdateData, isClub, price, setWeek, week]
  );
  const calculeRef = useRef(CalculeHours);
  const ChangeDay = (data) => {
    if (isClub) {
      var auxWeek = { ...week };
      if (auxWeek[data.name]) {
        auxWeek[data.name] = {
          ...auxWeek[data.name],
          disponibility: !auxWeek[data.name].disponibility,
        };

        setWeek({ ...auxWeek });
        setselectedDay(auxWeek[data.name]);
      }
    } else {
      setselectedDay(data);
      calculeRef.current?.([]);
    }
  };

  const [, setReloadData] = useState(true);

  useEffect(() => {
    let timer;
    let newCanchascrollDOM;
    const handleScrolltimer = () => {
      if (timer) clearTimeout(timer);
      setReloadData(false);
      timer = setTimeout(() => setReloadData(true), 100);
    };
    if (containerScrollID) {
      newCanchascrollDOM = document.querySelector(containerScrollID);
      if (newCanchascrollDOM) {
        newCanchascrollDOM.addEventListener("scroll", handleScrolltimer);
      }
    }

    return () => {
      if (newCanchascrollDOM)
        newCanchascrollDOM.removeEventListener("scroll", handleScrolltimer);
      if (timer) clearTimeout(timer);
    };
  }, [containerScrollID]);
  const nodes = useRef(null);

  useEffect(() => {
    nodes.current = ref.current?.querySelectorAll(
      isClub ? ".itemHour" : ".disponible"
    );
    return () => (nodes.current = null);
  }, [isClub]);
  const selectedGroup = useRef("");
  return (
    <div className={classNames("calendar-no-selectable")}>
      <form className="container-form-courts">
        <div
          className="form-column-rent-court tw-w-full"
          style={{ marginBottom: "0", display: "flex", alignItems: "center" }}
        >
          <HeaderWeek
            days={days}
            selectedDay={selectedDay}
            showNumberDay={!isClub}
            SetselectedDay={ChangeDay}
            staticWeek={staticWeek}
          />
          <BodyWeek
            days={bodyDays}
            Ref={ref}
            isSeleccionando={isSeleccionando}
            setIsSeleccionando={setIsSeleccionando}
            isClub={isClub}
            Callback={CalculeHours}
            selectedDay={selectedDay}
            isMobile={mobile}
          />
          <div className="CalendarHours-Description">
            <Labels colormap={colormap} />
          </div>
        </div>
      </form>
    </div>
  );
};

const Labels = ({ colormap }) => {
  return (
    <>
      {colormap
        ?.filter(({ visible }) => visible)
        .map(({ className, label, labelStyles }) => {
          const c = classNames(
            "court-days-width court-days circleItemHour tw-rounded-full",
            className
          );
          return (
            <div key={label} style={{ display: "flex", alignItems: "center" }}>
              <div style={{ maxHeight: 18 }}>
                <div className={c} style={labelStyles}></div>
              </div>
              <p style={{ margin: "0 5px" }}>{label}</p>
            </div>
          );
        })}
    </>
  );
};

export default memo(SingleWeekCalendar);
