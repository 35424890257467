import styled from "styled-components";

export const EventContainer = styled.div`
position: sticky;
height: 248px;
background-color: white;
box-shadow: 0px 4px 20px rgba(180, 172, 172, 0.25);
border-radius: 4px;
transition-duration: 200ms;
cursor: pointer;
border: 1px solid #e4e4e4;

@media screen and (max-width: 480px) {
  width: 100%;
  margin-bottom: 15px;
}
`;

export const EventImage = styled.img`
width: 100%;
height: 140px;
border-radius: 4px 4px 0px 0px;
object-fit: cover;
`;

export const Name = styled.h2`
font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
font-weight: bold;
font-size: 17px;
margin: 10px 0px 0px 10px;

@media (max-width: 480px) {
  font-size: 13px;
}
`;

export const Tipo = styled.h4`
font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
font-size: 14px;
color: #949494;
margin: 4px 30px 0px 10px;

@media (max-width: 480px) {
  font-size: 13px;
}
`;

export const Fecha = styled.h3`
font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
font-size: 16px;
font-weight: bold;
color: #000;
margin: 12px 30px 0px 10px;

@media (max-width: 480px) {
  font-size: 13px;
}
`;

// Estilo Modal Editar Torneo

export const customStylesEdit = {
content: {},
overlay: {
  backgroundColor: "rgba(0,0,0,0.75)",
},
};

export const customStylesDelete = {
content: {
  top: "33%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  border: "none",
},
overlay: {
  backgroundColor: "rgba(0,0,0,0.75)",
},
};

//Botón Modal
export const Button = styled.button`
position: relative;
padding-left: 32px;
padding-right: 32px;
padding-top: 12px;
padding-bottom: 12px;
border-radius: 8px;
border: none;
background-color: #000022;
color: #fff;
font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
font-weight: bold;
text-align: "right";
&:hover {
  background: #000022;
  opacity: 0.6;
}
cursor: pointer;
`;