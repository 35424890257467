import { colors } from '../costants/colors';

const Delete = (props) => {
    return (
      <svg {...props} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 14 16" fill="none">
        <path
          fill={colors.Verde.hover}
          d="M13.4 2.3H10v-.9c0-.4-.1-.7-.4-1-.3-.2-.7-.4-1-.4h-3c-.3 0-.7.2-1 .4-.3.3-.4.6-.4 1v.9H.6c-.2 0-.3 0-.4.2a.6.6 0 000 .8l.4.1h.6l.7 11c0 .9.8 1.6 1.7 1.6h6.8c1 0 1.6-.7 1.7-1.7l.7-10.9h.6l.4-.1a.6.6 0 000-.8.6.6 0 00-.4-.2zM4.7 13.7l-.4-.1a.6.6 0 01-.2-.4l-.3-8 .1-.4a.6.6 0 01.9 0L5 5l.3 8a.6.6 0 01-.2.4.6.6 0 01-.4.2zm2.9-.6c0 .2 0 .3-.2.4a.6.6 0 01-.8 0 .6.6 0 01-.2-.4v-8c0-.1 0-.3.2-.4a.6.6 0 01.8 0l.2.4v8zM8.8 2.3H5.2v-.9a.3.3 0 01.1-.2.3.3 0 01.2 0h3a.3.3 0 01.2 0 .3.3 0 010 .2v.9zm1.1 10.9c0 .1 0 .3-.2.4l-.4.1a.6.6 0 01-.4-.2.6.6 0 01-.2-.4l.3-8a.6.6 0 01.2-.4.6.6 0 01.7 0 .6.6 0 01.3.2v.3l-.3 8z"
        ></path>
      </svg>
    );
  }

export default Delete
