import { useState, useEffect } from "react";
import { colors } from "../../costants/colors";

const RenderTimeAdv = ({ timer,fechaLimite, timeover }) => {
  const [mensaje, setMensaje] = useState("");
  useEffect(() => {
    if (fechaLimite) {
      setMensaje("Las inscripciones cierran el " + fechaLimite);
    }
  }, [fechaLimite]);

  useEffect(() => {
    const { days, hours, minutes, seconds } = timer;
    let text = "";
    if (days === null) text = "";
    else if (hours <= -1 && minutes <= -1) text = "Las inscripciones han cerrado";
    else if (hours <= 12 && days < 1)
      text = `Rapido! solo faltan ${hours}hrs ${minutes}mins ${seconds}segs`;
    else if (days < 1)
      text = `Las inscripciones cierran en ${hours}hrs ${minutes}mins ${seconds}segs`;
    if (text) setMensaje(text);
  }, [timer]);
  return (
    <div
      className="detalle-alert"
      style={{ background: timeover ? '#EB5757' : colors.Verde.TenisCTA }}
    >
      <p style={{color:timeover?"white":colors.Turquesa, fontWeight:600}}>{mensaje}</p>
    </div>
  );
};

export default RenderTimeAdv;
