import Rodal from 'rodal';
// Estilos
import 'swiper/css';
import 'rodal/lib/rodal.css';
import { useMediaQuery } from '@material-ui/core';

const Modal = ({ children, show, onClose, width = 1024 }) => {
  const mobile = useMediaQuery('(max-width: 768px)');

  return (
    <Rodal
      width={width}
      height={900}
      visible={show}
      showCloseButton={false}
      showMask
      closeMaskOnClick
      onClose={onClose}
      customStyles={{
        maxHeight: !mobile ? 'calc(100vh - 100px)' : 'calc(100vh - 40px)',
        width: '100%',
        maxWidth: width,
        padding: 0,
        height: 'fit-content',
        borderRadius: 10,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </Rodal>
  );
};

export default Modal;
