
import Logo from "../../assets/logo.png";
import "../../styles/WelcomeClub.css";

const WelcomeClub = (props) => {
  // const dispatch = useDispatch()
  return (
    <div className="welcome-club-container">
      <div id="welcome-title">
        <h1>Bienvenido a </h1>
        <img id="logo-welcome" src={Logo} alt="logo"></img>
      </div>
      <p>
        A continuación en los siguientes pasos te haremos algunas preguntas sobre tu recinto
        tenistico
      </p>
    </div>
  );
};
export default WelcomeClub;
