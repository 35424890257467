import styled from "styled-components";
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
  },
};
export const Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    width: "60%",
    minWidth: 920,
    height: "70%",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
    zIndex: 100,
  },
};
export const ModalMobile = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    border: "none",
    width: "100vw",
    height: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
  },
};
export const Button1 = styled.button`
  position: relative;
  margin: 6px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  border: none;
  background-color: #000022;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  &:hover {
    background: #000022;
    opacity: 0.6;
  }
  cursor: pointer;
`;

export const Button2 = styled.button`
  position: relative;
  margin: 6px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  border: none;
  background-color: #000022;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  &:hover {
    background: #000022;
    opacity: 0.6;
  }
  cursor: pointer;
  margin-right: 50px;
`;
