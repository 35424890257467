function Tennis({size, color="#fff"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 25}
      viewBox="0 0 512 512"
    >
      <g fill={color} clipPath="url(#a)">
        <path d="M342 1.1a224 224 0 00-181 148.8c-4.5 13.7-7.3 27.1-23.5 114.1l-10.8 57.5-62.8 63C7.8 440.8 1.1 447.9.4 451.3c-1.4 7.8-.1 9.6 25.8 35.4 20.6 20.5 24.9 24.2 28 24.8 8.3 1.6 6 3.6 73.3-63.4l63-62.8 45-8.3c24.8-4.6 45.3-8.6 45.7-8.9.4-.4-.6-4.7-2.3-9.6-1.7-5-3.8-13.7-4.8-19.5l-1.8-10.5-7.4-2.1a114.1 114.1 0 01-30.6-13.7l-8.3-5.2 21-21 21-21 5 5c2.7 2.7 5.1 4.7 5.3 4.4a146.4 146.4 0 018.8-20.8c2.7-5 3.6-7.7 3-8.5-.7-.9 5.1-7.3 20.2-22.4 11.7-11.7 21.6-21 21.9-20.6.4.3 3.7-1.2 7.5-3.4 3.7-2.2 9.7-5.2 13.3-6.7a80 80 0 006.9-3.1c.3-.3-1.3-2.2-3.4-4.4l-3.9-4 21.2-21.3 21.2-21.2 23.7 23.6c23.7 23.6 23.8 23.7 30.8 25.9a141.6 141.6 0 0144.2 24.6c2.8 2.4 5.4 4.4 5.7 4.4a56 56 0 003.6-9.8c17-55.1 9.2-110-21.6-150.4a176.8 176.8 0 00-30.1-29.1A156.5 156.5 0 00391 2.5c-11.2-2-38.3-2.8-49-1.4zM389.5 33c12.7 2.7 27.7 8.7 38.3 15.3A55 55 0 01436 54c0 .3-9.2 9.7-20.5 21L395 95.5l-31-31c-17-17-30.6-31.3-30.2-31.6 1.6-1.6 17.5-2.8 31.2-2.4 9.4.3 18 1.2 24.5 2.5zm-53 47l37 37-21.3 21.2-21.2 21.3-42-42c-34.5-34.4-41.7-42.2-40.7-43.3A195 195 0 01269 58.5c5.5-3.6 28.1-15.4 29.5-15.5.6 0 17.7 16.7 38 37zm125.7 1.8a129.7 129.7 0 0116.9 96.4c-.3.4-14.6-13.2-31.6-30.2l-31-31L437 96.5A598.2 598.2 0 01458 76a35 35 0 014.2 5.8zm-193.9 57l41.7 41.7-21.3 21.3-21.2 21.2-37.3-37.3-37.2-37.2 5.8-11.6c3.2-6.4 8.4-15.4 11.5-20.2 6.2-9.3 14.4-19.7 15.5-19.7.4 0 19.5 18.8 42.5 41.8zM215.5 213l31 31-21 21-21 21-5.2-8.3a119.8 119.8 0 01-17.3-48.4c-1.8-12.9-.4-47.3 1.9-47.3.3 0 14.6 13.9 31.6 31zm-36.3 88.9a139.9 139.9 0 0046.4 40.5l7.6 4.4-5.3 1.1-21.7 4.1-16.3 3.1-6.2-5a189 189 0 01-24-24.2l-2.8-3.7 4.1-21.1 4-21.9c0-.4 2.3 3.3 5 8.2a156 156 0 009.2 14.5z"></path>
        <path d="M410.1 218.6a68.7 68.7 0 017.7 36.9A67 67 0 01396 305a67.3 67.3 0 01-48.9 21.8 68.7 68.7 0 01-36.8-7.4l-8.6-4.3.7 9c3.1 41.5 30.5 77 70 90.8 9.1 3.2 23.9 6.1 31.2 6.1h4.1l-4.2-8.4a69.2 69.2 0 01-7.3-36.7c.8-20 7.2-34.3 21.8-48.9 9.8-9.8 16.4-14 28.7-18.3a59 59 0 0120.8-3.5 68.7 68.7 0 0136.9 7.7l7.9 4-.7-8.2c-2-26.1-12.2-48-30.6-66.6a102.2 102.2 0 00-66.9-30.7l-8-.7 4 7.9z"></path>
        <path d="M364.9 219.9a129 129 0 00-28.8 18.7 111 111 0 00-26.2 37.4l-1.8 4.9 5.1 4.6c9 8.1 18 11.5 30.4 11.5a44.3 44.3 0 0044.4-44.4c0-12.3-4.9-24.3-13.2-32.3l-3.3-3.2-6.6 2.8zm91.6 117.2a44.6 44.6 0 00-30.5 42.3c0 12.4 3.4 21.4 11.5 30.4l4.6 5.1 5.2-2c23-9 45.3-30.6 55.3-53.8l3.3-7.6-3.2-3.3a46.3 46.3 0 00-46.2-11.1z"></path>
      </g>
      <defs>
        <clipPath id="a">
          <path fill={color} d="M0 0h512v512H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Tennis;
