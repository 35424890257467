import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getSingleClub, getSingleUser } from '../actions/auth';
import { useUser } from '../context/UserContext';
import { auth } from '../firebase/firebase';
import Loading from '../pages/Loading';

function mapStateToProps(state) {
  return {
    isRegistered: state.register.isRegistered,
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    existingUser: state.auth.existingUser,
    existingClub: state.auth.existingClub,
  };
}

const ProtectedRoutes = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, isVerifying, existingUser, existingClub } =
    useSelector(mapStateToProps);
  const { tipo_usuario, isClub, settings } = useUser();
  const location = useLocation();
  useEffect(() => {
    if (!isAuthenticated) {
    } else {
      if (auth.currentUser) {
        dispatch(getSingleClub(auth.currentUser.uid));
        dispatch(getSingleUser(auth.currentUser.uid));
      }
    }
  }, [isAuthenticated, dispatch, tipo_usuario]);
  if (isVerifying) return <Loading />;
  if (!isAuthenticated || (!existingUser && !existingClub && location.pathname !== '/'))
    return <Navigate to="/" replace />;
  if (settings || location.pathname === '/welcome/club') return <Outlet />;
  if (isClub) return <Navigate to="/welcome/club" />;
  return <Loading />;
};

export default ProtectedRoutes;
