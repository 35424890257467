import { useState, useEffect, useMemo, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";
import "../../../styles/EditProfileClub.css";
import { defaultUpdateClub } from "../../../actions/clubs";
import { MdEdit } from "react-icons/md";
import DefaultPhoto from "../../../assets/tennis-ball.png";
import Input from "../../ui/Input";
import PhoneInput from "../../ui/PhoneInput";
import Map from "../../mapa/Map";
// import Alert from "../../../components/alert/Alert";
// import { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import Select from "../../ui/Select";
import classNames from "classnames";
import { isValidPhoneNumber } from "react-phone-number-input";
import { checkImg } from "../../../services/Utils";

function mapStateToProps(state) {
  return {
    events: state.events,
    event: state.events.event,
    singleEventFetched: state.events.singleEventFetched,
    isUpdatingClub: state.club.isUpdatingClub,
    clubUpdated: state.club.clubUpdated,
  };
}

let lngReg = /^-?([1]?[1-7][1-9]|[1]?[1-8][0]|[1-9]?[0-9])\.{1}\d{1,6}/;
let latReg = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/;

const CHILE_REGIONS = require("../../../services/countries/chile_regions.json");

// yup.addMethod(yup.string, "phoneValidation", function (errorMessage) {
//   return this.test(`test-phone-validation`, errorMessage, function (value) {
//     const { path, createError } = this;

//     return (
//       (value && value.length === 16) ||
//       createError({ path, message: errorMessage })
//     );
//   });
// });
const defaultLat = -29.93225;
const defaultLng = -71.246173;
const schema = yup
  .object({
    name: yup.string().required("Inserte un nombre").min(6, "El nombre del club es muy corto"),
    about: yup
      .string()
      .required("Inserte una descripción")
      .max(100, "La descripcion debe contar con un maximo de 100 caracteres"),
    phone: yup
      .string()
      .required("El teléfono es requerido")
      .test("test-phone-validation", "El número no es válido", (value) =>
        isValidPhoneNumber(value ? "+" + value : "")
      ),
    ciudad: yup.string().required("Inserte una ciudad"),
    direccion: yup
      .string()
      .required("Inserte una direccion")
      .min(6, "El nombre de la direccion es muy corto"),
    region: yup.string().required("Inserte una region"),
    lat: yup
      .string()
      .required("Latitud no válida")
      .matches(latReg, "Longitud no válida, seleccione un lugar en el mapa")
      .default(defaultLat),
    lng: yup
      .string()
      .required("Longitud no válida")
      .matches(lngReg, "Longitud no válida, seleccione un lugar en el mapa")
      .default(defaultLng),
  })
  .required();

const EditProfileClub = (props) => {
  const { isUpdatingClub, welcomeClubSetup, dispatch, settings, onSubmit, setIsValid, setSubmit } =
    props;
  const {
    control,
    register,
    watch,
    setValue,
    reset,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const onSubmitData = (data) => {
    onSubmit?.({ ...data, logo });
  };
  const lat = watch("lat");
  const lng = watch("lng");
  const region = watch("region");
  const [logo, setLogo] = useState("");
  const [comunasList, setComunas] = useState([]);

  const regions = useMemo(
    () =>
      CHILE_REGIONS.map(({ region }, i) => ({
        key: "region-" + i,
        value: region,
        label: region,
      })),
    []
  );

  const comunas = useMemo(() => {
    const comunasList = {
      "": [],
    };
    CHILE_REGIONS.forEach(({ region, comunas }) => {
      comunasList[region] = comunas.map((co, j) => ({
        key: "comuna-" + j,
        value: co,
        label: co,
      }));
    });
    return comunasList;
  }, []);
  const refButton = useCallback(
    (node) => {
      setSubmit?.(node);
    },
    [setSubmit]
  );
  useEffect(() => {
    setIsValid?.(isValid);
  }, [setIsValid, isValid]);

  useEffect(() => {
    setComunas(comunas[region] || []);
  }, [region, comunas]);

  const getLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setValue("lat", position?.coords.latitude);
        setValue("lng", position?.coords.longitude);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, [setValue]);

  useEffect(() => {
    dispatch(defaultUpdateClub());
    getLocation();
  }, [dispatch, getLocation]);

  useEffect(() => {
    let { logo_200x200, logo_400x400, logo_800x800, logo } = settings;
    reset(settings);
    setLogo(logo_200x200 || logo_400x400 || logo_800x800 || logo || "");
  }, [settings, reset]);

  const handleChangePicture = async (event) => {
    event.preventDefault();
    if (event.target?.files.length === 1) {
      const file = event.target?.files[0];
      const fileReader = new window.FileReader();
      const filename = file?.name;
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setLogo({
          file: fileReader.result,
          filename: filename,
          blob: file,
        });
      };
    }
  };

  const onCircleInteraction = async (childKey, childProps, mouse) => {
    setValue("lat", mouse.lat);
    setValue("lng", mouse.lng);
    await trigger(["lat", "long"]);
  };

  // flex-direction: column;
  // justify-content: center;
  // overflow: hidden;
  // place-self: center;

  return (
    <div className={classNames("tw-w-full tw-max-w-7xl tw-text-center tw-flex, tw-px-10")}>
      <form
        onSubmit={handleSubmit(onSubmitData)}
        className="tw-flex tw-flex-wrap tw-gap-x-5 tw-justify-end"
      >
        <div id="club-container-image" className="tw-flex-grow tw-basis-44">
          <div style={{ position: "relative" }}>
            <img src={checkImg(logo) || DefaultPhoto} id="club-logo" alt="Imagen de perfil" />
            <div id="edit-foto-perfil-club">
              <MdEdit color="#333" size="20" />
              <input
                name="logo"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                onChange={handleChangePicture}
              />
            </div>
          </div>
        </div>
        <div className="tw-flex-wrap tw-flex tw-flex-grow-max tw-basis-0 tw-min-w-2/3 tw-gap-x-5 tw-max-w-2xl">
          <Input
            {...register("name")}
            placeholder="Ingrese el nombre de su club"
            error={errors.name?.message}
            label="Nombre del club"
            containerClass="tw-flex-1 tw-basis-60"
          />

          <Controller
            name="phone"
            control={control}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                label="Teléfono"
                placeholder="Ingresa tu número de teléfono"
                enableSearch
                value={value}
                onChange={(v) => {
                  onChange(v);
                }}
                error={errors.phone?.message}
                containerClass="tw-flex-1 tw-basis-60"
              />
            )}
          />
          <div className="custom-input-container perfil-textarea tw-min-w-full tw-flex-1">
            <label className="custom-input-label">
              Sobre Nosotros
              <textarea
                {...register("about")}
                className={errors.about ? "perfil-club-error" : "custom-input"}
                placeholder="Ingresa informacion de tu club"
                type="text"
              />
            </label>
            {errors.about && (
              <span className="perfil-club-errorMessage">{errors.about.message}</span>
            )}
          </div>
        </div>
        <Input
          {...register("direccion")}
          label="Direccion"
          placeholder="Ingrese su Dirección..."
          error={errors.direccion?.message}
          containerClass="tw-flex-1 tw-basis-60"
        />
        <Controller
          name="region"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Select
              label="Región"
              name={name}
              placeholder="Seleccione una region"
              value={value}
              onChange={({ value }) => onChange({ target: { name, value } })}
              options={regions}
              error={errors.region?.message}
              containerClass="tw-flex-1 tw-basis-60"
            />
          )}
        />
        <Controller
          name="ciudad"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Select
              label="Comuna"
              name={name}
              value={value}
              placeholder="Seleccione una comuna"
              onChange={({ value }) => onChange({ target: { name, value } })}
              options={comunasList}
              error={errors.ciudad?.message}
              containerClass="tw-flex-1 tw-basis-60"
            />
          )}
        />
        <div className="mapa-container perfil-mapa-container">
          <Map lat={lat} lng={lng} handleChildInteraction={onCircleInteraction} />
        </div>
        <div className="tw-flex tw-gap-x-5 tw-flex-wrap tw-w-full">
          <Input
            {...register("lng")}
            label="Longitud"
            placeholder="Selecciona una ubicación en el mapa"
            error={errors.lng?.message}
            disabled
            containerClass="tw-flex-1 tw-basis-44"
          />
          <Input
            {...register("lat")}
            label="Latitud"
            placeholder="Selecciona una ubicación en el mapa"
            error={errors.lat?.message}
            disabled
            containerClass="tw-flex-1 tw-basis-44"
          />
        </div>
        <button
          ref={refButton}
          disabled={isUpdatingClub}
          style={{
            opacity: isUpdatingClub ? 0.5 : 1,
            transform: isUpdatingClub && "none",
            display: welcomeClubSetup && "none",
          }}
          className="information-club-button"
        >
          {isUpdatingClub && <CircularProgress size={18} color="white" />}
          <p className={classNames({ "button-charging": isUpdatingClub })}>
            {isUpdatingClub ? "Guardando cambios..." : "Guardar perfil"}
          </p>
        </button>
      </form>
    </div>
  );
};

export default connect(mapStateToProps)(EditProfileClub);
