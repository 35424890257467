import { useState, useEffect, useCallback, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
// import { NavLink } from 'react-router-dom';

import { changeMenuState, changeMenuSelected } from "../../actions/menu";
import { isEditingEvent } from "../../actions/auth";
import { useResizeDetector } from "react-resize-detector";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

//style
import "../../styles/Dashboard.css";
// import { MdEventAvailable } from 'react-icons/md';
// import perfil from '../../assets/logo.png';

import User from "./User";

import { useParams } from "react-router-dom";
import LateralMenu from "./LateralMenu";
import { useUser } from "../../context/UserContext";

function mapStateToProps(state) {
  return {
    //menu
    isEditingEvent: state.auth.isEditingEvent,
  };
}
let ToastID = "";

const Dashboard = (props) => {
  let { dashboardPath } = useParams();
  const { isClub, settings } = useUser();
  const { width, ref } = useResizeDetector();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(isEditingEvent(false));
    dispatch(changeMenuSelected(dashboardPath));
  }, [dispatch, dashboardPath]);

  const handleClick = useCallback(
    (state) => {
      dispatch(changeMenuState(state));
    },
    [dispatch]
  );
  const noPopUp = useMemo(() => {
    if (!width) return window.innerWidth > 860;
    return width > 860;
  }, [width]);
  const [showToastConfig, setShowToastConfig] = useState(true);
  const [showToastConfigID, setShowToastConfigID] = useState(ToastID);
  const CloseToast = (id) => {
    toast.dismiss(id);
    setShowToastConfigID(null);
  };
  useEffect(() => {
    if (!isClub) setShowToastConfig(true);
  }, [isClub]);

  useEffect(() => {
    if (showToastConfig && !showToastConfigID)
      if (!isClub && settings) {
        const { padel, tenis } = settings.perfiljuego || {};
        if (
          !padel ||
          !tenis ||
          padel.categoria === "" ||
          padel.estiloJuego === "" ||
          padel.manoDominante === "" ||
          tenis.categoria === "" ||
          tenis.estiloJuego === "" ||
          tenis.manoDominante === ""
        ) {
          setShowToastConfig(false);
          toast(
            (t) => {
              ToastID = t.id;
              setTimeout(() => {
                CloseToast(t.id);
              }, 10000);
              return (
                <div className="toaster-notification">
                  <span>Completa tus perfiles de Juego</span>
                  <Link to="/editprofile">
                    <button style={{ backgroundColor: "#a1be11" }} onClick={() => CloseToast(t.id)}>
                      Ir a Config.
                    </button>
                  </Link>
                  <button onClick={() => CloseToast(t.id)}>Más tarde</button>
                </div>
              );
            },
            { duration: Infinity }
          );
          setShowToastConfigID(ToastID);
        }
      }
  }, [showToastConfig, isClub, settings, showToastConfigID]);

  return (
    <div ref={ref} style={{ userSelect: "none" }} className="dashboard-main-container">
      <LateralMenu noPopUp={noPopUp} handleClick={handleClick} />
      <div style={{ height: "100%", display: "grid", gridTemplateRows: "67px calc(100vh - 67px)" }}>
        <User handleClick={handleClick} popUp={width < 650} />
        {props.component}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Dashboard);
