import '../../styles/Pago.css';

const CashButton = ({ amount, onPress }) => {
    return (
        <div className="shadow" onClick={onPress}>
            <div className="efectivo-content">
                <h5>Pagar en el evento en efectivo</h5>
                <p>Pagarás $7.000 pesos más por pagar al llegar al evento</p>
                <h4>{`$${(+amount)+7000}`}</h4>
            </div>
        </div>
    )
}
export default CashButton
