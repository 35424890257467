import { lazy, Suspense, useMemo, useState } from "react";
import defaultClub1 from "../../../assets/default/1000x1000/clubs_1.jpg";
import defaultClub2 from "../../../assets/default/1000x1000/clubs_2.jpg";
import LoadingCad from "./LoadingCad";
const CardBtn = lazy(() => import("./CardBtn"));
const Card = lazy(() => import("./Card"));

const getRandomImg = () => {
  const img = [defaultClub1, defaultClub2];
  let index = Math.floor(Math.random() + 0.5);
  return img[index];
};

const RenderClub = ({ item, handleClick, handleClickModal, isPlayer, isNotPlayer, hideCardBtn, closeBtn }) => {
  const courts = useMemo(() => Object.values(item.courts || {}).length, [item.courts]);
  const [defaultClub] = useState(getRandomImg());

  const handleZoom = (e, zoom) => {
    handleClick(item.settings.lat, item.settings.lng, zoom, e);
  };
  const handleModal = (e) => {
    handleClickModal({ ...item, lat: item.settings.lat, lng: item.settings.lng }, e);
  };
  const { logo_400x400, logo_800x800, logo } = item.settings;
  return (
    <Suspense fallback={<LoadingCad />}>
      <Card
        onClick={(e) => handleZoom(e, 12)}
        logo={logo_400x400 || logo_800x800 || logo}
        defaultImg={defaultClub}
      >
        {!hideCardBtn && (
          <CardBtn
            action={(e) => handleZoom(e, 15)}
            isPlayer={isPlayer}
            isNotPlayer={isNotPlayer}
            closeBtn={closeBtn}
            modalAction={handleModal}
          />
        )}
        <div className="info-event-title">
          <h4>
            {item.settings.ciudad}, {item.settings.region}
          </h4>
        </div>
        <div className="info-event-status">
          <h3>{item.settings.name}</h3>
          <h4>{item.settings.direccion}</h4>
        </div>
        <div className="info-event-price">
          <h3>{`N° Teléfono: ${item.settings.phone}`}</h3>
          <h4>{`Canchas: ${courts}`}</h4>
        </div>
      </Card>
    </Suspense>
  );
};
export default RenderClub;
