import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

//style
import "../../styles/Dashboard.css";
import { getClubProfile } from "../../actions/profile";

import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";
function mapStateToProps(state) {
  return {
    mode: state.menu.mode,
    selected: state.menu.selected,
    user: state.auth.user.uid,
  };
}

const User = (props) => {
  const { handleClick, mode, user } = props;
  const dispatch = useDispatch();
  const { isClub, settings = {}, profileImage } = useUser();
  const { logo, imagen } = settings;
  const [error, setError] = useState(false);
  useEffect(() => {
    setError(false);
  }, [logo, imagen]);

  const defaultimg = profileImage.default;

  useEffect(() => {
    if (isClub) {
      dispatch(getClubProfile(user));
    }
  }, [dispatch, isClub, user]);

  if (settings)
    return (
      <div className="user-container">
        <FaBars
          className={"menu-action-" + mode}
          onClick={() => handleClick(mode)}
          color="#D5DAE2"
        />
        <Link to="/perfil/me">
          <div className="user-content">
            <img
              alt="imagen de usuario"
              className="user-img"
              src={
                error
                  ? defaultimg
                  : profileImage.x100 ||
                    profileImage.x200 ||
                    profileImage.x400 ||
                    profileImage.x800 ||
                    profileImage.original ||
                    defaultimg
              }
              onError={() => setError(true)}
            />
          </div>
        </Link>
      </div>
    );
};

export default connect(mapStateToProps)(User);
