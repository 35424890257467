import CrearEvento from "../components/events/CreateTorneo";
import { connect } from "react-redux";

function mapStateToProps(state) {
  return {
    //menu
    mode: state.menu.mode,
  };
}

const CreatePage = (props) => {
  const { mode } = props;

  return (
    <div className={"main-container-" + mode}>
      <CrearEvento />
    </div>
  );
};

export default connect(mapStateToProps)(CreatePage);
