import { IconContext } from "react-icons";
import { BsCircleFill } from "react-icons/bs";

const Indicator = ({ color, text }) => {
  return (
    <div className="tw-flex">
      <IconContext.Provider
        value={{
          color,
          className: "tw-mx-2",
        }}
      >
        <BsCircleFill />
      </IconContext.Provider>
      <p className="tw-font-medium tw-text-GrayTenis">{text}</p>
    </div>
  );
};

const RentColorIndicator = () => {
  return (
    <div className="tw-flex tw-mt-5 tw-flex-wrap sm:tw-flex tw-justify-center tw-select-none">
      <Indicator color="#C9EAFF" text="Disponible" />
      <Indicator color="#DBC9BC" text="No Disponible" />
      <Indicator color="#FA5519" text="Ocupada" />
      <Indicator color="#2085B7" text="Seleccionado" />
    </div>
  );
};

export default RentColorIndicator;
