import { useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import classNames from "classnames";

//components
import EventsRow from "../components/events/EventsRow";

//Images
import LogoBlanco from "../assets/logo-blanco.png";

//Styles
import "../styles/Header.css";
import LoginModal from "./LoginModal";
import Modal from "react-modal";
import styled from "styled-components";

//Redux
import { connect } from "react-redux";
import { getAllEvents, getSingleEvent } from "../actions/events";
import { changePestañaSelected, changeLoginOpen } from "../actions/header";
import { FaUserAlt } from "react-icons/fa";
import { BiUserCircle } from "react-icons/bi";
import Finder from "./finder/Finder";
import OptionsFinder from "./finder/OptionsFinder";
import { useSearchParams } from "react-router-dom";

function mapStateToProps(state) {
  return {
    //all-events
    mode: state.menu.mode,
    events: state.events.events,
    header: state.header,
    info: state.auth.info,
    isLoginopen: state.header.isLoginopen,
    isLoginClub: state.header.isLoginClub,
    loginSection: state.header.loginSection,
  };
}

const Header = ({
  contentSelected,
  setContentSelected,
  isChange,
  setIsChange,
  ...props
}) => {
  const [params] = useSearchParams();
  const nextRoute = params.get("nextRoute")
  const selectID = params.get("selectID")
  const [selectType, setSelectType] = useState("");
  const [searchSelect, setSearchSelect] = useState("")
  const [ubicacion, setUbicacion] = useState("");
  const [fecha, setFecha] = useState("");
  const [tipo, setTipo] = useState("");

  const [eventos, setEventos] = useState(false);
  const { width, height, ref } = useResizeDetector();
  const { dispatch, info, isLoginopen } = props;
  const [isRegistering, setisRegistering] = useState("Menu Principal");

  const [premium, setPremium] = useState(false);

  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });

  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (nextRoute) {
      dispatch(changeLoginOpen(true))
      setisRegistering("Registro User")
    }
  }, [nextRoute, dispatch])

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset >= 920) {
        setSticky({ isSticky: true, offset: height });
      } else {
        setSticky({ isSticky: false, offset: 0 });
      }
    };
    const isMobile = () => {
      if (width <= 640) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };
    isMobile();

    const handleScrollEvent = () => {
      handleScroll();
    };
    window.addEventListener("scroll", handleScrollEvent);

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, [height, width]);

  useEffect(() => {
    dispatch(getAllEvents(info?.tipo_usuario));
  }, [dispatch]); // eslint-disable-line

  const goToEvent = (uid) => {
    dispatch(getSingleEvent(uid));
  };

  const renderEventos = () => {
    return (
      <div>
        <div>Eventos disponibles</div>
        <EventsRow
          events={props.events}
          goToEvent={(uid) => goToEvent(uid)}
          tipo={0}
          isEventUpcoming={true}
        />
      </div>
    );
  };

  const renderIrCuenta = () => {
    if (mobile) {
      return (
        <div
          style={{ border: '1px solid rgb(235, 235, 235) ' }}
          className="tw-bg-white tw-fixed tw-z-10 tw-bottom-0  tw-h-20 tw-w-full  tw-text-sm tw-text-black hover:tw-text-Verde-TenisCTA"
        >
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-4" onClick={() => dispatch(changeLoginOpen(true))}>
            <BiUserCircle size={30} className="tw-text-black hover:tw-text-Verde-TenisCTA" />
            <button>
              Ir a mi cuenta
            </button>
          </div>
          {isLoginopen && (
            <LoginModal
              toggleModal={() => dispatch(changeLoginOpen(false))}
              showLogin={isLoginopen}
              width={width}
              isRegistering={isRegistering}
              setisRegistering={setisRegistering}
              nextRoute={nextRoute}
              selectID={selectID}
            />
          )}
        </div>
      )
    } else {
      return (
        <div
          className={classNames("container-header-account tw-mt-6")}

        >
          {!sticky.isSticky && (
            <FaUserAlt size={32} style={{ marginBottom: 5, marginRight: 5 }} />
          )}
          <button
            className={"home-header-account-sticky tw-text-sm xs:tw-text-xl"}
            onClick={() => dispatch(changeLoginOpen(true))}
          >
            Ir a mi cuenta
          </button>

          {isLoginopen && (
            <LoginModal
              toggleModal={() => dispatch(changeLoginOpen(false))}
              showLogin={isLoginopen}
              width={width}
              isRegistering={isRegistering}
              setisRegistering={setisRegistering}
              nextRoute={nextRoute}
              selectID={selectID}
            />
          )}
        </div>
      );
    }

  };

  const renderFinder = useMemo(() => {
    let options = []
    const finderOptions = {
      Todos:
        <Finder
          isWeb
          isGeneral
          selectType={selectType}
          searchSelect={searchSelect}
          setSearchSelect={setSearchSelect}
          ubicacion={ubicacion}
          setUbicacion={setUbicacion}
          fecha={fecha}
          setFecha={setFecha}
          tipo={tipo}
          setTipo={setTipo}
        />,
      Eventos:
        <Finder
          isWeb
          isEvents
          selectType={selectType}
          searchSelect={searchSelect}
          setSearchSelect={setSearchSelect}
          ubicacion={ubicacion}
          setUbicacion={setUbicacion}
          fecha={fecha}
          setFecha={setFecha}
          tipo={tipo}
          setTipo={setTipo}
        />,
      Clubes:
        <Finder
          isWeb
          isClubs
          selectType={selectType}
          searchSelect={searchSelect}
          setSearchSelect={setSearchSelect}
          ubicacion={ubicacion}
          setUbicacion={setUbicacion}
          fecha={fecha}
          setFecha={setFecha}
          tipo={tipo}
          setTipo={setTipo}
        />,
      Canchas:
        <Finder
          isWeb
          isCourts
          selectType={selectType}
          searchSelect={searchSelect}
          setSearchSelect={setSearchSelect}
          ubicacion={ubicacion}
          setUbicacion={setUbicacion}
          fecha={fecha}
          setFecha={setFecha}
          tipo={tipo}
          setTipo={setTipo}
        />
    }
    options = finderOptions[selectType];
    return options
}, [selectType, fecha, searchSelect, tipo, ubicacion])

  const cambiarContenido = (selected) => {
    dispatch(changePestañaSelected(selected));
    window.scrollTo({ top: 0, behavior: "smooth" });
    setContentSelected(selected);
  };

  useEffect(() => {
    if (contentSelected !== "") {
      setIsChange(true)
    }
    if (contentSelected === "inicio") {
      setIsChange(false)
    }
  }, [contentSelected, setIsChange])

  //Renderiza ambos Modals
  const renderModal = () => {
    return (
      <div>
        <Modal
          isOpen={eventos}
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setEventos(false)}
        >
          <button onClick={() => setEventos(false)} type="button" class="close">
            <span> x </span>
          </button>
          {props.events !== null ? renderEventos() : "Cargando"}
        </Modal>
        <Modal
          isOpen={premium}
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setPremium(false)}
        >
          <div className="modal-header" style={{ width: "200px", height: "50px" }}>
            <Label> Pronto disponible! </Label>
            <button onClick={() => setPremium(false)} type="button" class="close">
              <span> x </span>
            </button>
          </div>
        </Modal>
      </div>
    );
  };
  return (
    <>
      <div
        ref={ref}
        className={"tw-flex tw-justify-between tw-items-center tw-fixed tw-top-0 tw-w-full tw-z-10 tw-bg-black tw-duration-1200"}
        id="home-header"
        style={{
          zIndex: 1,
          position:
            width > 920
              ? sticky.isSticky
                ? "fixed"
                : "initial"
              : isLoginopen
                ? "absolute"
                : "fixed",
        }}
      >
        <div className="tw-flex tw-flex-col tw-items-center tw-content-between">
          <img
            src={sticky.isSticky ? LogoBlanco : mobile === true ? LogoBlanco : LogoBlanco}
            alt="tenis up logo"
            className={classNames("home-logo tw-w-28 xs:tw-w-40 tw-mt-4 xs:tw-mt-3", {
              "home-logo-sticky": sticky.isSticky,
            },
              { "tw-hidden": mobile }
            )}
            // className={sticky.isSticky ? "home-logo-sticky" : "home-logo"}
            onClick={() => cambiarContenido("inicio")}
          />
        </div>
        {sticky.isSticky && !mobile &&
          <div style={{ height: 100 }}>
            <Finder isSticky label="Empieza a buscar recintos y eventos tenísticos" />
          </div>

        }
        {sticky.isSticky && mobile &&
          <div style={{ height: 80 }}>
            <Finder isSticky label="Empieza a buscar!" />
          </div>}
        {renderIrCuenta()}
        {renderModal()}
      </div>
      <div className={classNames("tw-hidden xs:tw-flex tw-w-1/2 tw-flex-row tw-justify-evenly tw-items-center tw-absolute tw-left-0 tw-right-0 tw-m-auto tw-top-9 tw-z-10", {
        "tw-hidden xs:tw-flex tw-flex-row": sticky.isSticky,
        "home-header-buttons-change": isChange
      })}>
        {/* <div style={{ display: "flex", justifyContent: "space-evenly", backgroundColor: "black" }}> */}
        <h3
          className={sticky.isSticky ? "home-header-button-sticky" : "home-header-button-event"}
          onClick={() => cambiarContenido("eventos")}
        >
          <a>Eventos</a> {/*eslint-disable-line */}
        </h3>
        <h3
          className={sticky.isSticky ? "home-header-button-sticky" : "home-header-button"}
          onClick={() => cambiarContenido("instrucciones")}
        >
          <a>Como funciona</a> {/*eslint-disable-line */}
        </h3>
        <h3
          className={sticky.isSticky ? "home-header-button-sticky" : "home-header-button"}
          onClick={() => cambiarContenido("sponsor")}
        >
          <a>¿Quieres ser nuestro sponsor?</a> {/*eslint-disable-line */}
        </h3>
      </div>
      <div className={classNames("tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-4 tw-pb-4 tw-absolute tw-left-0 tw-right-0 tw-m-auto tw-top-28 xs:tw-top-36 tw-z-10", {
        "tw-hidden": isChange
      })}>
        <OptionsFinder
          label={mobile ? "¿Que estás buscando?" : "¿Que deseas buscar?"}
          placeholder={!mobile && "¿Que buscas?"}
          selectType={selectType}
          setSelectType={setSelectType}
          setSearchSelect={setSearchSelect}
          setUbicacion={setUbicacion}
          setFecha={setFecha}
          setTipo={setTipo}
          isMobil={width <= 800}
          searchSelect={searchSelect}
          ubicacion={ubicacion}
          fecha={fecha}
          tipo={tipo}
        />
        {selectType !== "" && <div className="tw-rounded-full tw-bg-white tw-p-1 tw-flex tw-flex-row tw-justify-between tw-w-auto">
          {renderFinder}
          {/* <div className="tw-rounded-full tw-bg-Verde-TenisCTA tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-1 tw-mt-1 tw-mb-1 tw-mr-2 tw-pr-4 tw-pl-4 tw-cursor-pointer">
            <FaSearch size={20} />
            <label className="tw-font-bold tw-pt-1">Buscar</label>
          </div> */}
        </div>}
      </div>
    </>
  );
};

export default connect(mapStateToProps)(Header);

const Label = styled.label`
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 33px;
  color: #000000;
`;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
  },
};
