import { update, ref, onValue } from "firebase/database";
import { db, auth } from "../firebase/firebase";
import { uploadImage } from "../services/images/images.service";

//Edicion de perfil
export const DEFAULT_PROFILE = "DEFAULT_PROFILE";
export const EDIT_PROFILE_REQUEST = "EDIT_PROFILE_REQUEST";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAILURE = "EDIT_PROFILE_FAILURE";

// Perfil Club
export const PROFILE_CLUB_REQUEST = "PROFILE_CLUB_REQUEST";
export const PROFILE_CLUB_SUCCESS = "PROFILE_CLUB_SUCCESS";
export const PROFILE_CLUB_FAILURE = "PROFILE_CLUB_FAILURE";

//Default de eventos
const requestDefaultProfile = () => {
  return {
    type: DEFAULT_PROFILE,
  };
};
//Creacion de eventos
const requestEditProfile = () => {
  return {
    type: EDIT_PROFILE_REQUEST,
  };
};

const receiveEditProfile = () => {
  return {
    type: EDIT_PROFILE_SUCCESS,
  };
};

const errorEditProfile = () => {
  return {
    type: EDIT_PROFILE_FAILURE,
  };
};

//Profile Club
const requestClubProfile = () => {
  return {
    type: PROFILE_CLUB_REQUEST,
  };
};

const receiveClubProfile = (club) => {
  return {
    type: PROFILE_CLUB_SUCCESS,
    club,
  };
};

const errorClubProfile = () => {
  return {
    type: PROFILE_CLUB_FAILURE,
  };
};

export const editProfile = (settings, imagen) => async (dispatch) => {
  dispatch(requestEditProfile());
  try {
    const UID = auth.currentUser.uid;

    if (imagen) {
      delete settings.imagen;
      uploadImage(`usuarios/${UID}.jpg`, imagen, `users/${UID}/settings/imagen`);
    }

    await update(ref(db, `users/${UID}/settings/`), settings);

    dispatch(receiveEditProfile());
    console.log("success");
  } catch (error) {
    console.log(error);
    dispatch(errorEditProfile());
  }
};

export const getClubProfile = (uid) => (dispatch) => {
  dispatch(requestClubProfile());
  onValue(
    ref(db, `clubes/${uid}`),
    (snapshot) => {
      dispatch(receiveClubProfile(snapshot.val()));
    },
    () => {
      dispatch(errorClubProfile());
    }
  );
};

export const defaultProfile = () => async (dispatch) => {
  dispatch(requestDefaultProfile());
};
