import React, { useEffect, useMemo, useState } from 'react';
import { getUserImages } from '../../services/user/user.services';
import perfil from '../../assets/perfil.png';
import classNames from 'classnames';

const UserImage = ({ uuid, size = 0, className, editSize = false }) => {
  const [images, setImages] = useState({ default: perfil });
  useEffect(() => {
    const getImages = async () => {
      if (uuid) {
        const images = await getUserImages(uuid);
        if (!images.error) setImages({ ...images, default: perfil });
      } else setImages({ default: perfil });
    };
    getImages();
  }, [uuid]);
  const image = useMemo(() => {
    let imageOutput = images?.imagen || perfil;
    if (size <= 800) imageOutput = images?.imagen_800x800 || imageOutput;
    if (size <= 400) imageOutput = images?.imagen_400x400 || imageOutput;
    if (size <= 200) imageOutput = images?.imagen_200x200 || imageOutput;
    if (size <= 100) imageOutput = images?.imagen_100x100 || imageOutput;
    return imageOutput;
  }, [images, size]);
  return (
    <div
      className={classNames('tw-rounded-full tw-overflow-hidden', className, {
        'tw-w-20 tw-h-20': !editSize,
      })}
    >
      <img
        src={image}
        alt="userImage"
        className={classNames(
          'tw-rounded-full tw-overflow-hidden tw-object-cover tw-object-center',
          className,
          { 'tw-w-20 tw-h-20': !editSize }
        )}
      />
    </div>
  );
};

export default UserImage;
