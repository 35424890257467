import { useCallback, useEffect, useMemo, useState } from 'react';
import { getTotalQuotes } from '../services/Utils';
import useUserCategoria from './useUserCategoria';
import useCategorias from './useCategorias';
import { auth } from '../firebase/firebase';

const useCupos = ({
  deporte,
  settings,
  event,
  getTotal,
  eventReplace,
  myEvents,
  userID,
}) => {
  const [inscrito, setInscrito] = useState(false);
  const [inscritoName, setInscritoName] = useState('');

  const [payment_method, setpayment_method] = useState('');
  const { categoria } = useUserCategoria({ deporte, settings });

  const { cupos = {}, inscritos = {}, isDual, id } = event || {};
  const totalcupos = useMemo(() => getTotalQuotes(cupos), [cupos]);
  const inscritosList = useMemo(() => {
    const listInscritos = {};
    Object.entries(inscritos).forEach(([key, categ]) => {
      Object.entries(categ || {}).forEach(([key2, users]) => {
        listInscritos[`${key2}-${key}`] = {
          ...users,
          uid: key2,
          categoria: key,
          id,
        };
      });
    });

    return listInscritos;
  }, [inscritos, id]);

  const getInscritobyiD = useCallback(
    (uid) => {
      return Object.values(inscritosList).find((i) => i.uid === uid);
    },
    [inscritosList]
  );
  const getAvailability = useMemo(() => {
    if (getTotal) return totalcupos;
    let disponibles = 0;
    disponibles =
      (cupos?.[eventReplace || categoria] || 0) -
      Object.keys(inscritos?.[eventReplace || categoria] || {}).length;
    return disponibles >= 0 ? disponibles : 0;
  }, [inscritos, cupos, categoria, getTotal, eventReplace, totalcupos]);

  useEffect(() => {
    let uuid = userID || auth.currentUser?.uid;
    const ownerEvent = myEvents?.find(
      (evento) =>
        evento.id === id && (!isDual || evento.categoria === eventReplace)
    );
    const { payment_method = null, categoria } = ownerEvent || {};
    const insc = inscritos?.[eventReplace]?.[uuid];
    const newCategoria = (insc && eventReplace) || (!isDual && categoria);
    setInscrito(newCategoria);
    setpayment_method(payment_method);
  }, [inscritos, eventReplace, id, userID, isDual, myEvents]);

  const { getCategorias } = useCategorias({ cupos });

  useEffect(() => {
    const { categoria: categoriaE } =
      myEvents?.find((evento) => evento.id === id) || {};
    let primeraCategoria =
      getCategorias.find((e) => e === (categoriaE || categoria)) ||
      getCategorias[0];
    if (
      deporte === 'tenis' &&
      (primeraCategoria !== categoria || primeraCategoria !== 'Abierta')
    ) {
      primeraCategoria = categoria;
    }
    setInscritoName(primeraCategoria);
  }, [getCategorias, deporte, myEvents, id, categoria]);

  return {
    getCategorias,
    getAvailability,
    categoria,
    totalcupos,
    inscrito,
    inscritoName,
    payment_method,
    inscritosList,
    getInscritobyiD,
  };
};

export default useCupos;
