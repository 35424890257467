import { Component } from 'react'
import Thumbnails from '../../assets/thumb.png'
import appStore from '../../assets/app-store.png'

//style
import '../../styles/EncuentraTenistas.css'

export default class EncuentraTenistas extends Component {
    render() {
        return (
            <div className='land-content'>
                <div className='land-text-container'>
                    <h1>ENCUENTRA TENISTAS <br /> CERCA TUYO</h1>
                    <p>Tenis-Up te permite conectar con jugadores de tu región. Envía mensajes, crea marcadores e inscríbete a torneos.</p>
                    <div style={{marginBottom:16}}>
                        <a target="_blank" rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=cl.tennisup.tennisup&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img style={{width: 160, height: 58}} alt='Disponible en Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/es-419_badge_web_generic.png' /></a>
                        <a target="_blank" rel="noopener noreferrer" href='https://apps.apple.com/cl/app/tenis-up/id1501218525?ign-mpt=uo=2'><img alt='Disponible en App Store' src={appStore} style={{width: 160, height: 58,padding: 9}} /></a>
                    </div>
                    <h5 className='google-trademark-text'>Google Play y el logotipo de Google Play son marcas comerciales de Google LLC.</h5>
                </div>
                <div className='land-screenshots-container'>
                    <img alt ="Imagen-phone" className='land-screenshots' src={Thumbnails} />
                </div>
            </div>
        )
    }
}
