import styled from "styled-components";
const TitleEvents = ({ title }) => {
  return <Title>{title}</Title>;
};

export default TitleEvents;

const Title = styled.h1`
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 28px;
  margin-left: 15px;
`;
