import classNames from 'classnames';
import { FaArrowCircleLeft } from 'react-icons/fa';

const Title = ({
  goBack,
  title,
  containerStyles = {},
  containerClass = '',
  size = '3xl',
  textCenter = false,
  titleType = 'principal',
}) => {
  const textSize = `tw-text-${size || '3xl'}`;
  return (
    <div
      className={classNames(
        'TitleEvent tw-h-15',
        {
          'tw-text-Turquesa': titleType === 'principal',
          'tw-text-gray-700': titleType !== 'principal',
        },
        containerClass
      )}
      style={{ ...containerStyles }}
    >
      {goBack && (
        <FaArrowCircleLeft
          onClick={goBack}
          size={30}
          className="tw-absolute tw-cursor-pointer"
          style={{
            fontSize: 30,
          }}
        />
      )}
      {/* font-size: 32px;*/}
      <h1
        className={classNames(
          'tw-mb-0 tw-w-full',
          {
            'tw-pl-10': !textCenter,
            'tw-text-center': !!textCenter,
            'tw-font-bold': titleType === 'principal',
          },
          textSize
        )}
      >
        {title}
      </h1>
    </div>
  );
};

export default Title;
