import '../styles/Home.css'


import Login from '../components/home/Instrucciones/Login'
import Eventos from '../components/home/Instrucciones/Eventos'
import Disp from '../components/home/Instrucciones/Disp'
import Conf from '../components/home/Instrucciones/Conf'


const Funciones = () => {
  return (
    <div className='home-container'>
      <Login />
      <Conf />
      <Disp />
      <Eventos />
    </div>
  )
}

export default Funciones;
