import { Component } from 'react'


import login from '../../../assets/instrucciones/login.jpeg'


import '../../../styles/Login.css'


// Librerias para jugar


export default class Login extends Component {
    render() {
        return (
            <div className='login-content'>
                <div className='login-screenshots-container'>
                    <img alt="login" className='login-screenshots' src={login} />
                </div>
                <div className='login-text-container'>
                    <h1>Inicia sesión</h1>
                    <p>Primero que todo, debes registrarte o iniciar sesión con tu usuario de Facebook o de Google.</p>
                </div>
            </div>
        )
    }
}