import { useMemo } from "react";
import Suggestion from "./Suggestion";
import moment from "moment";

const Recomendaciones = ({
    courts,
    events
}) => {

    const renderCourts = useMemo(() => {
        return courts.sort((a, b) => {
            if (a.precio > b.precio) return 1
            if (a.precio < b.precio) return -1
            return 0
        })
    }, [courts])

    const renderEvents = useMemo(() => {
        return events.filter(({ fechaTermino }) => {
            return (moment().isSameOrBefore(fechaTermino))
        }).sort((a, b) => {
            if (Number(a.precio) > Number(b.precio)) return 1
            if (Number(a.precio) < Number(b.precio)) return -1
            return 0
        })
    }, [events])

    return (
        <div>
            {renderCourts.length > 0 && <Suggestion
                data={renderCourts}
                label="Inspiración para tu próximo juego"
                labelpay="Arrienda"
                labeltype="Cancha"
                colorCourt
            />}
           {renderEvents.length > 0 && <Suggestion
                data={renderEvents}
                label="Compite contra los mejores"
                labelpay="Inscripciones"
                labeltype="Torneo"
                colorEvent
            />}
        </div>
    )
}

export default (Recomendaciones);