
import { connect } from 'react-redux'
import { useMemo } from 'react'

//Swiper component
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";

//style
import '../../styles/Socios.css'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

//Logos
import ClubTenisLS from '../../assets/socios/LogoClubLS.jpg'
import GCoquimbo from '../../assets/socios/dlogo_cborde.jpg'
import Mach from '../../assets/socios/mach.png'

//MediaQuery
import useMediaQuery from "@material-ui/core/useMediaQuery";

function mapStateToProps(state) {
    return {
        events: state.events.events,
        event: state.events.event,
    };
}

const Socios = (props) => {
    const { events } = props
    const mobile = useMediaQuery("(max-width: 420px)");
    const sponsors = useMemo(() => {
        return Object.entries(events[0]?.sponsors || {});
    }, [events])

    return (
        <div className='socios-content'>
            <h1>COLABORADORES</h1>
            <Swiper
                slidesPerView={mobile ? 1 : 5}
                slidesPerGroup={mobile ? 1 : 5}
                loop={true}
                modules={[Pagination, Navigation, Autoplay]}
                autoplay={{ delay: mobile ? 2000 : 3000 }}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                allowTouchMove={false}
                className='mySwiper tw-w-full'
                style={{ zIndex: 0 }}
            >
                {sponsors.map(([key, value]) => {
                    return (
                        <SwiperSlide key={key} className="tw-w-full swiper-socios">
                            <img src={value.imagen} alt="sponsor" className='socios-img-content' />
                        </SwiperSlide>
                    )
                })}
                <SwiperSlide className="tw-w-full swiper-socios">
                    <img src={ClubTenisLS} alt="sponsor" className='socios-img-content' />
                </SwiperSlide>
                <SwiperSlide className="tw-w-full swiper-socios">
                    <img src={GCoquimbo} alt="sponsor" className='socios-img-content' />
                </SwiperSlide>
                <SwiperSlide className="tw-w-full swiper-socios">
                    <img src={Mach} alt="sponsor" className='socios-img-content' />
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default connect(mapStateToProps)(Socios);
