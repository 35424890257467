import { useContext, createContext, useMemo } from "react";
import { connect } from "react-redux";
import perfil from "../assets/perfil.png";
import clubPhoto from "../assets/tennis-ball.png";

const userContext = createContext();

function mapStateToProps(state) {
  return {
    user: state.auth.info,
  };
}

const UserProvider = ({ children, user }) => {
  return <userContext.Provider value={{ user }}>{children}</userContext.Provider>;
};

export default connect(mapStateToProps)(UserProvider);

export const useUser = () => {
  const { user } = useContext(userContext);
  const { tipo_usuario, settings, uid } = user || {};
  const isAdmin = useMemo(() => tipo_usuario === "admin", [tipo_usuario]);
  const isClub = useMemo(() => tipo_usuario === "club", [tipo_usuario]);
  const isPlayer = useMemo(
    () => tipo_usuario === "admin" || tipo_usuario === "tenista",
    [tipo_usuario]
  );
  const isNotPlayer = useMemo(
    () => !tipo_usuario, [tipo_usuario])
  const profileImage = useMemo(() => {
    let image = {};
    if (isPlayer) {
      image = {
        original: settings?.imagen,
        x100: settings?.imagen_100x100,
        x200: settings?.imagen_200x200,
        x400: settings?.imagen_400x400,
        x800: settings?.imagen_800x800,
        default: perfil,
      };
      return image;
    }
    if (isClub) {
      image = {
        original: settings?.logo,
        x100: settings?.logo_100x100,
        x200: settings?.logo_200x200,
        x400: settings?.logo_400x400,
        x800: settings?.logo_800x800,
        default: clubPhoto,
      };
      return image;
    }
  }, [settings, isPlayer, isClub]);
  return { user, settings, isAdmin, isPlayer, isClub, tipo_usuario, uid, profileImage, isNotPlayer };
};
