import {API} from '../../config/Config'
import axios from 'axios'

export const saveInscriptionCSV=async(usersData, key_torneo, isDual)=>{
    try{
        const response=await axios.post(`${API}/inscription/cvsUsers`,{key_torneo,users:usersData, isDual})
        return response
    }catch(e){
        console.log(e, "axios error");
        return e
    } 
}