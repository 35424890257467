import {
  DEFAULT_PROFILE,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAILURE,
  PROFILE_CLUB_REQUEST,
  PROFILE_CLUB_SUCCESS,
  PROFILE_CLUB_FAILURE

} from '../actions/profile';

const profileState = (
  state = {
    isEditingProfile: false,
    profileEdited: false,
    errorEditingProfile: false,


    isFetchingProfileClub: false,
    profileClubFetched: false,
    fetchPerfilClubError: false,
    club: {},

  },
  action
) => {
  switch (action.type) {
    case DEFAULT_PROFILE:
      return {
        ...state,
        isEditingProfile: false,
        profileEdited: false,
        errorEditingProfile: false,
      }
    case EDIT_PROFILE_REQUEST:
      return {
        ...state,
        isEditingProfile: true,
        errorEditingProfile: false,
        profileEdited: false,
      }
    case EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        isEditingProfile: false,
        profileEdited: true,
      }
    case EDIT_PROFILE_FAILURE:
      return {
        ...state,
        isEditingProfile: false,
        profileEdited: false,
        errorEditingProfile: true
      }

    case PROFILE_CLUB_REQUEST:
      return {
        ...state,
        isFetchingProfileClub: true,
        profileClubFetched: false,
        fetchPerfilClubError: false,
      }
    case PROFILE_CLUB_SUCCESS:
      return {
        ...state,
        isFetchingProfileClub: false,
        profileClubFetched: true,
        fetchPerfilClubError: false,
        club: action.club
      }
    case PROFILE_CLUB_FAILURE:
      return {
        ...state,
        isFetchingProfileClub: false,
        profileClubFetched: false,
        fetchPerfilClubError: true,
      }
    default:
      return state;
  }
}

export default profileState;