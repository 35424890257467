import { useEffect } from "react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

//Componentes de Noticias

import LogoPNG from "../../assets/logotenis.png";

import img1 from "../../assets/carousel/N1.png";
import img2 from "../../assets/carousel/N2.png";
import img3 from "../../assets/carousel/N3.png";
import img4 from "../../assets/carousel/N4.png";
import img5 from "../../assets/carousel/N5.png";
import img6 from "../../assets/carousel/N6.png";

//style
import "../../styles/Noticias.css";

//Redux
import { connect, useDispatch } from "react-redux";
import { getAllEvents } from "../../actions/events";
import { useUser } from "../../context/UserContext";

function mapStateToProps(state) {
  return {
    //all-events
    event: state.events.event,
    events: state.events.events,
  };
}

const CarouselLanding = () => {
  const dispatch = useDispatch();
  const { tipo_usuario } = useUser();
  useEffect(() => {
    dispatch(getAllEvents(tipo_usuario));
  }, [dispatch, tipo_usuario]);

 
  return (
    //Se utiliza para colocar flayer de Eventos Importantes!
    // <div ref={ref}>
    //   <div className="carousel-info banner-info">
    //     <img src={width < 800 ? eventoImg1 : eventoImg } alt="Imagen torneo" style={{ width: "100%" }} />
    //   </div>
    // </div>
    <Carousel
      autoPlay={100000}
      // autoPlay={0}
      animationSpeed={500}
      infinite
    >
      <div className="carousel-img-container">
        <div className="carousel-info">
          <img src={img1} alt="1" className="carousel-img" />
          <div className="carousel-img-overlay">
            <img src={LogoPNG} className="tw-max-h-26 xs:tw-max-h-62" alt="Logo tenis-up" />
            <h2>VIVE TU TENIS</h2>
            <p>
              Tenis-<b>Up</b> la aplicación que te permite jugar a solo un click <br />
              con distintos jugadores y en los mejores clubes del país.
            </p>
          </div>
        </div>
      </div>
      <div className="carousel-img-container">
        <div className="carousel-info">
          <img src={img2} alt="2" className="carousel-img" />
          <div className="carousel-img-overlay">
            <h1>ACTÍVATE, MEJORA TUS ESTADÍSTICAS Y SUMA PUNTOS PARA TÚ RANKING</h1>
          </div>
        </div>
      </div>
      <div className="carousel-img-container">
        <div className="carousel-info">
          <img src={img3} alt="3" className="carousel-img" />
          <div className="carousel-img-overlay">
            <h1>¡DESCUBRE CANCHAS CERCA DE TI!</h1>
          </div>
        </div>
      </div>
      <div className="carousel-img-container">
        <div className="carousel-info">
          <img src={img4} alt="4" className="carousel-img" />
          <div className="carousel-img-overlay">
            <h1>¡REVISA NUESTRO CALENDARIO DE EVENTOS TENÍSTICOS!</h1>
          </div>
        </div>
      </div>
      <div className="carousel-img-container">
        <div className="carousel-info">
          <img src={img5} alt="5" className="carousel-img" />
          <div className="carousel-img-overlay">
            <h1>¡PARTICIPA EN NUESTRAS COMPETENCIAS Y AVANZA EN EL RANKING!</h1>
          </div>
        </div>
      </div>
      <div className="carousel-img-container">
        <div className="carousel-info">
          <img src={img6} alt="6" className="carousel-img" />
          <div className="carousel-img-overlay">
            <h1>¡PARTICIPA EN LAS COMPETENCIAS DE TU REGIÓN!</h1>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

export default connect(mapStateToProps)(CarouselLanding);
