import { Seed, SeedItem, SeedTeam } from 'react-brackets';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import './brackets.css';
import { FaPencilAlt } from 'react-icons/fa';
import { BiShowAlt } from 'react-icons/bi';
import InterpretaSet from './InterpretaSet';
import { useUser } from '../../context/UserContext';
const trofeo = require('../../assets/trophy.png');
const SetItems = ({ seed, breakpoint, finish, handleClick, round }) => {
  const [team, setTeam] = useState(seed || {});
  useEffect(() => {
    const newSeed = seed || {};
    if (!newSeed.teams) newSeed.teams = [];
    if (newSeed.teams.length === 0) newSeed.teams.push({});

    if (newSeed.teams.length === 1 && !newSeed.teams[0].winner)
      newSeed.teams.push({});
    newSeed.teams.sort(
      (a, b) => Number(a.teamPosition || 0) - Number(b.teamPosition || 0)
    );
    setTeam({ ...newSeed } || {});
  }, [seed]);

  const hasTeam = team?.teams?.[0]?.id || team?.teams?.[1]?.id;
  return (
    <Seed
      mobileBreakpoint={breakpoint}
      style={{ fontSize: 12 }}
      className={classNames('lines-White', {
        'tw-mr-16': team?.teams?.[0]?.winner,
      })}
    >
      <SeedItem
        style={{
          backgroundColor: hasTeam ? '#00000000' : '#ababab75',
          boxShadow: 'none',
        }}
      >
        <div
          className={classNames('tw-flex tw-gap-3 tw-flex-col ', {
            'tw-opacity-0': !hasTeam,
          })}
        >
          {hasTeam ? (
            team?.teams?.map((player, index) => (
              <SeedTeam
                key={player.id ?? 'SeedTeam' + index}
                style={{ padding: 0 }}
              >
                <TeamItem
                  player={player}
                  team={team}
                  index={index}
                  handleClick={handleClick}
                  sets={round?.sets}
                />
              </SeedTeam>
            ))
          ) : (
            <div className="tw-h-20" />
          )}
        </div>
      </SeedItem>
    </Seed>
  );
};

export default SetItems;

const TeamItem = ({ player, index, team, handleClick, sets }) => {
  const { isAdmin } = useUser();
  if (player?.isBye) {
    return (
      <div
        className="tw-w-full tw-flex tw-items-center tw-rounded-md tw-p-2"
        style={{ backgroundColor: '#ababab75', height: 36 }}
      >
        Bye
      </div>
    );
  }
  const oponent = [1, 0];
  const clickable =
    (!player.readOnly &&
      !player.winner &&
      !team?.teams[oponent[index]]?.isBye &&
      player.id) ||
    (player.readOnly && !team?.teams[oponent[index]]?.isBye);
  return (
    <div
      className={classNames(
        'tw-w-full tw-rounded-md tw-px-2 tw-flex tw-flex-col xs:tw-h-9',
        {
          'tw-cursor-pointer': clickable,
        }
      )}
      onClick={() => clickable && handleClick?.(team)}
      style={{
        minHeight: 36,
        backgroundColor: team?.teams?.[0]?.winner
          ? 'white'
          : player.score > team?.teams[oponent[index]]?.score ||
            team?.teams?.[0]?.winner
          ? '#FABF2D'
          : '#ababab75',
        color: team?.teams?.[0]?.winner && '#00000099',
      }}
    >
      <div className="tw-flex tw-w-full tw-pr-1 tw-overflow-hidden">
        <div className="tw-flex-1 tw-text-left">{player.name || ''}</div>
        {!player.readOnly &&
          !player.winner &&
          !team?.teams[oponent[index]]?.isBye &&
          isAdmin &&
          player.id && (
            <div
              role="button"
              className="tw-rounded-full tw-w-5 tw-h-5 tw-flex tw-justify-center tw-items-center tw-font-extrabold tw-bg-transparent tw-text-white "
              onClick={() => player.id && handleClick?.(team)}
            >
              <FaPencilAlt size={16} style={{ marginRight: 4 }} />
            </div>
          )}
        {(player.readOnly || !isAdmin) && !team?.teams[oponent[index]]?.isBye && (
          <div
            role="button"
            className="tw-rounded-full tw-w-5 tw-h-5 tw-flex tw-justify-center tw-items-center tw-font-extrabold tw-bg-transparent tw-text-white "
            onClick={() => player.id && handleClick?.(team)}
          >
            <BiShowAlt size={16} style={{ marginRight: 4 }} />
          </div>
        )}
        {player.winner && (
          <img
            alt="winner"
            src={trofeo}
            className="tw-rotate-12 tw-transform tw-bottom-0 tw-select-none xm:tw-absolute tw-relative xm:tw--right-1/2"
          />
        )}
      </div>
      {!player.winner && (
        <div className="tw-flex tw-items-center tw-justify-between">
          <WhiteBall number={player.score} />
          {sets?.[team?.id] ? (
            <InterpretaSet
              set={sets?.[team?.id]}
              group={[player, team?.teams[oponent[index]]]}
              index={0}
              row={1}
            />
          ) : (
            <div className="tw-w-3">{player.id ? player.score || 0 : ''}</div>
          )}
        </div>
      )}
    </div>
  );
};
const WhiteBall = ({ number = 0 }) => {
  return (
    <div className="tw-flex tw-gap-1">
      <div
        style={{ opacity: number < 1 && 0.3 }}
        className="tw-w-2 tw-h-2 tw-bg-white tw-rounded-full"
      />
      <div
        style={{ opacity: number < 2 && 0.3 }}
        className="tw-w-2 tw-h-2 tw-bg-white tw-rounded-full"
      />
      <div
        style={{ opacity: number < 3 && 0.3 }}
        className="tw-w-2 tw-h-2 tw-bg-white tw-rounded-full"
      />
    </div>
  );
};
