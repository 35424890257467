import { useEffect, useState } from "react";
import "../styles/Home.css";
import { Navigate } from "react-router-dom";
import { changeLoginOpen, changeLoginSection } from "../actions/header";
//pages & components
import LandingImg from "../components/home/LandingImg";
import Header from "../components/Header";
import CarouselLanding from "../components/home/CarouselLanding";
import RealtimeCounts from "../components/home/RealtimeCounts";
import AUnToque from "../components/home/AUnToque";
import EventosCerca from "../components/home/EventosCerca";
import EncuentraTenistas from "../components/home/EncuentraTenistas";
import Socios from "../components/home/Socios";
import Footer from "../components/Footer";
import EventsRow from "../components/events/EventsRow";
import Recintos from "../components/home/Recintos";
import Privacidad from "../pages/Privacity";
import BlogEvents from "../pages/BlogEvent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Sponsor from "../pages/Sponsor";
import Videos from "../pages/Videos";
import Instrucciones from "../pages/Instrucciones";
import appStore from "../assets/app-store.png";
import Modal from "react-modal";
import { changePestañaSelected } from "../actions/header";

//redux
import { connect, useSelector, useDispatch } from "react-redux";

//images
import IMG from "../assets/landing/tenis-player.jpg";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import Recomendaciones from "../components/home/Recomendaciones/Recomendaciones";
import { getAllCourts } from "../actions/clubs";

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    header: state.header.selected,
    isLoginopen: state.header.isLoginopen,

    events: state.events.events,
    courts: state.club.courts,
    existingUser: state.auth.existingUser,
    existingClub: state.auth.existingClub,
  };
}

const HomePage = (props) => {
  const [courts, setCourts] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventos, setEventos] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [contentSelected, setContentSelected] = useState("");
  const [isChange, setIsChange] = useState(false)
  const mobile = useMediaQuery("(max-width: 420px)");
  const { isAuthenticated, isVerifying, isLoginopen, existingUser, existingClub } = useSelector(mapStateToProps)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCourts());
  }, [dispatch])

  useEffect(() => {
    let infoEvent = props.events?.filter((event) => event?.region === "Coquimbo").map((event) => ({ name: event.nombre, id: event.id, ciudad: event.ubicacion, region: event.region, actionType: "Eventos", tipo: event.categoria?.deporte,  precio: event.precio, imagen: event.logo, fechaTermino: event.fechaTermino }))
    let infoCourt = props.courts?.filter((court) => court?.region === "Coquimbo").map((court) => ({ name: court.name, club: court.name_club, id: court.key, ciudad: court.ciudad, region: court.region, actionType: "Canchas", tipo: court.type, precio: court.price, imagen: court.image }));
    setCourts(infoCourt)
    setEvents(infoEvent)
  }, [props.courts, props.events])

  const renderEventos = () => {
    const cambiarContenido = (selected, eventInfo) => {
      dispatch(changePestañaSelected(selected, eventInfo));
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (
      <>
        <div className="tw-w-full tw-max-h-1/2">
          <div className="tw-flex tw-items-center tw-text-center tw-justify-center tw-relative tw-text-white tw-shadow-lg tw-font-airnb tw-font-bold tw-tracking-normal tw-w-full">
            <img src={IMG} alt="1" className="tw-relative tw-w-full tw-h-screen-3/5 tw-object-cover" />
            <div 
            className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-absolute tw-h-full tw-w-full"
            style={{ background: "linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35))" }}
            >
              <h1 
                className="tw-w-3/4 tw-font-bitner tw-font-bold tw-text-2xl xs:tw-text-80 tw-text-white tw-z-10"
                style={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}  
              >
                EVENTOS
              </h1>
            </div>
          </div>
        </div>
        <div className="tw-mr-0 xs:tw-mr-52 tw-ml-6 xs:tw-ml-52 tw-content-center tw-mb-2.5 tw-mt-2.5">
          <h2 className="tw-font-objet tw-text-xl tw-font-bold">Eventos por venir</h2>
          <EventsRow
            events={props.events}
            goToEvent={() => setEventos(true)}
            tipo={0}
            isEventUp={true}
          />
          {renderModal()}
        </div>
        <div className="tw-mr-0 xs:tw-mr-52 tw-ml-6 xs:tw-ml-52 tw-content-center tw-mb-2.5 tw-mt-2.5">
          <h2 className="tw-font-objet tw-text-xl tw-font-bold">Eventos terminados</h2>
          <EventsRow
            events={props.events}
            goToEvent={(id) => cambiarContenido("Detalletorneoterminado", id)}
            tipo={0}
            isEventCompleted={true}
          />
          {renderModal()}
        </div>
      </>
    );
  };
  useEffect(() => {
    if (!isLoginopen) dispatch(changeLoginSection(""));
  }, [isLoginopen, dispatch]);
  useEffect(() => {
    //Al iniciar consulta
    const handleScrollEvent = () => {
      handleScroll();
    };
    window.addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  });

  const renderIrCuenta = () => {
    return (
      <div
        style={
          mobile
            ? { paddingRight: 40, textAlignLast: "center" }
            : { width: "100%", textAlign: "center", paddingRight: "120px" }
        }
      >
        <button
          onClick={() => {
            dispatch(changeLoginOpen(true));
            dispatch(changeLoginSection("Registro User"));
            setEventos(false);
          }}
          className="tw-font-airnb tw-text-white tw-text-lg tw-cursor-pointer tw-relative tw-left-1/4 tw-rounded-xl tw-pt-4 tw-pr-14 tw-pb-4 tw-pl-14 tw-mt-5 tw-mr-0 tw-mb-5 tw-ml-0 tw-bg-AzulButton hover:tw-bg-black"
        >
          Registrarme
        </button>
      </div>
    );
  };

  const handleScroll = () => {
    if (window.pageYOffset > 800) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const renderModal = () => {
    return (
      <div>
        <Modal
          isOpen={eventos}
          style={!mobile ? customStyles : mobileStyle}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setEventos(false)}
        >
          <button onClick={() => setEventos(false)} type="button" className="close">
            <span> x </span>
          </button>
          <h3 className="tw-font-airnb tw-mt-5 tw-mr-0 tw-mb-1 tw-ml-0 tw-text-center tw-text-xs xs:tw-text-base">
            ¡Regístrate y participa en los nuevos torneos de tenis up!
          </h3>
          {renderIrCuenta()}
          <h5 style={mobile ? { fontSize: 17, textAlign: "center" } : { textAlign: "center" }}>
            Descarga la aplicación
          </h5>
          <div className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-w-nearly xs:tw-w-full">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=cl.tennisup.tennisup&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            >
              <img
                alt="Disponible en Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/es-419_badge_web_generic.png"
                className="tw-max-w-xs tw-max-h-16"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/cl/app/tenis-up/id1501218525?ign-mpt=uo=2"
            >
              <img alt="Disponible en App Store" src={appStore} className="tw-max-w-xs tw-max-h-12 tw-mt-1" />
            </a>
          </div>
        </Modal>
      </div>
    );
  };

  const renderContenido = () => {

    let contenido = [];
    const renders = {
      instrucciones:
        <div
          className={sticky === true ? "tw-pt-0 tw-duration-1200" : "tw-mt-0 tw-duration-500 xs:tw-duration-1200"}
        >
          <Instrucciones />
        </div>,
      eventos:
        <div
          className={sticky === true ? "tw-pt-0 tw-duration-1200" : "tw-mt-0 tw-duration-500 xs:tw-duration-1200"}
        >
          {renderEventos()}
        </div>,
      recintos:
        <div
          className={sticky === true ? "tw-pt-0 tw-duration-1200" : "tw-mt-0 tw-duration-500 xs:tw-duration-1200"}
        >
          {/* <Clubes mode='desactivado' /> */}
          <h3>Recintos Disponibles</h3>
          <Recintos />
        </div>,
      privacidad:
        <div
          className={sticky === true ? "tw-pt-0 tw-duration-1200" : "tw-mt-0 tw-duration-500 xs:tw-duration-1200"}
        >
          <Privacidad />
        </div>,
      sponsor:
        <div
          className={sticky === true ? "tw-pt-0 tw-duration-1200" : "tw-mt-0 tw-duration-500 xs:tw-duration-1200"}
        >
          <Sponsor />
        </div>,
      Detalletorneoterminado:
        <div
          className={sticky === true ? "tw-pt-0 tw-duration-1200" : "tw-mt-0 tw-duration-500 xs:tw-duration-1200"}
        >
          <BlogEvents />
        </div>,
      videos:
        <div
          className={sticky === true ? "tw-pt-0 tw-duration-1200" : "tw-mt-0 tw-duration-500 xs:tw-duration-1200"}
        >
          <Videos />
        </div>
    }

    contenido = renders[props.header] ||
      <div
        className={sticky === true ? "tw-pt-0 tw-duration-1200" : "tw-mt-0 tw-duration-500 xs:tw-duration-1200"}
      >
        <CarouselLanding />
        <Recomendaciones courts={courts} events={events} />
        <EncuentraTenistas />
        <RealtimeCounts />
        <AUnToque />
        <EventosCerca />
        <Socios />
        <LandingImg />
      </div>

    return contenido;
  }
  if ((isVerifying || isAuthenticated) && (existingUser || existingClub)) {
    return <Navigate to="/explore" replace />;
  }
  return (
    <div className="tw-flex tw-flex-col tw-flex-1 tw-min-h-screen">
      <div className="tw-hidden xs:tw-flex tw-flex-col tw-fixed tw-top-96 tw-left-3 tw-z-50 tw-rounded-lg">
        <div className="icon-instagram tw-flex tw-flex-row tw-m-2.5 tw-transition-all tw-duration-200 tw-ease-in-out hover:tw-scale-110">
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/tenisup.cl/">
            <FaInstagram size="30" color="white" />
          </a>
        </div>
        <div className="icon-facebook tw-flex tw-flex-row tw-m-2.5 tw-transition-all tw-duration-200 tw-ease-in-out hover:tw-scale-110">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/Tenis-up-658558037907309"
          >
            <FaFacebook size="30" color="white" />
          </a>
        </div>
        <div className="icon-youtube tw-flex tw-flex-row tw-m-2.5 tw-transition-all tw-duration-200 tw-ease-in-out hover:tw-scale-110">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/channel/UCsz9PBKr1a27tMFuk-SycCQ/featured"
          >
            <FaYoutube size="30" color="white" />
          </a>
        </div>
      </div>
      <Header contentSelected={contentSelected} setContentSelected={setContentSelected} isChange={isChange} setIsChange={setIsChange} />
      <div className="tw-duration-500">{renderContenido()}</div>
      <div className="tw-h-50 tw-flex tw-bg-Verde-TenisCTA tw-justify-center tw-items-center tw-pl-0 xs:tw-pl-2 tw-pr-0 xs:tw-pr-2">
        <h4 className="tw-m-0 tw-font-objet tw-text-xs xs:tw-text-base tw-text-black tw-text-center hover:tw-text-Verde-TenisCTA">
          <span role="img" aria-label="mano">
            👋
          </span>{" "}
          ¡Hola! Por favor, completa nuestra{" "}
          <a
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLScSXUicRaKEJgmqDFmglbn84hpxOBW18DwY_oK0nk-xyrn92A/viewform"
            rel="noopener noreferrer"
            className="tw-font-bold tw-text-xs xs:tw-text-base tw-text-black"
          >
            encuesta de uso
          </a>{" "}
          y ayúdanos a seguir mejorando. 🎾
        </h4>
      </div>
      <Footer contentSelected={contentSelected} setContentSelected={setContentSelected} setIsChange={setIsChange} />
    </div>
  );
};

export default connect(mapStateToProps)(HomePage);

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
  },
};

const mobileStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    width: "100%",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
  },
};
