import { useEffect, useState, createRef } from "react";
import Modal from "react-modal";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useResizeDetector } from "react-resize-detector";
import Loading from "../../pages/Loading";
import FiltrosJugadores from "./jugadores/FiltrosJugadores";

//styles
import "../../styles/Jugadores.css";
import styled from "styled-components";

//Actions
import { connect } from "react-redux";
import { getAllPlayers } from "../../actions/jugador";
import { deletePlayer, editPlayer, defaultUpdatePlayer } from "../../actions/jugador";

import { MdEdit, MdKeyboardArrowLeft } from "react-icons/md";

//loading page

//images
import perfil from "../../assets/perfil.png";
import SinglePlayer from "./SinglePlayer";
import IsAdmin from "../IsAdmin";
import toast from "react-hot-toast";
import { useUser } from "../../context/UserContext";
import classNames from "classnames";
// import { Style } from "@material-ui/icons";

function mapStateToProps(state) {
  return {
    isFetchingEvents: state.events.isFetchingEvents,
    eventsFetched: state.events.eventsFetched,
    fetchEventsError: state.events.fetchEventsError,
    events: state.events.events,
    users: state.jugador.players,
    playersFetched: state.jugador.playersFetched,
    //Menú
    mode: state.menu.mode,
    selected: state.menu.selected,
    //Jugadores
    jugador: state.jugador,
  };
}

const Jugadores = (props) => {
  const { playersFetched, users } = props;
  const { tipo_usuario, isAdmin } = useUser();

  const [searchString, setSearchString] = useState("");
  const [filterCategoria, setFilterCategoria] = useState("");
  const [filterGenero, setFilterGenero] = useState("");
  const [filterCountries, setFilterCountries] = useState("");
  const [showEdit, setShowEditUser] = useState(false);
  const [showDelete, setShowDeleteUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [, setLoadingData] = useState(true);
  const [, setTipo] = useState("");

  //Edicion correcta de datos
  const [, setLoadingSuccess] = useState(false);

  // Almacenar datos del usuarios
  const [nombre, setNombre] = useState("");
  const [sexo, setGenero] = useState("");
  const [nacionalidad, setNacionalidad] = useState("");
  const [imagen, setImagen] = useState({ file: "", filename: "" });
  const [categoria, setCategoria] = useState("");
  const [filterUsers, setUsers] = useState([]);
  const { width, ref } = useResizeDetector();

  useEffect(() => {
    setStates();
  });

  let fileRef = createRef();

  const mobile = useMediaQuery("(max-width: 420px)");
  const { dispatch } = props;
  const { playerUpdated } = props.jugador;
  useEffect(() => {
    // dispatch(defaultProfile());
    if (playerUpdated) {
      setLoadingSuccess(true);
      setTimeout(() => {
        setLoadingSuccess(false);
        dispatch(getAllPlayers());
        // window.location.reload();
        dispatch(defaultUpdatePlayer());
      }, 2500);
    }
  }, [dispatch, playerUpdated]);

  useEffect(() => {
    dispatch(getAllPlayers());
  }, [dispatch]);

  //Controla los cambios de los inputs
  useEffect(() => {
    if (selectedUser.jugador) {
      const {
        nombre: selectedNombre,
        sexo: selectedSexo,
        nacionalidad: selectedNacionalidad,
        categoria: selectedCategoria,
      } = selectedUser?.jugador?.settings;

      setNombre(selectedNombre);
      setGenero(selectedSexo);
      setNacionalidad(selectedNacionalidad);
      setCategoria(selectedCategoria);
    }
  }, [selectedUser]);



  useEffect(() => {
    props?.users?.length > 0 ? setLoadingData(false) : setLoadingData(true);
  }, [props]);

  useEffect(() => {
    const renderFilters = () => {
      if (
        filterGenero.toLowerCase() === "" &&
        filterCountries.toLowerCase() &&
        filterCategoria.toLowerCase() &&
        searchString
      )
        return (
          users.sort((a, b) => {
            if (b.ranking === undefined && a.ranking !== undefined) return -1;
            if (a.ranking === undefined && b.ranking !== undefined) return 1;
            if (a.ranking < b.ranking) return -1;
            if (a.ranking > b.ranking) return 1;
            return 0;
          }) || []
        );
      return (
        users
          ?.filter((item) => {
            const { settings } = item;
            return (
              settings &&
              (settings.sexo?.toLowerCase() === filterGenero.toLowerCase() ||
                filterGenero.toLowerCase() === "") &&
              (settings.nacionalidad?.toLowerCase() === filterCountries.toLowerCase() ||
                filterCountries.toLowerCase() === "") &&
              (settings.categoria?.toLowerCase() === filterCategoria.toLowerCase() ||
                filterCategoria.toLowerCase() === "") &&
              (settings.nombre?.toLowerCase().includes(searchString.toLowerCase()) ||
                searchString === "")
            );
          })
          .sort((a, b) => {
            if (b.ranking === undefined && a.ranking !== undefined) return -1;
            if (a.ranking === undefined && b.ranking !== undefined) return 1;
            if (a.ranking < b.ranking) return -1;
            if (a.ranking > b.ranking) return 1;
            return 0;
          }) || []
      );
    };
    setUsers(renderFilters());
  }, [filterCategoria, filterCountries, filterGenero, users, searchString]);

  // const setStatesVacio = () => {
  //   setNombre("");
  //   setGenero("");
  //   setNacionalidad("");
  //   setCategoria("");
  // };

  const setStates = () => {
    setTipo(tipo_usuario);
  };

  const returnModalPregunta = () => {
    return (
      <Modal
        isOpen={showDelete}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setShowDeleteUser(false)}
      >
        <div className="mensaje">
          <h5> ¿Estás seguro de eliminar al usuario? </h5>
        </div>

        <div className="modal-body">
          <Button1 className="modal-button" onClick={() => setShowDeleteUser(false)}>
            <span>Cancelar</span>
          </Button1>

          <Button1 onClick={() => handleDeletePlayer(selectedUser)} className="modal-button">
            <span>Eliminar</span>
          </Button1>
        </div>
      </Modal>
    );
  };

  const returnModalConfirmacion = () => {
    return (
      <Modal
        isOpen={showConfirm}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setShowConfirm(false)}
      >
        <div className="mensaje">
          <h5>Se ha editado exitosamente el usuario</h5>
          <h5>{props.jugador.playerUpdated}</h5>
        </div>

        <div className="modal-body">
          <Button1 className="modal-button" onClick={() => window.location.reload()}>
            <span>Aceptar</span>
          </Button1>
        </div>
      </Modal>
    );
  };

  const handleChangePicture = async (event) => {
    event.preventDefault();
    if (event.target?.files.length === 1) {
      const file = event.target?.files[0];
      const fileReader = new window.FileReader();
      const filename = file?.name;
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setImagen({ file: fileReader.result, filename: filename, blob: file });
      };
    }
  };

  const returnUserEdit = () => {
    const { jugador } = selectedUser;
    return (
      <Modal
        isOpen={showEdit}
        style={!mobile ? Styles : ModalMobile}
        onRequestClose={() => setShowEditUser(false)}
      >
        {selectedUser !== "" ? (
          <div className="container-form">
            <div className="create-club-title">
              <div className="boton-izquierda">
                <MdKeyboardArrowLeft size="40" onClick={() => setShowEditUser(false)} />
              </div>
              <h2>Editar usuario</h2>
            </div>
            <div className="container-form-img-user">
              <img
                src={
                  imagen?.file !== ""
                    ? imagen.file
                    : jugador?.settings?.imagen !== ""
                    ? jugador?.settings.imagen
                    : perfil
                }
                className="config-perfil-foto-jugador"
                alt="Imagen de perfil"
              />
              <div className="edit-foto-perfil">
                <MdEdit color="#333" size="20" />
                <input
                  name="logo"
                  type="file"
                  ref={fileRef}
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleChangePicture}
                />
              </div>
            </div>
            <form className="container-form-columns">
              <div className="container-form-column-jugadores">
                <div className="form-column">
                  <div className="jugadores-event-input">
                    <label className="club-event-label">Nombre</label>
                    <input
                      className="jugadores-event-intext"
                      placeholder="Ingrese su Nombre..."
                      type="text"
                      name="nombre"
                      value={nombre}
                      onChange={(event) => setNombre(event.target.value)}
                    />
                  </div>
                  <div className="jugadores-event-input">
                    <label className="club-event-label">Pais</label>
                    <select
                      className="jugadores-event-intext"
                      name="tipo"
                      type="text"
                      value={nacionalidad}
                      onChange={(event) => setNacionalidad(event.target.value)}
                    >
                      <option value="">Seleccione una categoria</option>
                      <option value="CHL">Chile</option>
                      <option value="PER">Peru</option>
                      <option value="ARG">Argentina</option>
                      <option value="BRA">Brasil</option>
                      <option value="COL">Colombia</option>
                      <option value="VEN">Venezuela</option>
                      <option value="BOL">Bolivia</option>
                      <option value="URY">Uruguay</option>
                      <option value="PRY">Paraguay</option>
                      <option value="CRI">Costa Rica</option>
                      <option value="MEX">México</option>
                      <option value="USA">Estados unidos</option>
                      <option value="FRA">Francia</option>
                      <option value="ESP">España</option>
                      <option value="ENG">Inglaterra</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="container-form-column-jugadores">
                <div className="form-column">
                  <div className="jugadores-event-input">
                    <label className="club-event-label">Categoria</label>
                    <select
                      className="jugadores-event-intext"
                      name="tipo"
                      type="text"
                      value={categoria}
                      onChange={(event) => setCategoria(event.target.value)}
                    >
                      <option value="">Seleccione una categoria</option>
                      <option value="Novicio">Novicio</option>
                      <option value="4°">Cuarta</option>
                      <option value="3°">Tercera</option>
                      <option value="2°">Segunda</option>
                      <option value="1°">Primera</option>
                      <option value="Honor">Honor</option>
                    </select>
                  </div>
                  <div className="jugadores-event-input">
                    <label className="club-event-label">Genero</label>
                    <select
                      className="jugadores-event-intext"
                      name="tipo"
                      type="text"
                      value={sexo}
                      onChange={(event) => setGenero(event.target.value)}
                    >
                      <option value="">Seleccione un Genero</option>
                      <option value="Hombre">Hombre</option>
                      <option value="Mujer">Mujer</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
            <div className="jugadores-btn">
              <div className="jugadores-btn-cancelar">
                <button
                  className="jugadores-cancelar-event-boton"
                  onClick={() => setShowEditUser(false)}
                >
                  Cancelar
                </button>
              </div>
              <div className="jugadores-btn-aceptar">
                <button
                  style={playerUpdated ? { background: "green" } : { background: "black" }}
                  className="jugadores-crear-event-boton"
                  type="button"
                  onClick={() => handleEditPlayer(selectedUser)}
                >
                  {playerUpdated ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <div>{${playerUpdated ? 'Cargando..' : ''}}</div> */}
                      <div>
                        <div>Perfil Editado</div>
                      </div>
                      <svg className="checkmark" viewBox="0 0 52 52">
                        <circle className="checkmarkcircle" cx="26" cy="26" r="25" fill="none" />
                        <path
                          className="checkmarkcheck"
                          fill="none"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        />
                      </svg>
                    </div>
                  ) : (
                    "Editar"
                  )}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>Cargando...</div>
        )}
      </Modal>
    );
  };
  const handleEditPlayer = async (selectedUser) => {
    const { uid } = selectedUser;
    const data = {
      nombre: nombre,
      sexo: sexo,
      nacionalidad: nacionalidad,
      categoria: categoria,
    };
    try {
      await dispatch(editPlayer(data, uid, fileRef.current.files[0]));
      setTimeout(() => {
        setShowEditUser(false);
      }, 2500);
      toast.success("Se ha editado correctamente");
      // setShowConfirm(true)
    } catch (error) {
      setShowEditUser(true);
      console.error(error);
      toast.error("No se ha podido editar el jugador");
    }
  };
  const handleDeletePlayer = async (selectedUser) => {
    try {
      await dispatch(deletePlayer(selectedUser.uid));
      setShowDeleteUser(false);
      toast.success("Se ha eliminado correctamente");
    } catch (error) {
      console.log(error);
      toast.error("Hubo en error al intentar eliminar el Jugador");
    }
  };
  
  return (
    <div
      ref={ref}
      className="tw-grid tw-grid-rows-2-auto-fr sm:tw-p-8 tw-pt-1 sm:tw-pt-8 tw-w-full tw-bg-slate-100 scroll"
    >
      <FiltrosJugadores
        filterCategoria={filterCategoria}
        filterCountries={filterCountries}
        filterGenero={filterGenero}
        setFilterCategoria={setFilterCategoria}
        setFilterCountries={setFilterCountries}
        setFilterGenero={setFilterGenero}
        setSearchString={setSearchString}
        searchString={searchString}
      />

      {!playersFetched ? (
        <div className="tw-relative tw-w-full tw-flex tw-items-center tw-justify-center tw-bg-white">
          <Loading label="Cargando Jugadores" />
        </div>
      ) : (
        <div
          className={classNames(
            "scroll scroll-custom tw-pl-4 tw-flex-col tw-gap-x-4 tw-gap-y-1 tw-h-fit tw-max-h-full tw-bg-white",
            {
              "jugadores-tabla-item": width > 800,
              "jugadores-tabla-admin": width > 800 && isAdmin,
            }
          )}
        >
          {width > 800 && (
            <>
              <ColumHeader text="#" />
              <ColumHeader text="PERFIL" />
              <ColumHeader text="NOMBRE" />
              <ColumHeader text="GENERO" />
              <ColumHeader text="MANO DOMINANTE" />
              <ColumHeader text="PAÍS" />
              <ColumHeader text="HISTÓRICO" />
              <ColumHeader text="PUNTAJE" />
              <ColumHeader text="CATEGORÍA" />
              <IsAdmin>
                <ColumHeader text="ACCIONES" />
              </IsAdmin>
            </>
          )}
          {filterUsers?.map((jugador, i) => (
            <SinglePlayer
              index={i + 1}
              key={"SinglePlayer" + i}
              player={jugador}
              setSelectedUser={setSelectedUser}
              setShowEditUser={setShowEditUser}
              setShowDeleteUser={setShowDeleteUser}
              isAdmin={isAdmin}
              isMobil={width <= 800}
              container={false}
            />
          ))}
        </div>
      )}
      {returnModalPregunta()}
      {returnUserEdit()}
      {returnModalConfirmacion()}
    </div>
  );
};
const ColumHeader = ({ text }) => {
  return <div className="jugadores-tabla-header-title">{text}</div>;
};
//Estilos Modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
  },
};

const Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    width: "60%",
    height: "70%",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
  },
};
const ModalMobile = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    border: "none",
    width: "100vw",
    height: "100vh",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
  },
};

const Button1 = styled.button`
  position: relative;
  margin: 6px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  border: none;
  background-color: #000022;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  &:hover {
    background: #000022;
    opacity: 0.6;
  }
  cursor: pointer;
`;

export default connect(mapStateToProps)(Jugadores);
