import classNames from "classnames";
import { useMemo, memo } from "react";
import { useUser } from "../../context/UserContext";
import useCupos from "../../hooks/useCupos";
import useTimeToToday from "../../hooks/useTimeToToday";

const EventStatus = ({ event }) => {
  const { id, fechaLimite } = event || {};

  const [, timeover] = useTimeToToday(fechaLimite);
  const { user, settings, isClub } = useUser();
  const { deporte } = event?.categoria || { deporte: "tenis" };
  const { owner } = event
  const { getAvailability } = useCupos({
    deporte,
    settings: settings,
    event,
    getTotal: deporte === "padel",
  });

  const encontrar = useMemo(() => {
    const userInEvent =
      user?.torneos !== undefined && user?.torneos !== null ? Object.values(user.torneos) : [];
    return userInEvent.find((event) => event.id === id);
  }, [id, user?.torneos]);

  const Owner = owner === user?.uid

  const getClassName = useMemo(() => {
    if (timeover && !Owner) return { className: "tw-bg-black", text: "Evento Terminado" };
    if (encontrar) return { className: "tw-bg-green-600", text: "Estás inscrito" };
    if (getAvailability > 0) return { className: "tw-bg-indigo-700", text: "¡Cupos disponibles!" };
    if (!timeover && isClub && !Owner) return { className: "tw-bg-indigo-700", text: "Evento Disponible" };
    if (Owner) return { className: "tw-bg-green-600", text: "Mi evento" }
    return { className: "tw-bg-red-700", text: "No quedan cupos" };
  }, [encontrar, getAvailability, timeover, isClub, Owner]);

  return (
    <div className="tw-top-0 tw-absolute tw-right-0 tw-m-2">
      <h1
        className={classNames(
          "tw-text-xs tw-text-white tw-p-1 tw-rounded tw-shadow-sm",
          getClassName.className
        )}
      >
        {getClassName.text}
      </h1>
    </div>
  );
};

export default memo(EventStatus);
