import { useMemo } from "react";

const useUserCategoria = ({ deporte, settings }) => {
  const categoria = useMemo(() => {
    const namePerfilJuego = {
      paddel: "padel",
      padel: "padel",
      tenis: "tenis",
    };
    const { categoria } = settings?.perfiljuego?.[namePerfilJuego[deporte] || "tenis"] ||
      settings || { };
    return categoria;
  }, [deporte, settings]);
  return { categoria };
};

export default useUserCategoria;
