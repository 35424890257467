import { useState, useEffect } from "react";
import moment from "moment";

const useTimeToToday = (fechaLimite) => {
  const [timeover, setTimeOver] = useState(false);
  const [timer, setTimer] = useState({
    days: null,
    hours: null,
    minutes: null,
    seconds: null,
  });
  useEffect(() => {
    const setContador = () => {
      const diff = moment(fechaLimite).diff(moment(), "seconds");
      const newdays = Math.floor(diff / (60 * 60 * 24));
      const newhours = Math.floor((diff % (60 * 60 * 24)) / (60 * 60));
      const newminutes = Math.floor((diff % (60 * 60)) / 60);
      const newseconds = Math.floor(diff % 60);

      setTimer({
        days: newdays,
        hours: newhours,
        minutes: newminutes,
        seconds: newseconds,
      });
      setTimeOver(newhours <= -1 && newminutes <= -1);
    };
    if (fechaLimite) {
      setContador();
    }
  }, [fechaLimite]);

  return [timer, timeover];
};

export default useTimeToToday;
