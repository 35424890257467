import { onValue, push, ref, set, update } from "firebase/database";
import { db, auth } from "../firebase/firebase";

//Registro a eventos
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

//Cancelacion de registros
export const CANCEL_REGISTER_REQUEST = "CANCEL_REGISTER_REQUEST";
export const CANCEL_REGISTER_SUCCESS = "CANCEL_REGISTER_SUCCESS";
export const CANCEL_REGISTER_FAILURE = "CANCEL_REGISTER_FAILURE";

//cleanup
export const CLEAN_REGISTRATION = "CLEAN_REGISTRATION";

//Status
export const SET_STATUS_REGISTRATION = "SET_STATUS_REGISTRATION";

//Estado categoria "Abierta"
export const EVENT_REPLACE = "EVENT_REPLACE";

const eventReplace = (replace_categoria) => {
  return {
    type: EVENT_REPLACE,
    data: replace_categoria,
  };
};

const requestRegister = () => {
  return {
    type: REGISTER_REQUEST,
  };
};

const receiveRegister = () => {
  return {
    type: REGISTER_SUCCESS,
  };
};

const registerFailure = () => {
  return {
    type: REGISTER_FAILURE,
  };
};

const cleanRegistrationRequest = () => {
  return {
    type: CLEAN_REGISTRATION,
  };
};

const setStatusRegistration = (data) => {
  return {
    type: SET_STATUS_REGISTRATION,
    data: data,
  };
};

export const registerInEvent =
  (torneo, user, typeInscription, transaction_id, replace_categoria) => async (dispatch) => {
    dispatch(requestRegister());

    const USER_UID = auth.currentUser.uid;
    const { nombre, imagen, categoria } = user.settings;

    try {
      var event_transaction_key = transaction_id;
      if (typeInscription !== "webpay")
        event_transaction_key = push(ref(db, "transactions/eventos")).key;
      var data = {
        nombre: torneo.nombre,
        id: torneo.id,
        fechaInicio: torneo.fechaInicio,
        fechaTermino: torneo.fechaTermino,
        lugar: torneo.lugar,
        logo: torneo.logo,
        payment_method: typeInscription,
        transaction_id: event_transaction_key,
        categoria: replace_categoria || categoria,
      };
      onValue(
        ref(db, `users/${USER_UID}/torneos/${torneo.id}/categoria`),
        async (snapshot) => {
          data.categoria = snapshot.val() || data.categoria;
          await set(ref(db, `users/${USER_UID}/torneos/${torneo.id}`), data);
          await set(ref(db, `eventos/${torneo.id}/inscritos/${data.categoria}/${USER_UID}`), {
            nombre,
            imagen,
            payment_method: typeInscription,
          });
        },
        async () => {
          await set(ref(db, `users/${USER_UID}/torneos/${torneo.id}`), data);
          await set(
            ref(
              db,
              `eventos/${torneo.id}/inscritos/${
                replace_categoria ? replace_categoria : categoria
              }/${USER_UID}`
            ),
            {
              nombre,
              imagen,
              payment_method: typeInscription,
            }
          );
        },
        { onlyOnce: true }
      );

      if (typeInscription !== "webpay") {
        await update(ref(db, `transactions/eventos/${event_transaction_key}`), {
          key_torneo: torneo.id,
          status: "PENDING",
          uid: USER_UID,
          payment_method: typeInscription,
          amount: parseInt(torneo.precio) + 7000,
        });
      }
      dispatch(set_eventReplace(""));
      dispatch(receiveRegister());
    } catch (e) {
      dispatch(registerFailure());
    }
  };

export const cleanRegistration = () => (dispatch) => {
  dispatch(cleanRegistrationRequest());
};
export const set_StatusRegistration = (data) => (dispatch) => {
  dispatch(setStatusRegistration(data));
};

export const set_eventReplace = (data) => (dispatch) => {
  dispatch(eventReplace(data));
};
