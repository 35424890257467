import { API } from "../../config/Config";
import axios from 'axios'
const headers = { "Content-Type": "application/json" };

export const addScore = async (data) => {
    const url = `${API}/ranking/addScore`;
    try {
      const result = await axios.post(url, data, {
        headers: headers,
      });
      return result.data;
    } catch (err) {
      console.error("🚀 ~ file: Transaction.service.js ~ line 18 ~ create ~ err", err);
    }
};