import { Component } from 'react'


import conf from '../../../assets/instrucciones/conf.jpeg'

import '../../../styles/Conf.css'


export default class Conf extends Component {
    render() {
        return (
            <div className='conf-content-land'>
                <div className='conf-text-container'>
                    <h1>Configura tus datos personales</h1>
                    <p>En esta sección puedes añadir tu información personal, además de añadir una foto de perfil y un banner
                    que te represente!</p>
                </div>
                <div className='conf-screenshots-container'>
                    <img alt="configuracion" className='conf-screenshots' src={conf} />
                </div>
            </div>
        )
    }
}
