import { useState, useEffect, useMemo, useRef, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getSingleEvent } from '../../actions/events';
import { changeMenuSelected } from '../../actions/menu';
// import { colors } from "../../costants/colors";
// import IsAdmin from '../../components/IsAdmin';
import Select from '../../components/ui/Select';
import Title from '../../components/ui/Title';
import Tournaments from './Tournaments';
import tennisBackgroundTournament from '../../assets/courts-bg.jpg';
import { IoArrowRedo } from 'react-icons/io5';
import { AiFillFile } from 'react-icons/ai';
import { MdCreateNewFolder } from 'react-icons/md';
import Loading from '../Loading';
import {
  createAllCourts,
  createNextRound,
  createRoundRanking,
  getCourtByCategory,
} from '../../services/tournament/playersTournament.service';
import '../../styles/Cuadros.css';
import Button from '../../components/ui/Button';
import CuadroRankingDrager from './CuadroRankingDrager';
import toast from 'react-hot-toast';
import TabsHeader from '../../components/ui/TabsHeader';
import { getNumberList } from '../../services/Utils';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import baseDesplegable from '../../assets/base-desplegable.png';
import { useResizeDetector } from 'react-resize-detector';
import DespleableBtn from '../../icons/DespleableBtn';
import classNames from 'classnames';
import { useUser } from '../../context/UserContext';

const TiposCuadro = {
  ED: {
    name: 'Eliminación Directa',
    value: 'ED',
  },
  RR: {
    name: 'Round Robin',
    value: 'RR',
  },
};
const Cuadros = (props) => {
  const { event } = props;
  const { isAdmin, user } = useUser();
  const isOwner = user.uid === event?.owner
  // const mobile = useMediaQuery('(max-width: 420px)');
  const [showCourt, setShowCourt] = useState(false);
  const [isOpenD, setisOpenD] = useState(true);
  const { width: widthContainer, ref: containerRef } = useResizeDetector();
  const [top, setTop] = useState(40);
  const sm = useMediaQuery('(max-width: 639px)');
  const [sticky, setSticky] = useState(true);
  const { width, height } = useResizeDetector();
  const [mobileSize, setMobileSize] = useState(false);
  const dispatch = useDispatch();
  const { eventId } = useParams('eventId');
  const navigate = useNavigate();
  const [CuadroCategoria, setCuadroCategoria] = useState('');
  const [tipoCuadro, setTipoCuadro] = useState(TiposCuadro.ED.value);
  const [isSaving, setIsSaving] = useState(false);
  const [roundsWithoutRankingPoints, setRoundsWithoutRankingPoints] = useState(
    {}
  );
  const [, setPageWidth] = useState(0);
  const [generate, setGenerate] = useState(true);
  const [rankingModal, setRankingModal] = useState(false);
  const [numPages] = useState(null);
  const [data, setData] = useState([]);
  const pdfWrapper = useRef();
  const [activetab, setActivetab] = useState(
    data?.type?.value || TiposCuadro.ED.value
  );
  const [numPlayersByGroup, setNumPlayersByGroup] = useState(4);
  const maxPlayerList = useMemo(() => getNumberList(2, 7), []);
  const configOptions = useMemo(() => {
    const tabs = [];
    const tipoCuadro = data?.type?.value || TiposCuadro.ED.value;
    if (tipoCuadro === TiposCuadro.RR.value) {
      tabs.push(TiposCuadro.RR.name);
    }
    if (
      tipoCuadro === TiposCuadro.ED.value ||
      data?.rounds?.find((round) => round.typeRound === TiposCuadro.ED.value)
    ) {
      tabs.push(TiposCuadro.ED.name);
    }
    return tabs;
  }, [data?.rounds, data?.type?.value]);
  const hasCuadro = useMemo(
    () => event?.cuadros?.[CuadroCategoria] || null,
    [CuadroCategoria, event?.cuadros]
  );

  useEffect(() => {
    const handleSize = () => {
      if (window.screen.width >= 920) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };
    const isMobile = () => {
      if (width <= 640) {
        setMobileSize(true);
      } else {
        setMobileSize(false);
      }
    };
    isMobile();

    const handleSizeEvent = () => {
      handleSize();
    };
    window.addEventListener("resize", handleSizeEvent);

    return () => {
      window.removeEventListener("resize", handleSizeEvent);
    };

  }, [height, width]);

  useEffect(() => {
    if (sm || showCourt) {
      setisOpenD(false);
    }
  }, [sm, showCourt]);
  useEffect(() => {
    setActivetab((value) =>
      data?.rounds?.find((round) => round.typeRound === value)
        ? value
        : data?.type?.value || TiposCuadro?.ED?.value
    );
  }, [data?.rounds, data?.type?.value]);
  const HandleCreateCuadros = async ({ id, category, tipoCuadro }) => {
    try {
      setGenerate(false);
      setIsSaving(true);

      const responseCreate = await createAllCourts({
        id,
        category,
        tipoCuadro,
        numPlayersByGroup,
      });
      if (!responseCreate) {
        toast.error('Ha ocurrido un problema!');
        return;
      }
      toast.success(
        category
          ? 'Cuadro de la categoría ' + category + ' creados con éxito'
          : 'Cuadros creados con éxito'
      );
    } catch (error) {
      toast.error('Ha ocurrido un problema!');
    }
    setGenerate(true);
    setIsSaving(false);
  };
  const HandleAssigRanking = async ({ idCuadro }) => {
    try {
      setGenerate(false);
      setIsSaving(true);
      setRoundsWithoutRankingPoints({});
      const { error, roundsWithoutRankingPoints } = await createRoundRanking(
        idCuadro
      );
      if (error) {
        toast.error(error);
        setRoundsWithoutRankingPoints(
          roundsWithoutRankingPoints?.reduce((a, b) => {
            a[b.id] = b.id;
            return a;
          }, {}) || {}
        );
        return;
      }
      toast.success('Se han asignado los puntos de ranking!');
    } catch (error) {
      toast.error('Ha ocurrido un problema!');
    }
    setGenerate(true);
    setIsSaving(false);
  };
  const toastRef = useRef();
  useEffect(() => {
    return () => {
      if (toastRef.current) {
        toast.remove(toastRef.current.id);
      }
    };
  }, []);

  const PrevHandleActions = ({
    atribs,
    callback,
    valdiation,
    text = '',
    loader,
  }) => {
    if (!valdiation) {
      return callback?.(atribs);
    }
    setIsSaving(loader ? 'waiting' : true);

    toast.custom(
      (ref) => {
        toastRef.current = ref;
        return (
          <div className="tw-bg-white tw-shadow-md tw-rounded-md tw-flex tw-flex-col tw-p-6 tw-pt-9 tw-text-gray-500">
            {text}
            <div className="tw-flex tw-self-center tw-mt-5 tw-gap-5">
              <Button
                buttonType="secundary"
                type="button"
                onClick={() => {
                  toastRef.current = null;
                  toast.remove(ref.id);
                  setIsSaving(false);
                }}
                className="tw-mr-2"
              >
                <div className="tw-flex tw-items-center">Cancelar</div>
              </Button>
              <Button
                type="button"
                onClick={() => {
                  callback?.(atribs);
                  toast.remove(ref.id);
                }}
              >
                <div className="tw-flex tw-items-center">Continuar</div>
              </Button>
            </div>
          </div>
        );
      },
      { duration: Infinity, id: 'next-phase-confirm' }
    );
  };
  const HandlecreateNextRound = async ({
    players,
    prevRound,
    maxPlayers,
    deleteRound,
  }) => {
    try {
      setGenerate(false);
      setIsSaving(true);
      const responseCreate = await createNextRound({
        idCuadro: data?.idCuadro,
        players,
        prevRound,
        id: eventId,
        category: CuadroCategoria,
        tipoCuadro: TiposCuadro.ED.value,
        maxPlayers,
        deleteRound,
      });
      if (!responseCreate) {
        toast.error('Ha ocurrido un problema!');
        setGenerate(true);
        setIsSaving(false);
        return;
      }
      toast.success('Ronda creada con éxito');
    } catch (error) {
      toast.error('Ha ocurrido un problema!');
    }
    setGenerate(true);
    setIsSaving(false);
    setRankingModal(false);
  };

  useEffect(() => {
    const info = async (eventId, CuadroCategoria) => {
      if (eventId && CuadroCategoria && generate) {
        setIsSaving(true);
        const result = await getCourtByCategory(eventId, CuadroCategoria);
        setData(null);
        let teamsNumber = 0;
        const tipoCuadro = result?.type?.value || TiposCuadro.ED.value;
        const numbPlayersGroup = result?.numPlayersByGroup || 4;
        setTipoCuadro(tipoCuadro);
        setNumPlayersByGroup(numbPlayersGroup);
        result.rounds
          ?.sort((a, b) => a.ronda - b.ronda)
          .filter((a) => a.typeRound !== TiposCuadro.RR.value)
          .forEach((round, index) => {
            const cantidadActualTeams = round.seeds.length || 0;
            if (index !== 0) {
              const teamRequeridos = Math.ceil(teamsNumber / 2);
              if (cantidadActualTeams < teamRequeridos) {
                for (
                  let index = 0;
                  index < teamRequeridos - cantidadActualTeams;
                  index++
                ) {
                  if (!round.seeds) round.seeds = [];
                  round.seeds.push({});
                }
              }
              teamsNumber = cantidadActualTeams;
            } else {
              teamsNumber = cantidadActualTeams;
              if (round.seeds.length % 2 !== 0) round.seeds.push({});
            }
          });
        setIsSaving(false);
        setData(result);
      }
    };
    info(eventId, CuadroCategoria);
  }, [eventId, CuadroCategoria, generate]);

  useEffect(() => {
    if (eventId) {
      dispatch(getSingleEvent(eventId));
    }
  }, [dispatch, eventId]);
  useEffect(() => {
    const resize = () => {
      if (pdfWrapper.current) {
        setPageWidth(pdfWrapper.current.getBoundingClientRect().width);
      }
    };
    window.addEventListener('resize', resize);
    resize();
    return () => window.removeEventListener('resize', resize);
  }, [numPages]);
  const getCategorias = useMemo(() => {
    if (!event?.cupos) return [];
    const categorias = Object.keys(event.cupos || {}).filter(
      (categoria) => categoria.toLowerCase() !== 'total'
    );
    return categorias;
  }, [event]);
  useEffect(() => {
    if (getCategorias.length)
      setCuadroCategoria((value) => value || getCategorias[0]);
  }, [getCategorias]);
  const goBack = () => {
    navigate('/event/' + eventId);
    dispatch(changeMenuSelected('/explore'));
  };

  return (
    <>
      {!sticky && (
        <div
          className={'perfil-container-' + props.mode}
          style={{ width: '100%' }}
        >
          <div className="tw-grid tw-h-full tw-w-full tw-rounded tw-shadow tw-text-left tw-bg-white tw-grid-rows-2-auto-fr tw-overflow-hidden tw-relative">
            <div className="tw-w-full tw-h-fit">
              <Title goBack={goBack} title={'Torneo ' + (eventId.nombre || '')} />
            </div>
            {sm && !showCourt && (
              <>
                <div
                  className="tw-absolute tw-w-0 tw-z-10 tw-transition-all"
                  onClick={() => setisOpenD((value) => !value)}
                  style={{
                    left: (widthContainer || 0) + 2,
                    top,
                    transitionProperty: 'top',
                  }}
                >
                  <DespleableBtn
                    classNameContainer="tw-absolute tw-right-0 tw-top-0 tw-translate-x-9"
                    right={!isOpenD}
                  />
                </div>
                {isOpenD && (
                  <div
                    className="tw-absolute tw-right-0 tw-top-0 tw-w-9 tw-h-full"
                    onClick={() => setisOpenD(false)}
                  ></div>
                )}
              </>
            )}
            <div className="tw-flex tw-flex-wrap tw-h-full tw-w-full tw-grid-rows-1 tw-overflow-auto tw-overflow-x-hidden">
              <div
                ref={containerRef}
                className={classNames(
                  `tw-h-full tw-flex tw-flex-col tw-items-center sm:tw-pt-0 tw-pt-9
            sm:tw-mr-0 tw-mr-14 sm:tw-bg-gray-50 tw-bg-Verde-TenisCTA tw-overflow-auto 
            tw-overflow-y-hidden sm:tw-px-4 tw-pb-4 sm:tw-w-60 tw-flex-1 sm:tw-flex-none tw-max-w-xs
            sm:tw-relative sm:tw-z-auto tw-z-10 scroll tw-absolute tw-top-0 tw-transition-all tw-content-start`,
                  { 'tw-w-full': isOpenD, 'tw-w-0': !isOpenD }
                )}
                style={{ width: isOpenD && sm && 'calc(100% - 37px)' }}
                onMouseMove={(e) => {
                  let top = e.clientY - 125;
                  if (top < 40) top = 40;
                  setTop(top);
                }}
              >
                {sm && (
                  <div className="tw-grid tw-gap-3 tw-w-full tw-text-black tw-opacity-60 tw-px-3 sm:tw-px-0 tw-mt-3 tw-mb-3">
                    <div
                      className="tw-max-w-xs tw-bg-white tw-flex-1"
                      style={{ minWidth: 176 }}
                    >
                      <Select
                        label="Categoría"
                        name="tipo"
                        value={CuadroCategoria}
                        selectStylesOff
                        selectClass="tw-border-Verde-TenisCTA tw-border-solid tw-border-2 tw-rounded tw-text-sm tw-w-full tw-h-10 tw-flex tw-items-center"
                        onChange={(event) => setCuadroCategoria(event.value)}
                        options={getCategorias.map((categoria) => ({
                          value: categoria,
                          label: categoria,
                        }))}
                        containerStylesOff
                      />
                    </div>
                    {(isAdmin || isOwner) && (
                      <Fragment>
                        <div
                          className="tw-max-w-xs tw-bg-white tw-flex-1"
                          style={{ minWidth: 176 }}
                        >
                          <Select
                            label="Tipo de Cuadro"
                            name="tipo"
                            value={tipoCuadro}
                            selectStylesOff
                            selectClass="tw-border-Verde-TenisCTA tw-border-solid tw-border-2 tw-rounded tw-text-sm tw-w-full tw-h-10 tw-flex tw-items-center"
                            onChange={(event) => setTipoCuadro(event.value)}
                            options={Object.values(TiposCuadro).map(
                              ({ name, value }) => ({
                                value: value,
                                label: name,
                              })
                            )}
                            containerStylesOff
                          />
                        </div>
                        {tipoCuadro === "RR" && <div
                          className="tw-max-w-xs tw-bg-white tw-flex-1"
                          style={{ minWidth: 176 }}
                        >
                          <Select
                            label="jugadores por grupo"
                            name="tipo"
                            value={numPlayersByGroup}
                            onChange={(numberPlayer) =>
                              setNumPlayersByGroup(numberPlayer.value)
                            }
                            selectStylesOff
                            selectClass="tw-border-Verde-TenisCTA tw-border-solid tw-border-2 tw-rounded tw-text-sm tw-w-full tw-h-10 tw-flex tw-items-center"
                            options={maxPlayerList}
                            containerStylesOff
                          />
                        </div>}
                      </Fragment>
                    )
                    }
                    {CuadroCategoria && (isAdmin || isOwner) && (
                      <Fragment>
                        <div className="tw-flex tw-gap-3 tw-flex-wrap tw-justify-center">
                          {data?.idCuadro &&
                            data?.type?.value === TiposCuadro.RR.value &&
                            activetab === TiposCuadro.RR.value && (
                              <Button
                                type="button"
                                onClick={() => setRankingModal(true)}
                              >
                                <div className="tw-flex tw-items-center">
                                  <IoArrowRedo
                                    size={20}
                                    style={{ marginRight: 4 }}
                                  />
                                  Siguiente Fase
                                </div>
                              </Button>
                            )}
                          {data?.finish && (
                            <Button
                              onClick={() => {
                                PrevHandleActions({
                                  atribs: {
                                    idCuadro: data?.idCuadro,
                                  },
                                  callback: HandleAssigRanking,
                                  valdiation: true,
                                  text: (
                                    <span>
                                      {!data?.hasRanking
                                        ? 'Se actualizará el ranking según los puntos de cada ronda,'
                                        : 'Este cuadro ya asignó puntos de ranking.'}
                                      <span className="tw-font-bold">
                                        ¿Seguro que desea continuar?
                                      </span>
                                    </span>
                                  ),
                                });
                              }}
                            >
                              <div className="tw-flex tw-items-center">
                                <AiFillFile
                                  size={16}
                                  style={{ marginRight: 4 }}
                                />
                                Generar Ranking
                              </div>
                            </Button>
                          )}
                          <Button
                            onClick={() => {
                              PrevHandleActions({
                                atribs: {
                                  id: eventId,
                                  tipoCuadro: tipoCuadro,
                                },
                                callback: HandleCreateCuadros,
                                valdiation: !!event?.cuadros,
                                text: (
                                  <span>
                                    Este Evento ya tiene cuadros generado, al
                                    continuar, se sobrescribirán{' '}
                                    <span className="tw-font-bold">
                                      ¿Seguro que desea continuar?
                                    </span>
                                  </span>
                                ),
                              });
                            }}
                          >
                            <div className="tw-flex tw-items-center">
                              <MdCreateNewFolder
                                size={16}
                                style={{ marginRight: 4 }}
                              />
                              Generar Cuadros
                            </div>
                          </Button>
                          <Button
                            onClick={() => {
                              PrevHandleActions({
                                atribs: {
                                  id: eventId,
                                  category: CuadroCategoria,
                                  tipoCuadro: tipoCuadro,
                                },
                                callback: HandleCreateCuadros,
                                valdiation: !!hasCuadro,
                                text: (
                                  <span>
                                    Esta Categoría ya tiene un cuadro generado,{' '}
                                    <span className="tw-font-bold">
                                      ¿Seguro que desea continuar?
                                    </span>
                                  </span>
                                ),
                              });
                            }}
                          >
                            <div className="tw-flex tw-items-center">
                              <AiFillFile size={16} style={{ marginRight: 4 }} />
                              Bracket individual
                            </div>
                          </Button>
                        </div>
                      </Fragment>
                    )}
                  </div>
                )}
              </div>
              <div className="scroll tw-z-0 tw-min-h-[100px] tw-px-3  sm:tw-px-0 tw-w-full">
                <div className="tw-mb-7">
                  <TabsHeader
                    menuOptions={configOptions}
                    onChange={(data) => {
                      setActivetab(
                        Object.values(TiposCuadro).find((tc) => tc.name === data)
                          ?.value || TiposCuadro.ED.value
                      );
                    }}
                    value={TiposCuadro[activetab]?.name}
                  />
                </div>
                <div
                  id="modalTorneo"
                  ref={pdfWrapper}
                  className="scroll tw-max-h-screen tw-h-full"
                >
                  <div className="tw-h-fit tw-min-h-full tw-grid tw-font-medium tw-m-0 tw-w-fit tw-min-w-full tw-relative tw-bg-gray-200 tw-flex-col">
                    <img
                      src={tennisBackgroundTournament}
                      alt="tournament"
                      className="tw-absolute tw-w-full tw-h-full tw-opacity-70 tw-object-cover tw-pointer-events-none"
                      style={{ minHeight: 500 }}
                    />
                    <CuadroRankingDrager
                      isSaving={isSaving}
                      data={data}
                      handleClose={() => setRankingModal(false)}
                      isOpen={rankingModal}
                      handleCreate={(players, prevRound, maxPlayers) => {
                        const round = data?.rounds?.find(
                          ({ typeRound }) => typeRound === TiposCuadro.ED.value
                        );
                        PrevHandleActions({
                          atribs: {
                            players,
                            prevRound,
                            maxPlayers,
                            deleteRound: round?.id,
                          },
                          loader: true,
                          callback: HandlecreateNextRound,
                          valdiation: !!round,
                          text: (
                            <span className="tw-px-9">
                              Ya existe la siguiente fase,{' '}
                              <span className="tw-font-bold">
                                ¿Seguro que desea continuar?
                              </span>
                            </span>
                          ),
                        });
                      }}
                    />
                    <Tournaments
                      data={data}
                      activetab={activetab}
                      setGenerate={setGenerate}
                      TiposCuadro={TiposCuadro}
                      roundsWithoutRankingPoints={roundsWithoutRankingPoints}
                    />
                  </div>
                </div>
                {isSaving && isSaving !== 'waiting' && (
                  <div className="tw-w-full tw-h-full tw-absolute tw-z-30 tw-flex tw-items-center tw-justify-center tw-bg-gray-600 tw-bg-opacity-25 tw-right-0 tw-top-0">
                    <Loading label="Procesando" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {sticky && (
        <div
          className="main-container-activado"
          style={{
            backgroundColor: 'white',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Title goBack={goBack} title={'Torneo ' + (eventId.nombre || '')} />
          <div className="tw-w-full tw-flex tw-gap-3 tw-justify-between tw-flex-wrap tw-items-center sm:tw-px-0 tw-px-3">
            <div className="tw-flex tw-gap-x-3 tw-flex-wrap">
              <div
                className="tw-max-w-xs tw-bg-white tw-flex-1"
                style={{ minWidth: 176 }}
              >
                <Select
                  label="Categoría"
                  name="tipo"
                  value={CuadroCategoria}
                  selectStylesOff
                  selectClass="tw-border-Verde-TenisCTA tw-border-solid tw-border-2 tw-rounded tw-text-sm tw-w-full tw-h-10 tw-flex tw-items-center"
                  onChange={(event) => setCuadroCategoria(event.value)}
                  options={getCategorias.map((categoria) => ({
                    value: categoria,
                    label: categoria,
                  }))}
                  containerStylesOff
                />
              </div>
              {(isAdmin || isOwner) && (
                <Fragment>
                  <div
                    className="tw-max-w-xs tw-bg-white tw-flex-1"
                    style={{ minWidth: 176 }}
                  >
                    <Select
                      label="Tipo de Cuadro"
                      name="tipo"
                      value={tipoCuadro}
                      selectStylesOff
                      selectClass="tw-border-Verde-TenisCTA tw-border-solid tw-border-2 tw-rounded tw-text-sm tw-w-full tw-h-10 tw-flex tw-items-center"
                      onChange={(event) => setTipoCuadro(event.value)}
                      options={Object.values(TiposCuadro).map(({ name, value }) => ({
                        value: value,
                        label: name,
                      }))}
                      containerStylesOff
                    />
                  </div>
                  {tipoCuadro === "RR" && <div
                    className="tw-max-w-xs tw-bg-white tw-flex-1"
                    style={{ minWidth: 176 }}
                  >
                    <Select
                      label="jugadores por grupo"
                      name="tipo"
                      value={numPlayersByGroup}
                      onChange={(numberPlayer) =>
                        setNumPlayersByGroup(numberPlayer.value)
                      }
                      selectStylesOff
                      selectClass="tw-border-Verde-TenisCTA tw-border-solid tw-border-2 tw-rounded tw-text-sm tw-w-full tw-h-10 tw-flex tw-items-center"
                      options={maxPlayerList}
                      containerStylesOff
                    />
                  </div>}
                </Fragment>
              )}
            </div>
            {CuadroCategoria && (isAdmin || isOwner) && (
              <Fragment>
                <div className="tw-flex tw-gap-3 tw-flex-wrap tw-justify-center">
                  {data?.idCuadro &&
                    data?.type?.value === TiposCuadro.RR.value &&
                    activetab === TiposCuadro.RR.value && (
                      <Button type="button" onClick={() => setRankingModal(true)}>
                        <div className="tw-flex tw-items-center">
                          <IoArrowRedo size={20} style={{ marginRight: 4 }} />
                          Siguiente Fase
                        </div>
                      </Button>
                    )}
                  {data?.finish && (
                    <Button
                      onClick={() => {
                        PrevHandleActions({
                          atribs: {
                            idCuadro: data?.idCuadro,
                          },
                          callback: HandleAssigRanking,
                          valdiation: true,
                          text: (
                            <span>
                              {!data?.hasRanking
                                ? 'Se actualizará el ranking según los puntos de cada ronda,'
                                : 'Este cuadro ya asignó puntos de ranking.'}
                              <span className="tw-font-bold">
                                ¿Seguro que desea continuar?
                              </span>
                            </span>
                          ),
                        });
                      }}
                    >
                      <div className="tw-flex tw-items-center">
                        <AiFillFile size={16} style={{ marginRight: 4 }} />
                        Generar Ranking
                      </div>
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      PrevHandleActions({
                        atribs: {
                          id: eventId,
                          tipoCuadro: tipoCuadro,
                        },
                        callback: HandleCreateCuadros,
                        valdiation: !!event?.cuadros,
                        text: (
                          <span>
                            Este Evento ya tiene cuadros generado, al continuar, se
                            sobrescribirán{' '}
                            <span className="tw-font-bold">
                              ¿Seguro que desea continuar?
                            </span>
                          </span>
                        ),
                      });
                    }}
                  >
                    <div className="tw-flex tw-items-center">
                      <MdCreateNewFolder size={16} style={{ marginRight: 4 }} />
                      Generar Cuadros
                    </div>
                  </Button>
                  <Button
                    onClick={() => {
                      PrevHandleActions({
                        atribs: {
                          id: eventId,
                          category: CuadroCategoria,
                          tipoCuadro: tipoCuadro,
                        },
                        callback: HandleCreateCuadros,
                        valdiation: !!hasCuadro,
                        text: (
                          <span>
                            Esta Categoría ya tiene un cuadro generado,{' '}
                            <span className="tw-font-bold">
                              ¿Seguro que desea continuar?
                            </span>
                          </span>
                        ),
                      });
                    }}
                  >
                    <div className="tw-flex tw-items-center">
                      <AiFillFile size={16} style={{ marginRight: 4 }} />
                      Bracket individual
                    </div>
                  </Button>
                </div>
              </Fragment>
            )}
          </div>
          <div className="tw-mb-7">
            <TabsHeader
              menuOptions={configOptions}
              onChange={(data) => {
                setActivetab(
                  Object.values(TiposCuadro).find((tc) => tc.name === data)
                    ?.value || TiposCuadro.ED.value
                );
              }}
              value={TiposCuadro[activetab]?.name}
            />
          </div>
          <div
            id="modalTorneo"
            ref={pdfWrapper}
            className="scroll tw-max-h-screen tw-h-full"
          >
            <div className="tw-h-fit tw-min-h-full tw-grid tw-font-medium tw-m-0 tw-w-fit tw-min-w-full tw-relative tw-bg-gray-200 tw-flex-col">
              <img
                src={tennisBackgroundTournament}
                alt="tournament"
                className="tw-absolute tw-w-full tw-h-full tw-opacity-70 tw-object-cover tw-pointer-events-none"
                style={{ minHeight: 500 }}
              />
              <CuadroRankingDrager
                isSaving={isSaving}
                data={data}
                handleClose={() => setRankingModal(false)}
                isOpen={rankingModal}
                handleCreate={(players, prevRound, maxPlayers) => {
                  const round = data?.rounds?.find(
                    ({ typeRound }) => typeRound === TiposCuadro.ED.value
                  );
                  PrevHandleActions({
                    atribs: {
                      players,
                      prevRound,
                      maxPlayers,
                      deleteRound: round?.id,
                    },
                    loader: true,
                    callback: HandlecreateNextRound,
                    valdiation: !!round,
                    text: (
                      <span className="tw-px-9">
                        Ya existe la siguiente fase,{' '}
                        <span className="tw-font-bold">
                          ¿Seguro que desea continuar?
                        </span>
                      </span>
                    ),
                  });
                }}
              />
              <Tournaments
                data={data}
                activetab={activetab}
                setGenerate={setGenerate}
                TiposCuadro={TiposCuadro}
                roundsWithoutRankingPoints={roundsWithoutRankingPoints}
              />
            </div>
          </div>
          {isSaving && isSaving !== 'waiting' && (
            <div className="tw-w-full tw-h-full tw-absolute tw-z-30 tw-flex tw-items-center tw-justify-center tw-bg-gray-600 tw-bg-opacity-25 tw-right-0 tw-top-0">
              <Loading label="Procesando" />
            </div>
          )}
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    isFetchingSingleEvent: state.events.isFetchingSingleEvent,
    singleEventFetched: state.events.singleEventFetched,
    fetchSingleEventError: state.events.fetchSingleEventError,
    event: state.events.event,
  };
}

export default connect(mapStateToProps)(Cuadros);
