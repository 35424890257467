import styled from "styled-components";

export const Container = styled.div`
display: flex;
flex-wrap: wrap;
height: 100%;
margin-left: 15px;

&::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

&::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 4px;
}

&::-webkit-scrollbar-thumb:hover {
  background: #494846;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

&::-webkit-scrollbar-thumb:active {
  background-color: #494846;
}

&::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}
`;
export const EmptyContainer = styled.div`
display: flex;
flex: 1;
width: 100%;
justify-content: center;
align-items: center;

p {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #9ba1bb;
  height: 100%;
  width: 100%;
  text-align: center;
}
`;
