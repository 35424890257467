import { API } from "../../config/Config";
import axios from 'axios'

export const saveForm = async (formData) => {
    try {
        const response = await axios({
            url: `${API}/sponsor`,
            method: 'POST',
            data: formData
        })
        return response
    } catch (e) {
        console.log(e)
    }
}