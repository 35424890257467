import {
    GET_SINGLE_PLAYER,
    //Obtener jugadores
    GET_PLAYERS_REQUEST,
    GET_PLAYERS_SUCCESS,
    GET_PLAYERS_FAILURE,

    //Eliminar un Jugador
    DELETE_PLAYER_REQUEST,
    DELETE_PLAYER_SUCCESS,
    DELETE_PLAYER_FAILURE,

    //Actualizar un Jugador
    UPDATE_PLAYER_DEFAULT,
    UPDATE_PLAYER_REQUEST,
    UPDATE_PLAYER_SUCCESS,
    UPDATE_PLAYER_FAILURE,

    //Actualizar un estaditicas de jugador
    UPDATE_STATISTICS_PLAYER_WIN,
    UPDATE_STATISTICS_PLAYER_LOSS,
    UPDATE_STATISTICS_PLAYER_DEFAULT,

    // MATCH
    CREATE_MATCH_REQUEST,
    CREATE_MATCH_SUCCESS,
    CREATE_MATCH_FAILURE,

} from '../actions/jugador'

const jugadorState = (
    state = {

    

        singlePlayer: null,
        //Obtener todos Los Jugadores
        isFetchingPlayers: false,
        playersFetched: false,
        fetchPlayersError: false,
        players: [],

        //Editar un Jugador
        isUpdatingPlayer: false,
        updatePlayerError: false,
        playerUpdated: false,

        //Eliminar Jugador
        isDeletePlayer: false,
        playerDeleted: false,
        deletePlayerError: false,

        //creacion de match
        isMatchCreated: false,
        matchCreated: false,
        matchCreatedError: false
    },
    action
) => {
    switch (action.type) {
        //Obtener todos Los Jugadores
        case GET_SINGLE_PLAYER:
            return {
                ...state,
                user: action.user,
            };
        case GET_PLAYERS_REQUEST:
            return {
                isFetchingPlayers: true,
                playersFetched: false,
                fetchPlayersError: false,
            }
        case GET_PLAYERS_SUCCESS:
            return {
                isFetchingPlayers: false,
                playersFetched: true,
                fetchPlayersError: false,
                players: action.players
            }
        case GET_PLAYERS_FAILURE:
            return {
                isFetchingPlayers: false,
                playersFetched: false,
                fetchPlayersError: true,
            }

        //Editar un Jugador
        case UPDATE_PLAYER_DEFAULT:
            return {
                isUpdatingPlayer: false,
                updatePlayerError: false,
                playerUpdated: false,
            }
        case UPDATE_PLAYER_REQUEST:
            return {
                isUpdatingPlayer: true,
                updatePlayerError: false,
                playerUpdated: false,
            }

        case UPDATE_PLAYER_SUCCESS:
            return {
                isUpdatingPlayer: false,
                updatePlayerError: false,
                playerUpdated: true,
            }
        case UPDATE_PLAYER_FAILURE:
            return {
                isUpdatingPlayer: false,
                updatePlayerError: true,
                playerUpdated: false,
            }

        // Editar estadisticas del jugador 

        case UPDATE_STATISTICS_PLAYER_DEFAULT:
            return {
                isUpdatingPlayer: false,
                updatePlayerError: false,
                playerUpdated: false,
            }

        case UPDATE_STATISTICS_PLAYER_WIN:
            return {
                isUpdatingPlayer: true,
                updatePlayerError: false,
                playerUpdated: true,
            }
        case UPDATE_STATISTICS_PLAYER_LOSS:
            return {
                isUpdatingPlayer: false,
                updatePlayerError: false,
                playerUpdated: true,
            }


        // Creacion de match 

        case CREATE_MATCH_REQUEST:
            return {
                ...state,
                isMatchCreated: true,
                matchCreatedError: false
            }

        case CREATE_MATCH_SUCCESS:
            return {
                ...state,
                isMatchCreated: false,
                matchCreated: true
            }
        case CREATE_MATCH_FAILURE:
            return {
                ...state,
                isMatchCreated: false,
                matchCreatedError: true
            }

        // Eliminar un jugador
        case DELETE_PLAYER_REQUEST:
            return {
                isDeletePlayer: true,
                playerDeleted: false,
                deletePlayerError: false,
            }
        case DELETE_PLAYER_SUCCESS:
            return {
                isDeletePlayer: false,
                playerDeleted: true,
                deletePlayerError: false,
            }
        case DELETE_PLAYER_FAILURE:
            return {
                isDeletePlayer: false,
                playerDeleted: false,
                deletePlayerError: true,
            }
        default:
            return state
    }
}

export default jugadorState;