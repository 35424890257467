import { FaArrowCircleLeft } from "react-icons/fa";
import styled from "styled-components";
import CourtIcon from "../ui/CourtIcon";
import { useUser } from "../../context/UserContext";
import SingleWeekCalendarNew from "../calendar/SingleWeekCalendarNew";
import RentColorIndicator from "../calendar/RentColorIndicator";
import { Link } from "react-router-dom";

const RentCourtModal = ({
  court,
  handleClose,
  handleCloseConfirm,
  payment,
  updateWeek,
  selectedDay,
  setselectedDay,
  SetPaymentData,
  courtID
}) => {
  const { isClub, isNotPlayer } = useUser();

  const renderButtonConfirm = () => {
    if (!isNotPlayer) {
      return (
        <>
          <div
            className="Modal-form-courts-Image tw-bg-white"
            style={{ height: "fit-content", position: "absolute" }}
          />
          <div className="tw-flex tw-items-center tw-w-full tw-justify-center tw-py-3 tw-gap-4">
            <Button1
              className="modal-button calendar-no-selectable"
              onClick={() => handleClose(false)}
            >
              <span>Cancelar</span>
            </Button1>
              <Button1
                className="modal-button calendar-no-selectable"
                style={{ backgroundColor: "#DCEC00" }}
                onClick={() => handleCloseConfirm()}
              >
                <span>Arrendar</span>
              </Button1>
          </div>
        </>
      )
    }
    if (isNotPlayer) {
      return (
        <>
          <Link
            to={`/?nextRoute=${"/mapa"}&selectID=${courtID}`}
            style={{ textDecoration: "none" }}
          >
            <Button1
              className="modal-button calendar-no-selectable"
              onClick={() => handleCloseConfirm()}
              style={{
                backgroundColor: "#DCEC00",
                cursor: "pointer",
              }}
            >
              <span>Registrate en tenis-up para arrendar canchas!</span>
            </Button1>
          </Link>
        </>
      )
    }
  }

  return (
    <>
      {isClub ? (
        <form className="container-form-courts tw-select-none">
          <div className="form-column-rent-court">
            <div className="rent-court-input">
              <label className="rent-court-label">
                Solo los usuarios regitrados pueden arrendar canchas!
              </label>
            </div>
          </div>
        </form>
      ) : (
        <>
          <div
            style={{
              position: "relative",
              minHeight: 140,
            }}
          >
            <div
              className="Modal-form-courts-Image tw-select-none"
              style={{
                backgroundImage: `url(${court.image})`,
                position: "relative",
                minHeight: 120,
              }}
            >
              <div className="tw-flex tw-items-center tw-bg-black tw-bg-opacity-20">
                <div className="tw-bg-black tw-bg-opacity-60 tw-m-5 tw-cursor-pointer tw-rounded-full">
                  <FaArrowCircleLeft onClick={() => handleClose(false)} size="40" color="#FFFFFF" />
                </div>
                <div className="tw-text-white tw-text-lg sm:tw-text-3xl">{court?.name}</div>
                <div className="tw-absolute tw-right-3 tw-top-3 tw-text-2xl tw-bg-Verde-TenisCTA tw-bg-opacity-80 tw-rounded-full tw-p-3 tw-flex">
                  <CourtIcon type={court?.type} />
                </div>
              </div>
              <div
                className="tw-absolute tw-bottom-0 tw-flex tw-w-full tw-p-5 tw-text-white tw-justify-between"
                style={{
                  background: "linear-gradient(rgb(72 72 72 / 5%) 0%, rgb(0 0 0) 100%)",
                }}
              >
                <div>{court?.description}</div>
                <div>
                  {payment?.total ? `Total: $${payment?.total}` : `Valor por hora: $${court?.price}`}
                </div>
              </div>
            </div>
          </div>
          <SingleWeekCalendarNew
            week={court.week}
            setWeek={updateWeek}
            selectedDay={selectedDay}
            setselectedDay={setselectedDay}
            payment={payment}
            clubId={court.club_uid}
            UpdateData={SetPaymentData}
            price={court?.price}
            isClub={isClub}
          ></SingleWeekCalendarNew>
          <RentColorIndicator />
        </>
      )}

      <div className="tw-sticky tw--bottom-5 md:tw-bottom-0 sm:tw-static tw-bg-white">
        <div className="modal-PerfilClub-Body tw-items-center tw-justify-center tw-flex tw-select-none tw-bg-white tw-gap-3 tw-relative">
          {renderButtonConfirm()}
        </div>
      </div>
    </>
  );
};
const Button1 = styled.button`
  position: relative;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  border: none;
  background-color: #eee;
  color: #2085b7;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  &:hover {
    background: #dcec00;
  }
  cursor: pointer;
`;
export default RentCourtModal;
