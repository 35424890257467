import axios from 'axios';
import { API } from '../../config/Config';

export const createAllCourts = async ({
  id,
  category,
  tipoCuadro,
  numPlayersByGroup,
}) => {
  const url = `${API}/tournaments/${id}`;
  try {
    const result = await axios.post(url, {
      category,
      tipoCuadro,
      numPlayersByGroup,
    });
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
export const createRoundRanking = async (idCuadro) => {
  const url = `${API}/tournaments/ranking/create`;
  try {
    const result = await axios.post(url, {
      idCuadro,
    });
    return { data: result.data, error: false, roundsWithoutRankingPoints: [] };
  } catch (error) {
    console.log({ error: error.response.data });
    return {
      error: error.response?.data?.error || 'Se ha producido un error',
      data: [],
      roundsWithoutRankingPoints:
        error.response?.data?.roundsWithoutRankingPoints || [],
    };
  }
};
export const createNextRound = async ({
  id,
  category,
  tipoCuadro,
  idCuadro,
  prevRound,
  players,
  maxPlayers,
  deleteRound,
}) => {
  const url = `${API}/tournaments/newRound/${id}`;
  try {
    const result = await axios.post(url, {
      category,
      tipoCuadro,
      idCuadro,
      prevRound,
      players,
      maxPlayers,
      deleteRound,
    });
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
export const updateSets = async ({ idCuadro, idRound, teamData, sets }) => {
  const url = `${API}/tournaments/sets/update`;
  try {
    const result = await axios.post(url, { idCuadro, idRound, teamData, sets });
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
export const updateTitleGroup = async ({
  idCuadro,
  idRound,
  title,
  rankingPoints,
}) => {
  const url = `${API}/tournaments/group/title/update`;
  try {
    await axios.post(url, {
      idCuadro,
      idRound,
      title,
      rankingPoints,
    });
    return { update: true };
  } catch (error) {
    return false;
  }
};
export const getCourtByCategory = async (id, category) => {
  const url = `${API}/tournaments/${id}/${category}`;
  try {
    const result = await axios.get(url);
    return result.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
