import "../styles/WelcomeClub.css";
import Stepper from "../components/stepper/HorizontalLinearStepper";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { useUser } from "../context/UserContext";

function mapStateToProps(state) {
  return {};
}

const WelcomeClub = () => {
  const { isClub, settings } = useUser();
  if (settings || !isClub) return <Navigate to="/explore" replace />;
  return (
    <div className="post-register">
      <div className="post-register-content">
        <Stepper />
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(WelcomeClub);
