import React, { useEffect, useRef, useState } from 'react';
import Button from '../../components/ui/Button';
import NumberInput from '../../components/ui/NumberInput';
import classNames from 'classnames';
import Modal from '../../components/ui/Modal';
import { AiFillSave } from 'react-icons/ai';
import { RiCloseLine } from 'react-icons/ri';
import { useUser } from '../../context/UserContext';
import Loading from '../Loading';
import Input from '../../components/ui/Input';
const TitleBracketModal = ({ onClose, isOpen, data, updateData, isSaving }) => {
  const [title, setTitle] = useState(data?.title || '');
  const [rankingPoints, setRankingPoints] = useState(data?.rankingPoints || 0);
  const { isAdmin } = useUser();
  const input = useRef();
  useEffect(() => {
    let timer;
    if (isOpen) {
      setTitle(data?.title || '');
      setRankingPoints(data?.rankingPoints || 0);
      timer = setTimeout(() => {
        input.current.focus();
      }, 300);
    } else {
      setTitle('');
      setRankingPoints(0);
    }
    return () => timer && clearTimeout(timer);
  }, [data?.title, data?.rankingPoints, isOpen]);

  const handleClose = () => {
    onClose?.();
  };
  return (
    <Modal
      onClose={() => !isSaving && handleClose?.()}
      show={isOpen && isAdmin}
      width={440}
    >
      <form
        className="tw-flex tw-flex-col tw-my-4 tw-px-7 scroll tw-h-full"
        onSubmit={(e) => {
          e.preventDefault();
          updateData?.({ title, rankingPoints, data });
        }}
      >
        {isSaving && (
          <div className="tw-w-full tw-h-full tw-absolute tw-z-30 tw-flex tw-items-center tw-justify-center tw-bg-gray-600 tw-bg-opacity-25 tw-right-0 tw-top-0">
            <Loading label="Guardando" />
          </div>
        )}
        <div className="tw-sticky tw-top-0 tw-text-Turquesa tw-font-bold tw-text-2xl tw-mb-3 tw-bg-white tw-z-20">
          <div className="tw-flex tw-h-12 tw-items-center tw-justify-between">
            <h1 className="tw-w-full tw-text-center">Editar Ronda</h1>
            <div className="tw-flex tw-h-12 tw-items-center tw-justify-between tw-text-Turquesa tw-font-bold tw-text-2xl tw-gap-10">
              <RiCloseLine
                size={30}
                onClick={() => !isSaving && handleClose?.()}
                className={classNames({ 'tw-cursor-pointer': !isSaving })}
              />
            </div>
          </div>
          <div className="tw-text-gray-500 tw-font-normal tw-flex tw-justify-center tw-flex-wrap">
            <div className="tw-bg-white tw-pt-3 tw-w-full">
              <Input
                required
                ref={input}
                autoFocus
                label="Nombre de la ronda"
                inputClass="tw-border-Verde-TenisCTA tw-border-solid tw-border-2 tw-rounded tw-text-sm tw-w-full tw-h-10 tw-flex tw-items-center tw-font-sans tw-px-2 tw-flex"
                placeholder="Nombre de la ronda"
                inputStylesOff
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                containerStylesOff
                disabled={isSaving}
                full
                inputStyles={{ paddingBlock: 0 }}
                containerClass="tw-pb-0"
              />
              <NumberInput
                label="Puntos por Ronda"
                inputClass="tw-border-Verde-TenisCTA tw-border-solid tw-border-2 tw-rounded tw-text-sm tw-w-full tw-h-10 tw-flex tw-items-center tw-font-sans tw-px-2 tw-flex"
                placeholder="Puntos por Ronda"
                inputStylesOff
                value={rankingPoints}
                onChange={(event) => setRankingPoints(event.target.value)}
                containerStylesOff
                disabled={isSaving}
                full
                required
                inputStyles={{ paddingBlock: 0 }}
              />
            </div>
            <div className="tw-relative tw-flex tw-items-center tw-gap-2 tw-justify-end tw-w-full">
              <Button type="submit" className="tw-py-2" disabled={isSaving}>
                <div className="tw-flex tw-items-center tw-text-sm tw-font-semibold">
                  <AiFillSave size={16} style={{ marginRight: 4 }} />
                  Guardar
                </div>
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default TitleBracketModal;
