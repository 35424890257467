import Thumbnails from '../../assets/thumb3.png'
import { changePestañaSelected } from "../../actions/header";
import { connect } from "react-redux";

//style
import '../../styles/EventosCerca.css'




function mapStateToProps(state) {
    return state
}


const EventosCerca = (props) => {
    const { dispatch } = props;
    const cambiarContenido = (selected) => {
        dispatch(changePestañaSelected(selected))
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div className='event-content-land'>
            <div className='event-text-container'>
                <h1>EVENTOS CERCA DE TU REGIÓN Y EN TODO CHILE</h1>
                <p>Con Tenis-Up encontrar jugadores cerca es más fácil que nunca.</p>
                
            </div>
            <div className='event-screenshots-container'>
                <img className='event-screenshots' src={Thumbnails} alt='Imagen Eventos Cercas' />
                <button className="event-button" onClick={() => cambiarContenido('eventos')}>VER COMO <b>FUNCIONA</b></button>
            </div>
        </div>
    )
}
export default connect(mapStateToProps)(EventosCerca)










// import { Component } from 'react'
// import Thumbnails from '../../assets/thumb3.png'

// //style
// import '../../styles/EventosCerca.css'
// import Modal from 'react-modal'
// import styled from 'styled-components'
// import '../../styles/Footer.css'
// import LoginModal from '../LoginModal'

// export default class EventosCerca extends Component {

//     state = {
//         showLogin: false
//       }

//       toggleModal = () => {
//         this.setState({showLogin: !this.state.showLogin})
//       }


//     state = {
//         showMess: false
//       }

//       ques2 = () => {
//         this.setState({showMess: false})
//       }

//       ques = () =>{
//           this.setState({showMess : true})
//       }
//     render() {
//         const customStyles = {
//             content : {
//               top: '50%',
//               left: '50%',
//               right: 'auto',
//               bottom: 'auto',
//               marginRight: '-50%',
//               transform: 'translate(-50%, -50%)',
//               border: 'none'
//             },
//             overlay: {
//                 backgroundColor: 'rgba(0,0,0,0.75)',
//             }
//         }; 
//         return (
//             <div className='event-content-land'>
//                 <div className='event-text-container'>
//                     <h1>Eventos cerca de tu región y en todo Chile</h1>
//                     <p>Con Tenis-Up encontrar jugadores cerca es más fácil que nunca.</p>
//                     <button onClick = {this.ques}>Ver Eventos</button>
//                     <Modal 
//                             isOpen={this.state.showMess}
//                             style={customStyles}
//                             shouldCloseOnOverlayClick={true}
//                             onRequestClose={this.ques2}
//                         >
//                             <div className = 'modal-header' 
//                             style = {{width:'200px', height: '50px'}}> 

//                                 <Label> Debes iniciar sesión para visualizar los eventos</Label>

//                                 <button onClick={this.ques2} type = 'button' class = 'close' > 
//                                     <span > x </span>
//                                 </button>
//                             </div> 
//                             <div className = 'modal-body'>
//                             <button onClick = {() => this.toggleModal() & this.ques} className='login-sesion'>🧍  Iniciar sesion</button>
//                                 <LoginModal 
//                                     toggleModal={this.toggleModal} 
//                                     showLogin={this.state.showLogin}
//                                 />      
//                             </div>
//                         </Modal>
//                 </div>
//                 <div className='event-screenshots-container'>
//                     <img className='event-screenshots' src={Thumbnails} alt='Imagen Eventos Cercas'/>
//                 </div>
//                 <LoginModal 
//                     toggleModal={this.toggleModal} 
//                     showLogin={this.state.showLogin}
//                 />
//             </div>
//         )
//     }
// }
// const Label = styled.label`
//     font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 25px;
//     line-height: 33px;
//     color: #000000;


// `