import classNames from "classnames";
import { useMemo } from "react";
import { Hours } from "../../costants/Time";

const WeekItem = ({
  name,
  disponibility = true,
  selectables = true,
  hours = {},
}) => {
  const hoursTemplate = useMemo(() => {
    let auxGroup = 1;
    return Hours.map((h, index) => {
      const classs = classNames({
        itemHour: disponibility && selectables,
        itemHourNd: !disponibility || !selectables,
        [`disponible selectGroup${name}${auxGroup}`]:
          disponibility === true && hours[h || index] === 1,
        nodisponible: !(disponibility === true && hours[h || index] === 1),
        courtocupada: hours[h || index] === 2,
      });
      if (hours[h || index] !== 1) auxGroup++;
      return { classs, key: name + "--" + h, h };
    });
  }, [disponibility, name, selectables, hours]);

  return (
    // <div className="sm:tw-col-span-1 tw-col-span-7">
    <div>
      {hoursTemplate.map(({ key, classs, h }) => (
        <div
          id={key}
          itemID={key}
          key={"innerWeekItem" + key}
          className={classs}
        />
      ))}
    </div>
  );
};

export default WeekItem;
