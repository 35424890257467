import {
    //Obtener rankings
    GET_RANKING_REQUEST,
    GET_RANKING_SUCCESS,
    GET_RANKING_FAILURE,
} from '../actions/ranking'

const rankingState = (
    state = {
        //Traer rankings 
        isFetchingRanking: false,
        rankingFetched: false,
        fetchRankingError: false,
        ranking: [],
    },
    action
) => {
    switch (action.type) {
        
        case GET_RANKING_REQUEST:
            return {
                isFetchingRanking: true,
                rankingFetched: false,
                fetchRankingError: false,
            }
        case GET_RANKING_SUCCESS:
            return {
                isFetchingRanking: false,
                rankingFetched: true,
                fetchRankingError: false,
                ranking: action.ranking
            }
        case GET_RANKING_FAILURE:
            return {
                isFetchingRanking: false,
                rankingFetched: false,
                fetchRankingError: true,
            }
            default:
                return state
    }
}

export default rankingState;