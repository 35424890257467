import classNames from 'classnames';

const Button = ({
  children,
  disabled = false,
  type = 'button',
  className,
  buttonType = 'primary',
  onClick,
}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      className={classNames(
        'tw-p-3 tw-rounded-lg tw-font-bold ',
        className || '',
        {
          'tw-bg-Verde-TenisCTA tw-text-Turquesa hover:tw-bg-Verde-seconCTA':
            buttonType === 'primary',
          'tw-bg-gray-100 hover:tw-bg-opacity-90 tw-text-Turquesa':
            buttonType === 'secundary',
        }
      )}
      onClick={onClick}
      style={{
        fontFamily: `"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
      }}
    >
      {children}
    </button>
  );
};

export default Button;
