import { useState, useEffect, useCallback } from "react";
import Map from "../mapa/Map";
import { useNavigate, useParams, Link } from "react-router-dom";
import { FaArrowCircleLeft } from "react-icons/fa";
import Modal from "react-modal";
import { changeMenuSelected } from "../../actions/menu";
import logoTU from "../../assets/logo.png";
import { connect } from "react-redux";
import defaultPerfil from '../../assets/perfil.png';
import {
  getSingleEvent,
  getMyEvents,
  clearSingleEvent,
  validatePrivacidad,
} from "../../actions/events";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  cleanRegistration,
  set_eventReplace,
  set_StatusRegistration,
} from "../../actions/inscription";
import Loading from "../../pages/Loading";
import Error from "../errors/Error";
import { Button1, customStyles, ModalMobile } from "./styles/EventStyles";
import "../../styles/Events.css";
import RenderTimeAdv from "./RenderTimeAdv";
import useTimeToToday from "../../hooks/useTimeToToday";
import { colors } from "../../costants/colors";
// import Select from "../ui/Select";
import { CourtType } from "../ui/CourtIcon";
import useCupos from "../../hooks/useCupos";
import { getAllPlayers, getSinglePlayerType } from "../../actions/jugador";
import toast from "react-hot-toast";
// import SimpleUser from "../ui/SimpleUser";
import { deleteInscription } from "../../services/transactions/Transaction.service";
import SelectUsers from "./SelectUsers";
import SelectCategorias from "./SelectCategorias";
import moment from "moment";
import classNames from "classnames";
import { useUser } from "../../context/UserContext";

function mapStateToProps(state) {
  return {
    isFetchingSingleEvent: state.events.isFetchingSingleEvent,
    singleEventFetched: state.events.singleEventFetched,
    fetchSingleEventError: state.events.fetchSingleEventError,
    event: state.events.event,
    myEvents: state.events.myEvents,

    //cancel registration
    isCancelingRegister: state.register.isCancelingRegister,
    isRegisterCanceled: state.register.isRegisterCanceled,
    errorCancelingRegister: state.register.errorCancelingRegister,

    users: state.jugador.players,

    eventReplace: state.register.eventReplace,
    mode: state.menu.mode,
  };
}

const DetailEvent = (props) => {
  const { viewInscritos, eventID, noShowBack, eventReplace, mode, users = [], customBack } = props;
  const { event, singleEventFetched } = props;
  const { fetchSingleEventError, myEvents, dispatch } = props;
  const { isAdmin, isClub, tipo_usuario, settings, uid, isNotPlayer, isPlayer } = useUser();

  const { deporte } = event?.categoria || { deporte: "tenis" };
  const { eventId: eventIdParam } = useParams("eventId");
  const eventId = eventID ? eventID : eventIdParam;
  const {
    getCategorias,
    getAvailability,
    categoria,
    totalcupos,
    inscrito,
    inscritoName,
    payment_method,
    inscritosList,
  } = useCupos({
    deporte,
    settings,
    event,
    eventReplace,
    myEvents,
  });

  const [equipo, setEquipo] = useState({});
  const [categoriaOpen, setCategoriaOpen] = useState(categoria);
  const [showModal, setShowModal] = useState();
  const [showConfirmationInscription, setConfirmationInscription] = useState(false);

  useEffect(() => {
    if (users?.length === 0) dispatch(getAllPlayers());
    dispatch(getSinglePlayerType(null));
  }, [dispatch, users]);

  const [timer, timeover] = useTimeToToday(event?.fechaLimite);
  const navigate = useNavigate();
  const mobile = useMediaQuery("(max-width: 800px)");

  useEffect(() => {
    dispatch(set_eventReplace(inscritoName || ""));
    setCategoriaOpen(inscritoName);
  }, [inscritoName, dispatch]);

  useEffect(() => {
    if (!isNotPlayer) {
      const offs = dispatch(getMyEvents());
      return () => {
        Object.values(offs || {}).forEach((off) => off?.());
      };
    }
  }, [dispatch, isNotPlayer]);
  
  useEffect(() => {
    if (!inscrito && showModal === "deleteInscription") setShowModal(false);
    if (inscrito && showModal === "inscription") setShowModal(false);
  }, [inscrito, showModal]);
  useEffect(() => {
    dispatch(getSingleEvent(eventId));
  }, [eventId, dispatch]);

  const goBack = useCallback(() => {
    navigate("/explore");
    dispatch(changeMenuSelected("/explore"));
    dispatch(clearSingleEvent());
  }, [dispatch, navigate]);

  useEffect(() => {
    if (event && !validatePrivacidad(event, tipo_usuario)) goBack();
  }, [tipo_usuario, event, goBack]);
  /**
   * Maneja las flags del cancelacion de registro.
   */
  const {
    errorCancelingRegister,
    isRegisterCanceled,
    event: { id },
  } = props;
  useEffect(() => {
    // navigate(`/event/${id}`)
    if (errorCancelingRegister) {
      alert("error");
    } else if (isRegisterCanceled) {
      dispatch(cleanRegistration());
    }
  }, [dispatch, errorCancelingRegister, isRegisterCanceled, id]);
  useEffect(() => {
    dispatch(
      set_StatusRegistration({
        isRegistering: false,
        isRegistered: inscrito,
        errorRegistering: false,
      })
    );
  }, [inscrito, dispatch]);
  /**
   * Función que establece el estado del usuario en el evento (inscrito, no inscrito).
   */

  const CancelBtn = () => (
    <Button1 className="modal-button" onClick={() => setShowModal(false)}>
      <span>Cancelar</span>
    </Button1>
  );
  const ModalInscription = () => {
    switch (showModal) {
      case "deleteInscription":
        return (
          <>
            <div className="mensaje">
              <h5> ¿Estás seguro de cancelar tu inscripción al torneo? </h5>
            </div>
            <div className="modal-body">
              <CancelBtn />
              <Button1
                className="modal-button"
                onClick={(event) => {
                  cancelRegistration(event);
                  setShowModal(false);
                }}
              >
                <span>Eliminar inscripción</span>
              </Button1>
            </div>
          </>
        );
      case "inscription":
        return (
          <>
            <div className="mensaje">
              <p style={{ marginBottom: 0 }}>
                ¿Estás seguro de inscribirte al torneo con la categoría "
                {eventReplace ? eventReplace : categoria}"?
              </p>
              {!eventReplace && (
                <p style={{ fontSize: 16, marginBottom: 0 }}>
                  Si deseas cambiarla, puedes hacerlo en{" "}
                  <Link to="/editprofile">configuración</Link>
                </p>
              )}
            </div>

            <div className="modal-body tw-flex tw-flex-row tw-justify-center">
              <CancelBtn />

              <Link to={`/inscripcion/${event.id}`}>
                <Button1 className="modal-button">
                  <span>Si estoy seguro!</span>
                </Button1>
              </Link>
            </div>
          </>
        );
      default:
        break;
    }
  };

  const returnModalConfirmacion = () => {
    return (
      <Modal
        isOpen={showConfirmationInscription}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setConfirmationInscription(false)}
      ></Modal>
    );
  };

  /**
   * Función que se ejecuta al presionar el botón de cancelar inscripción en evento.
   * @param event Evento del botón
   */
  const cancelRegistration = (event) => {
    event.preventDefault();
    const {
      event: { id, isDual },
    } = props;
    if (id) {
      const userID = uid;
      deleteInscription({ userID, EventId: id, categoria: !isDual ? inscrito : categoriaOpen });
    }
  };

  /**
   * Renderiza el botón dependiendo de si se puede inscribir o no.
   */
  const renderButton = () => {
    if (isNotPlayer) {
      return (
        <>
          <div className="detalle-inscripcion">
            <Link
              to={`/?nextRoute=${"/mapa"}&selectID=${eventID}`}
              style={{ textDecoration: "none" }}
            >
              <button
                className="detalle-inscribirse"
                style={{
                  color: "#2085b7",
                  backgroundColor: "#DCEC00",
                  cursor: "pointer",
                }}
              >
                Regístrate en tenis-up para inscribirte!
              </button>
            </Link>
          </div>

        </>
      )
    }
    if (timeover) {
      return (
        <div className="detalle-inscripcion">
          <button
            disabled
            className="detalle-inscribirse"
            style={{
              color: "#9d9dab",
              backgroundColor: "#EFEFF7",
              cursor: "auto",
            }}
          >
            Ya no te puedes inscribir
          </button>
        </div>
      );
    } else {
      if (inscrito) {
        if (payment_method !== "webpay" || isAdmin)
          return (
            <div className="detalle-inscripcion">
              <button
                onClick={() => setShowModal("deleteInscription")}
                className="detalle-inscribirse"
                style={{
                  color: "#fff",
                  backgroundColor: "#d43535",
                  cursor: "pointer",
                }}
              >
                Cancelar inscripcion
              </button>
            </div>
          );
        return (
          <div className="detalle-inscripcion">
            <button
              disabled
              className="detalle-inscribirse"
              style={{
                color: "#9d9dab",
                backgroundColor: "#EFEFF7",
                cursor: "auto",
              }}
            >
              No puedes cancelar la inscripción
            </button>
          </div>
        );
      } else {
        if (isClub) {
          return (
            <div className="detalle-inscripcion">
              <button
                disabled
                className="detalle-inscribirse"
                style={{
                  color: "#9d9dab",
                  backgroundColor: "#EFEFF7",
                  cursor: "auto",
                }}
              >
                Registrate como tenista para poder inscribirte al torneo!
              </button>
            </div>
          );
        }
        if (getAvailability === 0) {
          return (
            <div className="detalle-inscripcion">
              <button
                disabled
                className="detalle-inscribirse"
                style={{
                  color: "#9d9dab",
                  backgroundColor: "#EFEFF7",
                  cursor: "auto",
                }}
              >
                Ya no quedan cupos disponibles!
              </button>
            </div>
          );
        } else {
          return (
            <div className="detalle-inscripcion">
              <button
                onClick={() => {
                  if (equipo.key || !event.isDual) setShowModal("inscription");
                  else
                    toast.error("Selecciona primero un compañero para inscribirte", {
                      duration: 2000,
                    });
                }}
                style={{ backgroundColor: colors.Verde.TenisCTA, color: colors.Turquesa }}
                className="detalle-inscribirse"
              >
                Inscribirme al Torneo
              </button>
            </div>
          );
        }
      }
    }
  };

  const renderMensaje = () => {
    if (isClub) {
      return (
        <h2>
          Al ser un <b>club</b> no puedes inscribirte a torneos,
          <br />
          si deseas inscribirte, registrate como usuario tenista.
        </h2>
      )
    } if (isNotPlayer) {
      return (
        <h2>
          Todavía no cuentas con una cuenta en Tenis-Up.
          <br />
          Si deseas inscribirte, registrate como usuario tenista.
        </h2>
      )
    } if (isPlayer) {
      return (
        deporte !== "padel" && (
          <h2>
            Tú categoría actual es <b>"{categoria}"</b>, si deseeas cambiarla, puedes{" "}
            <br />
            hacerlo a través de tu{" "}
            <Link style={{ color: colors.Turquesa }} to="/editprofile">
              Configuración
            </Link>
          </h2>
        )
      )
    }
  }
  /**
   * Retorna los cupos libres del torneo en la categoría del jugador.
   */
  if (fetchSingleEventError) return <Error message="evento no encontrado"></Error>;
  if (singleEventFetched) {
    const fechaInFormat = moment(event.fechaInicio).format("DD/MM/YYYY");
    const fechaLimFormat = moment(event.fechaLimite).format("DD/MM/YYYY");
    return (
      <div className={"main-container-" + mode}>
        <div className="detail-event-container" style={{ position: "relative" }}>
          <div className="detalle-row">
            <div className="detalle-portada" style={{ position: "relative" }}>
              <div className="mapa-container" style={{ height: "180px", width: "100%" }}>
                <Map lat={event.lat} lng={event.lng} zoom={15} disable />
              </div>
              {!noShowBack && (
                <div
                  onClick={() => (customBack ? customBack() : goBack())}
                  className="button-back"
                  style={{ top: 0, zIndex: 0 }}
                >
                  <FaArrowCircleLeft color={colors.Turquesa} className="button-back-style" />
                </div>
              )}
              {!isNotPlayer && <RenderTimeAdv fechaLimite={event.fechaLimite} timer={timer} timeover={timeover} />}
            </div>
          </div>
          <div className="detalle-body">
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {viewInscritos && (
                <div className="detailEvent-toolsButtons-container">
                  <Link to={"register"} className="detailEvent-toolsButtons">
                    <h2 className="detalle-evento-botones">Ver Inscritos</h2>
                  </Link>
                  <Link to={"tournament-tables"} className="detailEvent-toolsButtons">
                    <h2 className="detalle-evento-botones">Ver Cuadro</h2>
                  </Link>
                  <Link to={"bases-details"} className="detailEvent-toolsButtons">
                    <h2 className="detalle-evento-botones">Ver Bases</h2>
                  </Link>
                </div>
              )}
              <div className="detail-event-title">
                <h1
                  style={{
                    marginRight: 12,
                    fontSize: "2.5rem",
                    fontWeight: 500,
                    marginBottom: "0.5rem",
                  }}
                >
                  {event.nombre}
                </h1>
              </div>
              <div style={{ width: "100%" }}>
                <div className="detalle-evento2 nopadding" style={{ padding: "20px 0 0 0" }}>
                  <div className="detalle-evento nopadding">
                    <div>
                      <h2 style={{ textTransform: "capitalize", marginBottom: "0.5rem" }}>
                        {event.categoria?.tipo || "Torneo"} {event.categoria?.subtipo}
                      </h2>
                      <h3>{event.region}</h3>
                    </div>
                    <CourtType type={deporte} size={30} bgColor={colors.Verde.TenisCTA} showLabel />
                  </div>
                  <div
                    className={classNames("detalle-cupos-grid tw-flex", {
                      "tw-mt-8": !event?.isDual,
                    })}
                  >
                    {event?.isDual && ( 
                      <div style={{ width: "100%" }}>
                        <SelectUsers
                          onChange={(v) => {
                            setEquipo(v);
                            dispatch(getSinglePlayerType(v));
                          }}
                          value={equipo}
                          users={users}
                          ownerUid={uid}
                          getCategorias={getCategorias}
                          inscritosList={inscritosList}
                          categoriaOpen={categoriaOpen}
                          imageDefault={defaultPerfil}
                        />
                      </div>
                    )}
                    <div className="detalle-cupos">
                      <h3>CATEGORÍA</h3>
                      <SelectCategorias
                        isSearchable
                        categoria={categoria}
                        categoriaOpen={categoriaOpen}
                        onChange={(value) => {
                          setCategoriaOpen(value);
                          dispatch(set_eventReplace(value));
                        }}
                        deporte={deporte}
                        getCategorias={getCategorias}
                      />
                    </div>
                    <div className="detalle-cupos">
                      <h3>CUPOS DISPONIBLES</h3>
                      <span style={{ marginTop: 12 }}>{getAvailability}</span>
                    </div>
                    <div className="detalle-cupos">
                      <h3>CUPOS TOTALES</h3>
                      <span style={{ marginTop: 12 }}>{totalcupos}</span>
                    </div>
                  </div>
                  <div className="detalle-fechas">
                    <h3 style={{ lineHeight: 1.2 }}>FECHAS</h3>
                    <div className="fecha-container-column">
                      <h1 style={{ marginBottom: "0.5rem" }}> {fechaLimFormat}</h1>
                      <h2 style={{ marginBottom: "0.5rem" }}>Fecha Limite</h2>
                    </div>
                    <div className="fecha-container-column">
                      <h1 style={{ marginBottom: "0.5rem" }}>{fechaInFormat}</h1>
                      <h2 style={{ marginBottom: "0.5rem" }}>Fecha Inicio</h2>
                    </div>
                  </div>
                  <div className="mensaje-container">
                    {renderMensaje()}
                  </div>
                </div>
              </div>
            </div>
            <div className="detalle-organizadores-inscripcion">
              <div className="detalle-organizadores">
                <h3>ORGANIZA</h3>
                <img alt="organizador" src={logoTU}></img>
              </div>
              {renderButton()}
              <Modal
                isOpen={!!showModal}
                style={!mobile ? customStyles : ModalMobile}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => setShowModal(false)}
              >
                {ModalInscription()}
              </Modal>
              {returnModalConfirmacion()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <Loading />;
};

export default connect(mapStateToProps)(DetailEvent);
