import classNames from "classnames";

const FilterBtn = ({ active, handleClick, text, wfit = false }) => {
  return (
    <button
      type="button"
      className={classNames(
        "tw-shadow-sm tw-border-Verde-hover-2 tw-border-2 tw-h-8 tw-rounded-lg tw-text-Turquesa tw-font-semibold",
        {
          "tw-bg-Verde-hover-2": active,
          "tw-bg-gray-100": !active,
          "tw-w-16": !wfit,
          "tw-w-fit": wfit,
        }
      )}
      onClick={handleClick}
    >
      {text}
    </button>
  );
};

export default FilterBtn;
