import authReducer from './authReducer'
import eventsReducer from './eventsReducer'
import { combineReducers } from 'redux'
import machReducer from './machReducer';
import menuReducer from './menuReducer';
import inscripcionReducer from './inscripcionReducer';
import clubsReducer from './clubsReducer';
import profileReducer from './profileReducer';
import privilegiosReducer from './privilegiosReducer';
import headerReducer from './headerReducer';
import jugadorReducer from './jugadorReducer';
import rentReducer from './rentReducer';
import paymentReducer from './paymentReducer';
import tipoRankingReducer from './tipoRankingReducer';
import rankingReducer from './rankingReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    events: eventsReducer,
    mach: machReducer,
    menu: menuReducer,
    register: inscripcionReducer,
    rent: rentReducer,
    club: clubsReducer,
    profile: profileReducer,
    privilegio: privilegiosReducer,
    header: headerReducer,
    jugador: jugadorReducer,
    payment:paymentReducer,
    typeRank: tipoRankingReducer,
    ranking: rankingReducer
})

export default rootReducer;