import { useState, useEffect, createContext, useContext, useMemo } from 'react';
import Button from '../../components/ui/Button';
import Modal from '../../components/ui/Modal';
import Title from '../../components/ui/Title';
import TabsHeader from '../../components/ui/TabsHeader';
import Input from '../../components/ui/Input';
import PhoneInput from '../../components/ui/PhoneInput';
import SelectCategorias from '../../components/events/SelectCategorias';
import useCategorias from '../../hooks/useCategorias';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import SelectUsers from '../../components/events/SelectUsers';
import {
  addPlayerInEvent,
  inscription,
} from '../../services/transactions/Transaction.service';
import toast from 'react-hot-toast';
import defaultPerfil from '../../assets/perfil.png';

const AddPlayerModalContext = createContext();

const AddPlayerModal = ({ show, closeModal, data = {} }) => {
  const [step, setStep] = useState(0);
  useEffect(() => {
    if (show) setStep(0);
  }, [show]);
  return (
    <AddPlayerModalContext.Provider value={{ ...data }}>
      <Modal show={show} onClose={closeModal} width={800}>
        <div className="md:tw-p-6 tw-py-6 md:tw-py-0 md:tw-pb-6 tw-grid tw-h-screen md:tw-h-full tw-overflow-hidden tw-grid-rows-2-auto-fr">
          {step === 0 && <MainStep goBack={closeModal} setStep={setStep} />}
          {step === 'AddStep' && (
            <AddStep goBack={() => setStep(0)} setStep={setStep} />
          )}
          {step === 'ExternalPlayerStep' && (
            <ExternalPlayerStep
              goBack={() => setStep('AddStep')}
              handleClose={closeModal}
              step={step}
            />
          )}
          {step === 'TUPlayerStep' && (
            <TUPlayerStep
              goBack={() => setStep('AddStep')}
              handleClose={closeModal}
              step={step}
            />
          )}
        </div>
      </Modal>
    </AddPlayerModalContext.Provider>
  );
};

const MainStep = ({ goBack, setStep }) => {
  return (
    <>
      <div className="tw-px-6 md:tw-px-0">
        <Title
          goBack={goBack}
          title={
            <div className="md:tw-text-3xl tw-text-lg tw-px-10">
              Elige como deseas agregar deportistas
            </div>
          }
          textCenter
          titleType="secundario"
        />
      </div>
      <div className="tw-flex tw-flex-col tw-w-full tw-gap-4 tw-h-72 tw-items-center tw-justify-center">
        <Button className="tw-w-44">Importar Lista</Button>
        <Button className="tw-w-44" onClick={() => setStep('AddStep')}>
          Añadir
        </Button>
      </div>
    </>
  );
};
const AddStep = ({ goBack, setStep }) => {
  return (
    <>
      <div className="tw-px-6 md:tw-px-0">
        <Title
          goBack={goBack}
          title={
            <div className="md:tw-text-3xl tw-text-lg tw-px-10">
              Elige como deseas agregar deportistas
            </div>
          }
          textCenter
          titleType="secundario"
        />
      </div>
      <div className="tw-flex tw-flex-col tw-w-full tw-gap-4 tw-h-72 tw-items-center tw-justify-center">
        <Button
          className="tw-w-52"
          onClick={() => setStep('ExternalPlayerStep')}
        >
          Añadir jugador externo
        </Button>
        <Button className="tw-w-52" onClick={() => setStep('TUPlayerStep')}>
          Añadir jugador Tenis-Up
        </Button>
      </div>
    </>
  );
};

const EmailReg = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const externalPlayerSchema = yup
  .object({
    player: yup.array().of(
      yup.object().shape({
        nombre: yup.string().required('Inserte un nombre'),
        apellido: yup.string().required('Inserte el apellido del jugador'),
        email: yup
          .string()
          .test(
            'test-email-validation',
            'El correo no es válido',
            (value) => !value || EmailReg.test(value)
          ),
        phone: yup
          .string()
          .required('El teléfono es requerido')
          .test('test-phone-validation', 'El número no es válido', (value) =>
            isValidPhoneNumber(value ? '+' + value : '')
          ),
      })
    ),
    categoria: yup.string().required('Seleccione una categoría'),
  })
  .required();
const TUPlayerSchema = yup
  .object({
    player: yup.array().of(
      yup.object().shape({
        uid: yup.string().required('Selecciona un usuario'),
      })
    ),
    categoria: yup.string().required('Seleccione una categoría'),
  })
  .required();

const ExternalPlayerStep = ({ goBack, step, handleClose }) => {
  const menu = ['Nuevo', 'Existente en TenisUp'];

  const data = useContext(AddPlayerModalContext);
  const {
    event,
    eventReplace,
    handleChangeCategoria,
    deporte,
    inscritosList,
    getInscritobyiD,
  } = data || {};
  const { cupos = {}, id } = event || {};
  const { getCategorias } = useCategorias({ cupos });
  const isDual = useMemo(() => event?.isDual, [event?.isDual]);
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(externalPlayerSchema),
    mode: 'onChange',
    defaultValues: { player: [{}] },
  });
  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'player',
  });
  useEffect(() => {
    if (isDual) {
      if (fields.length < 2) insert(0, {});
    } else if (fields.length === 2) remove(0);
  }, [fields.length, insert, isDual, remove]);

  const addPlayer = async ({ player }) => {
    const [player1, player2] = player;
    if (!player1 || !eventReplace || !id) {
      toast.error('Datos inválidos');
      return;
    }
    player1.isExternal = true;
    delete player1.type;
    if (player2) {
      player2.isExternal = true;
      delete player2.type;
    }
    const result = await addPlayerInEvent({
      EventId: id,
      categoria: eventReplace,
      player1,
      player2: isDual && player2,
      isDual,
    });
    if (result) toast.success('Se han creado satisfactoriamente');
    else toast.error('Se ha producido un error');
    handleClose(false);
  };
  return (
    <>
      <div className="tw-px-6 md:tw-px-0">
        <Title
          goBack={goBack}
          title={
            <div className="md:tw-text-3xl tw-text-lg tw-px-10">
              {step === 'ExternalPlayerStep'
                ? 'Añadir Deportistas Externos'
                : 'Inscribir Jugador de TenisUp'}
            </div>
          }
          textCenter
          titleType="secundario"
        />
      </div>
      <form
        onSubmit={handleSubmit(addPlayer, (error) => console.log({ error }))}
        className="tw-flex tw-gap-x-5 scroll tw-flex-col"
      >
        {fields.map((player, index) => {
          return (
            <div key={player.id}>
              <Controller
                name={`player[${index}].type`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <TabsHeader
                      menuOptions={menu}
                      value={value || 'Nuevo'}
                      onChange={(type) =>
                        setValue(`player[${index}]`, { type })
                      }
                    />
                    {!value || value === 'Nuevo' ? (
                      <NewExternalPlayerStep
                        player={player}
                        index={index}
                        register={register}
                        errors={errors}
                        control={control}
                        categoria={eventReplace}
                        categoriaOpen={eventReplace}
                        onChange={handleChangeCategoria}
                        deporte={deporte}
                        getCategorias={getCategorias}
                      />
                    ) : (
                      <ExistenteExternalPlayerStep
                        player={player}
                        index={index}
                        register={register}
                        errors={errors}
                        control={control}
                        categoria={eventReplace}
                        categoriaOpen={eventReplace}
                        onChange={handleChangeCategoria}
                        deporte={deporte}
                        getCategorias={getCategorias}
                        event={event}
                        inscritosList={inscritosList}
                        getInscritobyiD={getInscritobyiD}
                        setValue={setValue}
                      />
                    )}
                  </div>
                )}
              />
            </div>
          );
        })}
        <Button className="tw-w-48 tw-self-center" type="submit">
          Añadir Jugador
        </Button>
      </form>
    </>
  );
};
const TUPlayerStep = ({ goBack, step, handleClose }) => {
  const data = useContext(AddPlayerModalContext);
  const {
    event,
    eventReplace,
    handleChangeCategoria,
    deporte,
    inscritosList,
    getInscritobyiD,
    users,
  } = data || {};
  const { cupos = {}, id } = event || {};
  const { getCategorias } = useCategorias({ cupos });
  const isDual = useMemo(() => event?.isDual, [event?.isDual]);
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(TUPlayerSchema),
    mode: 'onChange',
    defaultValues: { player: [{}] },
  });
  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'player',
  });
  useEffect(() => {
    if (isDual) {
      if (fields.length < 2) insert(0, {});
    } else if (fields.length === 2) remove(0);
  }, [fields.length, insert, isDual, remove]);
  useEffect(() => {
    setValue('categoria', eventReplace);
  }, [eventReplace, setValue]);

  const addPlayer = async ({ player, categoria }) => {
    const [player1, player2] = player;
    if (
      !player1?.uid ||
      !categoria ||
      !id ||
      !(player2?.uid || !event?.isDual)
    ) {
      toast.error('Datos inválidos');
      return;
    }
    const user = users.filter(({ uid }) => uid === player2?.uid);
    const result = await inscription(
      {
        amount: event.precio,
        key_torneo: id,
        uid: player1.uid,
      },
      {
        singlePlayer: event.isDual && {
          imagen: user?.settings.imagen,
          nombre: user?.settings.nombre,
          uid: player2.uid,
        },
        categoria,
        payment_method: 'efectivo',
      }
    );
    if (result) toast.success('Se han creado satisfactoriamente');
    else toast.error('Se ha producido un error');
    handleClose(false);
  };

  return (
    <>
      <div className="tw-px-6 md:tw-px-0">
        <Title
          goBack={goBack}
          title={
            <div className="md:tw-text-3xl tw-text-lg tw-px-10">
              {step === 'ExternalPlayerStep'
                ? 'Añadir Deportistas Externos'
                : 'Inscribir Jugador de TenisUp'}
            </div>
          }
          textCenter
          titleType="secundario"
        />
      </div>
      <form
        onSubmit={handleSubmit(addPlayer, (error) => console.log({ error }))}
        className="tw-flex tw-gap-x-5 scroll tw-flex-wrap"
      >
        <Controller
          name="categoria"
          control={control}
          render={({ field: { onChange: mainChange, value } }) => (
            <div className="tw-flex-1 tw-basis-60">
              <SelectCategorias
                categoria={eventReplace}
                categoriaOpen={value}
                onChange={(value) => {
                  handleChangeCategoria(value);
                  mainChange(value);
                }}
                deporte={deporte}
                getCategorias={getCategorias}
                isExterno
                isClearable
                label="Categoria"
                error={errors?.categoria?.message}
              />
            </div>
          )}
        />

        {fields.map((player, index) => {
          return (
            <div key={player.id} className="tw-basis-60 tw-flex tw-flex-1">
              <Controller
                name={`player[${index}].uid`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className="tw-basis-60 tw-flex tw-flex-1 tw-h-full tw-flex-col">
                    <SelectUsers
                      isDisabled={!eventReplace}
                      value={{ key: value }}
                      users={users}
                      getCategorias={getCategorias}
                      inscritosList={inscritosList}
                      categoriaOpen={eventReplace}
                      disabledFilter={(uid) => getInscritobyiD(uid)}
                      onChange={(user) => {
                        onChange(user?.uid || '');
                      }}
                      filter={(settings) => {
                        return (
                          eventReplace &&
                          (deporte !== 'tenis' ||
                            eventReplace === 'Abierta' ||
                            settings?.perfiljuego?.[deporte]?.categoria ===
                              eventReplace)
                        );
                      }}
                      imageDefault={defaultPerfil}
                    />
                    {errors?.player?.[index]?.uid?.message && (
                      <span className="tw-text-red-500 tw-text-xs tw-font-bold">
                        {errors?.player?.[index]?.uid?.message || ''}
                      </span>
                    )}
                  </div>
                )}
              />
            </div>
          );
        })}
        <div className="tw-w-full tw-flex tw-justify-center tw-mt-8">
          <Button className="tw-w-48 tw-self-center" type="submit">
            Añadir Jugador
          </Button>
        </div>
      </form>
    </>
  );
};

const NewExternalPlayerStep = ({
  index,
  register,
  errors,
  control,
  categoria,
  categoriaOpen,
  onChange,
  deporte,
  getCategorias,
}) => {
  return (
    <div className="tw-flex tw-w-full tw-flex-wrap tw-mb-5 tw-pt-5 tw-gap-x-8 tw-px-4">
      <p className="tw-text-Turquesa tw-font-bold tw-w-full tw-text-lg">
        Jugador {index + 1}
      </p>
      <Input
        {...register(`player[${index}].nombre`)}
        label="Nombre"
        placeholder="Ingrese el nombre"
        error={errors?.player?.[index]?.nombre?.message}
        containerClass="tw-flex-1 tw-basis-60"
      />
      <Input
        {...register(`player[${index}].apellido`)}
        label="Apellido"
        placeholder="Ingresa el Apellido"
        error={errors?.player?.[index]?.apellido?.message}
        containerClass="tw-flex-1 tw-basis-60"
      />
      <Controller
        name={`player[${index}].phone`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <PhoneInput
            label="Teléfono"
            placeholder="Ingresa tu número de teléfono"
            enableSearch
            value={value}
            onChange={(v) => {
              onChange(v);
            }}
            error={errors?.player?.[index]?.phone?.message}
            containerClass="tw-flex-1 tw-basis-60"
          />
        )}
      />
      <Controller
        name="categoria"
        control={control}
        render={({ field: { onChange: mainChange, value } }) => (
          <div className="tw-flex-1 tw-basis-60">
            <SelectCategorias
              categoria={categoria}
              categoriaOpen={value}
              onChange={(value) => {
                onChange(value);
                mainChange(value);
              }}
              deporte={deporte}
              getCategorias={getCategorias}
              isExterno
              isClearable
              label="Categoria"
              error={errors?.categoria?.message}
            />
          </div>
        )}
      />
      <Input
        {...register(`player[${index}].email`)}
        label="Correo electronico"
        placeholder="Ingresa el email (Opcional)"
        error={errors?.player?.[index]?.email?.message}
        containerClass="tw-flex-1 tw-basis-60"
        type="email"
      />
    </div>
  );
};
const ExistenteExternalPlayerStep = ({
  index,
  categoria,
  categoriaOpen,
  onChange,
  control,
  deporte,
  getCategorias,
  event,
  inscritosList,
  getInscritobyiD,
  setValue,
  errors,
}) => {
  const externos = useMemo(
    () =>
      Object.values(event.externos || {}).map((ext) => ({
        uid: ext.uid,
        settings: { ...ext },
      })) || [],
    [event.externos]
  );

  return (
    <div className="tw-flex tw-w-full tw-flex-wrap tw-mb-5 tw-pt-5 tw-gap-x-8 tw-px-4">
      <p className="tw-text-Turquesa tw-font-bold tw-w-full tw-text-lg">
        Jugador {index + 1}
      </p>

      <Controller
        name="categoria"
        control={control}
        render={({ field: { onChange: mainChange, value } }) => (
          <div className="tw-flex-1 tw-basis-60">
            <SelectCategorias
              categoria={categoria}
              categoriaOpen={value}
              onChange={(value) => {
                onChange(value);
                mainChange(value);
              }}
              deporte={deporte}
              getCategorias={getCategorias}
              isExterno
              isClearable
              label="Categoria"
              error={errors?.categoria?.message}
            />
          </div>
        )}
      />
      <div className="tw-flex-1 tw-basis-60">
        <Controller
          name={`player[${index}].uid`}
          control={control}
          render={({ field: { value } }) => (
            <SelectUsers
              onChange={(user = {}) => {
                const externo = externos.find(
                  ({ uid }) => uid === user?.key
                )?.settings;
                if (externo) {
                  const { phone, nombre, apellido, email } = externo;
                  setValue(`player[${index}].phone`, phone);
                  setValue(`player[${index}].nombre`, nombre);
                  setValue(`player[${index}].apellido`, apellido);
                  setValue(`player[${index}].email`, email);
                  setValue(`player[${index}].uid`, user?.key || '');
                }
              }}
              value={{ key: value }}
              users={externos}
              getCategorias={getCategorias}
              inscritosList={inscritosList}
              categoriaOpen={categoria}
              disabledFilter={getInscritobyiD}
              imageDefault={defaultPerfil}
            />
          )}
        />
      </div>
    </div>
  );
};
export default AddPlayerModal;
