import React from 'react';
import { deleteIMG } from '../../actions/events';
import swal from "@sweetalert/with-react";
import { useDispatch } from "react-redux";

const DeleteModal = ({
    setShowDelete,
    selectType,
    mode,
    id,
}) => {

    let dispatch = useDispatch();

    const deleteImg = async () => {
        await dispatch(deleteIMG(selectType, id, mode))
        setShowDelete(false)
        swal({
            title: "Se eliminó la imagen correctamente",
            text: "La imagen se eliminó correctamente del torneo",
            icon: "success",
            button: {
                text: "Continuar",
                value: "done",
            },
        });
    }

    return (
        <>
            <div className="tw-text-lg xs:tw-text-xl tw-font-objet">
                ¿Estás seguro que quieres eliminar?
            </div>
            <div className="modal-body tw-flex tw-flex-row tw-gap-5">
                <button onClick={() => setShowDelete(false)} className='btn btn-secondary btn-lg tw-font-objet'>
                    Cancelar
                </button>
                <button onClick={() => deleteImg()} className='btn btn-danger btn-lg tw-font-objet'>
                    Estoy Seguro
                </button>
            </div>
        </>
    );
};

export default DeleteModal;