import axios from "axios";
import { API } from "../../config/Config";

export async function counterLanding() {
  const url = `${API}/statistics/landing`;
  try {
    const { data } = await axios.get(url);
    return data.statistics;
  } catch (error) {
    console.log(error);
  }
}
