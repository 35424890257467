import { useMemo } from 'react';
import Select from '../ui/Select';
import SimpleUser from '../ui/SimpleUser';

const SelectUsers = ({
  onChange,
  value,
  users,
  ownerUid,
  getCategorias,
  inscritosList,
  categoriaOpen,
  isDisabled,
  imageDefault,
  filter = () => true,
  disabledFilter = () => false,
}) => {
  const listusers = useMemo(() => {
    if (!getCategorias) return [];
    return users
      .filter(
        ({ settings, uid }) =>
          settings && uid !== ownerUid && filter(settings, uid)
      )
      .map(({ settings, uid }) => {
        return {
          label: (
            <SimpleUser
              imagen={settings.imagen || imageDefault}
              nombre={settings.nombre}
              uid={uid}
            />
          ),
          imagen: settings.imagen || '',
          value: settings.nombre,
          nombre: settings.nombre,
          key: uid,
          isDisabled:
            !getCategorias ||
            inscritosList?.[`${uid}-${categoriaOpen}`] ||
            disabledFilter?.(uid),
          uid,
        };
      });
  }, [users, ownerUid, getCategorias, inscritosList, categoriaOpen, filter]); // eslint-disable-line

  return (
    <Select
      isDisabled={isDisabled}
      isClearable
      isSearchable
      name="equipo"
      placeholder="Selecciona tu compañero"
      label="Selecciona tu compañero 1"
      options={listusers}
      value={value}
      onChange={onChange}
    />
  );
};

export default SelectUsers;
