import { useState, useMemo } from "react";
import Select from "../ui/Select"
import styled from "styled-components";
import swal from "@sweetalert/with-react"
import SimpleUser from "../ui/SimpleUser";
import foto from "../../assets/perfil.png"
import { useDispatch } from "react-redux";
import { matchPlayer } from "../../actions/jugador";

const MatchAdmin = ({
    users,
    mobile,
}) => {
    const dispatch = useDispatch();
    const [user1, setUser1] = useState("");
    const [user2, setUser2] = useState("");

    const renderUsers = useMemo(() => {
        return users
            ?.filter((user) => user?.settings && user?.uid)
            .map((user) => ({ key: user.uid, label: <SimpleUser imagen={user.settings.imagen} nombre={user.settings.nombre} defaultImg={foto} />, value: user.settings.nombre }));
    }, [users]);

    const user2Data = users.find(e => e.uid === user2?.key)
    const match = async () => {
        let userOne = user1.key
        let userTwo = user2Data
        try {
            await dispatch(matchPlayer(userOne, userTwo));
            setUser1("")
            setUser2("")
            swal({
                title: "Se ha hecho un match correctamente",
                icon: "success",
                button: {
                    text: "OK",
                    value: "done"
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <div style={{ width: "100%", justifyContent: "center" }} className='tw-flex tw-flex-col tw-mt-5 tw-gap-0 tw-ml-4 xs:tw-ml-0 xs:tw-flex-row xs:tw-gap-5'>
                <div style={{ maxWidth: !mobile && 220, width: "100%" }}>
                    <Select
                        label="Jugador 1: "
                        placeholder="Selecciona un jugador"
                        onChange={(event) => setUser1(event)}
                        value={user1}
                        options={renderUsers}
                        isClearable
                        isSearchable
                    />
                </div>
                <div style={{ maxWidth: !mobile && 220, width: "100%" }}>
                    <Select
                        label="Jugador 2: "
                        placeholder="Selecciona un jugador"
                        onChange={(event) => setUser2(event)}
                        value={user2}
                        options={renderUsers}
                        isClearable
                        isSearchable
                    />
                </div>
            </div>
            <div className="tw-text-center tw-relative tw-flex-auto tw-p-4">
                <Button1 className="modal-button"
                    onClick={() => match()}

                >
                    <span>Guardar</span>
                </Button1>
            </div>
        </>
    )
}

export default (MatchAdmin);

const Button1 = styled.button`
  position: relative;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background-color: #dcec00;
  color: #2085b7;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  max-width: 200px;
  font-size: 14px;
  cursor: pointer;
`;