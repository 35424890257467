import { API } from '../../config/Config';
import { auth } from '../../firebase/firebase';
const axios = require('axios');
const headers = { 'Content-Type': 'application/json' };

export const inscription = async (
  data,
  { categoria = null, singlePlayer = null, payment_method = null }
) => {
  const USER_ID = auth.currentUser.uid;
  const url = `${API}/inscription`;
  try {
    const result = await axios.post(
      url,
      {
        amount: data.amount,
        key_torneo: data.key_torneo,
        uid: data.uid || USER_ID,
        categoria,
        equipo: singlePlayer,
        payment_method,
      },
      {
        headers: headers,
      }
    );
    return result.data;
  } catch (err) {
    console.error(
      '🚀 ~ file: Transaction.service.js ~ line 18 ~ create ~ err',
      err
    );
  }
};

export const deleteInscription = async (data) => {
  const url = `${API}/inscription/delete`;
  try {
    const result = await axios.post(url, data, {
      headers: headers,
    });
    return result.data;
  } catch (err) {
    console.error(
      '🚀 ~ file: Transaction.service.js ~ line 18 ~ create ~ err',
      err
    );
  }
};

export const editInscription = async (data) => {
  const url = `${API}/inscription/edit`;
  try {
    const result = await axios.post(url, data, {
      headers: headers,
    });
    return result.data;
  } catch (err) {
    console.error(
      '🚀 ~ file: Transaction.service.js ~ line 18 ~ create ~ err',
      err
    );
  }
};

export const addPlayerInEvent = async (data) => {
  const url = `${API}/inscription/add`;
  try {
    const result = await axios.post(url, data, {
      headers: headers,
    });
    return result.data;
  } catch (err) {
    console.error(
      '🚀 ~ file: Transaction.service.js ~ line 18 ~ create ~ err',
      err
    );
  }
};

export const rent = async (data) => {
  const url = `${API}/rent`;
  let result;
  try {
    const SendData = {
      amount: data.total,
      key_court: data.courtKey,
      uid: data.uid,
      clubUid: data.clubID,
      date: data.day, //{start:fecha,end:fecha}
      duration: data.duracion,
      diffutc: new Date().getTimezoneOffset(),
    };
    result = await axios.post(url, SendData, {
      headers: headers,
    });
    return { data: result.data, error: false };
  } catch (err) {
    return {
      error: 'Ha ocurrido un error',
      data: null,
      ...(err?.response?.data || {}),
    };
  }
};

export const status = async (token, type) => {
  var url = '';
  switch (type) {
    case 'rent':
      url = `${API}/statusRent/${token}`;

      break;
    case 'inscription':
      url = `${API}/statusInscription/${token}`;

      break;
    default:
      url = `${API}/status`;
      break;
  }
  try {
    const result = await axios.get(url, {
      headers: headers,
    });
    return result.data;
  } catch (err) {
    console.error(
      '🚀 ~ file: Transaction.service.js ~ line 39 ~ status ~ err',
      err
    );
  }
};

export const approvedOrRejected = (response_code) => {
  let responseTitle;
  if (response_code === 0) {
    responseTitle = 'Transacción Aprobada';
  } else {
    responseTitle = 'Transacción Rechazada';
  }
  return responseTitle;
};

export const isString = (amount_field) => {
  return !isNaN(amount_field);
};

export const isEmpty = (amount_field) => {
  return amount_field == null || amount_field.length === 0;
};
