import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../components/ui/Button';
import NumberInput from '../../components/ui/NumberInput';
import Modal from '../../components/ui/Modal';
import { AiFillSave, AiFillCheckCircle } from 'react-icons/ai';
import { RiCloseLine } from 'react-icons/ri';
import UserImage from '../../components/user/UserImage';
import { useUser } from '../../context/UserContext';
import Loading from '../../pages/Loading';
const singleSet = (set = 1) => ({
  set,
  puntos: [0, 0],
  max: 6,
});
const EditSets = ({
  onClose,
  isOpen,
  data,
  setsData,
  updateData,
  isSaving,
}) => {
  const [sets, setSets] = useState([]);
  const [winner, setWinner] = useState(-1);
  const { isAdmin } = useUser();

  const { teams = [] } = data || {};
  const readOnly = useMemo(
    () =>
      !isAdmin ||
      teams[0]?.readOnly ||
      teams[1]?.readOnly ||
      !teams[0]?.id ||
      !teams[1]?.id ||
      false,
    [teams, isAdmin]
  );
  const updateSet = (puntos, index, prevSet) => {
    let sets;
    setSets((value) => {
      sets = prevSet || value;

      const newSets = [...sets];
      const newPuntos = puntos?.map((punto) => {
        if (punto < 0) punto = 0;
        if (punto > newSets[index]?.max) punto = newSets[index].max;
        return punto;
      });
      if (index !== null && index !== undefined) {
        newSets[index] = { ...newSets[index], puntos: newPuntos };
      }
      let team1 = 0;
      let team2 = 0;
      let incompleto = 0;
      newSets.forEach((set) => {
        if (set.puntos[0] >= set.max) team1++;
        if (set.puntos[1] >= set.max) team2++;
        if (set.puntos[0] < set.max && set.puntos[1] < set.max) incompleto++;
      });
      if (
        newSets.length === 3 &&
        (incompleto > 1 || (incompleto === 1 && (team1 > 1 || team2 > 1)))
      ) {
        newSets.splice(2);
      }
      if (newSets.length === 2 && team1 === 1 && team2 === 1) {
        newSets.push(singleSet(3));
      }
      if (team1 > 1) setWinner(0);
      else if (team2 > 1) setWinner(1);
      else setWinner(-1);
      return newSets;
    });
  };
  useEffect(() => {
    if (isOpen) {
      updateSet(
        null,
        null,
        setsData?.length > 0 ? setsData : [singleSet(1), singleSet(2)]
      );
    }
  }, [isOpen, setsData]);

  const handleClose = () => {
    onClose?.();
  };
  return (
    <Modal
      onClose={() => !isSaving && handleClose?.()}
      show={isOpen}
      width={440}
    >
      {isSaving && (
        <div className="tw-w-full tw-h-full tw-absolute tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-gray-600 tw-bg-opacity-25">
          <Loading label="Guardando" />
        </div>
      )}
      <div className="tw-flex tw-flex-col tw-p-4 tw-px-7">
        <div className="tw-flex tw-h-12 tw-items-center tw-justify-between tw-text-Turquesa tw-font-bold tw-text-2xl tw-mb-5">
          Sets
          <div className="tw-flex tw-h-12 tw-items-center tw-justify-between tw-text-Turquesa tw-font-bold tw-text-2xl tw-gap-10">
            {!readOnly && (
              <Button
                type="button"
                className="tw-py-2"
                onClick={() => {
                  updateData?.(sets);
                }}
              >
                <div className="tw-flex tw-items-center tw-text-sm tw-font-semibold">
                  <AiFillSave size={16} style={{ marginRight: 4 }} />
                  Guardar
                </div>
              </Button>
            )}
            <RiCloseLine
              size={30}
              onClick={handleClose}
              className="tw-cursor-pointer"
            />
          </div>
        </div>
        <div className="tw-flex tw-justify-center tw-gap-3 tw-items-center tw-pb-4">
          <SingleUser
            uuid={teams?.[0]?.id}
            name={teams?.[0]?.name}
            ranking={teams?.[0]?.ranking}
            winner={winner === 0}
          />
          <div className="tw-flex tw-w-12 tw-h-12 tw-items-center tw-justify-center tw-text-Turquesa tw-font-bold tw-text-2xl">
            Vs
          </div>
          <SingleUser
            uuid={teams?.[1]?.id}
            name={teams?.[1]?.name}
            ranking={teams?.[1]?.ranking}
            winner={winner === 1}
          />
        </div>
        {sets.map(({ set, puntos, max }, index) => (
          <SingleSet
            index={set}
            key={set}
            puntos={puntos}
            max={max}
            readOnly={readOnly}
            onChange={(puntos) => updateSet(puntos, index, sets)}
          />
        ))}
      </div>
    </Modal>
  );
};

export default EditSets;
const SingleUser = ({ uuid, name, winner, ranking }) => {
  return (
    <label className="tw-flex tw-flex-col tw-items-center tw-text-Turquesa tw-flex-1">
      <span
        title={name}
        className="line-clamp-1 tw-overflow-ellipsis tw-h-6 tw-overflow-hidden tw-whitespace-nowrap tw-w-36"
      >
        {name}
      </span>
      <div
        title={name}
        style={{ backgroundColor: winner && '#00E324', opacity: !uuid && 0.4 }}
        className="tw-rounded-full tw-p-0.5 tw-mt-1 tw-relative tw-text-white"
      >
        <UserImage uuid={uuid} size={400} />
        <div className="tw-text-xs tw-w-7 tw-h-7 tw-bg-slate-500 tw-bg-opacity-75 tw-rounded-full tw-absolute tw-right-0 tw-flex tw-items-center tw-justify-center tw-bottom-px">
          {ranking}
        </div>
      </div>
    </label>
  );
};
const SingleSet = ({ index = 0, puntos = [0, 0], max, onChange, readOnly }) => {
  const changeHandler = (puntos, index) => {
    const alter = [1, 0];
    const newPuntos = [...puntos];
    if (newPuntos[alter[index]] >= max) {
      if (newPuntos[index] >= max) {
        newPuntos[index] = max - 1;
      }
    }
    onChange(newPuntos);
  };
  return (
    <div className="tw-flex tw-justify-center tw-gap-3 tw-w-64 tw-self-center tw-items-center">
      <NumberInput
        labelStyles={{ margin: 0 }}
        containerClass="tw-w-12 tw-shadow-md"
        inputStyles={{ border: 'none', fontSize: 24 }}
        containerStyles={{ padding: 0 }}
        inputClass="tw-bg-gray-50 tw-text-center tw-text-Turquesa tw-font-bold tw-text-2xl"
        containerStylesOff
        value={puntos[0]}
        disabled={readOnly}
        onChange={({ target }) =>
          changeHandler([Number(target.value), puntos[1]], 0)
        }
      />
      <label className="tw-flex-1 tw-text-center tw-text-Turquesa tw-font-bold tw-text-2xl tw-flex tw-items-center tw-justify-between">
        # <AiFillCheckCircle size={27} color="#00E324" /> #
      </label>
      <NumberInput
        labelStyles={{ margin: 0 }}
        containerClass="tw-w-12 tw-shadow-md"
        inputStyles={{ border: 'none', fontSize: 24 }}
        containerStyles={{ padding: 0 }}
        inputClass="tw-bg-gray-50 tw-text-center tw-text-Turquesa tw-font-bold tw-text-2xl"
        containerStylesOff
        value={puntos[1]}
        disabled={readOnly}
        onChange={({ target }) =>
          changeHandler([puntos[0], Number(target.value)], 1)
        }
      />
    </div>
  );
};
