import { useRef, useState, useEffect } from "react";
const getObserver = (resizeObserver, setvisible) => {
  if (resizeObserver.current === null) {
    resizeObserver.current = new IntersectionObserver((t) => {
      setvisible(t[0].isIntersecting);
    });
  }
  return resizeObserver.current;
};
const useObserver = () => {
  const [visible, setVisible] = useState(false);
  const [ref, setRef] = useState(null);

  const resizeObserver = useRef(null);

  useEffect(() => {
    let resizer = resizeObserver.current;
    if (!resizeObserver.current) {
      resizer = getObserver(resizeObserver, setVisible);
    }
    if (ref) resizer?.observe(ref);
    return () => {
      if (resizer) resizer.disconnect();
      resizeObserver.current = null;
    };
  }, [ref, visible]);

  return [setRef, visible];
};

export default useObserver;
