import { useMemo } from 'react';

const InterpretaSet = ({ set, group, index, row }) => {
  const t1 = group[index]?.teamPosition;
  const t2 = group[row]?.teamPosition;
  const setString = useMemo(
    () =>
      set
        ?.map(({ puntos }) => {
          const p1 = t1 > t2 ? puntos[1] : puntos[0];
          const p2 = t1 > t2 ? puntos[0] : puntos[1];
          return p1 + '/' + p2;
        })
        ?.join(', ') || '',
    [set, t1, t2]
  );
  return <>{setString}</>;
};

export default InterpretaSet;
