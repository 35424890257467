import { connect, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { MdEventAvailable, MdMap } from "react-icons/md";
import perfil from "../../assets/logo.png";
import { logoutUser } from "../../actions/auth";

import {
  FaCog,
  FaSearchLocation,
  FaUser,
  FaPowerOff,
  FaBaseballBall,
  FaNetworkWired,
  FaBars,
  FaRegListAlt,
  FaSortAmountUp
} from "react-icons/fa";
import { useEffect } from "react";
import classNames from "classnames";
import { useUser } from "../../context/UserContext";
function mapStateToProps(state) {
  return {
    //menu
    mode: state.menu.mode,
    selected: state.menu.selected,
    isEditingEvent: state.auth.isEditingEvent,
  };
}

const LateralMenu = (props) => {
  const { isPlayer, isClub, isAdmin } = useUser();

  const { selected, mode, noPopUp, handleClick } = props;
  const dispatch = useDispatch();
  const onClickLogout = () => {
    dispatch(logoutUser());
  };
  const menu = [
    { path: "explore",  name: "Buscar eventos", Icon: FaSearchLocation, permisos: true },
    {
      path: "myevents",
      name: "Mis eventos",
      Icon: MdEventAvailable,
      permisos: isPlayer,
    },
    {
      path: "myrents",
      name: "Mis canchas",
      Icon: FaRegListAlt,
      permisos: isPlayer || isClub,
    },
    {
      path: "creartorneo",
      name: "Crear evento",
      Icon: FaNetworkWired,
      permisos: isAdmin || isClub,
    },
    {
      path: "subir-imagen",
      name: "Subir Imagenes",
      Icon: MdEventAvailable,
      permisos: isAdmin,
    },
    { path: "clubs", name: "Clubes", Icon: FaBaseballBall, permisos: true },
    { path: "mapa", name: "Mapa", Icon: MdMap, permisos: true },
    { path: "players", name: "Jugadores", Icon: FaUser, permisos: true },
    { path: "ranking", name: "Ranking", Icon: FaSortAmountUp, permisos: true },
    { path: "editprofile", name: "Configuracion", Icon: FaCog, permisos: true },
  ];

  useEffect(() => {
    if (noPopUp) handleClick("desactivado");
    else handleClick("activado");
  }, [noPopUp, handleClick]);
  return (
    <div>
      <div
        className={"menu-container-" + mode}
        style={noPopUp ? {} : { position: "fixed", zIndex: 100 }}
        onMouseLeave={() => {
          if (!noPopUp) handleClick("activado");
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 66,
              margin: "0 20px",
              position: "relative",
            }}
          >
            <NavLink to="/">
              <img alt="logo" className="menu-logo" src={perfil} />
            </NavLink>
            {!noPopUp && mode === "activado" && (
              <FaBars
                className={"menu-action-" + props.mode}
                onClick={() => handleClick(props.mode)}
                color="#D5DAE2"
                style={{ position: "absolute", right: 0 }}
              />
            )}
          </div>
          <div className="menu-actions">
            {menu.map((item) => {
              return (
                <ItemMenu
                  key={item.path}
                  Icon={item.Icon}
                  path={item.path}
                  name={item.name}
                  selected={selected}
                  redirect={true}
                  permisos={item.permisos}
                  Click={() => {
                    if (!noPopUp) handleClick("activado");
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="action-cerrar" onClick={() => onClickLogout()}>
          <ItemMenu
            Icon={FaPowerOff}
            path=""
            name="Cerrar Sesión"
            selected={selected}
            size="20"
            permisos={true}
            Click={() => {
              if (!noPopUp) handleClick("activado");
            }}
          />
        </div>
      </div>
    </div>
  );
};

const ItemMenu = (props) => {
  const { path, name, Icon, selected, className, size, redirect, permisos, Click } = props;
  let navigate = useNavigate();

  // const selectedBackgroundColor = (accion) => {
  //   if (accion === selected) {
  //     return "#F2F2FE";
  //   }
  // };
  const selectedTextColor = (accion) => {
    if (accion === selected) {
      return "#5C5C5C";
    }
  };
  const selectedTextStyle = (accion) => {
    if (accion === selected) {
      return {
        fontWeight: "bold",
        color: "#5C5C5C",
      };
    }
  };
  const cambiarEvento = (accion) => {
    if (redirect) navigate(`/${accion}`);
  };
  if (!permisos) return <></>;
  // padding-top: 18px;
  return (
    <div
      // className={classname}
      className={classNames(
        "tw-flex tw-flex-row tw-w-full tw-cursor-pointer tw-transition-all tw-bg-white tw-h-12 hover:tw-bg-zinc-100 tw-items-center",
        { "tw-bg-Verde-hover-1 hover:tw-bg-Verde-hover-2": selected === path }
      )}
      onClick={() => {
        cambiarEvento(path);
        Click();
      }}
    >
      {Icon ? (
        <Icon
          color={selectedTextColor(path)}
          className={className || "action-icon"}
          size={size || "18"}
        />
      ) : (
        <FaCog color={selectedTextColor(path)} className="action-icon" size="18" />
      )}
      <p style={selectedTextStyle(path)}>{name}</p>
    </div>
  );
};
export default connect(mapStateToProps)(LateralMenu);
