import { useState } from "react";
import ReactSlider from "react-slider";
import "../../styles/RangeInput.css";
const RangeInput = ({ onChange, label, valueMin = 12, valueMax = 45 }) => {
  const [values, setValues] = useState([valueMin, valueMax]);
  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <label className="custom-input-label">{label}</label>
        <label className="custom-input-label" style={{ width: 54 }}>
          {values[0]}-{values[1]}
        </label>
      </div>
      <div style={{ display: "flex", minHeight:35 }}>
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="example-thumb"
          markClassName="example-mark"
          trackClassName="example-track"
          defaultValue={[12, 45]}
          ariaLabel={["Lower thumb", "Upper thumb"]}
          ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
          renderThumb={(props, state) => <div {...props}></div>}
          pearling
          minDistance={10}
          max={45}
          min={12}
          value={values}
          onChange={(value, index) => setValues(value)}
          onAfterChange={(value, index) => onChange?.(value)}
        />
      </div>
    </div>
  );
};

export default RangeInput;
