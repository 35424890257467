import Select from "../ui/Select"
import Input from "../ui/Input"

import defaultImage from "../../assets/tennis-ball.png"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

//React Icons
import { AiFillPlusCircle } from "react-icons/ai"
import { MdImage } from "react-icons/md";
import { RiImageAddLine } from "react-icons/ri";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import DeleteModal from "./DeleteModal";
import EditModal from "./EditModal";

const UploadImages = ({
    events,
    setSelectType,
    selectType,
    handleChangePicture,
    imagen,
    images,
    onClick,
    winnerOption,
    nombre,
    setNombre,
    categoria,
    setCategoria,
    categorias,
    imgName,
    selectOption,
}) => {
    const [id, setId] = useState("");
    const [mode, setMode] = useState("")
    const [showDelete, setShowDelete] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [option, setOption] = useState('')
    const [data, setData] = useState('')

    useEffect(() => {
        if (selectOption === 'Sponsor') {
            setOption('sponsors')
        }
        if (selectOption === 'Fotos') {
            setOption('galeria')
        }
        if (selectOption === 'Ganadores') {
            setOption('ganadores')
        }
    }, [option, selectOption])

    const modal = () => {
        return (
            <Modal
                isOpen={mode === "eliminar" ? showDelete : showEdit}
                style={Style}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { mode === "eliminar" ? setShowDelete(false) : setShowEdit(false) }}
                ariaHideApp={false} // Este atributo evita problemas del react modal
            >
                <>
                    {mode === "eliminar" ?
                        <DeleteModal setShowDelete={setShowDelete} selectType={selectType} id={id} selectOption={selectOption} mode={option} />
                        :
                        <EditModal setShowEdit={setShowEdit} selectType={selectType} id={id} mode={option} data={data} categorias={categorias} isWinner={winnerOption}   />}
                </>

            </Modal>
        )
    }

    return (
        <div className="container-form-fotos">
            <div className="tw-flex tw-gap-1 tw-flex-col xs:tw-flex-row xs:tw-gap-3">
                <Select
                    label="Selecciona un torneo:"
                    placeholder="Selecciona un torneo"
                    name="torneo"
                    onChange={(value) => setSelectType(value)}
                    options={events}
                    containerClass={winnerOption ? "tw-flex-1 tw-w-80" : "tw-w-80"}
                    containerStylesOff
                    isClearable
                    value={selectType}
                    isHelp
                    help="Selecciona el torneo en donde deseas agregar las fotos"
                />
                {winnerOption && <><Input
                    label="Nombre del ganador:"
                    name="nombre"
                    placeholder="Ingresa el nombre"
                    value={nombre}
                    onChange={(event) => setNombre(event.target.value)}
                    containerClass="tw-flex-1 tw-w-80"
                    containerStylesOff
                    required
                    isHelp
                    help="Ingresa el nombre del jugador"
                />
                    <Select
                        label="Categoria del ganador:"
                        placeholder="Selecciona una categoria"
                        name="torneo"
                        onChange={setCategoria}
                        options={categorias}
                        containerClass="tw-flex-1 tw-w-80"
                        containerStylesOff
                        isClearable
                        value={categoria}
                        isHelp
                        help="Selecciona la categoría del ganador"
                    /></>}
            </div>
            <div className="tw-flex tw-justify-between tw-gap-4 tw-relative tw-flex-col xs:tw-flex-row xs:tw-gap-20">
                <div>
                    <label className="label-upload">Subir Foto</label>
                    <div className="img-size tw-border-dashed tw-border-2 tw-border-gray-400 tw-mt-0">
                        <input
                            type="file"
                            id="file-browser-input"
                            name="file-browser-input"
                            onChange={handleChangePicture}
                        />
                        <p className="tw-text-slate-400 tw-text-center tw-mt-6 tw-mb-2">Jpg, Png, Gif, Svg, Webm. Max 100 mb</p>
                        <AiFillPlusCircle size={60} color="#DCEC00" style={{ margin: "0 auto" }} />
                        <p className="tw-text-slate-400 tw-text-center tw-mt-2">Selecciona <b className="tw-text-Verde-TenisCTA">un archivo</b> de tu dispositivo</p>
                    </div>
                </div>
                <div>
                    <label className="label-upload">Foto Seleccionada</label>
                    <img src={imagen?.file || defaultImage} className="img tw-border-solid tw-border tw-border-gray-400" alt="Imagen de torneo" />
                </div>
            </div>
            <div>
                <label className="custom-input-label" style={{ textAlign: 'center' }} >Subidas</label>
                <Swiper
                    effect={"cards"}
                    grabCursor={true}
                    modules={[EffectCards]}
                    className="mySwiper"
                    style={{ width: 300, height: 270, backgroundColor: 'white' }}

                >
                    {!selectType && <SwiperSlide style={{ width: 300, height: 270 }}>
                        <div className="tw-w-full tw-h-full tw-rounded-xl tw-border-dashed tw-border-gray-400 tw-border-2 tw-flex tw-justify-center tw-items-center tw-bg-white tw-text-slate-400">
                            Selecciona un torneo
                        </div>
                    </SwiperSlide>}
                    {images.map(([key, value]) => {
                        return (
                            <SwiperSlide key={key} style={{ width: 250, height: 270 }} >
                                <div className="tw-relative tw-w-full tw-h-full tw-rounded-xl tw-border-2 tw-flex tw-justify-center tw-items-center tw-bg-white tw-overflow-hidden">
                                    <img src={value.imagen} className="tw-w-full tw-h-full tw-object-cover" alt={imgName} />
                                    <div className="tw-absolute tw-flex tw-flex-col tw-gap-2">
                                        <button onClick={() => { setId(key); setMode('eliminar'); setShowDelete(true) }} className='btn btn-danger tw-font-objet'>Eliminar</button>
                                        <button onClick={() => { setId(key); setMode("editar"); setShowEdit(true); setData(value) }} className='btn btn-info tw-font-objet'>Editar</button>
                                    </div>
                                    {winnerOption &&
                                        <>
                                            <div className="tw-text-base tw-font-objet tw-inline tw-border-solid tw-border tw-bg-white tw-absolute tw-top-0 tw-z-10 tw-rounded-b-lg tw-font-bold tw-pr-2 tw-pl-1">{value.nombre}</div>
                                            <div className="tw-text-base tw-font-objet tw-inline tw-border-solid tw-border tw-bg-white tw-absolute tw-mt-2 tw-bottom-0 tw-z-10 tw-rounded-t-lg tw-font-bold tw-pr-2 tw-pl-1">Categoría: {value.categoria}</div>
                                        </>
                                    }
                                </div>
                            </SwiperSlide>
                        )
                    })}
                    {images.length === 0 && selectType && <SwiperSlide style={{ width: 300, height: 270 }}>
                        <div className="tw-w-full tw-h-full tw-rounded-xl tw-border-dashed tw-border-gray-400 tw-border-2 tw-flex tw-flex-col tw-gap-6 tw-justify-center tw-items-center tw-bg-white tw-text-slate-400">
                            <MdImage size={80} />
                            No hay fotos subidas
                        </div></SwiperSlide>}
                </Swiper>
            </div>
            <div style={{ width: "100%" }}>
                <div className="tw-flex tw-justify-center tw-items-center tw-flex-row tw-gap-5 tw-mt-8 xs:tw-mt-4">
                    <input
                        type="file"
                        id="add-img"
                        className="input-upload"
                        required
                        accept="image/png, .jpeg, .jpg"
                        onChange={handleChangePicture}
                    />
                    <label htmlFor="add-img" className="input-img">
                        <RiImageAddLine style={{ margin: 5 }} size="20" />
                        Selecciona una Imagen
                    </label>
                    <input
                        type="submit"
                        id="upload-img"
                        className="input-upload"
                        required
                        accept="image/png, .jpeg, .jpg"
                        onClick={onClick}
                    />
                    <label
                        htmlFor="upload-img"
                        className="input-img"
                        style={{ backgroundColor: "#E5E5E5" }}
                    >
                        <FaCloudDownloadAlt style={{ margin: 5 }} size="20" />
                        Subir Imagen
                    </label>
                </div>
            </div>
            {modal()}
        </div>
    )
}

const Style = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "none",
    },
    overlay: {
        backgroundColor: "rgba(0,0,0,0.75)",
    },
};

export default UploadImages