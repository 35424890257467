import {
  SportIconContainer,
  SportContainer,
  SportcloseContainer,
} from "./styles/SportSelectModalStyle";
import { FaArrowLeft } from "react-icons/fa";
import CourtIcon from "../ui/CourtIcon";

const SportSelectModal = ({ onSelect, canClose, handleClose, value }) => {
  const handleSelect = (deporte) => {
    onSelect?.(deporte);
  };
  return (
    <SportContainer>
      {canClose && (
        <SportcloseContainer onClick={() => handleClose()}>
          <FaArrowLeft style={{ cursor: "pointer" }} color="white" size={25} />
        </SportcloseContainer>
      )}
      <h1 style={{ fontSize: 20 }}>Selecciona el deporte</h1>
      <div style={{ display: "flex", gap: 90 }}>
        <SportIconContainer
          style={{ border: value === "tenis" ? "2px dashed black" : "none" }}
          onClick={() => handleSelect("tenis")}
        >
          <CourtIcon type="tenis" size={50} />
        </SportIconContainer>
        <SportIconContainer
          style={{ border: value === "padel" ? "2px dashed black" : "none" }}
          onClick={() => handleSelect("padel")}
        >
          <CourtIcon type="padel" size={50} />
        </SportIconContainer>
      </div>
    </SportContainer>
  );
};

export default SportSelectModal;
