import classNames from "classnames";
import moment from "moment";
import { useMemo } from "react";
import { CircleDay } from "./SingleCalendar";
import { ConteinerDay } from "./SingleCalendar";

const MonthName = {
  January: "Enero",
  February: "Febrero",
  March: "Marzo",
  April: "Abril",
  May: "Mayo",
  June: "Junio",
  July: "Julio",
  August: "Agosto",
  September: "Septiembre",
  October: "Octubre",
  November: "Noviembre",
  December: "Diciembr",
};

const HeaderWeek = ({ days, selectedDay, showNumberDay, SetselectedDay, staticWeek }) => {
  return (
    <div className="CalendarHours tw-w-full tw-py-3 tw-overflow-hidden">
      <div className=" tw-flex tw-items-center tw-h-full tw-justify-center">
        <CircleDay onlyRead={true} />
        <p style={{ fontWeight: "bold", margin: 0 }}></p>
      </div>
      {days?.map((w, index) => {
        const isSelected = selectedDay?.name === w.name;
        return (
          <div
            key={"dayWeek" + index}
            className={classNames(
              "tw-flex tw-items-center tw-h-full tw-justify-center tw-transition-transform",
              {
                "tw-scale-125 tw-transform tw-translate-y-0": isSelected,
                "tw-translate-y-2": !isSelected,
              }
            )}
          >
            <CircleDay
              day={w}
              SetselectedDay={(day) => {
                if (!isSelected) SetselectedDay(day);
              }}
              isSelected={isSelected}
              showNumberDay={!staticWeek && showNumberDay}
              onlyRead={false}
            />
          </div>
        );
      })}
    </div>
  );
};

export const HeaderWeeks = ({ days, selectedDay, showNumberDay, SetselectedDay, staticWeek }) => {
  const nameMount = useMemo(
    () => MonthName[moment(selectedDay?.date).format("MMMM")],
    [selectedDay?.date]
  );
  return (
    <div className="New-CalendarHours tw-w-full tw-pt-3 tw-mb-3 tw-overflow-hidden tw-bg-gray-100 md:tw-bg-transparent tw-rounded-r-md">
      {selectedDay?.date && (
        <div className="tw-col-span-full tw-text-Turquesa tw-font-bold tw-text-center tw-text-xl md:tw-hidden">
          {nameMount}
        </div>
      )}
      {days?.map((w, index) => {
        const isSelected = selectedDay?.name === w.name;
        return (
          <div
            key={"dayWeek" + index}
            className={classNames(
              "tw-flex tw-flex-col tw-items-center tw-h-full tw-justify-center tw-transition-transform"
            )}
          >
            <ConteinerDay
              key={"container" + index}
              day={w}
              SetselectedDay={(day) => {
                if (!isSelected) SetselectedDay(day);
              }}
              isSelected={isSelected}
              showNumberDay={!staticWeek && showNumberDay}
              onlyRead={false}
            />
          </div>
        );
      })}
    </div>
  );
};

export default HeaderWeek;
