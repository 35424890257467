import { makeStyles } from "@material-ui/core/styles";
export const reportSyles= makeStyles({
    content: {
       position:"relative",
        width:"35vw",
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor:"rgb(250,250,250)",
        borderRadius:".2vw",
        padding:"0vw",
       
      },
    container:{
        color:"rgb(40,40,40)",
        backgroundColor:"rgb(50,50,50)",

    },
    cont_tittle:{
        marginBottom:"1.5vw",
        padding:"1vw 1vw .5vw 1vw",
        backgroundColor:"rgb(250,80,80)",
        textAlign:"center",
        color:"rgb(255,255,255)"
    },
    h6:{
        color:"rgb(255,255,255)",
        
   fontSize:"1vw"
    },
  
    item:{
        marginBottom:"2vw",
        padding:"0vw 4vw",
        display:"flex",
        textAlign:"left",
        fontSize:".9vw",
        color:"rgba(100, 100, 100, 1)"
    },
    container_button:{
        display:"flex",
        justifyContent:"center"
    },
    button:{
       width:"35%",
       padding:".9vw 2vw",
       background:" #DCEC00",
       border:0,
       borderRadius:".5vw",
       marginTop:"1vw",
       marginBottom:"3vw",
       outline:"none",
       color:"#2085B7",
       fontFamily: "Roboto",
       fontStyle: "normal",
       fontWeight:" bold",
       fontSize:"1.2vw",
       lineHeight:" 16px",
       letterSpacing: "0.01em",
    }
  });