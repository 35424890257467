const Loading = ({ label, hideLabel }) => {
  return (
    <div className="wrap">
      <div className="loading">
        <div className="bounceball"></div>
        {!hideLabel && <div className="text">{label || 'TennisUp'}</div>}
      </div>
    </div>
  );
};

export default Loading;
