// import perfil from "../../assets/default/250x250/user_1.jpg";
import perfil from "../../assets/perfil.png";
import masup from "../../assets/ranking/upmas.png";
import IsAdmin from "../IsAdmin";
import { FaTimesCircle, FaPen, FaFlag } from "react-icons/fa";

function SinglePlayer({
  index,
  player,
  setSelectedUser,
  setShowEditUser,
  setShowDeleteUser,
  isAdmin,
  isMobil,
  container,
}) {
  const { settings = {}, uid } = player;
  const {
    nombre,
    imagen_100x100,
    imagen_200x200,
    imagen_400x400,
    imagen_800x800,
    imagen,
    manoDominante,
    categoria,
    sexo,
    nacionalidad,
  } = settings || {};
  const finalImage = imagen_100x100 || imagen_200x200 || imagen_400x400 || imagen_800x800 || imagen;

  const handleEditModal = (jugador, uid) => {
    setSelectedUser({ jugador: jugador, uid: uid });
    setShowEditUser(true);
  };
  const handleDeleteModal = (jugador, uid) => {
    setSelectedUser({ jugador: jugador, uid: uid });
    setShowDeleteUser(true);
  };
  
  const mobileContent = () => {
    return (
      <>
        <div>
          <div style={{ fontSize: 20 }}>{nombre}</div>
        </div>
        <div
          style={{
            display: "grid",
            alignItems: "center",
            gap: 10,
            gridTemplateColumns: "105px auto 55px",
          }}
        >
          <div>
            <div style={{ position: "relative", width: "fit-content" }}>
              <img
                src={finalImage || perfil}
                style={{ height: 100, width: 100 }}
                className="user-img"
                alt="Imagen de perfil"
              />
              <div
                className="jugadores-tabla-item-column"
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#0000008f",
                  borderRadius: 13,
                  width: 25,
                  height: 25,
                  justifyContent: "center",
                  color: "white",
                }}
              >
                <div>{index}</div>
              </div>
            </div>
            <div></div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <FaFlag /> <strong>{nacionalidad}</strong> {sexo}{" "}
            </div>
            <div>
              <strong>Categoría: </strong> {categoria}
            </div>
            {manoDominante && (
              <div>
                <strong>Mano Dominante: </strong> {manoDominante}
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 15, alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div
                className="jugadores-tabla-item-column"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img style={{ height: 20 }} alt="jugadores" src={masup} />
                <label style={{ margin: 0, fontSize: 14 }}> +23 </label>
              </div>
              <div className="jugadores-tabla-item-column">34503</div>
            </div>
            <IsAdmin>
              <div className="jugadores-tabla-item-column">
                <div>
                  <FaPen
                    className="jugadores-editar"
                    onClick={() => handleEditModal(player, uid)}
                  />
                  <FaTimesCircle
                    className="jugadores-eliminar"
                    onClick={() => handleDeleteModal(player, uid)}
                    style={{ margin: 0 }}
                  />
                </div>
              </div>
            </IsAdmin>
          </div>
        </div>
      </>
    );
  };
  const largeContent = () => {
    return (
      <>
        <div className="jugadores-tabla-item-column">{index}</div>
        <div className="jugadores-tabla-item-column">
          <img src={finalImage || perfil} className="user-img" alt="Imagen de perfil" />
        </div>
        <div className="jugadores-tabla-item-column">{nombre}</div>
        <div className="jugadores-tabla-item-column">{sexo}</div>
        <div className="jugadores-tabla-item-column">{manoDominante || "derecha"}</div>
        <div className="jugadores-tabla-item-column">{nacionalidad}</div>
        <div className="jugadores-tabla-item-column">
          <img className="jugadores-tabla-historico" alt="jugadores" src={masup} />
          <h6> +23 </h6>
        </div>
        <div className="jugadores-tabla-item-column">34503</div>
        <div className="jugadores-tabla-item-column">{categoria}</div>
        {/* <select className="jugadores-tabla-item-column jugadores-filtro-usuarios">
                <option>Tenista</option>
                <option>Entrenador</option>
              </select> */}
        <IsAdmin>
          <div className="jugadores-tabla-item-column">
            <div>
              <FaTimesCircle
                className="jugadores-eliminar"
                onClick={() => handleDeleteModal(player, uid)}
              />

              <FaPen className="jugadores-editar" onClick={() => handleEditModal(player, uid)} />
            </div>
          </div>
        </IsAdmin>
      </>
    );
  };
  if (isMobil)
    return (
      <div
        style={{
          padding: "20px 20px",
          margin: 0,
        }}
        className="LargeCard LargeCard-items"
      >
        {mobileContent()}
      </div>
    );
  return (
    <>
      {container ? (
        <div className={"jugadores-tabla-item" + (isAdmin ? " jugadores-tabla-admin" : "")}>
          {largeContent()}
        </div>
      ) : (
        <>{largeContent()}</>
      )}
    </>
  );
}

export default SinglePlayer;
