import { useState } from "react";

import toast from "react-hot-toast";
import classNames from "classnames";
import { colors } from "../../costants/colors";
import { FaArrowCircleLeft } from "react-icons/fa";
import styled from "styled-components";
import NumberInput from "../ui/NumberInput";

//Metodo API 
import { addScore } from "../../services/ranking/ranking.service";

const ModalEdit = ({
    selectedUser,
    filterType,
    setShowEditRank,
    mobile

}) => {
    const [puntaje, setPuntaje] = useState("");

    const handleEditModal = async () => {
        let uid = selectedUser.id
        // let tipoRanking = selectedUser.tipoRanking
        let data = [
            {
                uid: uid,
                points: puntaje
            }
        ]
        try {
            const done = await addScore({
                users: data,
                tipoRanking: filterType === selectedUser.tipoRankingTenisUp ? selectedUser.tipoRankingTenisUp : selectedUser.tipoRankingAsociacion
            });
            if (done) toast.success("Se ha editado correctamente");
            else toast.error("Se ha producido un error");
            setShowEditRank(false)
        } catch (error) {
            setShowEditRank(true)
            console.error(error);
        }
    }

    return (
        <div>
            <div onClick={() => setShowEditRank(false)} className={classNames("button-back-event", { "tw-absolute tw-top-2.5 tw-left-0 tw-block": mobile })}>
                <FaArrowCircleLeft color={colors.Turquesa} className="button-back-style" />
            </div>

            <div>
                <div>
                    <NumberInput
                        label="Ingresa el puntaje: "
                        placeholder="Solo utiliza números"
                        value={puntaje}
                        onChange={(event) => setPuntaje(event.target.value)}
                        name="categoriaOpen"
                    />
                </div>
                <div className="tw-text-center tw-relative tw-flex-auto tw-p-4">
                    <Button1 className="modal-button"
                        onClick={() => handleEditModal(selectedUser)}

                    >
                        <span>Guardar</span>
                    </Button1>
                </div>
            </div>
        </div>
    )
}

export default ModalEdit;

const Button1 = styled.button`
  position: relative;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background-color: #dcec00;
  color: #2085b7;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  max-width: 200px;
  font-size: 14px;
  cursor: pointer;
`;