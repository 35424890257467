import { ref, onValue} from "firebase/database";
import { db } from "../firebase/firebase";

// Obtencion ranking
export const GET_RANKING_REQUEST = "GET_RANKING_REQUEST";
export const GET_RANKING_SUCCESS = "GET_RANKING_SUCCESS";
export const GET_RANKING_FAILURE = "GET_RANKING_FAILURE";

//RANKING

const requestGetRankings = () => {
    return {
      type: GET_RANKING_REQUEST,
    };
  };
  
  const recieveGetRankings = (ranking) => {
    return {
      type: GET_RANKING_SUCCESS,
      ranking,
    };
  };
  
  const errorGetRankings = () => {
    return {
      type: GET_RANKING_FAILURE,
    };
  };

  export const getAllNewRanking = () => (dispatch) => {
    //Se establece el estado como 'obteniendo todos los eventos'
    dispatch(requestGetRankings());
  
    let ranking = [];
  
    //obtener todos los eventos mediante firebase
    onValue(
      ref(db, "new_ranking/"),
      (snapshot) => {
        ranking = Object.values(snapshot.val());
        dispatch(recieveGetRankings(ranking));
      },
      () => {
        dispatch(errorGetRankings());
      },
      { onlyOnce: true }
    );
  };