
export const GET_MENU_ACTIVE = "GET_MENU_REQUEST";
export const GET_MENU_PASIVE = "GET_MENU_PASIVE";
export const MENU_SELECTED = "MENU_SELECTED";


const menuActive = () => {
    return {
        type: GET_MENU_ACTIVE
    };
};

const menuPasive = () => {
    return {
        type: GET_MENU_PASIVE
    };
};

const menuSelected = (event) => {
    return {
        type: MENU_SELECTED,
        selected: event 
    };
};



export const changeMenuState = (state) => dispatch => {
    //Se establece el estado como 'Creando evento'

    if (state === 'activado') {
        dispatch(menuPasive());

    } else {
        dispatch(menuActive());
    }
};


export const changeMenuSelected = (event) => dispatch => {
    //Se establece el estado como 'Creando evento'
    try{
        dispatch(menuSelected(event));
    }
    catch{
        console.log('Error al cambiar el estado');
    }

};
