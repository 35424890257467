import { MdKeyboardArrowRight } from "react-icons/md";
import { checkImg } from "../services/Utils";
import { SingleWeek } from "./calendar/SingleCalendar";
import CourtIcon from "./ui/CourtIcon";
import clubPhoto from "../assets/tennis-ball.png";
import { useUser } from "../context/UserContext";

function SingleCourt({ court, handleClick, mobile, singleClubFetched, botonera }) {
  const { image, type, description, name, price, week } = court;
  const { isPlayer } = useUser();
  return (
    <div
      className="map-events-cards"
      onClick={() => handleClick?.(court)}
      style={{ cursor: handleClick ? "pointer" : "auto", padding: 0 }}
    >
      <div className="court-card perfilClub-court-card">
        <div className="court-info" style={{ margin: 5, position: "relative" }}>
          <img className="tw-max-h-56" alt="court" src={checkImg(image) || clubPhoto}></img>
          <div className="tw-absolute tw-right-2 tw-top-2 tw-text-2xl tw-bg-black tw-rounded-full tw-p-3 tw-flex tw-bg-opacity-40">
            <CourtIcon type={type} size={20} />
          </div>
        </div>
        <div className="court-info" style={{ padding: "6px 0 6px 52px" }}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 style={{ color: "#5b5b5b", margin: 0 }}>{name}</h3>
              {botonera && botonera}
            </div>
            <div className="court-info-column">
              <p style={{ margin: 0, marginLeft: 8, color: "#999999" }}>{description}</p>
            </div>
          </div>

          {singleClubFetched && isPlayer ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto max-content min-content",
                alignItems: "end",
              }}
            >
              <div
                style={{
                  margin: 0,
                  placeSelf: "auto",
                  fontSize: "1rem",

                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontFamily: '"Airbnb Cereal App", Arial',
                }}
              >{`$ ${price} / hrs`}</div>
              {mobile ? (
                ""
              ) : (
                <>
                  <h4 className="tw-text-2xl tw-font-medium">Arrendar cancha</h4>
                  <MdKeyboardArrowRight className="arrow-rent-court" />{" "}
                </>
              )}
            </div>
          ) : (
            <h5 style={{ margin: 0, color: "#002688", fontSize: 14 }}>{`$ ${price} / hrs`}</h5>
          )}
          <div style={{ width: "100%", maxWidth: 300 }}>
            <SingleWeek week={week} onlyRead={true} small />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleCourt;
