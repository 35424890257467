import { useMemo } from "react";
import { colors } from "../../../costants/colors";
import SingleService from "./SingleService";
// import { FaTrash, FaEdit } from "react-icons/fa";
// import { IoIosTrash } from "react-icons/io";
const verde = colors.Verde.hover;
function ListService({
  service: ServicesData,
  handleOpenDeleteModal,
  setSelectedService,
  setAddService,
}) {
  const services = useMemo(() => ServicesData || [], [ServicesData]);
  if (services.length === 0) return <div>No tienes agregado ningún servicio</div>;
  const selectedService = (service, index) => {
    setSelectedService({
      service: { ...(service || {}), week: JSON.parse(JSON.stringify(service.week || [])) },
      index,
    });
    setAddService(true);
  };
  const Botonera = ({ service, index }) => (
    <div className="fa-courts">
      <div style={{ marginRight: 8 }} onClick={() => handleOpenDeleteModal(service, index)}>
        <DeleteIcon />
      </div>
      <div onClick={() => selectedService(service, index)}>
        <EditIcon />
      </div>
    </div>
  );
  return (
    <>
      {services?.map((service, index) => (
        <SingleService
          key={"SingleService-" + index}
          service={service}
          handleClick={() => selectedService(service, index)}
          botonera={<Botonera service={service} index={index} />}
        />
      ))}
    </>
  );
}

function EditIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="19" viewBox="0 0 17 17" fill="none">
      <path
        fill={verde}
        d="M16.8.2a.6.6 0 00-.9 0l-.5.5a.3.3 0 000 .4l.5.5a.3.3 0 00.4 0l.5-.5c.2-.3.3-.6 0-.9zm-2.3 1.4L7.4 8.7v.2l-.4 1a.2.2 0 00.2.1l1-.3.1-.1 7-7a.4.4 0 000-.6l-.3-.4a.4.4 0 00-.5 0z"
      ></path>
      <path
        fill={verde}
        d="M14 5.7l-4.8 4.8-.7.4-1 .3a1.4 1.4 0 01-1.7-1.7l.3-1c0-.3.2-.5.4-.7L11.3 3a.3.3 0 00-.2-.5H2.9A2.2 2.2 0 00.7 4.7v9.4A2.2 2.2 0 003 16.3h9.4a2.2 2.2 0 002.2-2.2V5.9a.3.3 0 00-.5-.2z"
      ></path>
    </svg>
  );
}

function DeleteIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 14 16" fill="none">
      <path
        fill={verde}
        d="M13.4 2.3H10v-.9c0-.4-.1-.7-.4-1-.3-.2-.7-.4-1-.4h-3c-.3 0-.7.2-1 .4-.3.3-.4.6-.4 1v.9H.6c-.2 0-.3 0-.4.2a.6.6 0 000 .8l.4.1h.6l.7 11c0 .9.8 1.6 1.7 1.6h6.8c1 0 1.6-.7 1.7-1.7l.7-10.9h.6l.4-.1a.6.6 0 000-.8.6.6 0 00-.4-.2zM4.7 13.7l-.4-.1a.6.6 0 01-.2-.4l-.3-8 .1-.4a.6.6 0 01.9 0L5 5l.3 8a.6.6 0 01-.2.4.6.6 0 01-.4.2zm2.9-.6c0 .2 0 .3-.2.4a.6.6 0 01-.8 0 .6.6 0 01-.2-.4v-8c0-.1 0-.3.2-.4a.6.6 0 01.8 0l.2.4v8zM8.8 2.3H5.2v-.9a.3.3 0 01.1-.2.3.3 0 01.2 0h3a.3.3 0 01.2 0 .3.3 0 010 .2v.9zm1.1 10.9c0 .1 0 .3-.2.4l-.4.1a.6.6 0 01-.4-.2.6.6 0 01-.2-.4l.3-8a.6.6 0 01.2-.4.6.6 0 01.7 0 .6.6 0 01.3.2v.3l-.3 8z"
      ></path>
    </svg>
  );
}
export default ListService;
