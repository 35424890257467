import { get, ref } from 'firebase/database';
import { db } from '../../firebase/firebase';

const sizes = [
  'imagen',
  'imagen_100x100',
  'imagen_200x200',
  'imagen_400x400',
  'imagen_800x800',
];
export const getUserImages = async (uuid) => {
  if (!uuid) return { error: true };
  const images = await Promise.all(
    sizes.map(
      async (size) => await get(ref(db, `users/${uuid}/settings/${size}`))
    )
  );
  const newImages = {};
  images
    .filter((snap) => snap.exists())
    .forEach((snap, index) => (newImages[sizes[index]] = snap.val()));
  return newImages;
};
