import { useState } from 'react'
import sponsor from "../assets/sponsor/sponsor-tenis.jpg"
import "../styles/Sponsor.css"
import Input from "../components/ui/Input";

//Número de teléfono
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

//Alerta 
import swal from '@sweetalert/with-react'

//Imagenes
import comu from '../assets/sponsor/comunidad.png'
import manos from '../assets/sponsor/apreton-de-manos.png'
import mundo from '../assets/sponsor/en-todo-el-mundo.png'

//Método Api
import { saveForm } from '../services/sponsor/sponsor.service'

const SponsorForm = () => {

    const [correo, setCorreo] = useState("");
    const [nombre, setNombre] = useState("");
    const [rubro, setRubro] = useState("");
    const [telefono, setTelefono] = useState("");
    const [direccion, setDireccion] = useState("");
    const [descripcion, setDescripcion] = useState("")
    const [aportar, setAportar] = useState("");

    //Estado de error
    const [errorCorreo, setErrorCorreo] = useState("")
    const [errorNombre, setErrorNombre] = useState("");
    const [errorRubro, setErrorRubro] = useState("");
    const [errorTelefono, setErrorTelefono] = useState("");
    const [errorDireccion, setErrorDireccion] = useState("");
    const [errorDescripcion, setErrorDescripcion] = useState("")
    const [errorAportar, setErrorAportar] = useState("")

    //Validación por si los campos están vacíos
    const formHasEmpty = () => {
        if (
            correo === "" ||
            nombre === "" ||
            rubro === "" ||
            telefono === "" ||
            direccion === "" ||
            descripcion === "" ||
            aportar === ""
        ) {
            return false;
        } else {
            if (nombre.length > 5 && rubro.length > 2 && direccion.length > 2 && descripcion.length > 49 && aportar.length > 30 && emailReg.exec(correo)) {
                return true
                // if (isValidPhoneNumber(telefono) || isPossiblePhoneNumber(telefono)) {
                //     return true;
                // } else {
                //     return false
                // }
            } else {
                return false
            }
        }
    }

    //Función ejecutada al darle al botón de enviar
    const sendForm = (e) => {
        e.preventDefault();
        let data = {
            correo: correo,
            empresa: nombre,
            rubro: rubro,
            telefono: telefono,
            direccion: direccion,
            descripcion: descripcion,
            aportar: aportar
        }
        saveForm(data)
        // const { dispatch } = props;
        // dispatch(sendFormSponsor(data))
        successForm()
    };

    //Expresiones regulares 
    var emailReg = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
    // var phoneReg = /^(\+?56)?(\s?)(0?9)(\s?)[9876543]\d{7}$/;
    //Validar campos 
    function handleChangeForm(e) {
        const { name, value } = e.target;
        switch (name) {
            case "email":
                setCorreo(value);
                emailReg.exec(value) === false || emailReg.exec(value) === null
                    ? setErrorCorreo("Ingresa un correo válido")
                    : setErrorCorreo("")
                break;
            case "nombre":
                setNombre(value);
                value.length < 6
                    ? setErrorNombre("El nombre de la empresa es muy corto")
                    : setErrorNombre("")
                break;
            case "rubro":
                setRubro(value);
                value.length < 3
                    ? setErrorRubro("Ingrese un rubro válido")
                    : setErrorRubro("")
                break;
            case "telefono":
                setTelefono(value);
                isPossiblePhoneNumber(value) === false || isValidPhoneNumber(value) === false
                    ? setErrorTelefono("Ingrese un número de teléfono válido")
                    : setErrorTelefono("")
                break;
            case "direccion":
                setDireccion(value);
                value.length < 3
                    ? setErrorDireccion("Debes ingresar una dirección válida")
                    : setErrorDireccion("")
                break;
            case "descripcion":
                setDescripcion(value);
                value.length < 50
                    ? setErrorDescripcion("La descripción debe contar con un máximo de 50 caracteres")
                    : setErrorDescripcion("")
                break;
            case "aportar":
                setAportar(value);
                aportar.length < 30
                    ? setErrorAportar("Ingrese un resumen maximo de 30 caracteres")
                    : setErrorAportar("")
                break;
            default:
                break;
        }
    }

    const successForm = () => {
        swal({
            title: "Se ha envíado el formulario correctamente",
            text: "Enhorabuena, el equipo tenis-up contactará contigo",
            icon: "success",
            button: {
                text: "Entendido",
                value: "done"
            }
        })
            .then((value) => {
                switch (value) {
                    case "done":
                        window.location = "/";
                        break
                    default: break;
                }
            })
    }

    return (
        <div>
            <div className="tw-w-full tw-h-1/2 tw-sticky">
                <div className="carousel-info">
                    <img src={sponsor} alt="1" className="sponsor-img" />
                    <div className="carousel-img-overlay">
                        <h1>
                            SPONSOR
                        </h1>

                    </div>
                </div>
            </div>
            <div className='sponsor-info'>
                <div className='sponsor-screenshots-container'>
                    <img alt="login" className='login-screenshots' src={comu} />
                </div>
                <div className='login-text-container'>
                    <h1>Se parte de Tenis Up</h1>
                    <p>Queremos que nuestros patrocinadores se sientan parte del equipo y la comunidad tenistica de todo chile.</p>
                </div>
            </div>
            <div className='help-content-land-sponsor'>
                <div className='help-text-container-sponsor'>
                    <h1>Ayuda</h1>
                    <p>Con tu colaboracion permitiras que más torneos se puedan hacer en el pais.</p>
                </div>
                <div className='help-screenshots-container-sponsor'>
                    <img alt="configuracion" className='conf-screenshots' src={manos} />
                </div>
            </div>
            <div className='sponsor-info'>
                <div className='sponsor-screenshots-container'>
                    <img alt="login" className='login-screenshots' src={mundo} />
                </div>
                <div className='login-text-container'>
                    <h1>Visibilidad</h1>
                    <p>Logra mejorar tu visibilidad corporativa patrocinando nuestros torneos y eventos! </p>
                </div>
            </div>
            <div className="register-form-sponsor">
                <form className="register-form-content-sponsor" onSubmit={(event) => sendForm(event)}>
                    <div className="Register-sponsor-title">
                        <h3>Colaboremos juntos</h3>
                    </div>

                    <div className="register-user-inputs flipInY scroll">
                            <Input label="Correo Electrónico"
                            name="email"
                            style={{ width: "100%" }}
                            value={correo}
                            onChange={handleChangeForm}
                            error={errorCorreo}
                            type='email'/>
                        <div className="register-club-input" style={{marginBottom:20}}>
                            <label className="custom-input-label">Teléfono</label>
                            <PhoneInput
                                enableAreaCodes={true}
                                placeholder="Ingresa tu número de teléfono"
                                enableSearch
                                localization={'es'}
                                regions={"america"}
                                country={'cl'}
                                value={telefono}
                                inputProps={{
                                    name: 'telefono',
                                    required: true,
                                    onChange: handleChangeForm
                                }}
                                inputStyle={{
                                    width: "100%",
                                    height: 41,
                                    fontSize: "calc(12px + 4 * ((100vw - 300px) / (1600 - 300)))",
                                    border: "1.5px solid",
                                    // borderColor:colors.Verde.hover
                                  }}
                                onChange={setTelefono}
                                enableLongNumbers={true}
                            />

                            {errorTelefono !== "" && (
                                <span className="perfil-club-errorMessage">{errorTelefono}</span>
                            )}
                        </div>
                        <Input
                            label="Nombre de la empresa"
                            name="nombre"
                            style={{ width: "100%" }}
                            value={nombre}
                            onChange={handleChangeForm}
                            error={errorNombre}
                            />
                        <Input
                            label="Rubro de la empresa"
                            name="rubro"
                            style={{ width: "100%" }}
                            value={rubro}
                            onChange={handleChangeForm}
                            error={errorRubro}
                            />
                        <Input
                            label="Dirección"
                            name="direccion"
                            style={{ width: "100%" }}
                            value={direccion}
                            onChange={handleChangeForm}
                            error={errorDireccion}
                            />
                        <Input
                            label="Descripción de la empresa"
                            name="descripcion"
                            style={{ width: "100%" }}
                            value={descripcion}
                            onChange={handleChangeForm}
                            error={errorDescripcion}
                            placeholder="Ingrese una descripción breve de su empresa"
                            />
                        <div className="add-court-input">
                            <label className="court-event-label">
                                ¿Que aportaría a tenis up?
                            </label>
                            <textarea
                                style={{ height: "90px" }}
                            className={errorAportar ? "court-club-error" : "form-court-event-intext-area"}
                                placeholder="Ingrese un pequeño resumen"
                                type="text"
                                name="aportar"
                                value={aportar}
                                onChange={handleChangeForm}
                            />
                            {errorAportar !== "" && (
                                <span className="perfil-club-errorMessage">{errorAportar}</span>
                            )}
                        </div>
                    </div>
                    <div className="register-btn">
                        <button
                            type="submit"
                            className="registro-boton"
                            style={!formHasEmpty() ? { opacity: 0.5, transform: "none", backgroundColor: '#DCEC00'  } : { opacity: 1, backgroundColor: '#DCEC00' }}
                            disabled={!formHasEmpty()}
                        >
                            Postular!
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SponsorForm;