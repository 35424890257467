import {
  //sadmin
  IS_USER,
  IS_ADMIN,
  IS_CLUB,
  IS_SUPER_ADMIN,
} from '../actions/privilegios';

const privilegiosState = (
  state = {
    //Privilegios CRUD Eventos
    canCreateEvento: false,
    canReadEvento: false,
    canUpdateEvento: false,
    canDeleteEvento: false,

    //Privilegios CRUD Clubs
    canCreateClub: false,
    canReadClub: false,
    canUpdateClub: false,
    canDeleteClub: false,

  },
  action
) => {
  switch (action.type) {

    case IS_USER:
      return {
        ...state,
        canReadEvento: true,
        canReadClub: true,
      };

    case IS_CLUB:
      return {
        ...state,
        //Eventos
        canCreateEvento: true,
        canReadEvento: true,
        canUpdateEvento: true,
        canDeleteEvento: true,
      };

    case IS_ADMIN:
      return {
        ...state,
        //Eventos
        canCreateEvento: true,
        canReadEvento: true,
        canUpdateEvento: true,
        canDeleteEvento: true,

        //Clubs
        canCreateClub: true,
        canReadClub: true,
        canUpdateClub: true,
        canDeleteClub: true,
      };

    case IS_SUPER_ADMIN:
      return {
        ...state,
        
      };
    default:
      return state;
  }

};

export default privilegiosState;