import classNames from "classnames";
import { useMemo } from "react";

const TabsHeader = ({ menuOptions, value, onChange, tabsStyle= {} }) => {
  const optionsmenu = useMemo(() => (Array.isArray(menuOptions) ? menuOptions : []), [menuOptions]);
  return (
    <div className="club-profile-menu">
      {optionsmenu.map((data) => (
        <div
          key={data}
          onClick={() => onChange?.(data)}
          className={classNames({ "menu-item-active": value === data })}
          style={{ ...tabsStyle, }}
        >
          {data}
        </div>
      ))}
    </div>
  );
};

export default TabsHeader;
