import InputFinder from "./InputFinder";
import { FaSearch } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import { getAllClubs, getAllCourts } from "../../actions/clubs";
import { getAllEvents } from "../../actions/events";
import Check from "../ui/Check";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";

function mapStateToProps(state) {
    return {
        events: state.events.events,
        clubs: state.club.clubs,
        courts: state.club.courts
    }
}

const CHILE = require("../../services/countries/chile_regions.json")

const Finder = ({
    isSticky,
    isGeneral,
    isEvents,
    isClubs,
    isCourts,
    selectType,
    searchSelect,
    setSearchSelect,
    ubicacion,
    setUbicacion,
    fecha,
    setFecha,
    tipo,
    setTipo,
    isMobile,
    isWeb,
    label,
    ...props
}) => {

    const dispatch = useDispatch();

    const [showTipo, setShowTipo] = useState(false);

    const [events, setEvents] = useState([]);
    const [clubs, setClubs] = useState([]);
    const [courts, setCourts] = useState([]);
    const [regiones, setRegiones] = useState([]);

    useEffect(() => {
        dispatch(getAllClubs());
        dispatch(getAllCourts());
        dispatch(getAllEvents());
    }, [dispatch])


    useEffect(() => {
        let dataEvent = props.events?.map((event) => ({ name: event.nombre, id: event.id, ciudad: event.ubicacion, region: event.region, actionType: "Eventos", tipo: event.categoria?.deporte }));
        let dataClub = props.clubs?.filter((club) => club?.settings).map((club) => ({ name: club.settings.name, id: club.uid, ciudad: club.settings.ciudad, region: club.settings.region, actionType: "Clubes" }))
        let dataCourt = props.courts?.map((court) => ({ name: `${court.name}, ${court.name_club}`, id: court.key, ciudad: court.ciudad, region: court.region, actionType: "Canchas", tipo: court.type }))
        let regions = CHILE.map((region) => ({ name: region.region }))
        setEvents(dataEvent || []);
        setClubs(dataClub || []);
        setCourts(dataCourt || []);
        setRegiones(regions)
    }, [props.events, props.clubs, props.courts]);

    const returnResult = useMemo(() => {
        let items = []
        const typesAction = {
            Eventos: events,
            Clubes: clubs,
            Canchas: courts
        }
        items = typesAction[selectType] || [...events, ...clubs, ...courts];
        if (ubicacion) {
            return items.filter((item) => item.region === ubicacion.name)
        }
        return items
    }, [events, clubs, courts, selectType, ubicacion])

    const scrollTo = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    useEffect(() => {
        const equalData = returnResult.find((e) => e.id === searchSelect?.id)
        if (equalData) {
            setTipo(equalData?.tipo)
        }
        if (searchSelect?.name === "") {
            setTipo("")
        }
    }, [returnResult, searchSelect, setTipo])

    return (
        <>
            {isSticky && <div className="tw-w-full tw-flex tw-items-center tw-justify-center tw-mt-4 xs:tw-mt-6">
                <div className="tw-rounded-full tw-bg-white tw-p-1 tw-flex tw-justify-between tw-mr-0 xs:tw-mr-40 tw-cursor-pointer" onClick={() => scrollTo()}>
                    <div className="tw-flex tw-flex-row tw-p-1 tw-gap-4 xs:tw-gap-48">
                        <label className="tw-text-base tw-font-medium tw-ml-2 tw-mt-1 tw-cursor-pointer">{label}</label>
                        <div className="tw-rounded-full tw-bg-Verde-TenisCTA tw-flex tw-flex-row tw-items-center tw-justify-center tw-p-3">
                            <FaSearch size={14} />
                        </div>
                    </div>
                </div>
            </div>
            }
            {isGeneral &&
                <>
                    <div className="tw-flex tw-flex-row tw-p-2 tw-text-sm tw-ml-2 tw-w-full" style={isMobile && { border: '1px solid rgb(235, 235, 235)', borderRadius: 100 }}>
                        {isMobile && isWeb && <div className="tw-flex tw-flex-col tw-pl-2 tw-border-r tw-border-slate-300 tw-pr-2">
                            <InputFinder
                                isInput={false}
                                isSearcheable
                                label="Ubicación"
                                placeholder="¿Donde queda?"
                                data={regiones}
                                value={ubicacion}
                                setValue={setUbicacion}
                                inputStyle={{ width: 120 }}
                            />
                        </div>}
                        <div className="tw-flex tw-flex-col tw-w-full tw-pl-2">
                            <InputFinder
                                isInput={false}
                                isSearcheable
                                label="Que deseas buscar?"
                                placeholder="Escribe lo que quieres buscar..."
                                data={returnResult}
                                value={searchSelect}
                                setValue={setSearchSelect}
                                inputStyle={{ width: 250 }}
                            />
                        </div>
                        <Link
                            style={isMobile ? { position: 'absolute', bottom: 0, right: 0, left: 0, height: 50, textDecoration: "none" } : { textDecoration: "none" }}
                            to={`/mapa?id=${searchSelect.id}&type=${"Todo"}&location=${ubicacion.name}`}
                            className="tw-rounded-full tw-bg-Verde-TenisCTA tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-1 tw-pr-4 tw-pl-4 tw-cursor-pointer"
                        >
                            <FaSearch size={20} />
                            <label className="tw-font-bold tw-pt-1">Buscar</label>
                        </Link>
                    </div>

                </>
            }
            {isEvents &&
                <>
                    <div className="tw-flex tw-flex-row tw-p-2 tw-text-sm tw-ml-2 tw-w-full" style={isMobile && { border: '1px solid rgb(235, 235, 235)', borderRadius: 100 }}>
                        {(isMobile || isWeb) && <div className="tw-flex tw-flex-col tw-pl-1 xs:tw-pl-2 tw-border-r-0 xs:tw-border-r tw-border-slate-300">
                            <InputFinder
                                isInput={false}
                                isSearcheable
                                label="Ubicación"
                                placeholder="¿Donde queda?"
                                data={regiones}
                                value={ubicacion}
                                setValue={setUbicacion}
                                onChange={(e) => setUbicacion(e.target.value)}
                                inputStyle={{ width: 150 }}
                            />
                        </div>}
                        {isWeb && <div className="tw-flex tw-flex-col tw-w-full tw-border-r tw-border-slate-300 tw-pl-1">
                            <InputFinder
                                isInput={false}
                                isSearcheable
                                label="Nombre del evento"
                                placeholder="Escribe nombre evento"
                                data={returnResult}
                                value={searchSelect}
                                setValue={setSearchSelect}
                                inputStyle={{ width: 220 }}
                            />
                        </div>}
                        {(isMobile || isWeb) && <div className="tw-flex tw-flex-col tw-border-r-0 xs:tw-border-r tw-border-slate-300 tw-pl-0 xs:tw-pl-1">
                            <InputFinder
                                isInput
                                value={fecha}
                                setValue={setFecha}
                                onChange={(e) => setFecha(e.target.value)}
                                type="date"
                                label="Fecha evento"
                                placeholder="Agrega una fecha"
                            />
                        </div>}
                        {isWeb && <div className="tw-flex tw-flex-col tw-pl-2">
                            <div className="tw-flex tw-flex-col tw-w-full tw-relative">
                                <div className="tw-flex tw-flex-col tw-w-full">
                                    <div className="tw-flex tw-flex-row tw-gap-1">
                                        <label className="tw-font-medium">Tipo de Evento</label>
                                        {/* <AiOutlineDown size={20} onClick={() => setShowTipo(false)} /> */}
                                    </div>
                                    <div className="tw-flex tw-flex-row tw-w-32" >
                                        <input value={tipo} style={{ width: 100 }} type="text" placeholder="Tenis o Padel" onClick={() => setShowTipo(true)} />
                                        {tipo !== "" ? <AiOutlineClose onClick={() => setTipo("")} size={18} className="tw-cursor-pointer" /> : ''}
                                    </div>
                                </div>
                                {showTipo && <div className="tw-absolute tw-z-10 tw-bg-white tw-overflow-hidden tw-overflow-y-auto tw-rounded-xl tw-mt-16 tw-w-auto tw-h-auto tw-p-3">
                                    <Check
                                        label="Cualquiera"
                                        value="cualquiera"
                                        id="cualquiera"
                                        name="all"
                                        type="radio"
                                        square
                                        checked={tipo === "cualquiera"}
                                        onChange={() => { setTipo("cualquiera"); setShowTipo(false) }}
                                        size="medium"
                                    />
                                    <Check
                                        label="Tenis"
                                        value="tenis"
                                        id="tenis"
                                        name="tenis"
                                        type="radio"
                                        square
                                        checked={tipo === "tenis"}
                                        onChange={() => { setTipo("tenis"); setShowTipo(false) }}
                                        size="medium"
                                    />
                                    <Check
                                        label="Padel"
                                        value="padel"
                                        id="padel"
                                        name="padel"
                                        type="radio"
                                        square
                                        checked={tipo === "padel"}
                                        onChange={() => { setTipo("padel"); setShowTipo(false) }}
                                        size="medium"
                                    />
                                </div>}
                            </div>
                        </div>}
                        <Link
                            style={isMobile ? { position: 'absolute', bottom: 0, right: 0, left: 0, height: 50, textDecoration: "none" } : { textDecoration: "none" }}
                            to={`/mapa?id=${searchSelect.id}&type=${searchSelect.actionType || "Eventos"}&location=${ubicacion.name}&date=${fecha}&sport=${tipo}`}
                            className="tw-rounded-full tw-bg-Verde-TenisCTA tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-1 tw-pr-4 tw-pl-4 tw-cursor-pointer"
                        >
                            <FaSearch size={20} />
                            <label className="tw-font-bold tw-pt-1">Buscar</label>
                        </Link>
                    </div>

                </>
            }
            {isClubs &&
                <>
                    <div className="tw-flex tw-flex-row tw-p-2 tw-text-sm tw-ml-2 tw-w-full" style={isMobile && { border: '1px solid rgb(235, 235, 235)', borderRadius: 100 }}>
                        {(isMobile || isWeb) && <div className="tw-flex tw-flex-col tw-pl-2 tw-border-r-0 xs:tw-border-r tw-border-slate-300 tw-w-full">
                            <InputFinder
                                isInput={false}
                                isSearcheable
                                label="Ubicación"
                                placeholder={isMobile ? "Selecciona la ubicación del Club" : "Donde queda?"}
                                data={regiones}
                                value={ubicacion}
                                setValue={setUbicacion}
                                inputStyle={isMobile ? { width: "100%" } : { width: 150 }}
                            />
                        </div>}
                        {isWeb && <div className="tw-flex tw-flex-col tw-w-full tw-pl-2">
                            <InputFinder
                                isInput={false}
                                isSearcheable
                                label="Nombre del Club"
                                placeholder="Escribe nombre del club"
                                data={returnResult}
                                value={searchSelect}
                                setValue={setSearchSelect}
                                inputStyle={{ width: 220 }}
                            />
                        </div>}
                        <Link
                            style={isMobile ? { position: 'absolute', bottom: 0, right: 0, left: 0, height: 50, textDecoration: "none" } : { textDecoration: "none" }}
                            to={`/mapa?id=${searchSelect.id}&type=${searchSelect.actionType || "Clubes"}&location=${ubicacion.name}`}
                            className="tw-rounded-full tw-bg-Verde-TenisCTA tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-1 tw-pr-4 tw-pl-4 tw-cursor-pointer"
                        >
                            <FaSearch size={20} />
                            <label className="tw-font-bold tw-pt-1">Buscar</label>
                        </Link>
                    </div>
                </>
            }
            {isCourts &&
                <>
                    <div className="tw-flex tw-flex-row tw-p-2 tw-text-sm tw-ml-2 tw-w-full" style={isMobile && { border: '1px solid rgb(235, 235, 235)', borderRadius: 100 }}>
                        {(isMobile || isWeb) && <div className="tw-flex tw-flex-col tw-pl-2 tw-border-r tw-border-slate-300">
                            <InputFinder
                                isInput={false}
                                isSearcheable
                                label="Ubicación"
                                placeholder="¿Donde queda?"
                                data={regiones}
                                value={ubicacion}
                                setValue={setUbicacion}
                                inputStyle={{ width: 150 }}
                            />
                        </div>}
                        {isWeb && <div className="tw-flex tw-flex-col tw-w-full tw-pl-2 tw-border-r tw-border-slate-300">
                            <InputFinder
                                isInput={false}
                                isSearcheable
                                label="Nombre de la cancha"
                                placeholder="Escribe nombre de la cancha"
                                data={returnResult}
                                value={searchSelect}
                                setValue={setSearchSelect}
                                inputStyle={{ width: 220 }}
                            />
                        </div>}
                        {(isMobile || isWeb) && <div className="tw-flex tw-flex-col tw-pl-2">
                            <div className="tw-flex tw-flex-col tw-w-full tw-relative">
                                <div className="tw-flex tw-flex-col tw-w-full">
                                    <div className="tw-flex tw-flex-row tw-gap-1">
                                        <label className="tw-font-medium">Tipo de Cancha</label>
                                        {/* <AiOutlineDown size={20} onClick={() => setShowTipo(false)} /> */}
                                    </div>
                                    <div className="tw-flex tw-flex-row tw-w-32" >
                                        <button value={tipo} className="tw-w-20" onClick={() => setShowTipo(true)}>{tipo !== "" ? tipo : "Tenis o Padel"}</button>
                                        {tipo !== "" ? <AiOutlineClose onClick={() => setTipo("")} size={18} className="tw-cursor-pointer" /> : ''}
                                    </div>
                                </div>
                                {showTipo && <div className={isMobile ? "tw-absolute tw-w-full tw-top-16 tw-right-40 tw-mt-20 tw-flex tw-flex-col tw-gap-10" : "tw-absolute tw-z-10 tw-bg-white tw-overflow-hidden tw-overflow-y-auto tw-rounded-xl tw-mt-16 tw-w-auto tw-h-auto tw-p-3"}>
                                    <Check
                                        label="Cualquiera"
                                        value="cualquiera"
                                        id="cualquiera"
                                        name="all"
                                        type="radio"
                                        square
                                        checked={tipo === "cualquiera"}
                                        onChange={() => { setTipo("cualquiera"); setShowTipo(false) }}
                                        size={!isMobile && "medium"}
                                    />
                                    <Check
                                        label="Tenis"
                                        value="tenis"
                                        id="tenis"
                                        name="tenis"
                                        type="radio"
                                        square
                                        checked={tipo === "tenis"}
                                        onChange={() => { setTipo("tenis"); setShowTipo(false) }}
                                        size={!isMobile && "medium"}
                                    />
                                    <Check
                                        label="Padel"
                                        value="padel"
                                        id="padel"
                                        name="padel"
                                        type="radio"
                                        square
                                        checked={tipo === "padel"}
                                        onChange={() => { setTipo("padel"); setShowTipo(false) }}
                                        size={!isMobile && "medium"}
                                    />
                                </div>}
                            </div>
                        </div>}
                        <Link
                            style={isMobile ? { position: 'absolute', bottom: 0, right: 0, left: 0, height: 50, textDecoration: "none" } : { textDecoration: "none" }}
                            to={`/mapa?id=${searchSelect.id}&type=${searchSelect.actionType || "Canchas"}&location=${ubicacion.name}&sport=${tipo}`}
                            className="tw-rounded-full tw-bg-Verde-TenisCTA tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-1 tw-pr-4 tw-pl-4 tw-cursor-pointer"
                        >
                            <FaSearch size={20} />
                            <label className="tw-font-bold tw-pt-1">Buscar</label>
                        </Link>
                    </div>
                </>
            }
        </>
    )
}

export default connect(mapStateToProps)(Finder);



