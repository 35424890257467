import { useState } from "react"

import { AiOutlineClose } from "react-icons/ai";

const InputFinder = ({
    label,
    placeholder = "",
    isInput,
    isSearcheable,
    isSelect,
    data,
    inputStyle = {},
    suggestStyle = {},
    value,
    setValue,
    onChange,
    type = "text"
}) => {

    const [dataFilter, setDataFilter] = useState([])
    const [showSelect, setShowSelect] = useState(false)
    const [showClear, setShowClear] = useState(false)

    const handleSearch = (e) => {
        setShowClear(true)
        if (isSearcheable) {
            let inputChange = e.target.value
            setValue(inputChange)
            let newFilter = data.filter((value) => {
                return value.name.toLowerCase().includes(inputChange.toLowerCase());
            })
            if (inputChange === "") {
                setDataFilter([])
            } else {
                setDataFilter(newFilter)
            }
        }
        if (isSelect) {
            let inputChange = e.target.value
            setValue(inputChange)
        }
    }

    const handleSuggestion = (value) => {
        setValue(value)
        setDataFilter([])
    }

    const clearInput = () => {
        setDataFilter([])
        setValue({ name: "" })
    }

    const renderInputs = () => {
        if (isInput) {
            return (
                <>
                    <label className="tw-font-medium">{label}</label>
                    <div className="tw-flex tw-flex-row">
                        <input value={value} placeholder={placeholder} onChange={onChange} type={type} />
                        {value !== "" ? <AiOutlineClose onClick={() => setValue("")} size={18} className="tw-cursor-pointer" /> : ''}
                    </div>
                </>
            )
        }
        if (isSearcheable) {
            return (
                <div className="tw-flex tw-flex-col tw-w-full tw-relative">
                    <div className="tw-flex tw-flex-col tw-w-full">
                        <label className="tw-font-medium">{label}</label>
                        <div className="tw-flex tw-flex-row" >
                            <input value={value.name} style={{ width: 500, ...inputStyle }} type={type} placeholder={placeholder} onChange={handleSearch} />
                            {showClear && <AiOutlineClose onClick={() => { clearInput(); setShowClear(false) }} size={18} className="tw-cursor-pointer" />}
                        </div>
                    </div>
                    {dataFilter.length !== 0 && <div className="tw-absolute tw-z-10 tw-bg-white tw-overflow-hidden tw-overflow-y-auto tw-rounded-xl tw-w tw-mt-16 tw-w-auto tw-h-auto tw-pr-2 tw-pl-2" style={{ ...suggestStyle }}>
                        {dataFilter.slice(0, 10).map((value, key) => {
                            return <div onClick={() => handleSuggestion(value)} className="tw-flex tw-flex-row tw-items-center tw-w-full tw-h-12 tw-text-black tw-mt-2 hover:tw-bg-Verde-hover-1">
                                <label>{value.name}</label>
                            </div>
                        })}
                    </div>}
                </div>
            )
        }
        if (isSelect) {
            return (
                <div className="tw-flex tw-flex-col tw-w-full tw-relative">
                    <div className="tw-flex tw-flex-col tw-w-full">
                        <label className="tw-font-medium">{label}</label>
                        <div className="tw-flex tw-flex-row tw-w-36" >
                            <input value={value} style={{ width: 120, ...inputStyle }} type="text" placeholder={placeholder} onClick={() => setShowSelect(true)} />
                            {value !== "" ? <AiOutlineClose onClick={() => setValue("")} size={18} className="tw-cursor-pointer" /> : ''}
                        </div>
                    </div>
                    {showSelect && <div className="tw-absolute tw-z-10 tw-bg-white tw-overflow-hidden tw-overflow-y-auto tw-rounded-xl tw-mt-16 tw-w-auto tw-h-auto tw-p-3" style={{ ...suggestStyle }}>
                        {data.map((value) => {
                            return <div onClick={() => { handleSuggestion(value.name); setShowSelect(false) }} className="tw-flex tw-flex-row tw-items-center tw-w-full tw-h-12 tw-text-black tw-mt-2 hover:tw-bg-Verde-hover-1">
                                <label>{value.name}</label>
                            </div>
                        })}
                    </div>}
                </div>
            )
        }
    }
    return (
        <>
            {renderInputs()}
        </>
    )
}

export default InputFinder;