import { createUserWithEmailAndPassword } from "firebase/auth";
import { child, off, onValue, push, ref, remove, set, update, get } from "firebase/database";
import moment from "moment";
import { db, auth } from "../firebase/firebase";
// import { getMyTransactions } from "../reducers/clubsReducer";
import { getWeek } from "../services/courts/court.service";
import { uploadImage } from "../services/images/images.service";
import { getSingleClub as getSingleAuthClub } from "./auth";
import { changeLoginOpen } from "./header";

//Regsitro de Club
export const REGISTER_CLUB_REQUEST = "REGISTER_CLUB_REQUEST";
export const REGISTER_CLUB_SUCCESS = "REGISTER_CLUB_SUCCESS";
export const REGISTER_CLUB_FAILURE = "REGISTER_CLUB_FAILURE";

//Creación de Club
export const CREATE_CLUB_REQUEST = "CREATE_CLUB_REQUEST";
export const CREATE_CLUB_SUCCESS = "CREATE_CLUB_SUCCESS";
export const CREATE_CLUB_FAILURE = "CREATE_CLUB_FAILURE";

//Creación de Cancha
export const CREATE_COURT_REQUEST = "CREATE_COURT_REQUEST";
export const CREATE_COURT_SUCCESS = "CREATE_COURT_SUCCESS";
export const CREATE_COURT_FAILURE = "CREATE_COURT_FAILURE";

//Creación de Servicio
export const CREATE_SERVICE_REQUEST = "CREATE_SERVICE_REQUEST";
export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS";
export const CREATE_SERVICE_FAILURE = "CREATE_SERVICE_FAILURE";

//Obtenición de todos los Clubes
export const GET_CLUBS_REQUEST = "GET_MY_CLUBS_REQUEST";
export const GET_CLUBS_SUCCESS = "GET_MY_CLUBS_SUCCESS";
export const GET_CLUBS_FAILURE = "GET_MY_CLUBS_FAILURE";

//Obtenición de un Club
export const GET_SINGLE_CLUB_REQUEST = "GET_SINGLE_CLUB_REQUEST";
export const GET_SINGLE_CLUB_SUCCESS = "GET_SINGLE_CLUB_SUCCESS";
export const GET_SINGLE_CLUB_FAILURE = "GET_SINGLE_CLUB_FAILURE";

//Eliminar un Club
export const DELETE_CLUB_REQUEST = "DELETE_CLUB_REQUEST";
export const DELETE_CLUB_SUCCESS = "DELETE_CLUB_SUCCESS";
export const DELETE_CLUB_FAILURE = "DELETE_CLUB_FAILURE";

//Editar un Club
export const UPDATE_CLUB_DEFAULT = "UPDATE_CLUB_DEFAULT";
export const UPDATE_CLUB_REQUEST = "UPDATE_CLUB_REQUEST";
export const UPDATE_CLUB_SUCCESS = "UPDATE_CLUB_SUCCESS";
export const UPDATE_CLUB_FAILURE = "UPDATE_CLUB_FAILURE";

//Editar una cancha
export const UPDATE_COURT_DEFAULT = "UPDATE_COURT_DEFAULT";
export const UPDATE_COURT_REQUEST = "UPDATE_COURT_REQUEST";
export const UPDATE_COURT_SUCCESS = "UPDATE_COURT_SUCCESS";
export const UPDATE_COURT_FAILURE = "UPDATE_COURT_FAILURE";

//Eliminar una cancha
export const DELETE_COURT_REQUEST = "DELETE_COURT_REQUEST";
export const DELETE_COURT_SUCCESS = "DELETE_COURT_SUCCESS";
export const DELETE_COURT_FAILURE = "DELETE_COURT_FAILURE";

//Editar un servicio
export const UPDATE_SERVICE_DEFAULT = "UPDATE_SERVICE_DEFAULT";
export const UPDATE_SERVICE_REQUEST = "UPDATE_SERVICE_REQUEST";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_FAILURE = "UPDATE_SERVICE_FAILURE";

//Eliminar un service
export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAILURE = "DELETE_SERVICE_FAILURE";

//Limpieza de estado

export const CLEAR_COURT = "CLEAR_COURT";
export const CLEAR_SERVICE = "CLEAR_SERVICE";
export const CLEAR_SINGLE_CLUB = "CLEAR_SINGLE_CLUB";

//Obtención canchas
export const GET_COURTS_REQUEST = "GET_COURTS_REQUEST";
export const GET_COURTS_SUCCESS = "GET_COURTS_SUCCESS";
export const GET_COURTS_FAILURE = "GET_COURTS_FAILURE";

//Obtención 1 cancha
export const GET_SINGLE_COURT_REQUEST = "GET_SINGLE_COURT_REQUEST";
export const GET_SINGLE_COURT_SUCCESS = "GET_SINGLE_COURT_SUCCESS";
export const GET_SINGLE_COURT_FAILURE = "GET_SINGLE_COURT_FAILURE";
export const GET_SINGLE_COURT_DEFAULT = "GET_SINGLE_COURT_DEFAULT";

export const UPDATE_SINGLE_COURT_WEEK = "UPDATE_SINGLE_COURT_WEEK";
//Test
export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST";

//Creación de Clubes
const requestRegisterClub = () => {
  return {
    type: REGISTER_CLUB_REQUEST,
  };
};

const recieveRegisterClub = () => {
  return {
    type: REGISTER_CLUB_SUCCESS,
  };
};

const errorRegisterClub = (error) => {
  return {
    type: REGISTER_CLUB_FAILURE,
    error,
  };
};

//Creación de Clubes
const requestCreateClub = () => {
  return {
    type: CREATE_CLUB_REQUEST,
  };
};

const recieveCreateClub = () => {
  return {
    type: CREATE_CLUB_SUCCESS,
  };
};

const errorCreateClub = () => {
  return {
    type: CREATE_CLUB_FAILURE,
  };
};

//Busqueda de un Club
const requestGetSingleClub = () => {
  return {
    type: GET_SINGLE_CLUB_REQUEST,
  };
};

const receiveGetSingleClub = (club) => {
  return {
    type: GET_SINGLE_CLUB_SUCCESS,
    club,
  };
};

const errorGetSingleClub = () => {
  return {
    type: GET_SINGLE_CLUB_FAILURE,
  };
};

//Busqueda de una cancha
const requestGetSingleCourt = () => {
  return {
    type: GET_SINGLE_COURT_REQUEST,
  };
};

const updateSingleCourtWeek = (court) => {
  return {
    type: UPDATE_SINGLE_COURT_WEEK,
    court,
  };
};
const receiveGetSingleCourt = (court) => {
  return {
    type: GET_SINGLE_COURT_SUCCESS,
    court,
  };
};

const errorGetSingleCourt = () => {
  return {
    type: GET_SINGLE_COURT_FAILURE,
  };
};

export const defaultSingleCourt = () => {
  return {
    type: GET_SINGLE_COURT_DEFAULT,
  };
};

//Busqueda de todos los clubes
const requestGetClubes = () => {
  return {
    type: GET_CLUBS_REQUEST,
  };
};

const receiveGetClubes = (clubs) => {
  return {
    type: GET_CLUBS_SUCCESS,
    clubs,
  };
};

const errorGetClubes = () => {
  return {
    type: GET_CLUBS_FAILURE,
  };
};

//Busqueda de todas las canchas
const requestGetCourts = () => {
  return {
    type: GET_COURTS_REQUEST,
  };
};

const receiveGetCourts = (courts) => {
  return {
    type: GET_COURTS_SUCCESS,
    courts,
  };
};

const errorGetCourts = () => {
  return {
    type: GET_COURTS_FAILURE,
  };
};

//Edición de Clubes
export const defaultUpdateClub = () => {
  return {
    type: UPDATE_CLUB_DEFAULT,
  };
};

export const requestUpdateClub = () => {
  return {
    type: UPDATE_CLUB_REQUEST,
  };
};

export const receiveUpdateClub = () => {
  return {
    type: UPDATE_CLUB_SUCCESS,
  };
};

export const errorUpdateClub = () => {
  return {
    type: UPDATE_CLUB_FAILURE,
  };
};

//Creación de cancha
const requestCreateCourt = () => {
  return {
    type: CREATE_COURT_REQUEST,
  };
};

const recieveCreateCourt = () => {
  return {
    type: CREATE_COURT_SUCCESS,
  };
};

const errorCreateCourt = () => {
  return {
    type: CREATE_COURT_FAILURE,
  };
};

//Edicion de cancha
export const defaultUpdateCourt = () => {
  return {
    type: UPDATE_COURT_DEFAULT,
  };
};
const requestUpdateCourt = () => {
  return {
    type: UPDATE_COURT_REQUEST,
  };
};

const receiveUpdateCourt = () => {
  return {
    type: UPDATE_COURT_SUCCESS,
  };
};

const errorUpdateCourt = () => {
  return {
    type: UPDATE_COURT_FAILURE,
  };
};

//Creación de servicios

const requestCreateService = () => {
  return {
    type: CREATE_SERVICE_REQUEST,
  };
};

const recieveCreateService = () => {
  return {
    type: CREATE_SERVICE_SUCCESS,
  };
};

const errorCreateService = () => {
  return {
    type: CREATE_SERVICE_FAILURE,
  };
};

//Edicion de servicios
export const defaultUpdateService = () => {
  return {
    type: UPDATE_SERVICE_DEFAULT,
  };
};

const requestUpdateService = () => {
  return {
    type: UPDATE_SERVICE_REQUEST,
  };
};

const receiveUpdateService = () => {
  return {
    type: UPDATE_SERVICE_SUCCESS,
  };
};

const errorUpdateService = () => {
  return {
    type: UPDATE_SERVICE_FAILURE,
  };
};

//Eliminación de Clubes
const requestDeleteClub = () => {
  return {
    type: DELETE_CLUB_REQUEST,
  };
};

const receiveDeleteClub = () => {
  return {
    type: DELETE_CLUB_SUCCESS,
  };
};

const errorDeleteClub = () => {
  return {
    type: DELETE_CLUB_FAILURE,
  };
};

//Eliminacion de canchas
const requestDeleteCourt = () => {
  return {
    type: DELETE_COURT_REQUEST,
  };
};

const receiveDeleteCourt = () => {
  return {
    type: DELETE_COURT_SUCCESS,
  };
};

const errorDeleteCourt = () => {
  return {
    type: DELETE_COURT_FAILURE,
  };
};

//Eliminacion de servicios
const requestDeleteService = () => {
  return {
    type: DELETE_SERVICE_REQUEST,
  };
};

const receiveDeleteService = () => {
  return {
    type: DELETE_SERVICE_SUCCESS,
  };
};

const errorDeleteService = () => {
  return {
    type: DELETE_SERVICE_FAILURE,
  };
};

//Limpieza de estados

export const defaultCourt = () => {
  return {
    type: CLEAR_COURT,
  };
};

export const defaultService = () => {
  return {
    type: CLEAR_SERVICE,
  };
};

export const defaultProfileClub = () => {
  return {
    type: CLEAR_SINGLE_CLUB,
  };
};

export const registerClub = (club) => async (dispatch) => {
  dispatch(requestRegisterClub());
  if (club.email && club.password) {
    const { email, password } = club;
    try {
      await createUserWithEmailAndPassword(auth, email, password)
        // .then((currentUser) => sendEmailVerification(auth.currentUser))
        .then(({user}) => {
          const refDb = ref(db, "clubes/" + user.uid);
          update(refDb, { uid: user.uid, tipo_usuario: "club" }).then(() => {
            dispatch(getSingleAuthClub(user.uid));
            dispatch(recieveRegisterClub());
            dispatch(changeLoginOpen(false));
          });
        })
        .catch((error) => {
          dispatch(errorRegisterClub(error));
          console.log("error: ", error);
        });
    } catch (error) {
      dispatch(errorRegisterClub(error));
      console.log(error);
    }
  }else{
    console.log("tejo")
  }
};

export const createClub = (data, logo) => async (dispatch) => {
  //Se establece el estado como 'Creando evento'
  dispatch(requestCreateClub());

  let databaseRef = ref(db, "clubes/");
  const key_club = push(databaseRef).key;

  if (data.uid === "") {
    data.uid = key_club;
  }

  try {
    if (logo) {
      delete data.logo;
      uploadImage(`clubes/${key_club}.jpg`, logo, `clubes/${key_club}/logo`);
    }
    databaseRef = ref(db, "clubes/" + key_club);

    update(databaseRef, data).then(() => {
      dispatch(recieveCreateClub());
    });
  } catch (error) {
    console.log(error);
    dispatch(errorCreateClub());
  }
};
export const addCourt = async (databaseRef, court, clubUid, settingsClub) => {
  const isUpdate = court.key;
  const key_court = court?.key || push(databaseRef).key;

  if (court?.image.blob) {
    const blob = court.image.blob;
    delete court.image;
    uploadImage(`courts/${key_court}.jpg`, blob).then(async (path) => {
      await set(child(databaseRef, `${key_court}/image`), path);
    });
  } else {
    if (typeof court.image !== "string") court.image = null;
  }
  court.key = key_court;
  court.club_uid = clubUid;
  let snapshot;
  if (!settingsClub) snapshot = await get(ref(db, `clubes/${clubUid}/settings`));
  const club = settingsClub || snapshot.val();
  if (club) {
    court.lat = club.lat;
    court.lng = club.lng;
    court.name_club = club.name;
    court.ciudad = club.ciudad;
    court.region = club.region;
  }
  try {
    if (isUpdate) {
      await update(child(databaseRef, isUpdate), court);
    } else {
      await set(child(databaseRef, key_court), court);
    }
    return { error: false, data: null };
  } catch (error) {
    console.log(error);
    return { error: error, data: null };
  }
};
export const addService = async (databaseRef, service, clubUid) => {
  const isUpdate = service?.key;
  const key_service = service?.key || push(databaseRef).key;
  if (service?.image.blob) {
    const blob = service.image.blob;
    delete service.image;
    uploadImage(`services/${key_service}.jpg`, blob).then(async (path) => {
      await set(child(databaseRef, `${key_service}/image`), path);
    });
  } else {
    if (typeof service.image !== "string") service.image = null;
  }
  service.key = key_service;
  service.club_uid = clubUid;

  try {
    if (isUpdate) {
      await update(child(databaseRef, isUpdate), service);
    } else {
      await set(child(databaseRef, key_service), service);
    }
    return { error: false, data: null };
  } catch (error) {
    console.log(error);
    return { error: error, data: null };
  }
};

export const setClub = (data) => async (dispatch) => {
  //Se establece el estado como 'Creando evento'
  dispatch(requestUpdateClub());
  let uid = auth.currentUser.uid;
  let { settings } = data;
  try {
    if (settings?.logo.blob) {
      const blob = settings.logo.blob;
      delete settings.logo;
      uploadImage(`clubes/${uid}.jpg`, blob, `clubes/${uid}/logo`);
    } else {
      data.settings.logo = null;
    }

    if (data?.courts) {
      let databaseRef = ref(db, `clubes/${uid}/courts`);
      await Promise.all(
        data.courts.map(async (court) => {
          await addCourt(databaseRef, court, uid);
        })
      );
      delete data.courts;
    }

    if (data?.services) {
      let databaseRef = ref(db, `clubes/${uid}/services`);
      await Promise.all(
        data.services.map(async (service) => {
          await addService(databaseRef, service, uid);
        })
      );
      delete data.services;
    }
    update(ref(db, "clubes/" + uid), data).then(() => {
      dispatch(receiveUpdateClub());
    });
  } catch (error) {
    console.log(error);
    dispatch(errorUpdateClub());
  }
};
let lastClub = null;

export const getSingleClub = (uid) => (dispatch) => {
  dispatch(defaultSingleCourt());
  dispatch(requestGetSingleClub());
  try {
    if (lastClub) off(lastClub);
    lastClub = ref(db, `clubes/${uid}`);
    onValue(lastClub, async (snapshot) => {
      // var club =snapshot.val()
      // const CourtID = Object.keys(club.courts)
      // for (const key in CourtID) {
      //   console.log(CourtID[key])
      //   club.courts[CourtID[key]].transaction = await getMyTransactions(CourtID[key])
      // }
      // dispatch(receiveGetSingleClub(club));
      if (snapshot.val()) dispatch(receiveGetSingleClub(snapshot.val()));
      else dispatch(errorGetSingleClub());
    });
  } catch {
    dispatch(errorGetSingleClub());
  }
};
export const UpdateSingleCourtWeek = (data) => (dispatch) => {
  dispatch(updateSingleCourtWeek(data));
};
let lastCourt = null;

export const getSingleCourt = (clubUid, courtKey, callback) => (dispatch) => {
  dispatch(requestGetSingleCourt());
  let firstTime = true;
  try {
    if (lastCourt) off(lastCourt);
    lastCourt = ref(db, `clubes/${clubUid}/courts/${courtKey}`);
    onValue(lastCourt, async (snapshot) => {
      var sCourt = snapshot.val();
      const court = await getWeek(clubUid, courtKey);
      if (!court) {
        dispatch(errorGetSingleCourt());
        return;
      }
      sCourt.week = court?.[0].week;
      if (typeof sCourt.week === "object")
        Object.values(sCourt.week).forEach((w) => (w.date = moment(w.date).toDate()));
      dispatch(receiveGetSingleCourt(sCourt));
      if (firstTime) {
        callback?.();
      }
      firstTime = false;
    });
  } catch {
    dispatch(errorGetSingleCourt());
  }
};

export const getAllClubs = () => (dispatch) => {
  //Se establece el estado como 'obteniendo todos los eventos'
  dispatch(requestGetClubes());
  let clubs = [];

  //obtener todos los eventos mediante firebase
  onValue(
    ref(db, "clubes/"),
    (snapshot) => {
      clubs = Object.values(snapshot.val());
      dispatch(receiveGetClubes(clubs));
    },
    () => {
      dispatch(errorGetClubes());
    },
    { onlyOnce: true }
  );
};

export const getAllCourts = () => async (dispatch) => {
  dispatch(requestGetCourts());
  try {
    const clubes = ref(db, "clubes/");
    onValue(
      clubes,
      (snapshot) => {
        const courts = [];
        Object.entries(snapshot?.val() || {}).forEach(([club_uid, club]) => {
          Object.entries(club.courts || {}).forEach(([key, court]) => {
            courts.push({ ...court, club_uid, key });
          });
        });
        dispatch(receiveGetCourts(courts));
      },
      { onlyOnce: true }
    );
  } catch (error) {
    dispatch(errorGetCourts());
  }
};

export const deleteClub = (clubUid) => (dispatch) => {
  //Se establece el estado como 'evento eliminado'
  dispatch(requestDeleteClub());

  //Eliminar evento mediante firebase
  remove(ref(db, `clubes/${clubUid}`))
    .then(() => {
      dispatch(receiveDeleteClub()); //si hubo exito se establece el estado como exitoso
    })
    .catch(() => {
      dispatch(errorDeleteClub()); //si hubo exito se establece el estado como falla
    });
};

export const updateClub = (data, logo) => async (dispatch) => {
  dispatch(requestUpdateClub());
  try {
    if (logo.blob) {
      const blob = logo.blob;
      delete data.settings.logo;
      uploadImage(`clubes/${data.uid}.jpg`, blob, `clubes/${data.uid}/logo`);
    }

    if (data?.courts) {
      let databaseRef = ref(db, `clubes/${data.uid}/courts`);
      await Promise.all(
        data.courts.map(async (court) => {
          await addCourt(databaseRef, court, data.uid);
        })
      );
      delete data.courts;
    }
    if (data?.services) {
      let databaseRef = ref(db, `clubes/${data.uid}/services`);
      await Promise.all(
        data.services.map(async (service) => {
          await addService(databaseRef, service, data.uid);
        })
      );
      delete data.services;
    }
    update(ref(db, "clubes/" + data.uid), data).then(() => {
      dispatch(receiveUpdateClub());
      console.log("success");
    });
  } catch (error) {
    console.log(error);
    dispatch(errorUpdateClub());
  }
};
export const createCourt = (court, clubUid) => async (dispatch) => {
  dispatch(requestCreateCourt());
  let databaseRef = ref(db, `clubes/${clubUid}/courts`);
  const { error } = await addCourt(databaseRef, court, clubUid);
  if (!error) {
    dispatch(recieveCreateCourt());
    setTimeout(() => {
      defaultUpdateCourt();
    }, 10);
    console.log("success");
  } else {
    console.log(error);
    dispatch(errorCreateCourt());
  }
};

export const createService = (service, clubUid) => async (dispatch) => {
  dispatch(requestCreateService());
  let databaseRef = ref(db, `clubes/${clubUid}/services`);

  const { error } = await addService(databaseRef, service, clubUid);
  if (!error) {
    dispatch(recieveCreateService());
    console.log("success");
  } else {
    console.log(error);
    dispatch(errorCreateService());
  }
};
export const updateCourt = (clubUid, court) => async (dispatch) => {
  dispatch(requestUpdateCourt());

  let databaseRef = ref(db, `clubes/${clubUid}/courts`);
  const { error } = await addCourt(databaseRef, court, clubUid);
  if (!error) {
    dispatch(receiveUpdateCourt());
    setTimeout(() => {
      defaultUpdateCourt();
    }, 10);
    console.log("success");
  } else {
    console.log(error);
    dispatch(errorUpdateCourt());
  }
};

export const updateService = (clubUid, service) => async (dispatch) => {
  dispatch(requestUpdateService());
  let databaseRef = ref(db, `clubes/${clubUid}/services`);

  const { error } = await addService(databaseRef, service, clubUid);
  if (!error) {
    dispatch(receiveUpdateService());
    console.log("success");
  } else {
    console.log(error);
    dispatch(errorUpdateService());
  }
};

export const deleteCourt = (courtKey, clubUid) => async (dispatch) => {
  dispatch(requestDeleteCourt()); //se establece el estado como "evento eliminado"
  try {
    await remove(ref(db, `clubes/${clubUid}/courts/${courtKey}`)).then(() => {
      console.log("success");
      dispatch(receiveDeleteCourt()); // Si hubo exito se establece el estado como exitoso
    });
  } catch (error) {
    console.log(error);
    dispatch(errorDeleteCourt()); //si hubo exito se establece el estado como falla
  }
};

export const deleteService = (serviceKey, clubUid) => async (dispatch) => {
  dispatch(requestDeleteService());
  try {
    remove(ref(db, `clubes/${clubUid}/services/${serviceKey}`)).then(() => {
      console.log("success");
      dispatch(receiveDeleteService());
    });
  } catch (error) {
    console.log(error);
    dispatch(errorDeleteService());
  }
};

export const getLatLngClub = (clubUid) => async () => {
  try {
    onValue(
      ref(db, `clubes/${clubUid}`),
      (snapshot) => {
        let lat = snapshot.val().settings.lat;
        let lng = snapshot.val().settings.lng;
        return { lat, lng };
      },
      { onlyOnce: true }
    );
  } catch (error) {
    console.log(error);
    return { lat: "error", lng: "error" };
  }
};
