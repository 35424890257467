import "../styles/notfound.css";
import img1 from "../assets/Sad.png";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div className="notfound">
        <img src={img1} alt="NOTFOUND" className="image-notfound"></img>
        <h1>Página no encontrada</h1>
        <Link to="/">Haz click aquí para volver al inicio</Link>
      </div>
    </>
  );
};

export default NotFound;
