import Padel from "../../icons/Padel";
import Tennis from "../../icons/Tennis";

const CourtIcon = ({ type, size, color }) => {
  if (type === "tenis") return <Tennis size={size} color={color} />;
  if (type === "padel") return <Padel size={size} color={color} />;
  return null;
};

export default CourtIcon;

export const CourtType = ({ type, size, bgColor, color, showLabel }) => {
  return (
    <div
      style={{
        backgroundColor: bgColor || "#0000005e",
        borderRadius: "100%",
        padding: 10,
        width: "fit-content",
        height: "fit-content",
        position: "relative",
        marginTop: showLabel ? 30 : 0,
      }}
    >
      {showLabel && (
        <label
          style={{
            position: "absolute",
            top: -30,
            textTransform: "capitalize",
            fontWeight: 600,
            left: 0,
            fontSize: 16,
          }}
        >
          {type}
        </label>
      )}
      <CourtIcon type={type} size={size} color={color} />
    </div>
  );
};
