import { useState } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import moment from "moment";
import {
  EventContainer,
  EventImage,
  Name,
  Tipo,
  Fecha,
  customStylesEdit,
  customStylesDelete,
  Button,
} from "./styles/EventrowItemStyle";

import className from "classnames";
import { isEditingEvent } from "../../actions/auth";
import { deleteEvent } from "../../actions/events";

// components
import CrearTorneo from "./CreateTorneo";
import ImageTorneo from "../../assets/tennis-ball.png";
//icons
import { FaPen, FaTimesCircle } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import EventStatus from "./EventStatus";

function mapStateToProps(state) {
  return {
    eventDeleted: state.events.eventDeleted,
    canDeleteEvento: state.privilegio.canDeleteEvento,
    canUpdateEvento: state.privilegio.canUpdateEvento,
  };
}

const EventRowItem = (props) => {
  const { goToEvent, showActions, isEventUp, isEventCompleted, allEvents, event } = props;
  const { isAdmin, user } = useUser();

  const navigate = useNavigate();
  const [showEdit, setShowEdit] = useState(false);
  const [mostrar, setMostrar] = useState(false);
  const [tipo] = useState("");

  const { nombre, fechaInicio, id, logo = "", logo_400x400, logo_800x800, fechaTermino } = event;
  const renderEditTorneo = () => {
    return (
      <Modal
        isOpen={showEdit}
        style={customStylesEdit}
        className="modal-editar"
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => setShowEdit(false)}
        ariaHideApp={false}
      >
        <CrearTorneo tournament={props.event} closeModal={() => setShowEdit(false)} />
      </Modal>
    );
  };

  const renderDeleteTorneo = () => {
    return (
      <Modal
        isOpen={mostrar}
        style={customStylesDelete}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setMostrar(false)}
        ariaHideApp={false} // Este atributo evita problemas del react modal
      >
        <div className="mensaje">
          <h5>¿Estás seguro que quieres eliminar el Torneo "{nombre}"?</h5>
        </div>
        <div className="modal-body" style={{ display: "flex", justifyContent: "space-around" }}>
          <Button onClick={() => setMostrar(false)}>
            <span>Cancelar</span>
          </Button>
          <Link to="/">
            <Button onClick={() => eliminarTorneo()}>
              <span>Estoy Seguro</span>
            </Button>
          </Link>
        </div>
      </Modal>
    );
  };

  const changeEditEvent = () => {
    const { dispatch } = props;
    dispatch(isEditingEvent(true));
    setShowEdit(true);
  };

  const eliminarTorneo = () => {
    const { dispatch } = props;
    dispatch(deleteEvent(id));
    setMostrar(false);
  };

  const renderActions = () => {
    //if user is admin
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          position: "absolute",
          top: 55,
          right: 5,
        }}
      >
        <FaTimesCircle
          style={{
            width: "16",
            height: "16",
            marginRight: "5",
          }}
          onClick={(e) => {
            setMostrar(true);
            e.stopPropagation();
          }}
        />
        <FaPen
          style={{
            width: "16",
            height: "16",
            marginLeft: 10,
          }}
          onClick={(e) => {
            changeEditEvent(true);
            e.stopPropagation();
          }}
        />
      </div>
    );
  };

  const eventUpcoming = moment().isSameOrBefore(fechaTermino);
  const eventComplete = !eventUpcoming;

  const eventos = (complete = true) => {
    const fechaInicioFormato = moment(fechaInicio).format("DD/MM/YYYY");
    return (
      <>
        <EventContainer
          className={className("tw-w-64 tw-flex-1 tw-basis-64 tw-max-w-sm", {
            "events-completed": complete,
            "events-upcoming": !complete,
          })}
          onClick={() => {
            if (goToEvent) goToEvent(id);
            else navigate("/event/" + id);
          }}
        >
          <div className="tw-sticky tw-flex tw-items-center tw-justify-center">
            <EventImage
              src={logo_400x400 || logo_800x800 || logo || ImageTorneo}
              alt="Torneo Tenis-Up"
            />
            {complete && <button>VER DETALLES</button>}
            {user ? <EventStatus event={event} /> : <div />}
          </div>
          <div id="event-card">
            <div>
              <Name>{nombre}</Name>
              <Tipo>Torneo {tipo}</Tipo>
              <Fecha>{fechaInicioFormato}</Fecha>
              {/* {eventoState} */}
            </div>
            <div id="icons">
              {showActions && (isAdmin || user?.uid === event.owner) && renderActions()}
            </div>
          </div>
        </EventContainer>
        {renderEditTorneo()}
        {renderDeleteTorneo()}
      </>
    );
  };

  if (isEventUp && eventUpcoming) return eventos(false);
  if (isEventCompleted && eventComplete) return eventos();
  if (allEvents) return eventos(false);
  return <></>;
};
export default connect(mapStateToProps)(EventRowItem);
