import classNames from "classnames";
import { useMemo, useRef, useEffect, useCallback } from "react";
import { Hours } from "../../costants/Time";
import WeekItem from "./WeekItem";

const BodyWeek = ({ days, isClub, Callback, isSeleccionando, setIsSeleccionando, isMobile, selectedDay }) => {
  const timeSlotWrapper = useMemo(() => {
    return Hours.filter((h) => h.split(":")[1] === "00");
  }, []);
  const selectorDragRef = useRef();
  const firstCords = useRef([]);
  const lastCords = useRef([]);
  const firstScrollPosition = useRef([]);
  const scrollPosition = useRef([]);
  const containerRef = useRef();
  const nodes = useRef();
  const update = useRef(new Date());
  const selectedGroup = useRef("");
  useEffect(() => {
    nodes.current = containerRef.current.querySelectorAll(isClub ? ".itemHour" : ".disponible");
    return () => {
      nodes.current = null;
    };
  }, [isClub, isSeleccionando]);
  const verificationNodes = (isClub) => {
    const newNodes = [];
    const position = selectorDragRef.current.getBoundingClientRect();
    nodes.current.forEach((node) => {
      const { bottom: b1, right: r1, x: x1, y: y1 } = node.getBoundingClientRect();
      if (
        ((position.y >= y1 && position.bottom <= b1) ||
          (position.y <= y1 && position.bottom >= b1) ||
          (position.y <= b1 && position.bottom >= b1) ||
          (position.y <= y1 && position.bottom >= y1)) &&
        ((position.x >= x1 && position.right <= r1) ||
          (position.x <= x1 && position.right >= r1) ||
          (position.x <= r1 && position.right >= r1) ||
          (position.x <= x1 && position.right >= x1)) &&
        (node.classList.contains(selectedGroup.current) || isClub || !selectedGroup.current)
      ) {
        node.classList.add("ds-selected");
        newNodes.push(node);
        if (!isClub && !selectedGroup.current) {
          selectedGroup.current = node.className
            .split(" ")
            .find(
              (c) => c.includes("selectGroup") && c.length > 11 && !c.includes("selectGroup", 1)
            );
        }
      } else node.classList.remove("ds-selected");
    });
    if (newNodes.length === 0) selectedGroup.current = null;
    return newNodes;
  };
  const tapScroll = useRef(false);
  const clear = useCallback((isClub) => {
    firstCords.current = [];
    lastCords.current = [];
    selectedGroup.current = null;
    setSelectorPosition(firstCords.current, lastCords.current);
    const nodes = verificationNodes(isClub);
    if (!isClub) getActualIdSelect(nodes);
    return nodes;
  }, []);
  
  useEffect(() => {
    clear(isClub);
  }, [isClub, clear, selectedDay]);

  useEffect(() => {
    tapScroll.current = false
    const handleMouseUp = () => {
      let isSeleccionando;
      setIsSeleccionando((value) => {
        isSeleccionando = value;
        return false;
      });

      if (isSeleccionando) {
        const nodes = clear(isClub);
        Callback?.(nodes);
      }
    };
    let timer;
    const click = () => {
      tapScroll.current = true;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        if (tapScroll) tapScroll.current = false;
      }, 50);
    };
    const handleStart = (e) => {
      update.current = new Date();
      firstScrollPosition.current = scrollPosition.current;
      const { target, originalEvent, nativeEvent } = e;
      const touch =
        nativeEvent?.touches?.[0] ||
        nativeEvent?.changedTouches?.[0] ||
        originalEvent?.touches?.[0] ||
        originalEvent?.changedTouches?.[0] ||
        {};
      const { x, y } = containerRef.current.getBoundingClientRect();

      const { pageX, pageY } = touch || {};
      firstCords.current = [pageX - x, pageY - y];
      setSelectorPosition(firstCords.current, firstCords.current);
      const { classList } = target;
      const selectStart =
        !tapScroll.current && (classList.contains("itemHour") || classList.contains("itemHourNd"));
      setIsSeleccionando(selectStart);
      if (classList.contains(isClub ? "itemHour" : "disponible")) {
        classList.add("ds-selected");
      }
      if (selectStart) e.preventDefault();
    };
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchend", handleMouseUp);
    document.addEventListener("touchstart", handleStart, { capture: true, passive: false });
    document.addEventListener("click", click);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchend", handleMouseUp);
      document.removeEventListener("touchstart", handleStart, { capture: true, passive: false });
      document.removeEventListener("click", click);
      if (timer) clearTimeout(timer);
    };
  }, [Callback, setIsSeleccionando, isClub, clear]);

  const getActualIdSelect = (items) => {
    const newItems = items
      .map((it) => it.id.split("--")[1])
      .reduce(
        (a, v) => ({
          ...a,
          [v.split(":")[0] + ":00"]:
            (v.split(":")[1] === "00" ? 1 : 2) + (a[v.split(":")[0] + ":00"] || 0),
        }),
        {}
      );
    containerRef.current.querySelectorAll(".itemHourBase")?.forEach((n) => {
      const id = n.childNodes[1].data;
      const childNode = n.childNodes[0];
      if (newItems[id]) {
        if (newItems[id] === 1) {
          childNode.classList.add("itemHourBaseMSelected");
          childNode.classList.remove("itemHourBaseMMSelected");
          n.classList.remove("itemHourBaseSelected");
        } else if (newItems[id] === 2) {
          childNode.classList.add("itemHourBaseMMSelected");
          childNode.classList.remove("itemHourBaseMSelected");
          n.classList.remove("itemHourBaseSelected");
        } else if (newItems[id] === 3) {
          childNode.classList.remove("itemHourBaseMMSelected");
          childNode.classList.remove("itemHourBaseMSelected");
          n.classList.add("itemHourBaseSelected");
        }
      } else {
        childNode.classList.remove("itemHourBaseMMSelected");
        childNode.classList.remove("itemHourBaseMSelected");
        n.classList.remove("itemHourBaseSelected");
      }
    });
  };

  useEffect(() => {
    let timer;
    if (isSeleccionando) {
      timer = setInterval(() => {
        if (update.current) {
          requestAnimationFrame(() => {
            const nodes = verificationNodes(isClub);
            if (!isClub) getActualIdSelect(nodes);
            const { scrollHeight, scrollTop, offsetHeight } = containerRef.current;
            const { offsetTop } = selectorDragRef.current;
            selectorDragRef.current.style.maxHeight = `${scrollHeight - offsetTop}px`;

            if (lastCords.current[1] >= offsetHeight - 28) {
              if (scrollTop + offsetHeight < scrollHeight) {
                containerRef.current.scrollTop += 12;
              }
            } else if (lastCords.current[1] <= 28) {
              containerRef.current.scrollTop -= 12;
            }
          });
        }
      }, 50);
      update.current = null;
    }
    return () => {
      clearInterval(timer);
    };
  }, [isSeleccionando, isClub]);

  const setSelectorPosition = (firstCords, lastCords) => {
    let [left = 0, top = 0] = firstCords;
    let [width = 0, height = 0] = lastCords;
    let [fScrollX = 0, fScrollY = 0] = firstScrollPosition.current;
    let [scrollX = 0, scrollY = 0] = scrollPosition.current;
    if (width < left && scrollX >= fScrollX) {
      left = width;
      width = firstCords[0];
    }
    if (height < top && scrollY >= fScrollY) {
      top = height;
      height = firstCords[1];
    }

    width = width - left + scrollX - fScrollX;
    height = height - top + scrollY - fScrollY;

    if (width < 0) {
      left += width;
      width = Math.abs(width);
    }
    if (height < 0) {
      top += height;
      height = Math.abs(height);
    }

    requestAnimationFrame(() => {
      if (selectorDragRef.current) {
        selectorDragRef.current.style.left = `${left + fScrollX}px`;
        selectorDragRef.current.style.top = `${top + fScrollY}px`;
        selectorDragRef.current.style.width = `${width}px`;
        selectorDragRef.current.style.height = `${height}px`;
      }
    });
  };

  return (
    <div
      className="CalendarHours tw-relative Scroll tw-w-full"
      style={{
        maxHeight: 300,
        overflow: isSeleccionando ? "hidden" : "auto",
        paddingRight: isSeleccionando && (isMobile ? 8 : 28),
        marginTop: 10,
      }}
      ref={containerRef}
      onMouseDown={(e) => {
        const { target, pageX, pageY } = e;
        firstScrollPosition.current = scrollPosition.current;
        const { x, y } = containerRef.current.getBoundingClientRect();
        firstCords.current = [pageX - x, pageY - y];
        setSelectorPosition(firstCords.current, firstCords.current);
        const { classList } = target;
        const selectStart =
          !tapScroll.current &&
          (classList.contains("itemHour") || classList.contains("itemHourNd"));
        setIsSeleccionando(selectStart);
        if (target.classList.contains(isClub ? "itemHour" : "disponible")) {
          target.classList.add("ds-selected");
        }
        update.current = new Date();
      }}
      onScroll={(e) => {
        const { scrollLeft, scrollTop } = e.target;
        scrollPosition.current = [scrollLeft, scrollTop];
        update.current = new Date();
      }}
      onMouseMove={(el) => {
        if (isSeleccionando) {
          const { target, pageX, pageY } = el;
          // el.target.classList.add("ds-selected");

          const { x, y } = containerRef.current.getBoundingClientRect();

          lastCords.current = [pageX - x, pageY - y];
          const [startX, startY] = firstCords.current;
          if (isNaN(startX)) firstCords.current[0] = lastCords.current[0];
          if (isNaN(startY)) firstCords.current[1] = lastCords.current[1];
          setSelectorPosition(firstCords.current, lastCords.current);

          if (target.classList.contains(isClub ? "itemHour" : "disponible")) {
            update.current = new Date();
          }
        }
      }}
      onTouchMove={(el) => {
        if (isSeleccionando) {
          const { originalEvent, nativeEvent } = el;
          const touch =
            nativeEvent?.touches?.[0] ||
            nativeEvent?.changedTouches?.[0] ||
            originalEvent?.touches?.[0] ||
            originalEvent?.changedTouches?.[0] ||
            {};
          const { x, y } = containerRef.current.getBoundingClientRect();
          const { pageX, pageY } = touch || {};
          lastCords.current = [pageX - x, pageY - y];
          const [startX, startY] = firstCords.current;
          if (isNaN(startX)) firstCords.current[0] = lastCords.current[0];
          if (isNaN(startY)) firstCords.current[1] = lastCords.current[1];
          setSelectorPosition(firstCords.current, lastCords.current);
          update.current = new Date();
        }
      }}
    >
      <div
        ref={selectorDragRef}
        className="tw-absolute tw-bg-Verde-TenisCTA tw-opacity-50 tw-pointer-events-none"
      />
      <div>
        {timeSlotWrapper.map((h, i) => (
          <div key={"itemHourBase" + h} className={classNames("itemHourBase")}>
            <div />
            {h}
          </div>
        ))}
      </div>
      {days?.map(({ name, hour, disponibility, selectables, hours }, index) => (
        <WeekItem
          key={"WeekItems" + name}
          name={name}
          hour={hour}
          disponibility={disponibility}
          selectables={selectables}
          hours={hours}
        />
      ))}
    </div>
  );
};
export default BodyWeek;
