import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "../../../styles/EditCancha.css";
import "../../../styles/Court.css";
import NewCancha from "./NewCancha";
import { deleteCourt, defaultCourt } from "../../../actions/clubs";
import Modal from "react-modal";
import { colors } from "../../../costants/colors";

import styled from "styled-components";
import ListCanchas from "./ListCanchas";
import toast from "react-hot-toast";
import { useUser } from "../../../context/UserContext";

// import club from "../../configuration/club";

function mapStateToProps(state) {
  return {
    events: state.events,
    event: state.events.event,
    courtDeleted: state.club.courtDeleted,
    courtUpdated: state.club.courtUpdated,
    courtCreated: state.club.courtCreated,
  };
}

function CanchaContainer(props) {
  const {
    welcomeClubSetup: welcomeClub,
    courtDeleted,
    courtUpdated,
    courtCreated,
    updateCourtError,
    createCourtError,
    courts,
  } = props;
  const dispatch = useDispatch();
  const [addCourt, setAddCourt] = useState(false);
  const [selectedCourt, setSelectedCourt] = useState("");
  const [change, setChange] = useState(false);
  const [filterCourts, setCourts] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const { uid } = useUser();
  useEffect(() => {
    setCourts(courts);
    dispatch(defaultCourt());
  }, [addCourt, courts, dispatch]);

  const handleDelete = async (court, index) => {
    if (court.key) {
      dispatch(deleteCourt(court.key, uid));
      let allCourts = courts;
      allCourts.splice(index, 1);
      setCourts(allCourts);
      setChange(!change);
    } else {
      let allCourts = courts;
      allCourts.splice(index, 1);
      setCourts(allCourts);
      setChange(!change);
      setAddCourt(false);
      toast.success("Se ha eliminado la cancha correctamente");
    }
    setShowDelete(false);
  };

  useEffect(() => {
    if (courtUpdated || courtDeleted || courtCreated || createCourtError || updateCourtError) {
      setAddCourt(false);
      setSelectedCourt("");
    }
  }, [courtUpdated, courtDeleted, courtCreated, createCourtError, updateCourtError]);
  useEffect(() => {
    if (courtUpdated) {
      toast.success("La cancha se ha editado correctamente");
    }
  }, [courtUpdated]);
  useEffect(() => {
    if (courtDeleted) {
      toast.success("Se ha eliminado la cancha correctamente");
    }
  }, [courtDeleted]);
  useEffect(() => {
    if (courtCreated) {
      toast.success("La cancha se agregó correctamente");
    }
  }, [courtCreated]);
  useEffect(() => {
    if (createCourtError || updateCourtError) {
      toast.error("Ha ocurrido un error");
    }
  }, [createCourtError, updateCourtError]);

  const handleOpenDeleteModal = (court, index) => {
    setSelectedCourt({ court, index });
    setShowDelete(true);
  };

  const returnModalDelete = () => (
    <>
      <Modal
        isOpen={showDelete}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setShowDelete(false)}
      >
        <div className="mensaje">
          <h5> ¿Estás seguro de eliminar la cancha? </h5>
        </div>

        <div className="modal-body">
          <Button1 className="modal-button" onClick={() => setShowDelete(false)}>
            <span>Cancelar</span>
          </Button1>

          <Button1
            onClick={() => handleDelete(selectedCourt.court, selectedCourt.index)}
            className="modal-button"
            alertDeleted
          >
            <span>Eliminar</span>
          </Button1>
        </div>
      </Modal>
    </>
  );

  return (
    <div className={"edit-court-club-content"}>
      {returnModalDelete()}
      <div className="edit-form-columns xs:tw-px-6">
        <button
          className="add-new-court-btn"
          onClick={() => {
            setAddCourt(!addCourt);
            setSelectedCourt("");
          }}
          style={{ backgroundColor: colors.Verde.TenisCTA }}
        >
          {addCourt ? "Cancelar" : "Agregar Cancha"}
        </button>
      </div>
      <div className="court-column" id="newCanchascroll">
        {addCourt ? (
          <NewCancha
            adding={(state) => setAddCourt(state)}
            selectedCourt={selectedCourt}
            courts={courts}
            welcomeClub={welcomeClub}
            setCourts={(court) => props.setCourts(court)}
            handleAddCourt={() => setAddCourt(!addCourt)}
          ></NewCancha>
        ) : (
          <ListCanchas
            courts={filterCourts}
            handleOpenDeleteModal={handleOpenDeleteModal}
            setSelectedCourt={setSelectedCourt}
            setAddCourt={setAddCourt}
          />
        )}
      </div>
    </div>
  );
}

// Estilos modal

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
  },
};

const Button1 = styled.button`
  position: relative;
  margin: 6px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  border: none;
  background-color: #000022;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  &:hover {
    background: #000022;
    opacity: 0.6;
  }
  cursor: pointer;
`;

export default connect(mapStateToProps)(CanchaContainer);
