import { useCallback, useState, useEffect } from "react";
import useContador from "../../hooks/useContador";
import useObserver from "../../hooks/useObserver";
import { counterLanding } from "../../services/transactions/CounterLanding.service";
import classNames from "classnames";
import "../../styles/RealtimeCounts.css";

const RealtimeCounts = () => {
  const [setRef, isRuning] = useObserver();

  const counterContainer = useCallback((node) => setRef(node), [setRef]);

  const [data, setData] = useState({});
  const { dascargas_applestore, total_arriendo, total_match, dascargas_playstore } = data || {};
  const totalUsers = dascargas_applestore || 0 + dascargas_playstore || 0;

  useEffect(() => {
    const handleData = async () => {
      const test = await counterLanding();
      if (test) setData(test);
    };
    handleData();
  }, []);

  return (
    <section className="realtime-counts-container" ref={counterContainer}>
      <h2 className="tw-mb-5">
        LA COMUNIDAD TENÍSTICA DE CHILE ESTA EN <strong>TENIS-UP</strong> CL
      </h2>
      <div className={classNames("counterOn", { "tw-hidden tw-opacity-0": !isRuning })}>
        <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center">
          <Circle isRuning={isRuning} totalCount={totalUsers} title="Usuarios" />
          <Circle isRuning={isRuning} totalCount={total_match} title="Partidos" />
          <Circle isRuning={isRuning} totalCount={total_arriendo} title="Arriendos de canchas" />
        </div>
      </div>
    </section>
  );
};

export default RealtimeCounts;

const Circle = ({ title, isRuning, totalCount }) => {
  const { counter } = useContador({
    isRuning,
    totalCount,
  });

  return (
    <div className="xm:tw-m-7 sm:tw-m-4 tw-m-3 tw-rounded-full xm:tw-w-60 tw-w-48 xm:tw-h-60 tw-h-48 tw-bg-Verde-hover-0 tw-flex tw-items-center tw-justify-center">
      <div className="tw-rounded-full xm:tw-w-48 tw-w-36 xm:tw-h-48 tw-h-36 tw-p-2 tw-bg-white tw-flex tw-items-center tw-justify-center tw-flex-col">
        <h3 className="xm:tw-text-3xl xs:tw-text-sm tw-text-center tw-font-bold tw-italic">
          {counter}
        </h3>
        <h4 className="xm:tw-text-2xl xs:tw-text-sm tw-text-center tw-font-medium tw-italic">
          {title}
        </h4>
      </div>
    </div>
  );
};
