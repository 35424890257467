import {
    PAYMENT_UPDATE_DATA_DURATION,
    PAYMENT_UPDATE_DATA_DAY,
    PAYMENT_UPDATE_DATA_COURTKEY,
    PAYMENT_UPDATE_DATA_CLUBUID,
    PAYMENT_UPDATE_DATA_TOTALMOUN,
    PAYMENT_UPDATE_DATA
} from "../actions/payment"

const paymentState = (
    state = {
        duracion: null,
        day: { start: null, end: null },
        courtKey: null,
        clubID: null,
        total: null
    },
    action
) => {
    switch (action.type) {
        case PAYMENT_UPDATE_DATA:
            return { ...state, ...action.data }
        case PAYMENT_UPDATE_DATA_DURATION:
            return { ...state, duracion: action.data }
        case PAYMENT_UPDATE_DATA_DAY:
            return { ...state, day: action.data }
        case PAYMENT_UPDATE_DATA_COURTKEY:
            return { ...state, courtKey: action.data }
        case PAYMENT_UPDATE_DATA_CLUBUID:
            return { ...state, clubID: action.data }
        case PAYMENT_UPDATE_DATA_TOTALMOUN:
            return { ...state, total: action.data }
        default: return state
    }
};

export default paymentState;