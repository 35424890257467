import { useState, useEffect, useMemo } from "react";
import "../../styles/Input.css";
import { connect, useDispatch } from "react-redux";
import {
  createEvent,
  updateEvent,
  resetStatusEvent,
  getAllEvents,
  defaultUpdateEvent,
} from "../../actions/events";
import { getTypeRanking } from "../../actions/tipoRanking";
import "../../styles/CrearTorneo.css";
import { MdEdit } from "react-icons/md";
import ImageTorneo from "../../assets/tennis-ball.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AlertErrorList from "../alert/AlertErrorList";
//Modal Cupos
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import Map from "../mapa/Map";
import Input from "../ui/Input";
import LoadingSuccess from "../ui/LoadingSuccess";
import { TournamentStatusList } from "../../costants/courtOptions";
import Select from "../ui/Select";
import ModalCupos from "./ModalCupos";
import moment from "moment";
import { getTotalQuotes, checkImg } from "../../services/Utils";
import SportSelectModal from "./SportSelectModal";
import NumberInput from "../ui/NumberInput";
import { useUser } from "../../context/UserContext";
import toast from "react-hot-toast";

const CHILE_REGIONS = require("../../services/countries/chile_regions.json");
//Expresiones Regulares (exReg) para la validación de distintos campos
const dataName = /^[a-zA-Z -ñÑáéíóúÁÉÍÓÚ]+$/;

const dateReg = /^\d{4}-\d{2}-\d{2}$/;
const cuposReg = /^[0-9]*[1-9][0-9]*$/;
const latReg = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/;
const lngReg = /^-?([1]?[1-7][1-9]|[1]?[1-8][0]|[1-9]?[0-9])\.{1}\d{1,6}/;

const formDefault = {
  nombre: "",
  deporte: "",
  lugar: "",
  precio: "",
  region: "",
  ubicacion: "",
  lat: 0,
  lng: 0,
  status: "Abierto",
  tipoRanking: "",
  fechaInicio: "",
  fechaLimite: "",
  fechaTermino: "",
  id: "",
  logoTorneo: { file: "", filename: "" },
};
const errorDefault = {
  nombre: null,
  deporte: null,
  lugar: null,
  precio: null,
  region: null,
  ubicacion: null,
  lat: null,
  lng: null,
  status: null,
  tipoRanking: null,
  fechaInicio: null,
  fechaLimite: null,
  fechaTermino: null,
  id: null,
  logoTorneo: null,
  cupos: null,
};
const CreateTorneo = (props) => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTypeRanking());
  }, [dispatch])

  
  const [alertOpen, setAlertOpen] = useState(false);
  const { isClub, tipo_usuario, uid } = useUser();

  const typeRank = useMemo(() => {
    return props.typeRank?.map((type) => ({ key: type.id, label: type.name, value: type.id }))
  }, [props.typeRank])

  //Variables usadas para alamcenar los datos temoporales
  //Para posteriormente enviarlos a la base de datos a Crear o Editar

  const mobile = useMediaQuery("(max-width: 475px)");
  const md = useMediaQuery("(max-width: 680px)");
  const { closeModal } = props;
  const navigate = useNavigate();
  const [
    {
      nombre,
      deporte,
      lugar,
      precio,
      region,
      ubicacion,
      lat,
      lng,
      status,
      fechaInicio,
      fechaLimite,
      fechaTermino,
      id,
      logoTorneo,
    },
    setFormData,
  ] = useState(formDefault);

  const [tipoRanking, setTipoRanking] = useState("");

  const [errReport, setErrReport] = useState([]);
  const [showCupos, setShowCupos] = useState(false);
  const [showSport, setShowSport] = useState(false);
  const [cupos, setCupos] = useState({});


  //State para almacenar los errores

  const [error, setError] = useState(errorDefault);
  const regions = useMemo(
    () =>
      [
        {
          key: "region-default",
          value: "",
          label: "Seleccione una region",
        },
      ].concat(
        CHILE_REGIONS.map(({ region }, i) => ({
          key: "region-" + i,
          value: region,
          label: region,
        }))
      ),
    []
  );
  const comunas = useMemo(() => {
    const comunasList = {
      "": [],
    };
    CHILE_REGIONS.forEach(({ region, comunas }) => {
      comunasList[region] = comunas.map((co, j) => ({
        key: "comuna-" + j,
        value: co,
        label: co,
      }));
    });
    return comunasList;
  }, []);

  const [comunasList, setComunas] = useState([]);
  useEffect(() => {
    setComunas([
      {
        key: "comuna-default",
        value: "",
        label: "Seleccione una comuna",
      },
      ...(comunas[region] || []),
    ]);
  }, [region, comunas]);

  //Si se obtuvieron los datos (singleEventFetched=true)
  //Carga los datos de props.tournament en las varaibles temporales (states)
  const { singleEventFetched } = props;

  useEffect(() => {
    if (props.tournament) {
      const {
        fechaInicio,
        fechaLimite,
        fechaTermino,
        id,
        cupos,
        configCupos,
        logo,
        categoria = { deporte: "tenis" },
        ...tournament
      } = props.tournament;
      if (id) {
        setFormData({
          ...tournament,
          fechaInicio: moment(fechaInicio).format("YYYY-MM-DD"),
          fechaLimite: moment(fechaLimite).format("YYYY-MM-DD"),
          fechaTermino: moment(fechaTermino).format("YYYY-MM-DD"),
          id,
          logoTorneo: logo,
          deporte: categoria.deporte,
        });
        if (cupos) {
          delete cupos.Total;
          Object.keys(cupos).forEach(
            (c) => (cupos[c] = { cupos: parseInt(cupos[c] || 0) })
          );
          if (configCupos)
            Object.keys(configCupos).forEach(
              (c) => (cupos[c] = { ...cupos[c], ...configCupos[c] })
            );
          setCupos(cupos);
        }
      }
    }
  }, [props.tournament, singleEventFetched]);
  //Limpia el formulario una vez se haya hecho alguna acción satisfactoria
  const setStatesVacio = () => {
    setFormData(formDefault);
    setCupos({});
  };
  //Sin este método los states quedarían en undefined y no se podría usar el el form para Editar

  const [loadingSuccess, setloadingSuccess] = useState(false);
  //Para llamar las propiedades de events
  const { eventUpdated, eventCreated } = props.events;
  //Cuando se actualice se haga el efecto "cargar (loading)"
  useEffect(() => {
    let timer;
    if (eventUpdated || eventCreated) {
      setloadingSuccess(true);
      timer = setTimeout(() => {
        setloadingSuccess(false);
        dispatch(getAllEvents(tipo_usuario));
        dispatch(defaultUpdateEvent());
        if (eventCreated) {
          navigate("/explore");
          toast.success("Se ha creado el torneo correctamente");
        } else {
          closeModal?.();
        }
        dispatch(resetStatusEvent());
      }, 2500);
    }
    return () => timer && clearTimeout(timer);
  }, [dispatch, eventUpdated, eventCreated, navigate, tipo_usuario, closeModal]);

  //Variable para manejar mensaje del Modal
  // const [msjModal, setMsjModal] = useState("mensaje modal");
  const [, setMostrar] = useState(false);

  //Función que Crea y Edita
  //Dependiendo del estado en que se encuentre {props.isEditingEvent}
  let formDataValidatorTenis = [
    nombre,
    deporte,
    lugar,
    precio,
    region,
    ubicacion,
    lat,
    lng,
    status,
    tipoRanking,
    fechaInicio,
    fechaLimite,
    fechaTermino,
    logoTorneo,
  ];
  let err = [];
  useEffect(() => {
    if (nombre !== "") {
      if (!dataName.test(nombre)) {
        setError((data) => ({
          ...data,
          nombre: "Solo se puenden introducir letras",
        }));
      }
    }
  }, [nombre]);
  const crearTorneo = (event) => {
    
    if(deporte === "tenis"){
    formDataValidatorTenis.forEach((item, index) => {
      if (item === "" && index === 0) {
        err.push("Nombre");
      }
      if (item === "" && index === 1) {
        err.push("Deporte");
      }
      if (item === "" && index === 2) {
        err.push("lugar");
      }
      if (item === "" && index === 3) {
        err.push("Precio");
      }
      if (item === "" && index === 4) {
        err.push("Region");
      }
      if (item === "" && index === 5) {
        err.push("Comuna");
      }
      if (item === "" && index === 6) {
        err.push("Latitud");
      }
      if (item === "" && index === 7) {
        err.push("Logitud");
      }
      if (item === "" && index === 8) {
        err.push("Status");
      }
      if (item === "" && index === 9) {
        err.push("Fecha De Inicio");
      }
      if (item === "" && index === 10) {
        err.push("Fecha Limite");
      }
      if (item === "" && index === 11) {
        err.push("Fecha De Termino");
      }
      if (item === "" && index === 13) {
        err.push("logo Del Torneo");
      }
    });
  };

    setErrReport(err);
    if (err.length === 0) {
      const Total = getTotalQuotes(cupos);
      let compDate = moment(fechaLimite.match(dateReg)[0]).isBefore(
        moment(fechaTermino.match(dateReg)[0])
      );
      if (
        nombre.trim().length < 3 ||
        deporte.length < 3 ||
        lugar.trim().length < 3 ||
        Total < 2 ||
        // cupos.match(cuposReg) == null ||
        region.length < 3 ||
        ubicacion.length < 3 ||
        status.length < 3 ||
        fechaInicio.match(dateReg) === null ||
        fechaLimite.match(dateReg) === null ||
        fechaTermino.match(dateReg) === null ||
        compDate === false
        ) {
          setMostrar(true);
          event.preventDefault();
      } else {
        //Array con la información FINAL que se quiera Crear o Eliminar
        //Son poblados con los datos de State
        const TotalCupos = {};
        const configCupos = {};
        Object.entries(cupos).forEach(([key, value]) => {
          const newValue = { ...value };
          if (newValue?.cupos) {
            TotalCupos[key] = parseInt(newValue.cupos);
            delete newValue.cupos;
          }
          if (Object.keys(newValue).length > 0 && TotalCupos[key])
            configCupos[key] = newValue;
        });
        TotalCupos.Total = Total;
        let data = {
          isDual: deporte === "padel",
          nombre: nombre.trim(),
          categoria: { deporte: deporte, tipo: "torneo", subtipo: "Regional"},
          ranking: (deporte === "tenis") ? { nombre: tipoRanking.label, id: tipoRanking.key } : "",
          lugar: lugar.trim(),
          precio,
          cupos: TotalCupos,
          configCupos,
          region,
          ubicacion,
          lat: parseFloat(lat),
          lng: parseFloat(lng),
          status,
          fechaInicio: moment(fechaInicio).format("YYYY/MM/DD"),
          fechaLimite: moment(fechaLimite).format("YYYY/MM/DD"),
          fechaTermino: moment(fechaTermino).format("YYYY/MM/DD"),
          id,
          logo: logoTorneo,
          owner: isClub ? uid : null,
        };
        let dataEdit = {
          isDual: deporte === "padel",
          nombre: nombre.trim(),
          categoria: { deporte: deporte, tipo: "torneo", subtipo: "Regional"},
          ranking: (deporte === "tenis") ? { nombre: tipoRanking.label, id: tipoRanking.key } : "",
          lugar: lugar.trim(),
          precio,
          cupos: TotalCupos,
          configCupos,
          region,
          ubicacion,
          lat: parseFloat(lat),
          lng: parseFloat(lng),
          status,
          fechaInicio: moment(fechaInicio).format("YYYY/MM/DD"),
          fechaLimite: moment(fechaLimite).format("YYYY/MM/DD"),
          fechaTermino: moment(fechaTermino).format("YYYY/MM/DD"),
          id,
          logo: logoTorneo || ImageTorneo,
          owner: isClub ? uid : null,
        };
        if (props.isEditingEvent !== true) {
          //Si se esta creando
          dispatch(createEvent(data));
          event.preventDefault();
          setMostrar(false);
        } else {
          //Si se esta editando
          event.preventDefault();
          dispatch(updateEvent(dataEdit, logoTorneo));
          setMostrar(false);
        }
      }
    } else if (err.length > 0) {
      setAlertOpen(true);
    }
    //Valicación de campos
  };
  const onCircleInteraction = (childKey, childProps, mouse) => {
    const { lat, lng } = mouse;
    setFormData((data) => ({ ...data, lat, lng }));
  };

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude: lat, longitude: lng } = position?.coords || {};
        setFormData((data) => ({ ...data, lat, lng }));
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  useEffect(() => {
    getLocation();
  }, []);
  const lengthError = ({ name, value }, errorText, minSize = 3) => {
    setError((data) => ({
      ...data,
      [name]: value.length < minSize ? errorText : "",
    }));
  };
  const dateError = ({ name, value }, errorText) => {
    setError((data) => ({
      ...data,
      [name]: !moment(value).isValid ? errorText : "",
    }));
  };
  useEffect(() => {
    if (fechaLimite.match(dateReg) > fechaTermino.match(dateReg)) {
      setError((data) => ({
        ...data,
        fechaLimite: "La fecha limite no puede ser mayor a la fecha de termino",
      }));
    } else if (fechaLimite.match(dateReg) <= fechaTermino.match(dateReg)) {
      setError((data) => ({ ...data, fechaLimite: null }));
    }
    if (fechaInicio.match(dateReg) > fechaTermino.match(dateReg)) {
      setError((data) => ({
        ...data,
        fechaInicio: "La fecha de inicio no puede ser mayor a la fecha de termino",
      }));
    }
  }, [fechaLimite, fechaTermino, fechaInicio]);
  useEffect(() => {
    if (status === "") {
      setError((data) => ({ ...data, status: "El status no es Valido" }));
    } else if (status !== "") {
      setError((data) => ({ ...data, status: null }));
    }
  }, [status]);

  function handleChange(e) {
    let { name, value } = e.target;

    if (formDefault[name] !== undefined) {
      if (name === "precio") {
        let regex = /(\d*.\d{0,2})/;
        setFormData((data) => ({ ...data, precio: value.match(regex)?.[0] || "" }));
      } else {
        setFormData((data) => ({ ...data, [name]: value }));
      }
    }
    if (
      name === "fechaInicio" ||
      name === "fechaLimite" ||
      name === "fechaTermino"
    ) {
      dateError(e.target, "Fecha invalida");
    }
    if (name === "nombre") {
      setFormData((data) => ({ ...data, nombre: value.match(dataName)?.[0] || "" }));
    }

    switch (name) {
      case "nombre":
        lengthError(
          e.target,
          "Nombre muy corto o uso de caracteres incorrectos"
        );
        break;
      case "lugar":
        lengthError(e.target, "Lugar inválido");
        break;
      case "region":
        lengthError(e.target, "Región invalida");
        break;
      case "ranking":
        lengthError(e.target, "Tipo de ranking invalido");
        break;
      case "comunas":
        lengthError(e.target, "La ubicacion es muy corta");
        break;
      case "deporte":
        setError((data) => ({
          ...data,
          [name]: !value ? "Seleccione un deporte de la lista" : "",
        }));
        break;

      case "cupos":
        setCupos(value);
        setError((data) => ({
          ...data,
          [name]:
            cupos.match(cuposReg) === null ? "Debes ingresar un número" : "",
        }));
        break;
      case "lat":
        setError((data) => ({
          ...data,
          [name]:
            latReg.exec(value) === false || latReg.exec(value) === null
              ? "Porfavor verifique la latitud"
              : "",
        }));
        break;
      case "lng":
        setError((data) => ({
          ...data,
          [name]:
            lngReg.exec(value) === false || lngReg.exec(value) === null
              ? "Porfavor verifique la longitud"
              : "",
        }));
        break;
      default:
        break;
    }
  }
  const handleChangePicture = async (event) => {
    event.preventDefault();
    if (event.target?.files.length === 1) {
      const file = event.target?.files[0];
      const fileReader = new window.FileReader();
      const filename = file?.name;
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setFormData((data) => ({
          ...data,
          logoTorneo: {
            file: fileReader.result,
            filename: filename,
            blob: file,
          },
        }));
      };
    }
  };



  const handleModalCupos = () => {
    setShowCupos(true);
  };
  const handleModalSport = () => {
    setShowSport(true);
  };

  const renderModalCupos = () => {
    return (
      <Modal
        id="modalLindo"
        isOpen={showCupos}
        style={!md ? modalCupos : ModalMobile}
        shouldCloseOnOverlayClick={showCupos}
        onRequestClose={() => setShowCupos(false)}
        ariaHideApp={false}
      >
        <ModalCupos
          tipo={deporte}
          close={() => setShowCupos(false)}
          cupos={cupos}
          setCupos={setCupos}
        />
      </Modal>
    );
  };
  const renderModalDeporte = () => {
    return (
      <Modal
        isOpen={!deporte || showSport}
        style={!mobile ? modalDeportes : ModalMobile}
        shouldCloseOnOverlayClick={showSport}
        onRequestClose={() => setShowSport(false)}
        ariaHideApp={false}
      >
        <SportSelectModal
          onSelect={(deporte) => {
            handleChange({ target: { name: "deporte", value: deporte } });
            setShowSport(false);
          }}
          canClose={deporte}
          handleClose={() => setShowSport(false)}
          value={deporte}
        />
      </Modal>
    );
  };
  const [cleanReport, setCleanReport] = useState(false);
  if (cleanReport === true) {
    setErrReport([]);
    setCleanReport(false);
  }
  return (
    <div className="scroll torneo-event-content">
      <div className="torneo-event-edit-img">
        <div style={{ position: "relative", height: "100%", display: "flex" }}>
          <img
            src={checkImg(logoTorneo) || ImageTorneo}
            className="torneo-event-photo"
            alt="Imagen de torneo"
          />
          <div className="torneo-edit-foto">
            <input
              name="logo-torneo"
              type="file"
              required
              accept="image/png, .jpeg, .jpg"
              onChange={handleChangePicture}
            />
            <MdEdit color="#333" size="20" className="torneo-edit-foto-icon" />
          </div>
        </div>
      </div>
      <h3 className="torneo-event-general tw-text-3xl tw-font-medium">Información General</h3>
      <Input
        label="Nombre"
        error={error.nombre}
        placeholder="Ingrese el nombre..."
        name="nombre"
        onChange={handleChange}
        value={nombre}
      />
      <InlineFormBtn
        label="Cupos"
        placeholder="Añadir Cupos"
        disabled={!deporte}
        onClick={handleModalCupos}
      />
      <NumberInput
        label="Precio"
        error={error.precio}
        placeholder="Ingrese el precio..."
        name="precio"
        onChange={handleChange}
        value={precio}
        decimal={2}
      />
      <InlineFormBtn
        label="Deporte"
        placeholder={deporte || "Selecciona el Deporte"}
        onClick={handleModalSport}
      />

      <h3 className="torneo-event-preferencias tw-text-3xl tw-font-medium">
        Preferencias del torneo
      </h3>
      <div className="torneo-event-preferencias">
        <Select
          label="Status"
          name="status"
          value={status}
          onChange={({ value }) =>
            handleChange({ target: { name: "status", value: value } })
          }
          options={TournamentStatusList}
          error={error.status}
          isHelp
          help="Se utiliza para elegir si el torneo será con inscripción abierta para todo el público, o bien cerrado"
        />
        {deporte === "tenis" &&
          <Select
            placeholder="Selecciona tipo de ranking"
            label="Tipo ranking"
            name="ranking"
            value={tipoRanking}
            onChange={(event) => setTipoRanking(event)}
            options={typeRank}
            error={error.tipoRanking}
            isHelp
            help="Se utiliza para seleccionar el ranking del torneo"
          />
        }
      </div>
      <Input
        label="Fecha Límite"
        error={error.fechaLimite}
        name="fechaLimite"
        onChange={handleChange}
        value={fechaLimite}
        type="date"
        isHelp
        help="La fecha límite es el tiempo limitado que tiene el jugador para inscribirse"
      />
      <Input
        label="Fecha de Inicio"
        error={error.fechaInicio}
        name="fechaInicio"
        onChange={handleChange}
        value={fechaInicio}
        type="date"
        isHelp
        help="Fecha de comienzo del evento"
      />
      <Input
        label="Fecha de Término"
        error={error.fechaTermino}
        name="fechaTermino"
        onChange={handleChange}
        value={fechaTermino}
        type="date"
        isHelp
        help="Fecha de termino del evento"
      />
      <Select
        label="Región"
        name="region"
        value={region}
        onChange={({ value }) =>
          handleChange({ target: { name: "region", value: value } })
        }
        options={regions}
        error={error.region}
      />
      <Select
        label="Comuna"
        name="ubicacion"
        value={ubicacion}
        onChange={({ value }) =>
          handleChange({ target: { name: "ubicacion", value: value } })
        }
        options={comunasList}
        error={error.ubicacion}
      />
      <Input
        label="Direccion"
        error={error.lugar}
        name="lugar"
        onChange={handleChange}
        value={lugar}
        type="text"
        placeholder="Ingrese la direccion del torneo..."
      />
      <h3 className="torneo-event-preferencias tw-text-3xl tw-font-medium">Localización</h3>
      <div className="mapa-container torneo-mapa-container">
        <Map
          lat={lat}
          lng={lng}
          zoom={15}
          handleChildInteraction={onCircleInteraction}
          text="Mi marcador"
        />
      </div>
      <div className="torneo-event-preferencias">
        <Input
          disabled
          label="Longitud"
          value={lng}
          type="number"
          name="lng"
          placeholder="Ingresa la longitud del club"
          error={error.lng}
          onChange={handleChange}
        />
        <Input
          disabled
          label="Latitud"
          value={lat}
          type="number"
          name="lat"
          placeholder="Ingresa la latitud del club"
          error={error.lat}
          onChange={handleChange}
        />
      </div>
      <div className="torneo-btn_form torneo-event-preferencias">
        <button
          className="torneo-cancelar-event-boton"
          onClick={() =>
            props.isEditingEvent ? props.closeModal() : setStatesVacio()
          }
        >
          {props.isEditingEvent ? "Cancelar" : "Limpiar Campos"}
        </button>
        {loadingSuccess ? (
          <LoadingSuccess text={`${eventCreated ? "Evento Creado" : "Evento Editado"}`} />
        ) : (
          <button
            style={{ background: "#DCEC00" }}
            type="button"
            onClick={(event) => crearTorneo(event)}
            className="torneo-crear-event-boton"
          >
            {id ? "Editar" : "Crear"}
          </button>
        )}
      </div>
      <AlertErrorList
        clean={setCleanReport}
        list={errReport}
        setIsOpenAlert={setAlertOpen}
        modalIsOpenAlert={alertOpen}
      />

      {renderModalCupos()}
      {renderModalDeporte()}
    </div>
  );
};
export const InlineFormBtn = ({ label, disabled, onClick, placeholder }) => {
  return (
    <div className="torneo-event-input">
      <label className="torneo-event-label">{label}</label>
      <button
        type="button"
        disabled={disabled}
        className="torneo-event-button"
        style={{ textTransform: "capitalize" }}
        onClick={onClick}
      >
        {placeholder}
      </button>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    events: state.events,
    isEditingEvent: state.auth.isEditingEvent,
    event: state.events.event,
    singleEventFetched: state.events.singleEventFetched,
    typeRank: state.typeRank.typeRank,
  };
}
export default connect(mapStateToProps)(CreateTorneo);

const modalCupos = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    width: "100%",
    maxWidth: 1000,
    height: "70%",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
  },
};
const modalDeportes = {
  content: {
    top: "50%",
    left: "50%",
    right: "50%",
    bottom: "-45%",
    // marginRight: '-50%',
    transform: "translate(-50%, -50%)",
    border: "none",
    width: "60%",
    height: "fit-content",
    padding: "0px",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
  },
};

const ModalMobile = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    border: "none",
    width: "100vw",
    height: "100vh",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
  },
};
