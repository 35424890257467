export const HEADER_SELECTED = "MENU_SELECTED";
export const IS_LOGIN_OPEN = "IS_LOGIN_OPEN";
export const LOGIN_SECTION = "LOGIN_SECTION";

const menuSelected = (pestaña, eventInfo) => {
  
    return {
        type: HEADER_SELECTED,
        selected: pestaña,
        eventInfo: eventInfo
    }
}
const isLoginopen = (isOpen) => {
    return {
        type: IS_LOGIN_OPEN,
        isLoginopen: isOpen
    }
}
const setLoginSection = (loginSection) => {
    return {
        type: LOGIN_SECTION,
        loginSection: loginSection
    }
}

export const changePestañaSelected = (pestaña, eventInfo) => dispatch => {
    dispatch(menuSelected(pestaña, eventInfo));
}

export const changeLoginOpen = (isOpen) => dispatch => {
    dispatch(isLoginopen(isOpen));
}
export const changeLoginSection = (loginSection) => dispatch => {
    dispatch(setLoginSection(loginSection));
}