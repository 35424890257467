export const PAYMENT_UPDATE_DATA_DURATION = "PAYMENT_UPDATE_DATA_DURATION";
export const PAYMENT_UPDATE_DATA_DAY = "PAYMENT_UPDATE_DATA_DAY";
export const PAYMENT_UPDATE_DATA_COURTKEY = "PAYMENT_UPDATE_DATA_COURTKEY";
export const PAYMENT_UPDATE_DATA_CLUBUID = "PAYMENT_UPDATE_DATA_CLUBUID";
export const PAYMENT_UPDATE_DATA_TOTALMOUN = "PAYMENT_UPDATE_DATA_TOTALMOUNT";
export const PAYMENT_UPDATE_DATA = "PAYMENT_UPDATE_DATAT";
const paymentChange = data =>{
  return {
    type: PAYMENT_UPDATE_DATA,
    data:data
  };
}
const paymentDuration = (data) => {
    return {
      type: PAYMENT_UPDATE_DATA_DURATION,
      data:data
    };
  };
const paymentDay = (data) => {
    return {
      type: PAYMENT_UPDATE_DATA_DAY,
      data:data
    };
  };
const paymentCourtKey = (data) => {
    return {
      type: PAYMENT_UPDATE_DATA_COURTKEY,
      data:data
    };
  };
const paymentClubUID = (data) => {
    return {
      type: PAYMENT_UPDATE_DATA_CLUBUID,
      data:data
    };
  };
const paymentTotalMount = (data) => {
    return {
      type: PAYMENT_UPDATE_DATA_TOTALMOUN,
      data:data
    };
  };
  export const CreatePaymentChange = (data) => async (dispatch) => {
    dispatch(paymentChange(data))
    };
  export const CreatePaymentDuration = (data) => async (dispatch) => {
    dispatch(paymentDuration(data))
    };
  export const CreatePaymentDay = (data) => async (dispatch) => {
    dispatch(paymentDay(data))
    };
  export const CreatePaymentCourtKey = (data) => async (dispatch) => {
    dispatch(paymentCourtKey(data))
    };
  export const CreatePaymentClubUID = (data) => async (dispatch) => {
    dispatch(paymentClubUID(data))
    };
  export const CreatePaymentTotalMount = (data) => async (dispatch) => {
    dispatch(paymentTotalMount(data))
    };