export async function requestMachPayment(){

    const data = {
        payment: {
            amount: 500,
            message: "Test pago MACH",
            title: 'Evento tenis-up 2020',
            is_message_editable: false,
            is_amount_editable: false,
        }
    };

    const bearer_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJidXNpbmVzc01hY2hJZCI6ImMyMzg3NzAxLTRjNWUtNGNiZi05NWQ2LThlMDY5ZDA0ODQ0ZCIsImJ1c2luZXNzU2VjcmV0SWQiOiI5MmQzN2Q3ZS1hMmMxLTQ1YzQtYWFkZi05MTZhMDE5ZmYxYjYiLCJzY29wZXMiOlsicGF5bWVudHMuY3JlYXRlIiwicGF5bWVudHMuZ2V0Il0sImlhdCI6MTU4MjI0MjY3MH0.T3IxdaBbsl2vNuxER6kYVdyQXzooZXcKAjGT9ERBK5M';

    var bearer = 'Bearer ' + bearer_token;

    return fetch("https://rocky-journey-40182.herokuapp.com/https://biz-development.soymach.com/payments", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearer,
        },
        body: JSON.stringify(data),
    })
    .then(response => response.json())
}