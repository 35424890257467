import { useEffect, useMemo, useState } from 'react'
import { connect } from "react-redux";
import sponsorIMG from "../assets/carousel/18.png"
import { SRLWrapper } from 'simple-react-lightbox';
import moment from 'moment/min/moment-with-locales'
//Imagenes
import Loading from '../pages/Loading';
import { getSingleEvent } from '../actions/events';

function mapStateToProps(state) {
    return {
        isFetchingSingleEvent: state.events.isFetchingSingleEvent,
        singleEventFetched: state.events.singleEventFetched,
        fetchSingleEventError: state.events.fetchSingleEventError,
        event: state.events.event,
        eventId: state.header.eventId
    };
}

const BlogEventForm = (props) => {

    const { dispatch } = props
    const [fechaIn, setFechaIn] = useState("")
    const [fechaTer, setFechaTer] = useState("")
    const [abierta, setAbierta] = useState("")
    const [novicio, setNovicio] = useState("")
    const [segunda, setSegunda] = useState("")
    const [tercera, setTercera] = useState("")
    const [cuarta, setCuarta] = useState("")
    const [escalafon, setEscalafon] = useState("")
    const [honor, setHonor] = useState("")


    useEffect(() => {
        if (props.eventId) {
            dispatch(getSingleEvent(props.eventId));
        }
    }, [props.eventId, dispatch])


    const { nombre, region, ubicacion, lugar, fechaInicio, fechaTermino, inscritos, ganadores, galeria, sponsors } = props.event
    const { singleEventFetched } = props;

    //Fechas
    const duration = moment(fechaTer).diff(fechaIn, 'day')
    let dateinfo = moment(fechaIn).locale('es').format("[el cual se desarrolló entre el día] dddd DD [de] MMMM ") + moment(fechaTer).locale('es').format("[al día] dddd DD [de] MMMM [del año] YYYY. ")

    // Datos participantes
    const playersAbierta = abierta !== undefined && abierta !== null ? Object.values(abierta).length : 0
    const playersNovicio = novicio !== undefined && novicio !== null ? Object.values(novicio).length : 0
    const playersSegunda = segunda !== undefined && segunda !== null ? Object.values(segunda).length : 0
    const playersTercera = tercera !== undefined && tercera !== null ? Object.values(tercera).length : 0
    const playersCuarta = cuarta !== undefined && cuarta !== null ? Object.values(cuarta).length : 0
    const playersEscalafon = escalafon !== undefined && escalafon !== null ? Object.values(escalafon).length : 0
    const playersHonor = honor !== undefined && honor !== null ? Object.values(honor).length : 0
    const total = playersAbierta + playersNovicio + playersSegunda + playersTercera + playersCuarta + playersEscalafon + playersHonor

    //Imagenes
    const winners = useMemo(() => {
        let wins = ganadores !== undefined && ganadores !== null ? Object.values(ganadores) : []
        return wins
    }, [ganadores])
    const fotos = useMemo(() => {
        let pictures = galeria !== undefined && galeria !== null ? Object.values(galeria) : []
        return pictures
    }, [galeria])
    const sponsor = useMemo(() => {
        let sponsor = sponsors !== undefined && sponsors !== null ? Object.values(sponsors) : []
        return sponsor
    }, [sponsors])

    useEffect(() => {
        if (singleEventFetched) {
            setFechaIn(fechaInicio)
            setFechaTer(fechaTermino)
            setAbierta(inscritos?.Abierta || undefined)
            setNovicio(inscritos?.Novicio || undefined)
            setSegunda(inscritos?.["2°"] || undefined)
            setTercera(inscritos?.["3°"] || undefined)
            setCuarta(inscritos?.["4°"] || undefined)
            setEscalafon(inscritos?.["Escalafón"] || undefined)
            setHonor(inscritos?.Honor || undefined)
        }
    }, [singleEventFetched]) //eslint-disable-line



    return (
        <>
            {!singleEventFetched ? (
                <div
                    className="tw-relative tw-w-full tw-flex tw-items-center tw-justify-center tw-h-75"
                >
                    <Loading label="Cargando informacion" />
                </div>
            ) : (
                <div>
                    <div className="tw-w-full tw-h-1/2 tw-sticky">
                        <div
                            className="tw-w-full tw-flex tw-text-center tw-items-center tw-justify-center tw-relative"
                            style={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                        >
                            <img src={sponsorIMG} alt="1" className="tw-relative tw-w-full tw-h-screen-1/2 tw-object-cover" />
                            <div
                                className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-absolute tw-h-full tw-w-full"
                                style={{ background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35))' }}
                            >
                                <h1 className="tw-font-bitner tw-font-bold tw-text-white tw-z-10 tw-text-6xl">
                                    {nombre.toUpperCase()}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className='tw-flex tw-flex-1 tw-pl-8 tw-pr-8 tw-mt-4'>
                        {fotos.length > 0 && <div className='tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-items-end tw-mr-0 xs:tw-mr-8 tw-pb-8'>
                            <img alt="imagen principal" className='tw-object-contain tw-w-120 tw-h-auto xs:tw-h-120' src={fotos[0]?.imagen} />
                        </div>}
                        <div className='tw-flex tw-flex-1 tw-pr-4 tw-flex-col tw-justify-center'>
                            <p className="tw-font-objet tw-text-lg tw-font-bold">Este fue el {nombre} que fue realizado en la Región de {region}, {ubicacion} {dateinfo}
                                Este torneo tuvo un total de {total} participantes y fue llevado a cabo en el sector {lugar} teniendo una duración de {duration} dias. </p>
                        </div>
                    </div>
                    {winners?.length > 0 && <div>
                        <div className="tw-w-full tw-text-center tw-font-objet">
                            <h3 className="tw-text-xl tw-font-objet tw-font-bold">Ganadores</h3>
                        </div>
                        <SRLWrapper showDownloadButton={false}>
                            <div className="tw-w-full tw-max-w-screen-xl tw-h-1/2 tw-flex tw-flex-wrap tw-justify-center tw-m-auto">
                                {winners.map((data, i) => (
                                    <div key={"winner-" + i} className="tw-w-80 tw-h-90 tw-rounded-lg tw-shadow-md tw-overflow-hidden tw-m-5 tw-text-center tw-transition-all tw-ease-in-out tw-duration-200 hover:tw-scale-110">
                                        <img alt="abierta" className="tw-w-82 tw-h-58 tw-object-cover" src={data.imagen} />
                                        <h4 className="tw-font-objet tw-font-bold tw-pt-3 tw-pr-3 tw-pl-3 tw-pb-5">Ganador categoría {data.categoria}</h4>
                                        <p className="tw-font-objet tw-text-base">Ganador del {nombre} en la categoria {data.categoria}: <b>{data.nombre}</b></p>
                                    </div>
                                ))}
                            </div>
                        </SRLWrapper >
                    </div>}
                    {fotos?.length > 0 && <div>
                        <div className="tw-w-full tw-text-center tw-font-objet">
                            <h3 className="tw-text-xl tw-font-objet tw-font-bold">Imagenes del torneo</h3>
                        </div>
                        <SRLWrapper showDownloadButton={false}>
                            <div className="tw-mt-5 tw-flex tw-flex-row tw-justify-center tw-flex-wrap">
                                {fotos.map((data, i) => (
                                    <div key={"fotos-" + i} className="tw-m-3 tw-overflow-hidden tw-rounded-lg tw-transition-all tw-ease-in-out tw-duration-400 hover:tw-scale-110">
                                        <img className="tw-w-75 tw-h-75 tw-object-cover" alt="galeria" src={data.imagen} />
                                    </div>
                                ))}
                            </div>
                        </SRLWrapper>
                        <div className="tw-w-full tw-text-center tw-font-objet">
                            <h3 className="tw-text-xl tw-font-objet tw-font-bold">Auspiciadores</h3>
                        </div>
                        <div className="tw-mt-5 tw-flex tw-flex-row tw-justify-center tw-flex-wrap">
                            {sponsor.map((data, i) => (
                                <div key={"sponsor-" + i} className="tw-m-3">
                                    <img alt="sponsor" src={data.imagen} className="tw-w-28 tw-h-28 tw-rounded-lg tw-transition-all tw-ease-in-out tw-duration-400 tw-opacity-50 hover:tw-opacity-100 hover:tw-scale-95"/>
                                </div>
                            ))}
                        </div>
                    </div>}
                </div >
            )
            }
        </>
    );

}

export default connect(mapStateToProps)(BlogEventForm);