import { useState, useMemo, useEffect } from 'react'
import { connect, useDispatch } from "react-redux";
import Select from "./ui/Select"
import Input from "./ui/Input"
import foto from "../assets/perfil.png"
import Modal from "react-modal";
import styled from "styled-components";
import moment from "moment";
import SimpleUser from "./ui/SimpleUser";
import clubPhoto from "../assets/tennis-ball.png";
import { useNavigate } from "react-router-dom";

//styles
import "../styles/MyRents.css";
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { FaAlignJustify } from "react-icons/fa";
import { MdSearch } from 'react-icons/md';
import { getAllClubs } from '../actions/clubs';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Title from './ui/Title';


function mapStateToProps(state) {
    return {
        court: state.club.court,
        info: state.auth.info,
        clubes: state.club.clubs,
    };
}

const MyRents = (props) => {
    const navigate = useNavigate();
    const mobile = useMediaQuery("(max-width: 420px)");
    const { info, clubes } = props
    const dispatch = useDispatch();
    const [courtSelected, setCourtSelected] = useState('')
    const [showDetails, setShowDetails] = useState(false)
    const [selectedRents, setSelectedRents] = useState('')
    const { tipo_usuario, courts, arriendos } = info || {}
    const [filterForName, setFilterForName] = useState('')
    const [filterForClub, setFilterForClub] = useState(null)

    useEffect(() => {
        dispatch(getAllClubs())
    }, [dispatch])


    // const renderClubs = (() => {
    //     let clubRegister = clubes?.filter((club) => club?.settings)
    //     return clubRegister
    // })
    
    const renderClubs = useMemo(() => {
        return clubes
            ?.filter((club) => club?.settings)
            .map((club) => ({ key: club.uid, label: <SimpleUser imagen={club.settings.logo} nombre={club.settings.name} defaultImg={clubPhoto} />, value: club.uid }));
    }, [clubes]);

    const courtsClub = useMemo(() => {
        return (
            Object.values(courts || {}).map((court) => ({
                key: court.key,
                label: court.name,
                value: court.key,
            })) || []
        );
    }, [courts]);

    const userRentCourt = useMemo(() => {
        const mapping = tipo_usuario === 'club' ? arriendos?.[courtSelected.key] : arriendos
        let arrayRentCourt = []
        Object.entries(mapping || {}).forEach(([userKey, userValue]) => Object.entries(userValue).forEach(([transactionKey, transactionValue]) => {
            let transaction = transactionValue
            transactionValue.userUid = userKey
            transactionValue.transactionUid = transactionKey
            arrayRentCourt.push(transaction)
        }))
        return arrayRentCourt

    }, [arriendos, courtSelected, tipo_usuario])


    const rents = useMemo(() => {
        const nameFilter = filterForName?.toLowerCase() || "";
        if (!nameFilter && !filterForClub) return userRentCourt || [];

        const courtsFilters = () => {
            return userRentCourt?.filter((rent) => {
                return (
                    (!nameFilter ||
                        (tipo_usuario === "club" ? rent.arrendatario : rent.cancha)
                            ?.toLowerCase()
                            ?.includes(nameFilter)) &&
                    (!filterForClub || rent.club_uid === filterForClub?.key)
                );
            });
        };
        return courtsFilters();
    }, [filterForName, filterForClub, userRentCourt, tipo_usuario]);

    const renderModalDetails = () => {
        const inicio = moment(selectedRents?.horario?.inicio).format("h:mm A")
        const fin = moment(selectedRents?.horario?.fin).format("h:mm A")
        const fecha = moment(selectedRents?.fecha).format("DD/MM/YYYY")
        return (
            <Modal
                isOpen={showDetails}
                style={!mobile ? customStylesDetails : ModalMobile}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => setShowDetails(false)}
            >
                <div>
                    <div className="details-myrents">
                        <h1>Detalle de arriendo</h1>
                    </div>
                    <div className="details-content">
                        {tipo_usuario === "club" && <p>Nombre del arrendatario: <b>{selectedRents.arrendatario}</b> </p>}
                        <p>Nombre de la cancha: <b>{selectedRents.cancha}</b> </p>
                        <p>Fecha seleccionada:  <b>{fecha}</b></p>
                        <p>Horario seleccionado: <b>De {inicio} a las {fin}</b></p>
                        {tipo_usuario !== "club" && <p>Lugar: <b>{selectedRents.lugar}</b></p>}
                        <p>Método de pago: <b>{selectedRents.payment_method}</b> </p>
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "10px", marginTop: 15 }}><Button1 onClick={() => setShowDetails(false)}>Volver</Button1></div>
                </div>
            </Modal>
        )
    }

    const handleDetails = (rent) => {
        setSelectedRents(rent)
        setShowDetails(true)
    }

    const renderDates = (date) => {
        return moment(date).format("DD/MM/YYYY")
    }

    function goBack() {
        navigate("/explore");
    }

    const myRents = () => {
        if (tipo_usuario !== "club") {
            return (
                <div className="main-container-activado" style={{ backgroundColor: "white" }}>
                    <div style={{ position: "relative" }} className="scroll">
                        <div
                            style={{
                                paddingInline: mobile && 12,
                                width: "100%",
                            }}
                        >
                            <Title
                                goBack={goBack}
                                title="Mis Arriendos"
                                containerStyles={{
                                    fontSize: 32,
                                    zIndex: mobile && 10,
                                    backgroundColor: "white",
                                    height: mobile ? 70 : 60,
                                    position: mobile && "sticky",
                                    top: mobile && 0,
                                    paddingBottom: mobile && 10,
                                }}
                            />
                            <p className='title-myrents'>Aca podras revisar tu historial de arriendos</p>
                        </div>
                        <div className='tw-flex tw-flex-col tw-mt-5 tw-gap-0 tw-ml-4 xs:tw-ml-0 xs:tw-flex-row xs:tw-gap-5'>
                            <div style={{ width: "none" }}>
                                <Input
                                    full={mobile}
                                    value={filterForName}
                                    placeholder='Filtrar por nombre'
                                    onChange={(event) => setFilterForName(event.target.value)}
                                    Icon={
                                        <MdSearch color="#C9C9E3" size="20" style={{ marginLeft: 10 }} />
                                    }
                                    containerStyles={{ width: 300 }}
                                />
                            </div>
                            <div style={{ maxWidth: !mobile && 220, width: "100%" }}>
                                <Select
                                    value={filterForClub}
                                    placeholder='Selecciona un club'
                                    onChange={(event) => setFilterForClub(event)}
                                    options={renderClubs}
                                    containerStyles={{ width: 300 }}
                                    isClearable
                                />
                            </div>
                        </div>
                        <div className="scroll">
                            <Table aria-label="simple table" style={{ borderCollapse: "collapse" }}>
                                <TableHead className="Inscritos-header">
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell />
                                        <TableCell>Nombre de la cancha</TableCell>
                                        <TableCell>Club</TableCell>
                                        <TableCell>Fecha de Arriendo</TableCell>
                                        <TableCell>Método de pago</TableCell>
                                        <TableCell>Ver Detalles</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="Inscritos-body">
                                    {rents.map((rent, index) => (
                                        <TableRow key={'row' + index}>
                                            <TableCell width={36} >{index + 1}</TableCell>
                                            <TableCell width={36} style={{ padding: 0 }}>
                                                <img
                                                    alt='imagen user'
                                                    className='user-img'
                                                    src={rent.imagen ? rent.imagen : foto}
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope='row'>
                                                <div>{rent.cancha}</div>
                                            </TableCell>
                                            <TableCell>{rent.club}</TableCell>
                                            <TableCell>{renderDates(rent.fecha)}</TableCell>
                                            <TableCell>
                                                <ColorTypePayment payment={rent.payment_method} />
                                            </TableCell>
                                            <TableCell>
                                                <FaAlignJustify onClick={() => handleDetails(rent)} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            )
        }
        if (tipo_usuario === 'club') {
            return (
                <div className="main-container-activado" style={{ backgroundColor: "white" }}>
                    <div style={{ position: "relative" }} className="scroll">
                        <div>
                            <div
                                style={{
                                    paddingInline: mobile && 12,
                                    width: "100%",
                                }}
                            >
                                <Title
                                    goBack={goBack}
                                    title="Mis Arriendos"
                                    containerStyles={{
                                        fontSize: 32,
                                        zIndex: mobile && 10,
                                        backgroundColor: "white",
                                        height: mobile ? 70 : 60,
                                        position: mobile && "sticky",
                                        top: mobile && 0,
                                        paddingBottom: mobile && 10,
                                    }}
                                />
                                <p className='title-myrents'>Selecciona una cancha para ver sus arriendos</p>
                            </div>
                            <div className='tw-flex tw-flex-col tw-mt-5 tw-gap-0 tw-ml-4 xs:tw-ml-0 xs:tw-flex-row xs:tw-gap-5'>
                                <div style={{ width: "none" }}>
                                    <Input
                                        full={mobile}
                                        value={filterForName}
                                        placeholder='Filtrar por nombre'
                                        onChange={(event) => setFilterForName(event.target.value)}
                                        Icon={
                                            <MdSearch color="#C9C9E3" size="20" style={{ marginLeft: 10 }} />
                                        }
                                        containerStyles={{ width: 300 }}
                                    />
                                </div>
                                <div style={{ maxWidth: !mobile && 220, width: "100%" }}>
                                    <Select
                                        value={courtSelected}
                                        placeholder='Selecciona una cancha'
                                        onChange={(event) => setCourtSelected(event)}
                                        options={courtsClub}
                                        containerStyles={{ width: 300 }}
                                    />
                                </div>
                            </div>
                            <div className="scroll">
                                <Table aria-label="simple table" style={{ borderCollapse: "collapse" }}>
                                    <TableHead className="Inscritos-header">
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell />
                                            <TableCell>Arrendatario</TableCell>
                                            <TableCell>Fecha de Arriendo</TableCell>
                                            <TableCell>Duración</TableCell>
                                            <TableCell>Método de pago</TableCell>
                                            <TableCell>Ver Detalles</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="Inscritos-body">
                                        {rents.map((rent, index) => (
                                            <TableRow key={'row' + index}>
                                                <TableCell width={36} >{index + 1}</TableCell>
                                                <TableCell width={36} style={{ padding: 0 }}>
                                                    <img
                                                        alt='imagen user'
                                                        className='user-img'
                                                        src={rent.imagen ? rent.imagen : foto}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope='row'>
                                                    <div>{rent.arrendatario}</div>
                                                </TableCell>
                                                <TableCell>{moment(rent.fecha).format("DD/MM/YYYY")}</TableCell>
                                                <TableCell>{rent.duracion}</TableCell>
                                                <TableCell>
                                                    <ColorTypePayment payment={rent.payment_method} />
                                                </TableCell>
                                                <TableCell>
                                                    <FaAlignJustify onClick={() => handleDetails(rent)} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            {myRents()}
            {renderModalDetails()}
        </>
    )

}


const ColorTypePayment = (props) => {
    const { payment } = props;
    const color = (payment) => {
        const colores = {
            webpay: "#ae81ff",
            efectivo: "#66d9ef",
        };
        return colores[payment] || "gray";
    };
    return (
        <div className="Inscritos-paymenttype" style={{ color: color(payment) }}>
            {payment}
        </div>
    );
};

const customStylesDetails = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "none",
        height: "50%",
        width: "30%"
    },
    overlay: {
        backgroundColor: "rgba(0,0,0,0.75)",
    },
};

const ModalMobile = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        border: "none",
        width: "100vw",
        height: "100%",
        position: "fixed",
    },
    overlay: {
        backgroundColor: "rgba(0,0,0,0.75)",
        zIndex: 12,
    },
};

const Button1 = styled.button`
  position: relative;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background-color: #dcec00;
  color: #2085b7;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  width: 150px;
  font-size: 14px;
  cursor: pointer;
`;


export default connect(mapStateToProps)(MyRents)