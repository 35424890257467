import { Link } from "react-router-dom";
import img1 from "../../assets/Sad.png";
import "../../styles/Error.css";

function Error(props) {
  const { message } = props;
  return (
    <div className="error">
      <img src={img1} alt="ERROR" className="image-error"></img>
      <h1>{message}</h1>

      <Link to="/">Haz click aquí para volver al inicio</Link>
    </div>
  );
}

export default Error;
