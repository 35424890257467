import { render } from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";

import configureStore from "./store/store";
import SimpleReactLightbox from "simple-react-lightbox";
import UserProvider from "./context/UserContext";

const store = configureStore();

render(
  <Provider store={store}>
    <UserProvider>
      <SimpleReactLightbox>
        <App />
      </SimpleReactLightbox>
    </UserProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
