import { Component } from 'react'
import Inscripcion from '../components/inscripcion/Incripcion'

import styled from 'styled-components'

export default class InscriptionPage extends Component {
  render() {
    return (
      <Container>
          <Inscripcion/>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #efeff7;

  @media (max-width: 902px) {
    height: 100%;
}
`
