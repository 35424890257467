import { forwardRef } from 'react';
import Input from './Input';

const NumberInput = forwardRef(
  (
    {
      decimal = false,
      name,
      placeholder = '',
      value,
      label,
      error,
      onChange,
      type = 'text',
      Icon,
      full = true,
      containerStyles = {},
      ...props
    },
    ref
  ) => {
    const onKeyPress = (event) => {
      if (decimal) {
        const theEvent = event || window.event;
        let key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);

        if (key === '.' || key === ',') key = '.0';
        const value = Number(event.target.value) + Number(key);
        const regex = new RegExp(
          '^[+-]?([0-9]+\\.?[0-9]{0,' +
            decimal +
            '}|\\.[0-9]{0,' +
            decimal +
            '})$'
        ); // Decimal
        if (regex.test(String(value))) return;

        theEvent.returnValue = false;

        if (theEvent.preventDefault) {
          theEvent.preventDefault();
        }

        return;
      }

      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    };

    const onPaste = (event) => {
      const pastedValue = event.clipboardData.getData('Text');

      if (isNaN(+pastedValue)) {
        event.preventDefault();
      }

      return false;
    };
    const onBlurCapture = (e) => {
      if (decimal) {
        const regex = /^[+-]?([0-9]+\.)$/; // Decimal
        let tempValue = e.target.value || '';
        tempValue = tempValue.replace(',', '.');
        if (regex.test(String(tempValue))) {
          tempValue = tempValue.replace('.', '');
        }
        tempValue = parseFloat(tempValue || 0).toFixed(decimal);
        if (e.target.value !== tempValue) {
          e.target.value = tempValue;
          onChange?.(e);
        }
      }
    };
    return (
      <Input
        name={name}
        placeholder={placeholder}
        value={value}
        label={label}
        error={error}
        onChange={onChange}
        Icon={Icon}
        full={full}
        containerStyles={containerStyles}
        {...props}
        onKeyPress={onKeyPress}
        onPaste={onPaste}
        onBlurCapture={onBlurCapture}
        ref={ref}
      />
    );
  }
);
export default NumberInput;
