import { MdEdit} from 'react-icons/md';
import ticket from "../../assets/ticket-tenisup.png";
import ticket2 from "../../assets/ticket-asociasion.png";
import perfil from "../../assets/perfil.png";
const TableMobileRank = ({
    player,
    filterType,
    setShowEditRank,
    setSelectedUser,
    tipo_usuario

}) => {
    const { nombre, categoria, pais, imagen_100x100,
        imagen_200x200,
        imagen_400x400,
        imagen_800x800,
        imagen, } = player
    const finalImage = imagen_100x100 || imagen_200x200 || imagen_400x400 || imagen_800x800 || imagen;
    const points = () => {
        if (filterType === player.tipoRankingTenisUp) {
            return (
                <>{player.puntajeTenisUp}</>
            )
        }
        if (filterType === player.tipoRankingAsociacion)
            return (
                <>{player.puntajeAsociacion}</>
            )
    }

    const position = () => {
        if (filterType === player.tipoRankingTenisUp) {
            return (
                <>{player.posicionTenisUp}</>
            )
        }
        if (filterType === player.tipoRankingAsociacion)
            return (
                <>{player.posicionAsociacion}</>
            )

    }

    const imgTypeRanking = () => {
        if (filterType === player.tipoRankingTenisUp) {
            return (
                <img width={50} style={{ maxWidth: "none" }} alt="TenisUp" src={ticket} />
            )
        }
        if (filterType === player.tipoRankingAsociacion)
            return (
                <img width={50} style={{ maxWidth: "none" }} alt="TenisUp" src={ticket2} />
            )
    }
    return (
        <>
            <div>
                <div style={{ fontSize: 20 }}>{nombre}</div>
            </div>
            <div
                style={{
                    display: "grid",
                    alignItems: "center",
                    gap: 10,
                    gridTemplateColumns: "105px auto 55px",
                }}
            >
                <div>
                    <div style={{ position: "relative", width: "fit-content" }}>
                        <img
                            src={finalImage || perfil}
                            style={{ height: 100, width: 100 }}
                            className="user-img"
                            alt="Imagen de perfil"
                        />
                        <div
                            className="jugadores-tabla-item-column"
                            style={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "#0000008f",
                                borderRadius: 13,
                                width: 25,
                                height: 25,
                                justifyContent: "center",
                                color: "white",
                            }}
                        >
                            <div>{position()}</div>
                        </div>
                    </div>
                    <div></div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div> <strong>{pais}</strong>
                    </div>
                    <div>
                        <strong>Categoría: </strong> {categoria}
                    </div>
                    <div>
                        <strong>Puntaje: </strong>{points()}
                    </div>
                    <div>
                        <strong>Tipo Ranking: </strong> {imgTypeRanking()}
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: 15, alignItems: "center" }}>
                    {(tipo_usuario === "admin") && <div className="jugadores-tabla-item-column">
                        <div>
                            <MdEdit
                                color="#333"
                                size="20"
                                onClick={() => { setShowEditRank(true); setSelectedUser(player); }}
                            />
                        </div>
                    </div>}
                </div>
            </div >
        </>
    )
}

export default TableMobileRank;