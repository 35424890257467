import Modal from "react-modal";
import { reportSyles } from "../events/styles/reportStyle";

export default function AlertErrorList({ list, clean, setIsOpenAlert, modalIsOpenAlert }) {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const styles = reportSyles();

  function closeModal() {
    setIsOpenAlert(false);
    clean(true);
  }
  return (
    <div>
      <Modal
        style={{
          overlay: {
            backgroundColor: "rgba(20,20,20,0.4)",
          },
        }}
        isOpen={modalIsOpenAlert}
        className={styles.content}
        contentLabel="Example Modal"
      >
        <div className={styles.cont_tittle}>
          <h6 style={{ color: "rgb(255,255,255)" }}>Error en los siguientes campos</h6>
        </div>
        <ul>
          {list.map((item) => {
            return (
              <>
                <div className={styles.item}>
                  <li>El Campo "{item}" no puede ir vacio</li>
                </div>
              </>
            );
          })}
        </ul>
        <div className={styles.container_button}>
          <button className={styles.button} onClick={closeModal}>
            Volver
          </button>
        </div>
      </Modal>
    </div>
  );
}
