import classNames from 'classnames';
import { forwardRef } from 'react';
import { BsQuestionSquare } from 'react-icons/bs';
import '../../styles/Input.css';

const Input = forwardRef(
  (
    {
      name,
      placeholder = '',
      value,
      label,
      error,
      onChange,
      onKeyPress,
      onPaste,
      onBlurCapture,
      type = 'text',
      Icon,
      full = true,
      containerStyles = {},
      containerClass = '',
      inputClass = '',
      inputStyles = {},
      labelStyles = {},
      containerStylesOff = false,
      inputStylesOff = false,
      isHelp,
      help,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={classNames(
          {
            'custom-input-container': !containerStylesOff,
            'w-full': full,
            'tw-w-auto': !full,
            'tw-pb-3': !error,
          },
          containerClass
        )}
        style={{
          ...containerStyles,
        }}
      >
        <label className="custom-input-label" style={labelStyles}>
          <div className="tw-flex tw-flex-row tw-gap-2">
            {label}
            {isHelp && (
              <BsQuestionSquare size={20} className="tw-pt-1" title={help} />
            )}
          </div>
          <div style={{ position: 'absolute', top: label ? 40 : 10, left: 0 }}>
            {Icon}
          </div>
          <input
            style={{
              marginTop: label ? '0.5rem' : 0,
              paddingLeft: Icon ? 40 : 10,
              ...inputStyles,
            }}
            className={classNames(
              "focus-visible:tw-outline-none",
              {
                'custom-input-error': error,
                'custom-input tw-font-sans tw-p-2 tw-flex tw-border tw-border-solid tw-border-slate-700 tw-rounded tw-text-sm tw-w-full':
                  !error && !inputStylesOff,
              },
              'tw-bg-white',
              inputClass
            )}
            placeholder={placeholder}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            onKeyPress={onKeyPress}
            onPaste={onPaste}
            onBlurCapture={onBlurCapture}
            {...props}
            ref={ref}
          />
        </label>
        {error && <span className="custom-input-errorMessage">{error}</span>}
      </div>
    );
  }
);

export default Input;
