import { useEffect, useState, Fragment } from "react";

//components
import QRCode from "qrcode.react";

//styles
import styled from "styled-components";
import Mach from "../icons/Mach";

//redux
import { connect } from "react-redux";
import { payWithMach } from "../../actions/mach";
import { checkIfMobile } from "../../services/Utils";
function mapStateToProps(state) {
  return {
    isProcessingPayment: state.mach.isProcessingPayment,
    paymentReceived: state.mach.paymentReceived,
    paymentError: state.mach.paymentError,
    payment: state.mach.payment,
  };
}

const MachDetails = (props) => {
  const [mode, setMode] = useState("desktop");

  //get payment order
  const { dispatch } = props;
  useEffect(() => {
    dispatch(payWithMach());
  }, [dispatch]);

  //once the payment order is ready
  const { paymentReceived, payment, isProcessingPayment } = props;
  useEffect(() => {
    if (paymentReceived && !isProcessingPayment) {
      let mobile = checkIfMobile();
      if (mobile) {
        setMode("mobile");
      }
    }
  }, [paymentReceived, isProcessingPayment, payment]);

  //action
  const renderAction = () => {
    if (mode === "mobile") {
      return (
        <Button>
          <ButtonText href={props.payment.url}>Ir a app mach</ButtonText>
        </Button>
      );
    } else {
      return (
        <Fragment>
          <Title>
            Escanea el siguiente código QR con tu App{" "}
            <div styles={{ width: 92, marginLeft: 8 }}>
              <Mach />
            </div>
          </Title>
          <QRCode value={props.payment.url} size={200} style={{ marginBottom: "20px" }} />
        </Fragment>
      );
    }
  };

  //render
  if (props.isProcessingPayment) {
    return <Container />;
  } else if (props.payment != null) {
    return <Container>{renderAction()}</Container>;
  } else {
    return <div />;
  }
};

export default connect(mapStateToProps)(MachDetails);

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top 50px;
`;

const Title = styled.h2`
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin: 0;
  padding-bottom: 35px;
`;

const Button = styled.div`
  height: 80px;
  width: 200px;
  background-color: #5a5fe8;
  border-radius: 8px;
`;

const ButtonText = styled.a`
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #fff;
`;
