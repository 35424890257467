import { colors } from '../costants/colors';

const Edit = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="19" viewBox="0 0 17 17" fill="none">
      <path
        fill={colors.Verde.hover}
        d="M16.8.2a.6.6 0 00-.9 0l-.5.5a.3.3 0 000 .4l.5.5a.3.3 0 00.4 0l.5-.5c.2-.3.3-.6 0-.9zm-2.3 1.4L7.4 8.7v.2l-.4 1a.2.2 0 00.2.1l1-.3.1-.1 7-7a.4.4 0 000-.6l-.3-.4a.4.4 0 00-.5 0z"
      ></path>
      <path
        fill={colors.Verde.hover}
        d="M14 5.7l-4.8 4.8-.7.4-1 .3a1.4 1.4 0 01-1.7-1.7l.3-1c0-.3.2-.5.4-.7L11.3 3a.3.3 0 00-.2-.5H2.9A2.2 2.2 0 00.7 4.7v9.4A2.2 2.2 0 003 16.3h9.4a2.2 2.2 0 002.2-2.2V5.9a.3.3 0 00-.5-.2z"
      ></path>
    </svg>
  );
}

export default Edit
