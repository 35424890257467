import { useMemo } from "react";

const useCategoria = ({ cupos }) => {
    const getCategorias = useMemo(() => {
        if (!cupos) return [];
        return Object.keys(cupos || {}).filter((categoria) => categoria.toLowerCase() !== "total");
      }, [cupos]);
  return { getCategorias };
};

export default useCategoria;
