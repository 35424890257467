import { memo, useEffect, useState } from 'react';
import classNames from 'classnames';

import ReactSelect, { components } from 'react-select';
import { BsQuestionSquare } from 'react-icons/bs';
const controlStyles = {
  width: '100%',
};

const ControlComponent = (props) => (
  <div style={controlStyles} className="focus-visible:tw-border-none">
    <components.Control
      className="focus-visible:tw-border-0"
      style={{ border: 'none' }}
      {...props}
    />
  </div>
);
const MenuListComponent = (props) => (
  <components.MenuList {...props} className="scroll" />
);
const customStyles = {
  control: (base) => ({
    ...base,
    backgroundColor: 'rgba(255,5,5,0)',
    border: 0,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};
const Select = ({
  menuPlacement = 'bottom',
  name,
  placeholder = '',
  value = '',
  label,
  error,
  onChange,
  isClearable,
  isSearchable = false,
  options = [],
  single = false,
  isDisabled,
  full = true,
  containerStyles = {},
  containerClass = '',
  selectClass = '',
  selectStylesOff,
  containerStylesOff,
  isHelp,
  help,
  ...props
}) => {
  const [selectOption, setSelectOption] = useState(null);
  useEffect(() => {
    if (options) {
      const op =
        options.find((o) => {
          return (
            (o.key !== undefined &&
              value?.key !== undefined &&
              o.key === value?.key) ||
            o.value === value ||
            o === value
          );
        }) || null;
      setSelectOption(op);
    }
  }, [value, options]);
  const changeValue = (v) => {
    if (onChange) onChange(v, name);
    else setSelectOption(v);
  };
  return (
    <div
      className={classNames(
        {
          'custom-input-container': !containerStylesOff,
          'w-full': full,
          'tw-w-auto': !full,
          'tw-pb-3': !error,
        },
        containerClass
      )}
      style={{
        ...containerStyles,
      }}
    >
      {label && (
        <label
          className={
            isHelp
              ? 'custom-input-label tw-flex tw-flex-row tw-gap-2'
              : 'custom-input-label'
          }
        >
          {label}
          {isHelp && (
            <BsQuestionSquare size={20} className="tw-pt-1" title={help} />
          )}
        </label>
      )}
      {single ? (
        <select
          {...props}
          className={error ? 'custom-select-error' : 'custom-select-t'}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={changeValue}
        >
          {options.map((mat) => (
            <option key={mat.key} value={mat.value}>
              {mat.label}
            </option>
          ))}
        </select>
      ) : (
        <ReactSelect
          isDisabled={isDisabled}
          isClearable={isClearable}
          components={{
            Control: ControlComponent,
            MenuList: MenuListComponent,
          }}
          isSearchable={isSearchable}
          name={name}
          value={selectOption}
          className={classNames(
            { 'custom-select-t': !selectStylesOff },
            selectClass,
            {
              'custom-select-error': error,
            }
          )}
          options={options}
          onChange={changeValue}
          placeholder={placeholder}
          menuPlacement={menuPlacement}
          menuPortalTarget={document.body}
          styles={customStyles}
          menuPosition={'fixed'}
        />
      )}
      {error && <span className="custom-input-errorMessage">{error}</span>}
    </div>
  );
};
export default memo(Select);
