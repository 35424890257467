
export const IS_USER = "IS_USER";
export const IS_CLUB = "IS_CLUB";
export const IS_ADMIN = "IS_ADMIN";
export const IS_SUPER_ADMIN = "IS_SUPER_ADMIN";


export const CAN_READ_CLUB = "CAN_READ_CLUB";

//Privilegios
const isUser = () => {
  return {
    type: IS_USER,
  };
};
const isClub = () => {
  return {
    type: IS_CLUB,
  };
};
const isAdmin = () => {
  return {
    type: IS_ADMIN,
  };
};

const isSuperAdmin = () => {
  return {
    type: IS_SUPER_ADMIN,
  };
};

export const getPrivilegios = tipo => dispatch => {
  switch(tipo){
    case 0:
      dispatch(isUser());
      break;
    case 1:
      dispatch(isClub());
      break;
    case 2:
      dispatch(isAdmin());
      break;
    case 3:
      dispatch(isSuperAdmin());
      break;
    default:
      dispatch(isUser());
  }
};

const CargarClubs = () => {
  return {
    type: CAN_READ_CLUB,
  };
};

//FUNCION

export const canReadClub = (dispatch) => {
  //Aqui se hace el return a la pag club? o arriba
  dispatch(CargarClubs());

  
};