import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { off, onValue, ref, update } from "firebase/database";
import { auth, db } from "../firebase/firebase";
import { changeLoginOpen } from "./header";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

export const SET_USER = "SET_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGIN_CLUB_SUCCESS = "LOGIN_CLUB_SUCCESS";
export const LOGIN_CLUB_FAILURE = "LOGIN_CLUB_FAILURE";

export const EDIT_EVENT_PASIVE = "EDIT_EVENT_PASIVE";
export const EDIT_EVENT_ACTIVE = "EDIT_EVENT_ACTIVE";

export const EDIT_CLUB_PASIVE = "EDIT_CLUB_PASIVE";
export const EDIT_CLUB_ACTIVE = "EDIT_CLUB_ACTIVE";

//Prueba
export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";

//OTRA PRUEBA
export const RECOVER_REQUEST = "RECOVER_REQUEST";

//Registro de Usuario
export const REGISTER_USER_REQUEST = "REGISTER_CLUB_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";

//Actualizar datos Usuario
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

//Registro de Usuario
const requestRegisterUser = () => {
  return {
    type: REGISTER_USER_REQUEST,
  };
};

const recieveRegisterUser = () => {
  return {
    type: REGISTER_USER_SUCCESS,
  };
};

const errorRegisterUser = (error) => {
  return {
    type: REGISTER_USER_FAILURE,
    error,
  };
};

//Actualizar datos Usuario
const requestUpdateUser = () => {
  return {
    type: UPDATE_USER_REQUEST,
  };
};

const receiveUpdateUser = () => {
  return {
    type: UPDATE_USER_SUCCESS,
  };
};

const errorUpdateUser = () => {
  return {
    type: UPDATE_USER_FAILURE,
  };
};

const requestRecoverPassword = () => {
  return {
    type: RECOVER_REQUEST,
  };
};

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const receiveLogin = (user) => {
  return {
    type: LOGIN_SUCCESS,
    user,
  };
};

const loginError = (error) => {
  return {
    type: LOGIN_FAILURE,
    error,
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE,
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST,
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS,
  };
};

const offEditingEvent = () => {
  return {
    type: EDIT_EVENT_PASIVE,
  };
};

const onEditingEvent = () => {
  return {
    type: EDIT_EVENT_ACTIVE,
  };
};

const offEditingClub = () => {
  return {
    type: EDIT_CLUB_PASIVE,
  };
};

const onEditingClub = () => {
  return {
    type: EDIT_CLUB_ACTIVE,
  };
};

const setUserData = (info) => {
  return {
    type: SET_USER,
    info: info,
  };
};

const loginUserSuccess = () => {
  return {
    type: LOGIN_USER_SUCCESS,
  };
};

const loginClubSuccess = () => {
  return {
    type: LOGIN_CLUB_SUCCESS,
  };
};
const loginUserFailure = () => {
  return {
    type: LOGIN_USER_FAILURE,
  };
};
const loginClubFailure = () => {
  return {
    type: LOGIN_CLUB_FAILURE,
  };
};

export const registerUser = (user, data) => async (dispatch) => {
  dispatch(requestRegisterUser());
  try {
    createUserWithEmailAndPassword(auth, user.email, user.password)
      .then(async (userCredential) => {
        let user = userCredential.user;
        let uid = user.uid;

        // await sendEmailVerification(user.email);
        const refDb = ref(db, "users/" + uid);
        update(refDb, { ...data, uid: user.uid }).then(() => {
          dispatch(getSingleUser(user.uid));
          dispatch(recieveRegisterUser());
          dispatch(receiveUpdateUser());
          dispatch(changeLoginOpen(false));
        });
      })
      .catch((error) => {
        dispatch(errorRegisterUser(error));
      });
  } catch (error) {
    dispatch(errorRegisterUser(error));
  }
};

export const setUser = (data) => async (dispatch) => {
  dispatch(requestUpdateUser());
  let uid = auth.currentUser.uid;
  try {
    update("users/" + uid, data).then(() => {
      dispatch(receiveUpdateUser());
    });
  } catch (error) {
    console.log(error);
    dispatch(errorUpdateUser);
  }
};

export const loginUser = (email, password, data) => (dispatch) => {
  dispatch(requestLogin());
  signInWithEmailAndPassword(auth, email, password)
    .then(({ user }) => {
      const refDb = ref(db, "users/" + user.uid);

      const ongetvalue = (snapshot) => {
        const userdata = snapshot.val();
        if (!userdata?.uid && userdata?.preferencias === "tenista") {
          update(refDb, { ...data, uid: user.uid });
        }
      };

      onValue(refDb, ongetvalue, {
        onlyOnce: true,
      });
      dispatch(receiveLogin(user));
      dispatch(changeLoginOpen(false));
    })
    .catch((error) => {
      dispatch(loginError(error));
    });
};

export const logoutUser = () => (dispatch) => {
  dispatch(requestLogout());
  signOut(auth)
    .then(() => {
      dispatch(receiveLogout());
      console.log("success");
    })
    .catch((error) => {
      dispatch(logoutError());
      console.log([error, "failed"]);
    });
};

//Recuperación de contraseña
export const recoverPassword = (email) => async (dispatch) => {
  dispatch(requestRecoverPassword());
  return await sendPasswordResetEmail(auth, email);
};

export const verifyAuth = () => (dispatch) => {
  dispatch(verifyRequest());
  onAuthStateChanged(auth, (user) => {
    if (user !== null) {
      //TODO: Fetch user settings
      dispatch(receiveLogin(user));
    }
    dispatch(verifySuccess());
  });
};

export const getSingleClub = (uid) => (dispatch) => {
  const refDb = ref(db, `clubes/${uid}`);

  onValue(refDb, (snapshot) => {
    if (snapshot.exists()) {
      dispatch(setUserData(snapshot.val(), "club"));
      dispatch(loginClubSuccess());
    } else {
      dispatch(loginClubFailure());
    }
  });
};

export const getSingleUser = (uid) => (dispatch) => {
  const refDb = ref(db, `users/${uid}`);

  onValue(refDb, (snapshot) => {
    if (!auth.currentUser) {
      off(refDb);
      return;
    }
    if (snapshot.exists()) {
      dispatch(setUserData(snapshot.val(), "player"));
      dispatch(loginUserSuccess());
    } else {
      dispatch(loginUserFailure());
    }
  });
};

export const isEditingEvent = (edit_state) => (dispatch) => {
  if (edit_state === true) {
    dispatch(onEditingEvent());
  } else {
    dispatch(offEditingEvent());
  }
};

export const isEditingClub = (edit_state) => (dispatch) => {
  if (edit_state === true) {
    dispatch(onEditingClub());
  } else {
    dispatch(offEditingClub());
  }
};
