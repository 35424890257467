import classNames from "classnames";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import desplegableImg from "../assets/desplegable.png";
const DespleableBtn = ({ classNameContainer = "", right = true }) => {
  return (
    <div
      className={classNames(classNameContainer, "tw-w-10 tw-flex tw-items-center tw-justify-start tw-cursor-pointer")}
    >
      <img src={desplegableImg} alt="desplegableImg"></img>
      {right ? (
        <FaChevronRight size={50} className="tw-fill-white tw-w-5 tw-absolute" />
      ) : (
        <FaChevronLeft size={50} className="tw-fill-white tw-w-5 tw-absolute" />
      )}
    </div>
  );
};

export default DespleableBtn;
