import styled from "styled-components";

export const SportIconContainer = styled.div`
  cursor: pointer;
  background-color: #dcec00;
  border-radius: 100%;
  padding: 12px;
  width: fit-content;
  transition: all 200ms;
  &:hover {
    transform: scale(1.5) rotate(-20deg);
    background-color: #eeff00;
  }
`;
export const SportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding: 40px;
`;
export const SportcloseContainer = styled.div`
  position: absolute;
  background-color: #2085b7;
  border-radius: 100%;
  left: 20px;
  padding: 10px;
  top: 30px;
`;
