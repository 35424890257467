import React, { useState } from 'react';
import Input from '../ui/Input';
import Select from '../ui/Select';
import swal from "@sweetalert/with-react";
import { editIMG } from '../../actions/events';
import toast from "react-hot-toast";
import defaultImage from "../../assets/tennis-ball.png"
import { RiImageAddLine } from "react-icons/ri";
import { useDispatch } from "react-redux";

const EditModal = ({
    setShowEdit,
    selectType,
    id,
    mode,
    data,
    categorias,
    isWinner
}) => {
    let dispatch = useDispatch();
    const { nombre, categoria, imagen } = data
    const [name, setName] = useState(nombre)
    const [cat, setCat] = useState(categoria)
    const [newImagen, setNewImagen] = useState(imagen);

    const handleChangePicture = async (event) => {
        event.preventDefault();
        if (event.target?.files.length === 1) {
            const file = event.target?.files[0];
            const fileReader = new window.FileReader();
            const filename = file?.name;
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                setNewImagen({
                    file: fileReader.result,
                    filename: filename,
                    blob: file,
                });
            };
        }
    };

    const editImg = async () => {
        let data = {
            imagen: newImagen || null,
            nombre: name || null,
            categoria: cat || null
        }
        const { error } = await dispatch(editIMG(selectType, id, mode, data))
        if (!error) {
            setShowEdit(false)
            swal({
                title: "Se editó la imagen correctamente",
                text: "La imagen se editó correctamente del torneo",
                icon: "success",
                button: {
                    text: "Continuar",
                    value: "done",
                },
            });
        } else {
            toast.error(error)
        }
    }

    return (
        <div className='col mt-4 tw-w-full xs:tw-w-120'>
            <h1 className='tw-text-Turquesa tw-font-bold tw-w-full tw-text-lg'>Edita los campos</h1>
            <div>
                <label className="label-upload">Foto Subida</label>
                <img src={imagen} className="img tw-border-solid tw-border tw-border-gray-400" alt="Imagen de torneo" />
            </div>
            <div>
                <label className="label-upload">Foto Subida</label>
                <img src={newImagen?.file || defaultImage} className="img tw-border-solid tw-border tw-border-gray-400" alt="Imagen de torneo" />
                <input
                    type="file"
                    id="add-new"
                    className="input-upload"
                    required
                    accept="image/png, .jpeg, .jpg"
                    onChange={handleChangePicture}
                />
                <label htmlFor="add-new" className="input-img tw-mt-4" style={{ backgroundColor: "#E5E5E5" }}>
                    <RiImageAddLine style={{ margin: 5 }} size="20" />
                    Selecciona una Imagen
                </label>
            </div>
            {isWinner && <div className='tw-flex tw-flex-col'>
                <Input
                    value={name}
                    onChange={(e) => { setName(e.target.value) }}
                    label={'Nombre del participante: '}
                />
                <Select
                    label="Categoria del ganador:"
                    placeholder="Selecciona una categoria"
                    onChange={({ value }) => setCat(value)}
                    options={categorias}
                    isClearable
                    value={cat}
                />
            </div>}

            <div>
                <input
                    type="submit"
                    id="upload-new-imagen"
                    className="input-upload"
                    required
                    accept="image/png, .jpeg, .jpg"
                    onClick={() => editImg()}
                />
                <label
                    htmlFor="upload-new-imagen"
                    className="input-img"
                    style={{ width: '100%', padding: 14 }}
                >
                    Editar
                </label>
            </div>
        </div>
    );
};

export default EditModal;