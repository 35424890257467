import { Link } from "react-router-dom";
import { MdLocationPin } from 'react-icons/md';
import classNames from "classnames";

const Suggestion = ({
    label = "",
    data,
    labelpay = "",
    labeltype = "",
    colorEvent,
    colorCourt
}) => {
    return (
        <>
            <div className="tw-pl-0 xs:tw-pl-56 tw-mt-6">
                <h1 className="tw-font-bitner tw-text-Turquesa tw-text-2xl xs:tw-text-4xl tw-text-center xs:tw-text-left">
                    {label}
                </h1>
            </div>
            <div className="tw-flex tw-flex-wrap tw-gap-10 tw-justify-center tw-sticky tw-w-full tw-h-full tw-mt-8">
                {data.map((data) => (
                    <Link
                        style={{ textDecoration: "none" }}
                        to={`/mapa?id=${data.id}&type=${data.actionType}&location=${data.region}&sport=${data.tipo}`}
                    >
                        <div className={classNames("tw-shadow-xl tw-w-80 tw-h-96 tw-rounded-xl tw-relative tw-overflow-hidden tw-transition-all tw-delay-75 hover:tw-translate-y-1 hover:tw-scale-110 hover:tw-duration-300 tw-cursor-pointer", {
                            "tw-bg-Verde-TenisCTA": colorCourt,
                            "tw-bg-Turquesa": colorEvent

                        })}>
                            <img className="tw-w-80 tw-h-72 tw-object-cover" alt="foto-torneo" src={data.imagen} />
                            <div className="tw-text-base tw-font-objet tw-inline tw-border-solid tw-border tw-bg-white tw-absolute tw-right-0 tw-top-0 tw-z-10 tw-rounded-bl-lg tw-font-bold tw-pr-2 tw-pl-1">Región de {data.region}</div>
                            <div className="tw-text-base tw-font-objet tw-inline tw-border-solid tw-border tw-bg-white tw-absolute tw-mt-2 tw-left-0 tw-bottom-24 tw-z-10 tw-rounded-tr-lg tw-font-bold tw-pr-2 tw-pl-1">{labelpay} desde: ${data.precio}</div>
                            <div className="tw-pl-4 tw-mt-2">
                                <div className="tw-flex tw-flex-row tw-gap-1">
                                    <MdLocationPin color={colorEvent ? "white" : "black"} size={25} />
                                    <h1 className={classNames("tw-font-objet tw-text-lg tw-font-bold", {
                                        "tw-text-white": colorEvent,
                                        "tw-text-black": colorCourt
                                    })}>{data.ciudad}</h1>
                                </div>

                                <p className={classNames("tw-text-sm tw-font-objet tw-ml-7 tw-font-bold", {
                                    "tw-text-white": colorEvent,
                                    "tw-text-black": colorCourt
                                })}>{labeltype} de {data.tipo}</p>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </>
    )
}

export default Suggestion;