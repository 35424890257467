import { useState } from "react";
import Check from "../ui/Check";
import { AiOutlineClose, AiOutlineDown } from "react-icons/ai";
import Modal from "react-modal";
import { FaArrowCircleLeft } from "react-icons/fa";
import Finder from "./Finder";


const OptionsFinder = ({
    label,
    placeholder,
    selectType,
    setSelectType,
    setSearchSelect,
    setUbicacion,
    setFecha,
    setTipo,
    isMobil,
    searchSelect,
    ubicacion,
    fecha,
    tipo
}) => {
    const handlerButton = () => {
        if (isMobil) {
            setShowModal(true)
            setShowOptions(false)
        }
        if (showOptions === true && !isMobil) {
            setShowOptions(false)
        } if (showOptions === false && !isMobil) {
            setShowOptions(true)
        }
    }

    const [showOptions, setShowOptions] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [section, setSection] = useState("Principal")

    const resetValues = () => {
        setSearchSelect("")
        setUbicacion("")
        setFecha("")
        setTipo("")
    }

    const sectionMobile = () => {
        switch (section) {
            case "Principal":
                return (
                    <>
                        <div className="tw-min-w-full tw-px-5 tw-pt-2 tw-mb-2 tw-flex tw-flex-row">
                            <FaArrowCircleLeft
                                className="tw-text-Turquesa tw-cursor-pointer"
                                size={35}
                                onClick={() => setShowModal(false)}
                            />
                            <label className="tw-text-Turquesa tw-font-medium tw-mt-1 tw-ml-4">Buscador Tenis-Up</label>
                        </div>
                        <div className="tw-rounded-full tw-bg-white tw-mt-8 tw-flex tw-flex-col tw-relative" style={{ border: '1px solid rgb(235, 235, 235) ' }}>

                            <div className="tw-flex tw-flex-row tw-items-center">
                                <div className="tw-flex tw-flex-col tw-items-center tw-w-full tw-p-2">
                                    <div className="tw-flex tw-flex-row tw-gap-1">
                                        <label className="tw-font-medium tw-text-sm xs:tw-text-base">{label}</label>
                                    </div>
                                    <div className="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-center" >
                                        <button className="tw-text-sm xs:tw-text-base" value={selectType} style={{ width: 100 }} onClick={() => handlerButton()} >Que buscas?</button>
                                        {/* <input className="tw-text-sm xs:tw-text-base" value={selectType} style={{ width: 100 }} type="text" placeholder="Que buscas?" onClick={() => handlerButton()} /> */}
                                        {selectType !== "" ? <AiOutlineClose onClick={() => setSelectType("")} size={18} className="tw-cursor-pointer" /> : ''}
                                    </div>
                                </div>
                            </div>

                            <div className="tw-absolute tw-z-10 tw-bg-white tw-overflow-hidden tw-overflow-y-auto tw-rounded-xl tw-mt-20 tw-ml-9 tw-w-auto tw-h-auto tw-p-3">
                                <Check
                                    label="Todos"
                                    value="todos"
                                    id="todos"
                                    name="all"
                                    type="radio"
                                    square
                                    checked={selectType === "Todos"}
                                    onChange={() => { setShowOptions(false); resetValues(); setSection("Todos") }}
                                    size="medium"
                                />
                                <Check
                                    label="Eventos"
                                    value="eventos"
                                    id="eventos"
                                    name="events"
                                    type="radio"
                                    square
                                    checked={selectType === "Eventos"}
                                    onChange={() => { setShowOptions(false); resetValues(); setSection("Eventos") }}
                                    size="medium"
                                />
                                <Check
                                    label="Clubes"
                                    value="clubes"
                                    id="clubes"
                                    name="clubs"
                                    type="radio"
                                    square
                                    checked={selectType === "Clubes"}
                                    onChange={() => { setShowOptions(false); resetValues(); setSection("Clubes") }}
                                    size="medium"
                                />
                                <Check
                                    label="Canchas"
                                    value="canchas"
                                    id="canchas"
                                    name="courts"
                                    type="radio"
                                    square
                                    checked={selectType === "Canchas"}
                                    onChange={() => { setShowOptions(false); resetValues(); setSection("Canchas") }}
                                    size="medium"
                                />
                            </div>
                        </div>
                    </>
                )
            case "Todos":
                return (
                    <>
                        <div className="tw-min-w-full tw-px-5 tw-pt-2 tw-mb-2 tw-flex tw-flex-row">
                            <FaArrowCircleLeft
                                className="tw-text-Turquesa tw-cursor-pointer"
                                size={35}
                                onClick={() => setSection("Principal")}
                            />
                            <label className="tw-text-Turquesa tw-font-medium tw-mt-1 tw-ml-4">Estás buscando "{section}"</label>
                        </div>
                        <Finder
                            isGeneral
                            isMobile
                            selectType={selectType}
                            searchSelect={searchSelect}
                            setSearchSelect={setSearchSelect}
                            ubicacion={ubicacion}
                            setUbicacion={setUbicacion}
                            fecha={fecha}
                            setFecha={setFecha}
                            tipo={tipo}
                            setTipo={setTipo}
                        />
                    </>
                )
            case "Eventos":
                return (
                    <>
                        <div className="tw-min-w-full tw-px-5 tw-pt-2 tw-mb-2 tw-flex tw-flex-row">
                            <FaArrowCircleLeft
                                className="tw-text-Turquesa tw-cursor-pointer"
                                size={35}
                                onClick={() => setSection("Principal")}
                            />
                            <label className="tw-text-Turquesa tw-font-medium tw-mt-1 tw-ml-4">Estás buscando "{section}"</label>
                        </div>
                        <Finder
                            isEvents
                            isMobile
                            selectType={selectType}
                            searchSelect={searchSelect}
                            setSearchSelect={setSearchSelect}
                            ubicacion={ubicacion}
                            setUbicacion={setUbicacion}
                            fecha={fecha}
                            setFecha={setFecha}
                            tipo={tipo}
                            setTipo={setTipo}
                        />
                    </>
                )
            case "Clubes":
                return (
                    <>
                        <div className="tw-min-w-full tw-px-5 tw-pt-2 tw-mb-2 tw-flex tw-flex-row">
                            <FaArrowCircleLeft
                                className="tw-text-Turquesa tw-cursor-pointer"
                                size={35}
                                onClick={() => setSection("Principal")}
                            />
                            <label className="tw-text-Turquesa tw-font-medium tw-mt-1 tw-ml-4">Estás buscando "{section}"</label>
                        </div>
                        <Finder
                            isClubs
                            isMobile
                            selectType={selectType}
                            searchSelect={searchSelect}
                            setSearchSelect={setSearchSelect}
                            ubicacion={ubicacion}
                            setUbicacion={setUbicacion}
                            fecha={fecha}
                            setFecha={setFecha}
                            tipo={tipo}
                            setTipo={setTipo}
                        />
                    </>
                )
            case "Canchas":
                return (
                    <>
                        <div className="tw-min-w-full tw-px-5 tw-pt-2 tw-mb-2 tw-flex tw-flex-row">
                            <FaArrowCircleLeft
                                className="tw-text-Turquesa tw-cursor-pointer"
                                size={35}
                                onClick={() => setSection("Principal")}
                            />
                            <label className="tw-text-Turquesa tw-font-medium tw-mt-1 tw-ml-4">Estás buscando "{section}"</label>
                        </div>
                        <Finder
                            isCourts
                            isMobile
                            selectType={selectType}
                            searchSelect={searchSelect}
                            setSearchSelect={setSearchSelect}
                            ubicacion={ubicacion}
                            setUbicacion={setUbicacion}
                            fecha={fecha}
                            setFecha={setFecha}
                            tipo={tipo}
                            setTipo={setTipo}
                        />
                    </>
                )
            default:
                break;
        }

    }

    const modal = () => {
        if (isMobil) {
            return (
                <Modal
                    isOpen={showModal}
                    style={ModalMobile}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={() => setShowModal(false)}
                >
                    {sectionMobile()}
                </Modal>
            )
        }
    }


    return (
        <div className="tw-rounded-full tw-bg-white tw-w-52 tw-flex tw-flex-col tw-relative">
            <div className="tw-flex tw-flex-row tw-items-center">
                <div className="tw-flex tw-flex-col tw-items-center tw-w-full tw-p-4 xs:tw-p-2">
                    <div className="tw-flex tw-flex-row tw-gap-1">
                        <label className="tw-font-medium tw-text-sm xs:tw-text-base">{label}</label>
                    </div>
                    <div className="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-center" >
                        <button className="tw-text-sm xs:tw-text-base" value={selectType} style={{ width: 100 }} onClick={() => handlerButton()} >{selectType !== "" ? selectType : placeholder}</button>
                        {selectType !== "" ? <AiOutlineClose onClick={() => setSelectType("")} size={18} className="tw-cursor-pointer" /> : ''}
                    </div>
                </div>
                <div>
                    <AiOutlineDown className="tw-mr-2" color="#DCEC00" size={25} onClick={() => handlerButton()} />
                </div>
            </div>
            {showOptions && <div className="tw-absolute tw-z-10 tw-bg-white tw-overflow-hidden tw-overflow-y-auto tw-rounded-xl tw-mt-20 tw-ml-9 tw-w-auto tw-h-auto tw-p-3">
                <Check
                    label="Todos"
                    value="todos"
                    id="todos"
                    name="all"
                    type="radio"
                    square
                    checked={selectType === "Todos"}
                    onChange={() => { setSelectType("Todos"); setShowOptions(false); resetValues() }}
                    size="medium"
                />
                <Check
                    label="Eventos"
                    value="eventos"
                    id="eventos"
                    name="events"
                    type="radio"
                    square
                    checked={selectType === "Eventos"}
                    onChange={() => { setSelectType("Eventos"); setShowOptions(false); resetValues() }}
                    size="medium"
                />
                <Check
                    label="Clubes"
                    value="clubes"
                    id="clubes"
                    name="clubs"
                    type="radio"
                    square
                    checked={selectType === "Clubes"}
                    onChange={() => { setSelectType("Clubes"); setShowOptions(false); resetValues() }}
                    size="medium"
                />
                <Check
                    label="Canchas"
                    value="canchas"
                    id="canchas"
                    name="courts"
                    type="radio"
                    square
                    checked={selectType === "Canchas"}
                    onChange={() => { setSelectType("Canchas"); setShowOptions(false); resetValues() }}
                    size="medium"
                />
            </div>}
            {modal()}
        </div>
    )
}

const ModalMobile = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        border: "none",
        width: "100vw",
        height: "100%",
        position: "fixed",
    },
    overlay: {
        backgroundColor: "rgba(0,0,0,0.75)",
        zIndex: 12,
    },
};

export default OptionsFinder;