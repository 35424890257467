import { update, ref } from "firebase/database";
import { db, auth } from "../firebase/firebase";

//Arriendo cancha
export const REGISTER_COURTRENT_REQUEST = "REGISTER_COURTRENT_REQUEST";
export const REGISTER_COURTRENT_SUCCESS = "REGISTER_COURTRENT_SUCCESS";
export const REGISTER_COURTRENT_FAILURE = "REGISTER_COURTRENT_FAILURE";

//Cancelar arriendo a cancha
export const CANCEL_COURTRENT_REQUEST = "CANCEL_COURTRENT_REQUEST";
export const CANCEL_COURTRENT_SUCCESS = "CANCEL_COURTRENT_SUCCESS";
export const CANCEL_COURTRENT_FAILURE = "CANCEL_COURTRENT_FAILURE";

//cleanup

export const CLEAN_REGISTRATION_COURTRENT = "CLEAN_REGISTRATION_COURTRENT";

const requestRentCourt = () => {
  return {
    type: REGISTER_COURTRENT_REQUEST,
  };
};

const receiveRentCourt = () => {
  return {
    type: REGISTER_COURTRENT_SUCCESS,
  };
};

const rentCourtFailure = () => {
  return {
    type: REGISTER_COURTRENT_FAILURE,
  };
};

// const requestCancelRentCourt = () => {
//     return {
//         type: CANCEL_COURTRENT_REQUEST,
//     };
// };

// const receiveCancelRentCourt = () => {
//     return {
//         type: CANCEL_COURTRENT_SUCCESS,
//     }
// }

// const cancelRentCourtFailure = () => {
//     return {
//         type: CANCEL_COURTRENT_FAILURE
//     }
// }

// const cleanRentCourtRequest = () => {
//     return {
//         type: CLEAN_REGISTRATION_COURTRENT
//     }
// }

export const rentCourt = (court, club, user) => async (dispatch) => {
  dispatch(requestRentCourt());

  const USER_UID = auth.currentUser.uid;

  try {
    await update(ref(db, `users/${USER_UID}/arriendos`), {
      [court.key]: {
        key_court: court.key,
        nombre: court.name,
        precio: court.price,
      },
    });

    await update(ref(db, `clubes/${club.uid}/arriendos/${court.key}`), {
      [USER_UID]: {
        key_court: court.key,
        nombre: court.name,
        precio: court.price,
        hour: {
          AM: "10:00",
          PM: "15:00",
        },
        day: "Monday",
        // date: "13/08/2020"
      },
    });
    dispatch(receiveRentCourt());
  } catch (e) {
    dispatch(rentCourtFailure());
  }
};
