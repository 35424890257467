import EventRowItem from "./EventRowItem";
import { EmptyContainer } from "./styles/EventRowStyle";
const EventsRow = ({
  events = [],
  goToEvent,
  showActions,
  isEventUp,
  isEventCompleted,
  allEvents,
}) => {
  if (!events.length)
    return (
      <EmptyContainer>
        <p>No se encontraron eventos.</p>
      </EmptyContainer>
    );
  return (
    <div className="scroll tw-flex tw-flex-wrap tw-gap-5 tw-p-3 tw-justify-center">
      {events.map((item, i) => (
        <EventRowItem
          key={i}
          event={item}
          goToEvent={goToEvent ? (id) => goToEvent(id) : null}
          showActions={showActions}
          isEventUp={isEventUp}
          isEventCompleted={isEventCompleted}
          allEvents={allEvents}
        />
      ))}
    </div>
  );
};

export default EventsRow;
