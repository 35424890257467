import { useState, createRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import "../../../styles/EditService.css";
import { BiImageAdd } from "react-icons/bi";
import { updateService, defaultService, createService } from "../../../actions/clubs";
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from "../../ui/Input";
import NumberInput from "../../ui/NumberInput";
import Select from "../../ui/Select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ServiciosOptionList } from "../../../costants/clubServicesOption";
import toast from "react-hot-toast";

function mapStateToProps(state) {
  return {
    events: state.events,
    event: state.events.event,
    singleEventFetched: state.events.singleEventFetched,
    info: state.auth.info,
    serviceUpdated: state.club.serviceUpdated,
    isUpdatingService: state.club.isUpdatingService,
    isCreatingService: state.club.isCreatingService,
    createServiceError: state.club.createServiceError,
    serviceCreated: state.club.serviceCreated,
  };
}

const schema = yup
  .object({
    name: yup.string().required("Inserte un nombre").min(6, "El nombre del servicio es muy corto"),
    price: yup
      .number()
      .required("Ingrese el precio del servicio")
      .min(5000, "El precio minimo debe ser de $5.000"),
    selection: yup.string().required("Seleccione un tipo de servicio"),
    description: yup
      .string()
      .required("Inserte una descripción")
      .max(100, "La descripcion debe contar con un maximo de 100 caracteres"),
  })
  .required();

const NewService = (props) => {
  const { adding } = props;

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const dispatch = useDispatch();
  const [key, setKey] = useState("");
  const [imagen, setImagen] = useState("");
  const {
    welcomeClub,
    serviceUpdated,
    updateServiceError,
    isUpdatingService,
    serviceCreated,
    isCreatingService,
    createServiceError,
  } = props;
  let fileRef = createRef();

  useEffect(() => {
    dispatch(defaultService());
  }, [dispatch]);

  useEffect(() => {
    if (props?.selectedService) {
      var {
        key,
        name,
        selection,
        price,
        description,
        image: imagen_,
      } = props?.selectedService.service;
      setKey(key);
      setImagen(imagen_);
      reset({ name, selection, price, description });
    }
  }, [props.selectedService, reset]);
  const handleAddService = (data) => {
    const { name, selection, description, price } = data;
    let service = {
      key: key,
      name,
      selection,
      description,
      price,
      image: imagen,
    };

    if (welcomeClub) {
      if (props.selectedService) {
        toast.success("El servicio se ha editado correctamente");
        props.setServices?.((prevState) => {
          let tempServices = prevState;
          tempServices[props.selectedService.index] = service;
          return tempServices;
        });
      } else {
        toast.success("El servicio se agregó correctamente");
        props.setServices?.((prevState) => [...prevState, service]);
      }
    } else {
      if (props.selectedService) {
        dispatch(updateService(props.info.uid, service));
      } else {
        dispatch(createService(service, props.info.uid));
        console.log("success");
        props.setServices((prevState) => [...prevState, service]);
      }
    }
    if (welcomeClub) {
      props.adding(false);
    }
  };

  useEffect(() => {
    if (!isUpdatingService || !isCreatingService) {
      if (serviceUpdated || serviceCreated) {
        adding(false);
      } else if (updateServiceError || createServiceError) {
        adding(true);
      }
    }
  }, [
    serviceUpdated,
    updateServiceError,
    isUpdatingService,
    createServiceError,
    serviceCreated,
    isCreatingService,
    adding,
  ]);

  const handleChangePicture = async (event) => {
    event.preventDefault();
    if (event.target?.files.length === 1) {
      const file = event.target?.files[0];
      const fileReader = new window.FileReader();
      const filename = file?.name;
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setImagen({ file: fileReader.result, filename: filename, blob: file });
      };
    }
  };
  return (
    <div className="tw-w-full tw-max-w-7xl tw-text-center tw-flex, sm:tw-px-10">
      <form onSubmit={handleSubmit(handleAddService)} className="tw-flex tw-flex-wrap tw-gap-x-5">
        <div className="column-img-court tw-flex tw-justify-center tw-bg-slate-100 tw-flex-grow tw-basis-60 tw-max-w-3xl tw-max-h-72 tw-overflow-hidden">
          {imagen !== "" && (
            <img
              src={imagen.file ? imagen.file : imagen}
              alt="Imagen del Servicio"
              className="tw-w-auto tw-h-auto tw-object-cover tw-aspect-square"
            />
          )}
          <div id={imagen?.file || imagen !== "" ? "new-court-photo-added" : "new-court-photo"}>
            <BiImageAdd color="#333" size="100" />
            <input
              type="file"
              accept="image/png, .jpeg, .jpg"
              ref={fileRef}
              onChange={handleChangePicture}
            />
          </div>
        </div>
        <div className="tw-flex-wrap tw-flex tw-flex-grow-max tw-basis-0  tw-min-w-2/3 tw-gap-x-5 tw-max-w-3xl tw-mb-28 xs:tw-mb-0">
          <Controller
            name="selection"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Select
                label="Seleccione un servicio"
                name={name}
                value={value}
                placeholder="Seleccione un Servicio"
                onChange={({ value }) => onChange({ target: { name, value } })}
                options={ServiciosOptionList}
                error={errors[name]?.message}
                containerClass="tw-flex-1 tw-basis-64"
              />
            )}
          />
          <Input
            {...register("name")}
            label="Nombre del servicio"
            placeholder="Ingrese el nombre del servicio"
            error={errors.name?.message}
            containerClass="tw-flex-1 tw-basis-64"
          />
          <NumberInput
            {...register("price")}
            label="Precio"
            placeholder="$10.000"
            error={errors.price?.message}
            decimal={2}
            containerClass="tw-flex-1 tw-basis-64"
          />
          <div className="add-court-textarea tw-min-w-full tw-flex-1">
            <label className="court-event-label">Descripción del servicio</label>
            <textarea
              {...register("description")}
              className={
                errors.description?.message ? "service-club-error" : "service-event-intext-area"
              }
              placeholder="Ingrese una descripción..."
              type="text"
            />
            {errors.description?.message && (
              <span className="service-club-errorMessage">{errors.description.message}</span>
            )}
          </div>
        </div>
        <div className="service-club-btn">
          <button
            className="information-service-button"
            disabled={!isValid || isUpdatingService || isCreatingService}
            style={
              !isValid || isUpdatingService || isCreatingService
                ? { opacity: 0.5, transform: "none" }
                : { opacity: 1 }
            }
          >
            {(isUpdatingService || isCreatingService) && (
              <CircularProgress size={18} color="white" />
            )}
            {props?.selectedService !== "" ? "Guardar Cambios" : "Agregar Servicio"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default connect(mapStateToProps)(NewService);
