import { connect } from "react-redux";
import { FaFacebook, FaInstagram, FaGooglePlay, FaAppStoreIos } from "react-icons/fa";
import { useEffect } from "react";

//style
import "../styles/Footer.css";
// import styled from 'styled-components'
import { changePestañaSelected } from "../actions/header";

function mapStateToProps(state) {
  return state;
}

const Footer = ({
  contentSelected,
  setContentSelected,
  setIsChange,
  ...props
}) => {
  const { dispatch } = props;
  const cambiarContenido = (selected) => {
    dispatch(changePestañaSelected(selected));
    window.scrollTo({ top: 0, behavior: "smooth" });
    setContentSelected(selected);
  };

  useEffect(() => {
    if (contentSelected !== "") {
      setIsChange(true)
    }
    if (contentSelected === "inicio") {
      setIsChange(false)
    }
  }, [contentSelected, setIsChange])


  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-container">
          <h2>SOBRE TENIS-UP</h2>
          <span onClick={() => cambiarContenido("instrucciones")} className="home-header-account">
            Como funciona
          </span>
          <span onClick={() => cambiarContenido("eventos")} className="home-header-account">
            Eventos
          </span>
          <span onClick={() => cambiarContenido("privacidad")} className="home-header-account">
            Nuestras politicas de privacidad
          </span>
          <span onClick={() => cambiarContenido("videos")} className="home-header-account">
            Revisa nuestros videos
          </span>
        </div>
        <div className="footer-container">
          <h2>DESCARGA TENIS-UP</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              style={{ paddingRight: "25px", textAlign: "-webkit-center" }}
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=cl.tennisup.tennisup&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            >
              Google Play <FaGooglePlay className="icons-footer" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/cl/app/tenis-up/id1501218525?ign-mpt=uo=2"
            >
              App Store <FaAppStoreIos style={{ marginLeft: 22 }} className="icons-footer" />
            </a>
          </div>
        </div>
        <div className="footer-container">
          <h2>SÍGUENOS EN</h2>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/tenisup.cl/">
              <FaInstagram size="30" style={{ marginRight: 15 }} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/Tenis-up-658558037907309"
            >
              <FaFacebook size="30" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <p>
          <strong>©{new Date().getFullYear()} Tenis-UP.</strong> Todo los derechos reservados
        </p>
      </div>
      {/* <LoginModal
                toggleModal={this.toggleModal}
                showLogin={this.state.showLogin}
            /> */}
    </div>
  );
};
export default connect(mapStateToProps)(Footer);

// const Label = styled.label`
//     font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 25px;
//     line-height: 33px;
//     color: #000000;

// `
