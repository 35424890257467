import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
// import SingleWeekCalendar from "../calendar/SingleWeekCalendar";
import { useNavigate } from 'react-router-dom';
// import styled from "styled-components";
import { CreatePaymentChange } from '../../actions/payment';
import RentCourtModal from '../inscripcion/RentCourtModal';
import { UpdateSingleCourtWeek } from '../../actions/clubs';
import moment from 'moment';

function mapStateToProps(state) {
  return {
    mode: state.menu.mode,
    club: state.club.club,
    court: state.club.court,
    singleClubFetched: state.club.singleClubFetched,
    payment: state.payment,
    singleCourtFetched: state.club.singleCourtFetched,
  };
}

const PreviewCourt = (props) => {
  const {
    dispatch,
    showModal,
    setShowModal,
    court,
    singleCourtFetched,
    courtID,
  } = props;
  const navigate = useNavigate();
  const [selectedDay, setselectedDay] = useState();

  useEffect(() => {
    if (court?.week) {
      setselectedDay(
        court.week?.[moment().format('dddd')] || Object.values(court.week)?.[0]
      );
    }
  }, [court?.week]);

  const SetPaymentData = useCallback(
    (data) => {
      dispatch(CreatePaymentChange(data));
    },
    [dispatch]
  );

  useEffect(() => {
    if (showModal && singleCourtFetched)
      SetPaymentData({
        duracion: null,
        day: { start: null, end: null },
        total: null,
        clubID: court.club_uid,
        courtKey: court.key,
      });
  }, [
    singleCourtFetched,
    SetPaymentData,
    court.club_uid,
    court.key,
    showModal,
  ]);

  return (
    <RentCourtModal
      handleClose={setShowModal}
      handleCloseConfirm={() => {
        if (props.payment?.total >= court?.price) {
          navigate(`/arriendo/${court.key}`);
        }
      }}
      court={court}
      payment={props.payment}
      updateWeek={(week) => {
        dispatch(UpdateSingleCourtWeek({ ...court, week: week }));
      }}
      selectedDay={selectedDay}
      setselectedDay={setselectedDay}
      SetPaymentData={SetPaymentData}
      courtID={courtID}
    />
  );
};

export default connect(mapStateToProps)(PreviewCourt);
