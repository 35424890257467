export const materialOptionList = [
  { key: "material-2", value: "cesped", label: "Césped" },
  { key: "material-3", value: "arcilla", label: "Arcilla" },
  { key: "material-4", value: "dura", label: "Dura" },
  { key: "material-5", value: "moqueta", label: "Moqueta" },
];
export const materialPadelOptionList = [
  { key: "material-2", value: "sintetico", label: "Sintético" },
  { key: "material-3", value: "cesped", label: "Césped artificial" },
  { key: "material-4", value: "cemento", label: "Cemento" },
  { key: "material-5", value: "hormigon", label: "Hormigón" },
];
export const TypeOptionList = [
  { key: "type-2", value: "tenis", label: "Tenis" },
  { key: "type-3", value: "padel", label: "Padel" },
];
export const TypeEventoOptionList = [
  { key: "type-1", value: "", label: "" },
  { key: "type-2", value: "tenis", label: "Tenis" },
  { key: "type-3", value: "padel", label: "Padel" },
];
export const TournamentStatusList = [
  { key: "status-1", value: "", label: "Seleccione el status" },
  { key: "status-2", value: "Abierto", label: "Abierto" },
  { key: "status-3", value: "Cerrado", label: "Cerrado" },
];

export const cuposTorneo = [
  { key: "status-1", label: "2", value: 2 },
  { key: "status-2", label: "4", value: 4 },
  { key: "status-3", label: "8", value: 8 },
  { key: "status-4", label: "16", value: 16 },
  { key: "status-5", label: "32", value: 32 },
  { key: "status-6", label: "64", value: 64 },
];
