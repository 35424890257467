import { MdEdit } from 'react-icons/md';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { paisesOptions } from "../../costants/profile";


import ticket from "../../assets/ticket-tenisup.png";
import ticket2 from "../../assets/ticket-asociasion.png";

const TableRank = ({
    filterType,
    tipo_usuario,
    filter,
    setShowEditRank,
    setSelectedUser
}) => {
    const points = (user) => {
        if (filterType === user.tipoRankingTenisUp) {
            return (
                <div>{user.puntajeTenisUp}</div>
            )
        }
        if (filterType === user.tipoRankingAsociacion)
            return (
                <div>{user.puntajeAsociacion}</div>
            )
    }

    const position = (user) => {
        if (filterType === user.tipoRankingTenisUp) {
            return (
                <div>{user.posicionTenisUp}</div>
            )
        }
        if (filterType === user.tipoRankingAsociacion)
            return (
                <div>{user.posicionAsociacion}</div>
            )

    }

    const imgTypeRanking = (user) => {
        if (filterType === user.tipoRankingTenisUp) {
            return (
                <img width={50} style={{ maxWidth: "none" }} alt="TenisUp" src={ticket} />
            )
        }
        if (filterType === user.tipoRankingAsociacion)
            return (
                <img width={50} style={{ maxWidth: "none" }} alt="TenisUp" src={ticket2} />
            )
    }

    return (
        <div style={{ position: "relative" }} className="scroll">
            <div className="scroll">
                <Table aria-label="simple table" style={{ borderCollapse: "collapse" }}>
                    <TableHead className="Inscritos-header">
                        <TableRow>
                            <TableCell>Posición</TableCell>
                            <TableCell>Rank +/-</TableCell>
                            <TableCell>Pais</TableCell>
                            <TableCell>Jugador</TableCell>
                            {/* <TableCell>Edad</TableCell> */}
                            <TableCell>Categoria</TableCell>
                            <TableCell>Puntaje</TableCell>
                            <TableCell>Tipo ranking</TableCell>
                            {(tipo_usuario === "admin") && <TableCell>Acciones</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody className="Inscritos-body">
                        {filter?.map((user, index) => (
                            <TableRow key={'row' + index}>
                                <TableCell component="th" scope='row'>
                                    <div>{position(user)}</div>
                                </TableCell>
                                <TableCell width={50}>
                                    <div> +1</div>
                                </TableCell>
                                <TableCell>
                                    {paisesOptions?.map((pais) => pais.codigo === user.pais && <img
                                        alt="pais icono"
                                        src={pais.img}
                                    >
                                    </img>)}
                                </TableCell>
                                <TableCell width={50}>
                                    <div>{user.nombre}</div>
                                </TableCell>
                                {/* <TableCell>{getEdad(user?.edad)}</TableCell> */}
                                <TableCell>
                                    <div>{user.categoria}</div>
                                </TableCell>
                                <TableCell>
                                    <div>{points(user)}</div>
                                </TableCell>
                                <TableCell>
                                    <div>{imgTypeRanking(user)}</div>
                                    {/* <img width={50} style={{ maxWidth: "none" }} alt="TenisUp" src={ticket} /> */}
                                </TableCell>
                                {(tipo_usuario === "admin") && <TableCell><TableCell>
                                    <div className="tw-flex tw-flex-row">
                                        <MdEdit
                                            color="#333"
                                            size="20"
                                            onClick={() => { setShowEditRank(true); setSelectedUser(user); }}
                                        />
                                    </div>
                                </TableCell></TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default TableRank;

