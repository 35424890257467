import { useState, useEffect, useRef, Suspense, lazy } from "react";

//components
import Modal from "react-modal";
import { FaArrowCircleLeft } from "react-icons/fa";
//styles
// import styled from "styled-components";
import "../styles/LoginModal.css";

import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";

//redux
import { connect } from "react-redux";
import { loginUser } from "../actions/auth";
import { registerClub } from "../actions/clubs";
import { registerUser } from "../actions/auth";
import { useNavigate } from "react-router-dom";

import logoBlanco from "../assets/login/logoBlanco.png";
import Check from "./ui/Check";
import { Link } from "react-router-dom";
// import FB from "../services/login/facebook";
const Google = lazy(() => import("../services/login/google"));

const responsiveWidth = 640;

const desktopStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "90%",
    maxWidth: 1200,
    maxHeight: 700,
    height: "85%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: 0,
    overflow: "hidden",
    zIndex: 1,
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
  },
};

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated,
    registerClubError: state.club.registerClubError,
    errorRegister: state.club.error,
    errorLogin: state.auth.error,
  };
}

const LoginModal = (props) => {
  const navigate = useNavigate();
  const { toggleModal, width, showLogin, isRegistering, setisRegistering, nextRoute, selectID } = props;
  const [styles, setStyles] = useState({});
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  // const [isRegistering, setisRegistering] = useState(
  //   pageLogin || "Menu Principal"
  // );

  // const [registerClub, setRegisterClub] = useState('');

  //Login
  // const [errorEmail, setErrorEmail] = useState("");
  const [errorUser, setErrorUser] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [tooManyRequest, setTooManyRequest] = useState("");
  const [tooManyRequestRegister, setTooManyRequestRegister] = useState("");

  //Register
  const [erroremailregister, setErrorEmailRegister] = useState("");
  const [errorpasswordregister, setErrorPasswordRegister] = useState("");
  // const [errorUserregister, setErrorRegister] = useState("");
  //Registro Usuario
  const [nombre, setNombre] = useState("");
  const [sexo, setSexo] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  // const [categoria, setCategoria] = useState("");
  const [telefono, setTelefono] = useState("");
  const [errorNombre, setErrorNombreRegister] = useState("");
  const [errorSexo, setErrorSexoRegister] = useState("");
  const [errorFechaNacimiento, setErrorfechaNacimientoRegister] = useState("");
  // const [errorCategoria, setErrorCategoriaRegister] = useState("");
  const [errorTelefono, setErrorTelefono] = useState("");
  const [errorRegisterUser, setErrorRegisterUser] = useState("");
  // useEffect(() => {
  //   setisRegistering(pageLogin || "Menu Principal");
  // }, [pageLogin]);

  const formHasEmpty = () => {
    if (email === "" || password === "") {
      return true;
    } else {
      return false;
    }
  };

  const formHasEmptyUser = () => {
    if (
      email === "" ||
      password === "" ||
      nombre === "" ||
      // categoria === "" ||
      sexo === "" ||
      fechaNacimiento === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  //funcion que se ejecuta al presionar el boton.
  const handleLogin = (e) => {
    e.preventDefault();
    if (formHasEmpty() === false) {
      const profilDataTemplate = require("../costants/profileDataTemplate.json");
      let data = profilDataTemplate;
      data.settings.nombre = email;
      data.settings.telefono = telefono;
      const { dispatch } = props;
      dispatch(loginUser(email, password, data));
    } else {
      if (email === "") {
        setErrorUser("Falta escribir el email");
      } else {
        setErrorUser("");
      }
      if (password === "") {
        setErrorPassword("Falta escribir la Contraseña");
      } else {
        setErrorPassword("");
      }
    }
  };

  const handleRegisterUser = (e) => {
    e.preventDefault();

    if (formHasEmptyUser() === false) {
      const { dispatch } = props;
      let user = {
        email,
        password,
        nombre,
      };
      const profilDataTemplate = require("../costants/profileDataTemplate.json"); //Plantilla reutilizable para crear un usuario
      let data = profilDataTemplate;
      data.settings = {
        ...data.settings,
        telefono,
        sexo,
        nombre,
        categoria: "Novicio",
        estiloJuego: "defensivo",
        manoDominante: "derecha",
        fechaNacimiento: convertDate(fechaNacimiento),
      };
      dispatch(registerUser(user, data));
      if (nextRoute) {
        navigate(`${nextRoute}/?selectID=${selectID}`)
      }
      // dispatch(setUser(data));
    } else {
      if (nombre === "") {
        setErrorNombreRegister("Te falta escribir un nombre");
      } else {
        setErrorNombreRegister("");
      }
      if (sexo === "") {
        setErrorSexoRegister("Te falta elegir un genero");
      } else {
        setErrorSexoRegister("");
      }
      if (fechaNacimiento === "") {
        setErrorfechaNacimientoRegister("Te falta tu fecha de nacimiento");
      } else {
        setErrorfechaNacimientoRegister("");
      }
      // if (categoria === "") {
      //   setErrorCategoriaRegister("Te falta la categoria");
      // } else {
      //   setErrorCategoriaRegister("");
      // }
      if (email === "") {
        setErrorEmailRegister("Te falta escribir el email");
      } else {
        setErrorEmailRegister("");
      }
      if (password === "") {
        setErrorPasswordRegister("Te falta escribir la contraseña");
      } else {
        setErrorPasswordRegister("");
      }
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (formHasEmpty() === false) {
      const { dispatch } = props;
      let club = {
        email: email,
        password: password,
      };
      dispatch(registerClub(club));
    } else {
      if (email === "") {
        setErrorEmailRegister("Te falta escribir el email");
      } else {
        setErrorEmailRegister("");
      }
      if (password === "") {
        setErrorPasswordRegister("Te falta escribir la contraseña");
      } else {
        setErrorPasswordRegister("");
      }
    }
  };

  useEffect(() => {
    const setEstilos = () => {
      if (width < responsiveWidth) {
        const newStyles = {
          content: {
            position: "unset",
            top: "25%",
            left: "0%",
            width: "100%",
            maxHeight: "100vh",
            height: "-webkit-fill-available",
            border: "none",
            padding: 0,
          },
          overlay: {
            backgroundColor: "rgba(0,0,0,0.75)",
          },
        };

        setStyles(newStyles);
      } else {
        setStyles(desktopStyles);
      }
    };
    setEstilos(width);
  }, [width]);

  //Controla la resolución
  // useEffect(() => {
  //   if (width < responsiveWidth) {
  //     if (props.showLogin) {
  //       document.getElementById("home-header").style.display = "none";
  //     } else {
  //       document.getElementById("home-header").style.display = "flex";
  //     }
  //   } else {
  //     document.getElementById("home-header").style.display = "flex";
  //   }
  // }, [props.showLogin, width]);

  useEffect(() => {
    setEmail("");
    setPassword("");
    setErrorNombreRegister("");
    setErrorSexoRegister("");
    setErrorfechaNacimientoRegister("");
    // setErrorCategoriaRegister("");
  }, [isRegistering]);

  useEffect(() => {
    setErrorRegisterUser("");
    setErrorUser("");
    // setErrorEmail("");
    setErrorPassword("");
    setErrorPasswordRegister("");
    setErrorEmailRegister("");
  }, []);

  function handleChangeRegister(e) {
    const { name, value } = e.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      // break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
      // PassReg.exec(value) === false || PassReg.exec(value) === null
      //   ? setErrorPasswordRegister("Ingresa una contraseña con un minimo de 8 caracteres y una mayuscula")
      //   : setErrorPasswordRegister("");
      // break;
    }
  }

  function descomposeDate(date) {
    var convertDate = date.split("/").reverse().join("-");
    return convertDate;
  }

  function convertDate(date) {
    var convertDate = date.split("-").reverse().join("/");
    return convertDate;
  }

  function handleChangeRegisterUser(e) {
    const { name, value } = e.target;
    switch (name) {
      case "nombre y apellido":
        setNombre(value);
        break;
      case "gender":
        setSexo(value);
        break;
      case "fecha nacimiento":
        setFechaNacimiento(value);
        break;
      case "telefono":
        setTelefono(value);
        isPossiblePhoneNumber(value) === false ||
          isValidPhoneNumber(value) === false
          ? setErrorTelefono("Ingrese un número de teléfono válido")
          : setErrorTelefono("");
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    //Expresion regular de email
    const EmailReg =
      /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
    const PassReg = /(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/;
    if (password?.length > 0 || email?.length > 0) {
      if (EmailReg.exec(email) === false || EmailReg.exec(email) === null) {
        email === ""
          ? setErrorEmailRegister("")
          : setErrorEmailRegister("Ingresa un email valido");
      } else {
        setErrorEmailRegister("");
      }

      if (PassReg.exec(password) === false || PassReg.exec(password) === null) {
        password === ""
          ? setErrorPasswordRegister("")
          : setErrorPasswordRegister(
            "Ingresa una contraseña con 8 caracteres minimos y una mayuscula"
          );
      } else {
        setErrorPasswordRegister("");
      }
    } else {
      setErrorEmailRegister("");
      setErrorPasswordRegister("");
    }
  }, [email, password]);

  function handleChangeLogin(e) {
    const { name, value } = e.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  }

  var firebaseError = props.errorLogin?.code;
  var firebaseErrorRegister = props.errorRegister?.code;

  useEffect(() => {
    if (firebaseError === "auth/user-not-found") {
      setErrorUser("Usuario no encontrado");
      setErrorPassword("");
      setTooManyRequest("");
    }

    if (firebaseError === "auth/invalid-email") {
      setErrorUser("Email invalido");
      setErrorPassword("");
      setTooManyRequest("");
    }
    if (firebaseError === "auth/wrong-password") {
      setErrorPassword("Contraseña incorrecta");
      setErrorUser("");
      setTooManyRequest("");
    }
    //
    if (firebaseError === "auth/email-already-in-use") {
      setErrorEmailRegister("El correo se encuentra en uso");
    }
    if (firebaseErrorRegister === "auth/email-already-in-use") {
      setErrorEmailRegister("El correo se encuentra en uso");
      setErrorPasswordRegister("");
    }
    //
    if (firebaseError === "auth/too-many-requests") {
      setTooManyRequest(
        "Haz realizado muchas peticiones, intentalo dentro de un minuto"
      );
    }
    if (firebaseErrorRegister === "auth/too-many-requests") {
      setTooManyRequestRegister(
        "Haz realizado muchas peticiones, intentalo dentro de un minuto"
      );
    }
  }, [firebaseError, firebaseErrorRegister]);
  const formsList = useRef([
    {
      title: "¿Tienes un recinto tenistico?",
      state: "Club",
      class: "register-form-2",
      buttonClass: "login-boton-1",
    },
    {
      title: "¿Eres un Deportista?",
      state: "User",
      class: "register-form-1",
      buttonClass: "login-boton",
    },
  ]);

  const LoginAndRegister = () => {
    switch (isRegistering) {
      case "Menu Principal":
        return (
          <div
            className="container-main-login-form flipLogin scroll"
            style={{ height: "100%" }}
          >
            <img src={logoBlanco} alt="logoTenisUp" className="logo-blanco" />
            <div className="go-back-login" onClick={() => toggleModal()}>
              <FaArrowCircleLeft size={30} color="#ffffffd1" />
            </div>
            {formsList.current.map((formL, i) => (
              <div key={"LoginModal" + i} className={formL.class}>
                <form
                  className="login-form login-form-main"
                  onSubmit={(event) => this.handleLogin(event)}
                >
                  <div className="register-message-form">
                    <h1>{formL.title}</h1>
                  </div>
                  <div className="login-btn">
                    <button
                      type="button"
                      className={formL.buttonClass}
                      onClick={() => setisRegistering("Login " + formL.state)}
                    >
                      Iniciar sesión
                    </button>
                  </div>
                  <div className="login-btn">
                    <button
                      type="button"
                      className="login-boton-2"
                      onClick={() =>
                        setisRegistering("Registro " + formL.state)
                      }
                    >
                      Registrar
                    </button>
                  </div>
                  {tooManyRequest && (
                    <p className="Login-club-errorMessage">{tooManyRequest}</p>
                  )}
                </form>
              </div>
            ))}
          </div>
        );
      case "Registro Club":
        return (
          <div className="container-register-form flipRegister club-background">
            <div className="register-club-message">
              <div>
                <h1 style={{ fontSize: "4.6vmin" }}>
                  Es un gusto tenerte entre nuestra comunidad
                </h1>
                <label className="label-login-form">
                  Aquí podrás mostrar ante los jugadores tus recintos
                  actividades o campeonatos hechos en tu recinto deportivo
                </label>
              </div>
            </div>
            <div className="login-form-content scroll">
              <div className="register-form">
                <div
                  className="go-back-login"
                  onClick={() => setisRegistering("Menu Principal")}
                >
                  <FaArrowCircleLeft size={30} color="#ffffffd1" />
                </div>
                <form
                  className="register-form-content"
                  onSubmit={(event) => handleRegister(event)}
                >
                  <div className="Register-club-title">
                    <h2 style={{ fontSize: "2rem" }}>Registra tu Club</h2>
                    {/* <label className="label-login-form">
                    Rellena los siguientes campos para iniciar con el proceso de registro
                  </label> */}
                  </div>

                  <div className="register-club-inputs flipInY">
                    <div className="register-club-input">
                      <label className="label-login-form">
                        Correo Electrónico
                      </label>
                      <input
                        className="register-club-intext"
                        name="email"
                        // type="email"
                        placeholder="correo@gmail.com"
                        style={{ width: "100%" }}
                        value={email}
                        onChange={handleChangeRegister}
                        // onChange={(event) => setEmail(event.target.value)}
                        required
                      />
                      {erroremailregister !== "" && (
                        <span className="perfil-club-errorMessage">
                          {erroremailregister}
                        </span>
                      )}
                    </div>
                    <div className="register-club-input">
                      <label className="label-login-form">Contraseña</label>
                      <input
                        className="register-club-intext"
                        name="password"
                        type="password"
                        placeholder="********"
                        style={{ width: "100%" }}
                        value={password}
                        onChange={handleChangeRegister}
                        // onChange={(event) => setPassword(event.target.value)}
                        required
                      />
                      {errorpasswordregister !== "" && (
                        <span className="perfil-club-errorMessage">
                          {errorpasswordregister}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="register-btn">
                    <button type="submit" className="registro-boton">
                      Registrar mi club
                    </button>
                  </div>
                  {tooManyRequestRegister && (
                    <p className="Login-club-errorMessage">
                      {tooManyRequestRegister}
                    </p>
                  )}
                </form>
                <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-full tw-h-10% tw-gap-2">
                  <label className="tw-text-sm tw-font-objet tw-text-white" style={{ textAlign: "center" }}>
                    ¿Ya estas registrado?
                  </label>
                  <label className="tw-text-sm tw-font-objet tw-text-Verde-TenisCTA tw-cursor-pointer hover:tw-underline" onClick={() => setisRegistering("Login Club")}>
                    Inicia sesión
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      case "Login Club":
        return (
          <LoginComponent
            setisRegistering={setisRegistering}
            handleLogin={handleLogin}
            email={email}
            errorUser={errorUser}
            password={password}
            handleChangeLogin={handleChangeLogin}
            errorPassword={errorPassword}
            tooManyRequest={tooManyRequest}
            State={"Registro Club"}
            pregunta={"¿Eres dueño de un recinto tenístico?"}
            width={width}
            showLoginGoogle={false}
            backgroundUrl={false}
          />
        );
      case "Registro User":
        return (
          <div
            className="container-register-form flipRegister user-background"
            style={{ flexDirection: "row-reverse" }}
          >
            <div
              className="go-back-login"
              onClick={() => setisRegistering("Menu Principal")}
            >
              <FaArrowCircleLeft
                size={30}
                color={width < responsiveWidth ? "#000000d1" : "#ffffffd1"}
              />
            </div>
            <div className="login-form-content scroll">
              <div className="register-form">
                <form
                  className="register-form-content"
                  onSubmit={(event) => handleRegisterUser(event)}
                >
                  <div className="Register-user-title">
                    <h2 style={{ fontSize: "2rem" }}>Regístrate</h2>
                  </div>

                  <div className="register-user-inputs flipInY scroll">
                    <div className="register-club-input">
                      <label className="label-login-form">
                        Nombre y apellido
                      </label>
                      <input
                        className="register-club-intext"
                        name="nombre y apellido"
                        // type="email"
                        style={{ width: "100%" }}
                        value={nombre}
                        onChange={handleChangeRegisterUser}
                        // onChange={(event) => setEmail(event.target.value)}
                        required
                      />
                      {errorNombre !== "" && (
                        <span className="perfil-club-errorMessage">
                          {errorNombre}
                        </span>
                      )}
                    </div>
                    <div className="register-club-input">
                      <label className="label-login-form">Teléfono</label>
                      <PhoneInput
                        enableAreaCodes={true}
                        placeholder="Ingresa tu número de teléfono"
                        inputStyle={{
                          width: "100%",
                          border: "2px solid #efeff7",
                          backgroundColor: "transparent",
                          color: "white",
                          height: 40,
                        }}
                        buttonStyle={{ backgroundColor: "transparent" }}
                        enableSearch
                        localization={"es"}
                        regions={"america"}
                        country={"cl"}
                        value={telefono}
                        inputProps={{
                          name: "telefono",
                          required: true,
                          onChange: handleChangeRegisterUser,
                        }}
                        onChange={setTelefono}
                        enableLongNumbers={true}
                      />
                      {errorTelefono !== "" && (
                        <span className="perfil-club-errorMessage">
                          {errorTelefono}
                        </span>
                      )}
                    </div>
                    <div className="tittle-style">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label className="label-login-form">
                          Fecha de nacimiento
                        </label>
                        <div className="register-perfil-input-container">
                          <input
                            type="date"
                            className="register-perfil-input-container-select"
                            value={descomposeDate(fechaNacimiento)}
                            onChange={(event) =>
                              setFechaNacimiento(event.target.value)
                            }
                          />
                          {errorFechaNacimiento !== "" && (
                            <label className="perfil-club-errorMessage">
                              {errorFechaNacimiento}
                            </label>
                          )}
                        </div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label className="label-login-form">
                          Cual es tu sexo?
                        </label>
                        <div className="register-perfil-sex-container">
                          <div className="register-perfil-sex">
                            <Check
                              label="Hombre"
                              value="hombre"
                              id="hombre"
                              name="gender"
                              type="radio"
                              square
                              checked={sexo === "Hombre"}
                              onChange={() => setSexo("Hombre")}
                            />
                          </div>
                          <div className="register-perfil-sex">
                            <Check
                              label="Mujer"
                              value="mujer"
                              id="mujer"
                              name="gender"
                              type="radio"
                              square
                              checked={sexo === "Mujer"}
                              onChange={() => setSexo("Mujer")}
                            />
                          </div>
                        </div>
                        {errorSexo !== "" && (
                          <label className="perfil-club-errorMessage">
                            {errorSexo}
                          </label>
                        )}
                      </div>
                    </div>

                    {/* <div className='tittle-style'>

                    </div> */}
                    {/* <div className="register-club-input">
                    <label className="label-login-form">Selecciona tu categoria</label>
                    <div>{renderCategoria()}</div>
                  </div>  */}

                    <div className="register-club-input">
                      <label className="label-login-form">
                        Correo Electrónico
                      </label>
                      <input
                        className="register-club-intext"
                        name="email"
                        // type="email"
                        placeholder="correo@gmail.com"
                        style={{ width: "100%" }}
                        value={email}
                        onChange={handleChangeRegister}
                        // onChange={(event) => setEmail(event.target.value)}
                        required
                      />
                      {erroremailregister !== "" && (
                        <span className="perfil-club-errorMessage">
                          {erroremailregister}
                        </span>
                      )}
                    </div>
                    <div className="register-club-input">
                      <label className="label-login-form">Contraseña</label>
                      <input
                        className="register-club-intext"
                        name="password"
                        type="password"
                        placeholder="********"
                        style={{ width: "100%" }}
                        value={password}
                        onChange={handleChangeRegister}
                        // onChange={(event) => setPassword(event.target.value)}
                        required
                      />
                      {errorpasswordregister !== "" && (
                        <span className="perfil-club-errorMessage">
                          {errorpasswordregister}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="register-btn">
                    <button type="submit" className="registro-boton">
                      Registrate
                    </button>
                  </div>
                  {tooManyRequestRegister && (
                    <p className="Login-club-errorMessage">
                      {tooManyRequestRegister}
                    </p>
                  )}

                  {errorRegisterUser && (
                    <p className="Login-club-errorMessage">
                      {errorRegisterUser}
                    </p>
                  )}
                </form>
                <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-full tw-h-10% tw-gap-2">
                  <label className="tw-text-sm tw-font-objet tw-text-white" style={{ textAlign: "center" }}>
                    ¿Ya estas registrado?
                  </label>
                  <label className="tw-text-sm tw-font-objet tw-text-Verde-TenisCTA tw-cursor-pointer hover:tw-underline" onClick={() => setisRegistering("Login User")}>
                    Inicia sesión
                  </label>
                </div>
              </div>
            </div>
            <div className="register-club-message">
              <div>
                <h1 style={{ fontSize: "4.6vmin" }}>
                  Es un gusto tenerte entre nuestra comunidad
                </h1>
                <label className="label-login-form">
                  Mediante este registro podras entrar a nuestra comunidad de
                  deportistas
                </label>
              </div>
            </div>
          </div>
        );
      case "Login User":
        return (
          <LoginComponent
            setisRegistering={setisRegistering}
            handleLogin={handleLogin}
            email={email}
            errorUser={errorUser}
            password={password}
            handleChangeLogin={handleChangeLogin}
            errorPassword={errorPassword}
            tooManyRequest={tooManyRequest}
            State={"Registro User"}
            pregunta={"¿Aún no tienes una cuenta?"}
            width={width}
            reverse
            showLoginGoogle={true}
            backgroundUrl={true}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <Modal
        isOpen={showLogin}
        style={styles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => toggleModal()}
        overlayClassName="loginZindex1"
      >
        {LoginAndRegister({ toggleModal: toggleModal })}
        {/* <div className='modal-body'>
          <a onClick={() => setisRegistering(!isRegistering)}>No tienes cuenta? registrate aqui</a>
        </div>
        <div class='button'>
          <FB class='fb-login-button'>
            Inicia sesión con Facebook
          </FB>
        </div>

        <div class='button'>
          <Google class='google-login-button'>
            Inicia sesión con Google
          </Google>
        </div> */}

        {/* <label className="label-login-form">{helperText}</label> */}
      </Modal>
    </>
  );
};

const LoginComponent = ({
  setisRegistering,
  handleLogin,
  email,
  handleChangeLogin,
  errorUser,
  password,
  errorPassword,
  tooManyRequest,
  pregunta,
  State,
  width,
  showLoginGoogle,
  backgroundUrl,
}) => {
  return (
    <div
      className={
        backgroundUrl === true
          ? "container-login-form flipLogin login-userBackground"
          : "container-login-form flipLogin login-clubBackground"
      }
      style={{ flexDirection: "row-reverse" }}
    >
      <div className="login-form-content scroll">
        <div
          className="go-back-login"
          onClick={() => setisRegistering("Menu Principal")}
        >
          <FaArrowCircleLeft
            size={30}
            color={width < responsiveWidth || "#ffffffd1"}
          />
        </div>
        <form className="login-form" onSubmit={(event) => handleLogin(event)}>
          <div className="login-club-title">
            <h2 style={{ fontSize: "2rem" }}>Iniciar Sesión</h2>
          </div>
          <div className="login-inputs">
            <div className="login-input">
              <label className="label-login-form">Email</label>
              <div>
                <input
                  className="register-club-intext"
                  type="email"
                  placeholder="example@email.com"
                  value={email}
                  name="email"
                  onChange={handleChangeLogin}
                  // onChange={event => setEmail(event.target.value)}
                  style={{ width: "100%" }}
                  required
                />
                {errorUser !== "" && (
                  <label className="perfil-club-errorMessage">
                    {errorUser}
                  </label>
                )}
              </div>
            </div>
            <div className="login-input">
              <label className="label-login-form">Contraseña</label>
              <div>
                <input
                  className="register-club-intext"
                  type="password"
                  placeholder="*******"
                  value={password}
                  name="password"
                  onChange={handleChangeLogin}
                  // onChange={event => setPassword(event.target.value)}
                  style={{ width: "100%" }}
                  required
                />
                {errorPassword !== "" && (
                  <label className="perfil-club-errorMessage">
                    {errorPassword}
                  </label>
                )}
              </div>
            </div>
          </div>
          <div className="login-btn">
            <button
              type="submit"
              className="login-boton"
              style={{ backgroundColor: "white" }}
            >
              Iniciar sesión
            </button>
            <Link to="recuperar">¿Olvidaste tu contraseña?</Link>
          </div>
          {tooManyRequest && (
            <p className="Login-club-errorMessage">{tooManyRequest}</p>
          )}
        </form>
        {showLoginGoogle === true ? (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "35px",
              display: "flex",
              height: 0,
            }}
          >
            <Suspense fallback={<div></div>}>
              {/* <FB /> */}
              <Google />
            </Suspense>
          </div>
        ) : (
          ""
        )}
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-full tw-h-10% tw-gap-2">
          <label className="tw-text-sm tw-font-objet tw-text-white" style={{ textAlign: "center" }}>
            {pregunta}
          </label>
          <label className="tw-text-sm tw-font-objet tw-text-Verde-TenisCTA tw-cursor-pointer hover:tw-underline" onClick={() => setisRegistering(State)}>
            Regístrate
          </label>
        </div>
      </div>
      <div className="register-club-message">
        <div>
          <h1 style={{ fontSize: "4.6vmin" }}>
            Bienvenido devuelta a las canchas
          </h1>
          <label className="label-login-form">
            Nos alegra tenerte de vuelta, en tenis up podrás unirte a
            campeonatos ver la disponibilidad de canchas de tus clubes cercanos
          </label>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(LoginModal);
