import { API } from "../../config/Config";
import { deleteObject, getDownloadURL, ref as refStorage, uploadBytes } from "firebase/storage";
import { db, storage } from "../../firebase/firebase";
import { imageSizes } from "../../costants/size";
import { ref, set } from "firebase/database";
const axios = require("axios");
const headers = { "Content-Type": "application/json" };

export const checkOptimizedRoutes = async (dbRoutePath, routesConfig) => {
  const url = `${API}/images/updatefireobject`;
  try {
    const result = await axios.post(
      url,
      {
        dbRoutePath,
        routesConfig,
      },
      {
        headers: headers,
      }
    );
    return result.data;
  } catch (err) {
    console.error("🚀 ~ file: Images.service.js err", err);
  }
};

export const uploadImage = async (path, blob, dbPath) => {
  let storageRef = refStorage(storage, path);
  await uploadBytes(storageRef, blob);
  const imagenPath = await getDownloadURL(storageRef);
  if (dbPath) {
    await set(ref(db, dbPath), imagenPath);
  }
  return imagenPath;
};
export const deleteImage = async (path) => {
  let storageRef = refStorage(storage, path);
  return await deleteObject(storageRef);
};
export const deleteOptimizedImages = async (pathBase) => {
  let response = null;
  try {
    response = await Promise.all(
      imageSizes.map(async (size) => await deleteImage(`${pathBase}_${size}.jpg`))
    );
  } catch (error) {
    response = false;
  }
  return response;
};
