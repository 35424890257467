export const colors = {
  Verde: {
    TenisCTA: "#DCEC00",
    seconCTA: "#BDDA2B",
    hover: "#95C11F",
  },
  Turquesa: "#2085B7",
  AzulWarm: "#002688",
  GrayTenis: "#7A7A7A",
};
