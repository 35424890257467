import Thumbnails from '../../assets/thumb2.png'
import { changePestañaSelected } from "../../actions/header";
import { connect } from "react-redux";

//style
import '../../styles/Noticias.css'

function mapStateToProps(state) {
    return state
}


const AUnToque = (props) => {
    const { dispatch } = props;
    const cambiarContenido = (selected) => {
        dispatch(changePestañaSelected(selected))
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div className='noticia-content'>
             <div className='noticia-text-container'>
                <h1>JUGADORES EN TU REGION A 1 TOQUE</h1>
                <p>Con Tenis-Up encontrar jugadores cerca es más fácil que nunca.</p>
            </div>
            <div className='noticia-screenshots-container'>
                <img alt="noticia" className='noticia-screenshots' src={Thumbnails} />
                <button className="noticias-button" onClick={() => cambiarContenido('instrucciones')}>VER COMO <b>FUNCIONA</b></button>
            </div>
           
        </div>
    )
}
export default connect(mapStateToProps)(AUnToque)



