import { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

//Styles
import "./App.css";

//Components
import Paid from "./components/Paid";

import { connect } from "react-redux";
import RecoverPassword from "./pages/RecoverPassword";
import ProtectedRoutes from "./components/ProtectedRoutes";
import PerfilClub from "./components/dashboard/PerfilClub";
import Dashboard from "./components/dashboard/Dashboard";
import BasesDetails from "./pages/eventDetails/BasesDetails";
import DetailEvent from "./components/events/DetailEvent";
import MyEventsPage from "./components/events/MyEventsPage";
import Loading from "./pages/Loading";

//Pages
import CreatePage from "./pages/CreatePage";
import ClubWelcome from "./pages/WelcomeClub";
import InscriptionPage from "./pages/InscriptionPage";
import Home from "./pages/DashboardPage";
import MapEvents from "./pages/MapEvents";
import ActionMode from "./pages/ActionMode";
import Inscritos from "./pages/Inscritos";
import Cuadros from "./pages/eventDetails/Cuadros";
import Videos from "./pages/Videos";
import UploadImages from "./pages/UploadImages";
import EventsPage from "./components/events/EventsPage";
import Clubes from "./components/clubes/Clubes";
import Jugadores from "./components/dashboard/Jugadores";
import MyRents from "./components/MyRents";
import Ranking from "./components/ranking-matchup/Ranking";
import HomePage from "./pages/HomePage";
import NotFound from "./pages/NotFound";

const RentCourtPage = lazy(() => import("./pages/RentCourtPage"));
const Configuracion = lazy(() => import("./components/dashboard/Configuracion"));
const Profile = lazy(() => import("./components/dashboard/Profile"));

//Redux
function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
  };
}

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Toaster
          position="top-center"
          toastOptions={{
            duration: 2000,
            style: {
              maxWidth: 800,
            },
          }}
        />
        <Routes>
          <Route element={<ProtectedRoutes />}>
            <Route element={<Home />}>
              <Route path="explore" element={<EventsPage />} />
              <Route path="home" element={<Navigate to="/explore" replace />} />
              <Route path="index" element={<Navigate to="/explore" replace />} />
              <Route path="myevents" element={<MyEventsPage />} />
              <Route path="myrents" element={<MyRents />} />
              <Route path="perfil">
                <Route path="me" element={<Profile />} />
              </Route>
              <Route path="/clubs" element={<Clubes />} />
              <Route path="/creartorneo" element={<CreatePage />} />
              <Route path="/subir-imagen" element={<UploadImages />} />
              <Route path="/players" element={<Jugadores />} />
              <Route path="/ranking" element={<Ranking />} />
              <Route path="/editprofile" element={<Configuracion />} />
              <Route path="event">
                <Route path="me" element={<Navigate to="/myevents" replace />} />
              </Route>
            </Route>

            <Route path="event">
              <Route
                path=":eventId"
                element={<Dashboard component={<DetailEvent viewInscritos={true} />} />}
              />
              <Route path=":eventId/register" element={<Dashboard component={<Inscritos />} />} />
              <Route
                path=":eventId/tournament-tables"
                element={<Dashboard component={<Cuadros />} />}
              />
              <Route
                path=":eventId/bases-details"
                element={<Dashboard component={<BasesDetails />} />}
              />
            </Route>

            <Route path="/club/:clubUid" element={<Dashboard component={<PerfilClub />} />} />
          
            <Route path="/arriendo/:courtKey" element={<RentCourtPage />} />
            <Route path="/inscripcion/:eventId" element={<InscriptionPage />} />

            <Route path="/voucher/:token" element={<Paid />} />
            <Route path="/welcome/club" element={<ClubWelcome />} />
          </Route>
          <Route path="/mapa" element={<MapEvents />} />
          <Route path="/" element={<HomePage />} />
          <Route path="recuperar" element={<RecoverPassword />} />
          <Route path="action" element={<ActionMode />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default connect(mapStateToProps)(App);
