import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import Welcome from "./WelcomeClub";
import EditProfileClub from "../clubes/config/EditProfileClub";
// import EditCancha from "../clubes/config/EditCancha";

import { useNavigate } from "react-router-dom";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { logoutUser } from "../../actions/auth";
import { connect } from "react-redux";
import CanchaContainer from "../clubes/config/CanchaContainer";
import toast from "react-hot-toast";
import { updateClub } from "../../services/clubs/club.service";
import { auth } from "../../firebase/firebase";
import ServiceContainer from "../clubes/config/ServiceContainer";
import classNames from "classnames";

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated,

    club: state.club,
    isUpdatingClub: state.club.isUpdatingClub,
    clubUpdated: state.club.clubUpdated,
    updateClubError: state.club.updateClubError,
  };
}

const muiTheme = createTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        color: "red", // or 'rgba(0, 0, 0, 1)'
        "&$active": {
          color: "green",
        },
        "&$completed": {
          color: "green",
        },
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(0.5),
  },
  instructions: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  buttonFixedBottom: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "25px",
    width: "100%",
  },
}));

function getSteps() {
  return ["Bienvenido a Tenis-Up", "Información General", "Canchas", "Servicios"];
}

function HorizontalLinearStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const SM = useMediaQuery("(max-width:600px)");
  const [settings, setSettings] = useState({});
  const [submit, setSubmit] = useState();
  const [isValid, setIsValid] = useState(false);
  //General Information
  //Courts
  const [courts, setCourts] = useState([]);

  //Services
  const [services, setServices] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (props.clubUpdated) {
      setTimeout(() => {
        navigate("/explore");
      }, [2000]);
    }
  }, [props.clubUpdated, props.isUpdatingClub, navigate]);

  const createClub = async () => {
    try {
      let uid = auth.currentUser.uid;
      const { lat, lng } = settings;
      courts.forEach((court) => {
        court.lat = parseFloat(lat);
        court.lng = parseFloat(lng);
      });
      let data = {
        settings: { ...settings, lat: parseFloat(lat), lng: parseFloat(lng) },
        uid,
        courts,
        services,
        tipo_usuario: "club",
      };
      await updateClub(uid, { club: data, logo: settings.logo });
      toast.success("el Club se ha creado correctamente");
    } catch (error) {
      console.log(error);
      toast.error("Se ha producido un error");
    }
  };

  useEffect(() => {
    if (activeStep !== 1) setSubmit(null);
  }, [activeStep]);

  const isStepOptional = (step) => {
    return step === 2;
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    submit?.click();
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(activeStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const onClickLogout = () => {
    const { dispatch } = props;
    dispatch(logoutUser());
  };

  const checkAnimation = () => (
    <svg class="checkmark" viewBox="0 0 52 52">
      <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
      <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  );
  const isButtonDisabled = useMemo(() => {
    if (activeStep === 1) return !isValid;
    return props.isUpdatingClub || props.clubUpdated;
  }, [activeStep, isValid, props.clubUpdated, props.isUpdatingClub]);

  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={muiTheme}>
        {
          <Stepper activeStep={activeStep} orientation={SM ? "vertical" : "horizontal"}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = <Typography variant="caption">Opcional</Typography>;
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        }
      </MuiThemeProvider>

      <div>
        {activeStep === steps.length ? (
          <div style={classes.buttonFixedBottom}>
            <Typography className={classes.instructions}>
              Se han completado todos los pasos - Haz finalizado
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reiniciar
            </Button>
          </div>
        ) : (
          <div
            className={classNames(
              { "tw-mb-24 xs:tw-mb-0": activeStep === 2 || activeStep === 3 },
              "tw-flex tw-w-auto tw-flex-col tw-items-center tw-relative"
            )}
          >
            {activeStep === 0 && <Welcome />}
            {activeStep === 1 && (
              <EditProfileClub
                welcomeClubSetup
                settings={settings}
                setIsValid={setIsValid}
                setSubmit={setSubmit}
                onSubmit={(settings) => {
                  setSettings(settings);
                }}
              />
            )}
            {activeStep === 2 && (
              <CanchaContainer
                welcomeClubSetup
                courts={courts}
                setCourts={(court) => setCourts(court)}
              />
            )}
            {activeStep === 3 && (
              <ServiceContainer
                welcomeClubSetup
                services={services}
                setServices={(service) => setServices(service)}
              />
            )}

            <div className={classes.buttonFixedBottom}>
              <Button onClick={() => onClickLogout()} className={classes.button}>
                Cerrar Sesión
              </Button>
              <div>
                <Button onClick={handleBack} className={classes.button}>
                  Atras
                </Button>
                <Button
                  disabled={isButtonDisabled}
                  variant="contained"
                  color="primary"
                  onClick={() => (activeStep === steps.length - 1 ? createClub() : handleNext())}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? (
                    <>
                      {props.isUpdatingClub && <CircularProgress size={30} color="white" />}
                      {props.clubUpdated ? <>{checkAnimation()} Finalizado</> : "Finalizar"}
                    </>
                  ) : (
                    "Siguiente"
                  )}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(HorizontalLinearStepper);
