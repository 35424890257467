import Input from "../ui/Input";
import Select from "../ui/Select";
import { MdSearch } from 'react-icons/md';
import categoryOptions from "../../costants/ranking/categoria.json";
import Countryoptions from "../../costants/ranking/paises.json";

const RenderRank = ({
    mobile,
    filterUsers,
    setFilterUser,
    filterCountries,
    setFilterCountries,
    filterForCategory,
    setFilterCategoria,
    filterType,
    setFilterType,
    TypeRanking,
    renderTable,
}) => {
    return (
        <div style={{ width: "100%" }}>
                        <div className="main-container-activado-3" style={{ backgroundColor: "white" }}>
                            <div
                                style={{
                                    paddingInline: mobile && 12,
                                    width: "100%",
                                }}
                            >
                                <p className='title-myrents'> Acá podrás revisar el ranking de los jugadores de Tenis-Up!</p>
                            </div>
                            <div className='tw-flex tw-flex-col tw-mt-5 tw-gap-0 tw-ml-0 xs:tw-ml-4  xs:tw-flex-row xs:tw-gap-5'>
                                <div style={{ width: "none", position: "relative" }}>
                                    <Input
                                        containerStylesOff
                                        full={mobile}
                                        value={filterUsers}
                                        placeholder='Filtrar por nombre'
                                        onChange={(event) => setFilterUser(event.target.value)}
                                        Icon={
                                            <MdSearch color="#C9C9E3" size="20" style={{ marginLeft: 10 }} />
                                        }
                                        containerStyles={{ width: "100%" }}
                                    />
                                </div>

                                <div style={{ maxWidth: !mobile && 220, width: "100%" }}>
                                    <Select
                                        containerStylesOff
                                        value={filterCountries}
                                        placeholder="Selecciona un país"
                                        onChange={(event) => setFilterCountries(event?.value || "")}
                                        options={Countryoptions}
                                        isClearable
                                    // containerClass={containerClass}
                                    />
                                </div>
                                <div style={{ maxWidth: !mobile && 220, width: "100%" }}>
                                    <Select
                                        containerStylesOff
                                        value={filterForCategory}
                                        placeholder="Selecciona una categoria"
                                        onChange={(event) => setFilterCategoria(event?.value || "")}
                                        options={categoryOptions}
                                        isClearable
                                    // containerClass={containerClass}
                                    />
                                </div>
                                <div style={{ maxWidth: !mobile && 220, width: "100%" }}>
                                    <Select
                                        containerStylesOff
                                        value={filterType}
                                        placeholder="Selecciona tipo de ranking"
                                        onChange={(event) => setFilterType(event?.value || "")}
                                        options={TypeRanking}
                                    // containerClass={containerClass}
                                    />
                                </div>
                            </div>
                            {renderTable}
                        </div >
                    </div>
    )

}

export default RenderRank