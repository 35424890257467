export const handleChangePicture = async ({ Name, files, handleOnload }) => {
  if (Name && files.length === 1) {
    const file = files[0];
    const fileReader = new FileReader();
    const filename = file?.name;
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      const auxCuadroTorneo = {
        file: fileReader.result,
        filename: filename,
        blob: file,
      };
      handleOnload?.(auxCuadroTorneo, Name);
    };
  }
};
