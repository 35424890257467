const LoadingSuccess = ({ disabled, text = "" }) => {
  return (
    <button disabled={disabled} className="config-perfil-button-success">
      <div>{text}</div>
      <svg className="checkmark" viewBox="0 0 52 52">
        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
    </button>
  );
};

export default LoadingSuccess;
