import { createRef, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { updateCourt, defaultCourt, createCourt } from "../../../actions/clubs";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BiImageAdd } from "react-icons/bi";
import CircularProgress from "@material-ui/core/CircularProgress";
import SingleWeekCalendar2 from "../../calendar/SingleWeekCalendar2";
// import Scheduler from "../../scheduler";
import { weektemplate } from "../../../costants/Time";
import {
  materialOptionList,
  TypeOptionList,
  materialPadelOptionList,
} from "../../../costants/courtOptions";
import Input from "../../ui/Input";
import NumberInput from "../../ui/NumberInput";
import Select from "../../ui/Select";
import moment from "moment";
import toast from "react-hot-toast";
import "../../../styles/CrearCancha.css";

function mapStateToProps(state) {
  return {
    events: state.events,
    event: state.events.event,
    singleEventFetched: state.events.singleEventFetched,
    courtUpdated: state.club.courtUpdated,
    isUpdatingCourt: state.club.isUpdatingCourt,
    isCreatingCourt: state.club.isCreatingCourt,
    createCourtError: state.club.createCourtError,
    courtCreated: state.club.courtCreated,
    info: state.auth.info,
  };
}

const schema = yup
  .object({
    name: yup.string().required("Inserte un nombre").min(6, "El nombre de la cancha es muy corto"),
    price: yup
      .number()
      .required("Ingrese el precio de arriendo")
      .min(5000, "El precio minimo debe ser de $5.000"),
    type: yup.string().required("Seleccione un tipo de cancha"),
    material: yup.string().required("Seleccione el material de la cancha"),
    description: yup
      .string()
      .required("Inserte una descripción")
      .max(100, "La descripcion debe contar con un maximo de 100 caracteres"),
  })
  .required();

const NewCancha = (props) => {
  const dispatch = useDispatch();
  const {
    welcomeClub,
    courtUpdated,
    updateCourtError,
    isUpdatingCourt,
    courtCreated,
    isCreatingCourt,
    createCourtError,
    selectedCourt,
    adding,
  } = props;
  const {
    control,
    register,
    watch,
    setValue,
    reset,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const [{ key, image, utc }, setFormData] = useState({
    key: "",
    image: "",
    utc: moment().format("Z"),
  });
  const [week, setWeek] = useState(weektemplate());

  let fileRef = createRef();

  useEffect(() => {
    dispatch(defaultCourt());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCourt) {
      var {
        key,
        name,
        material,
        description,
        price,
        type,
        image: imagen_,
        week: week_,
      } = selectedCourt.court;
      reset({ name, material, price, type, description });
      setFormData((value) => {
        return { ...value, key, image: imagen_ };
      });
      setWeek(week_);
    }
  }, [selectedCourt, reset]);

  const handleAddCourt = (data) => {
    let filteredWeek = {};
    Object.entries(week).forEach(([key, day]) => {
      filteredWeek[key] = {
        name: day.name,
        disponibility: day.disponibility,
        hour: day.hour ?? null,
      };
    });
    const { name, material, description, price, type } = data;
    let court = {
      key,
      name,
      material,
      description,
      price,
      image,
      type,
      week: filteredWeek,
      club_uid: props.info.uid,
      utc,
    };
    if (welcomeClub) {
      if (selectedCourt) {
        toast.success("La cancha se ha editado correctamente");
        props.setCourts((prevState) => {
          let tempCourts = prevState;
          tempCourts[selectedCourt.index] = court;
          return tempCourts;
        });
      } else {
        toast.success("La cancha se agregó correctamente");
        props.setCourts((prevState) => [...prevState, court]);
      }
    } else {
      if (selectedCourt) {
        dispatch(updateCourt(props.info.uid, court));
      } else {
        dispatch(createCourt(court, props.info.uid));
        console.log("Success");
      }
    }
    if (welcomeClub) {
      adding(false);
    }
  };

  useEffect(() => {
    if (!isUpdatingCourt || !isCreatingCourt) {
      if (courtUpdated || courtCreated) {
        adding(false);
      } else if (updateCourtError || createCourtError) {
        adding(true);
      }
    }
  }, [
    courtUpdated,
    updateCourtError,
    isUpdatingCourt,
    createCourtError,
    courtCreated,
    isCreatingCourt,
    adding,
  ]);
  const type = watch("type");
  useEffect(() => {
    if (type === "padel") {
      setValue("material", "sintetico", { shouldValidate: true, shouldDirty: true });
    } else if (getValues("material")) {
      setValue(
        "material",
        materialOptionList.find((m) => m.value === getValues("material"))?.value || "",
        { shouldValidate: true, shouldDirty: true }
      );
    }
  }, [type, getValues, setValue]);

  const handleWeek = (week) => {
    setWeek({ ...week });
  };
  const handleChangePicture = async (event) => {
    event.preventDefault();
    if (event.target?.files.length === 1) {
      const file = event.target?.files[0];
      const fileReader = new window.FileReader();
      const filename = file?.name;
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setFormData((value) => {
          return { ...value, image: { file: fileReader.result, filename: filename, blob: file } };
        });
      };
    }
  };

  const UpdateData = () => {};

  return (
    <div className="tw-w-full tw-max-w-7xl tw-text-center tw-flex, sm:tw-px-10">
      <form onSubmit={handleSubmit(handleAddCourt)} className="tw-flex tw-flex-wrap tw-gap-x-5">
        <div className="column-img-court tw-flex tw-justify-center tw-bg-slate-100 tw-flex-grow tw-basis-60 tw-max-w-3xl tw-max-h-72 tw-overflow-hidden">
          {image !== "" && (
            <img
              src={image.file ? image.file : image}
              alt="Imagen de la Cancha"
              className="tw-w-auto tw-h-auto"
            />
          )}
          <div id={image?.file || image !== "" ? "new-court-photo-added" : "new-court-photo"}>
            <BiImageAdd color="#333" size={image?.file || image !== "" ? 30 : 100} />
            <input
              type="file"
              accept="image/png, .jpeg, .jpg"
              ref={fileRef}
              onChange={handleChangePicture}
            />
          </div>
        </div>
        <div className="tw-flex-wrap tw-flex tw-flex-grow-max tw-basis-0 tw-min-w-2/3 tw-gap-x-5 tw-max-w-3xl">
          <Input
            {...register("name")}
            placeholder="Cancha numero 1"
            error={errors.name?.message}
            label="Nombre de la cancha"
            containerClass="tw-flex-1 tw-basis-64"
          />
          <NumberInput
            {...register("price")}
            placeholder="$10.000"
            error={errors.price?.message}
            label="Precio"
            decimal={2}
            containerClass="tw-flex-1 tw-basis-64"
          />
          <Controller
            name="type"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Select
                label="Tipo de Cancha"
                name={name}
                value={value}
                placeholder="Seleccione el tipo de cancha"
                onChange={({ value }) => onChange({ target: { name, value } })}
                options={TypeOptionList}
                error={errors.type?.message}
                containerClass="tw-flex-1 tw-basis-64"
              />
            )}
          />
          <Controller
            name="material"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Select
                label="Material"
                name={name}
                value={value}
                placeholder="Seleccione un tipo de material"
                onChange={({ value }) => onChange({ target: { name, value } })}
                options={type === "padel" ? materialPadelOptionList : materialOptionList}
                error={errors.material?.message}
                containerClass="tw-flex-1 tw-basis-64"
              />
            )}
          />
          <div className="add-court-textarea tw-min-w-full tw-flex-1">
            <label className="court-event-label">Descripción de la cancha</label>
            <textarea
              {...register("description")}
              style={{ height: "90px" }}
              className={
                errors.description?.message ? "court-club-error" : "court-event-intext-area"
              }
              placeholder="Ingrese la descripcion de la cancha"
            />
            {errors.description?.message > 0 && (
              <span className="court-club-errorMessage">{errors.description.message}</span>
            )}
          </div>
        </div>
        <div id="container-new-cancha-calendar" className="tw-w-full">
          <div id="filtros-content-cancha">
            <h2 className="rent">Disponibilidad de arriendos</h2>
          </div>
          <SingleWeekCalendar2
            numberDay={7}
            isClub={true}
            week={week}
            setWeek={(time) => handleWeek(time)}
            selectedDay={week.Monday}
            setselectedDay={() => {}}
            UpdateData={() => {
              UpdateData();
            }}
            price={selectedCourt?.price}
            containerScrollID="#newCanchascroll"
            staticWeek
          />
        </div>
        <div
          className="service-club-btn service-club-bg-btn"
          style={{
            position: "fixed",
            height: 115,
            bottom: 0,
            width: "100%",
            backgroundColor: "#fff",
          }}
        ></div>
        <div className="service-club-btn">
          <button
            className="information-service-button"
            disabled={!isValid || isUpdatingCourt || isCreatingCourt}
            style={
              !isValid || isUpdatingCourt || isCreatingCourt
                ? { opacity: 0.5, transform: "none" }
                : { opacity: 1 }
            }
          >
            {(isUpdatingCourt || isCreatingCourt) && <CircularProgress size={18} color="white" />}
            {props?.selectedCourt !== "" ? "Guardar Cambios" : "Agregar Cancha"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default connect(mapStateToProps)(NewCancha);
