import {
    REGISTER_COURTRENT_REQUEST,
    REGISTER_COURTRENT_SUCCESS,
    REGISTER_COURTRENT_FAILURE,
    CANCEL_COURTRENT_REQUEST,
    CANCEL_COURTRENT_SUCCESS,
    CANCEL_COURTRENT_FAILURE,
    CLEAN_REGISTRATION_COURTRENT
} from '../actions/rent';

const rentState = (
    state = {
        //arriendo de cancha
        isRenting: false,
        isRented: false,
        errorRenting: false,

        //Cancelación de arriendo
        isCancelingRenting: false,
        isRentingCanceled: false,
        errorCancelingRent: false
    },
    action
) => {
    switch (action.type) {
        //arriendo de cancha
        case REGISTER_COURTRENT_REQUEST:
            return {
                ...state,
                isRenting: true,
                errorRenting: false
            };
        case REGISTER_COURTRENT_SUCCESS:
            return {
                ...state,
                isRenting: false,
                isRented: true,
            };
        case REGISTER_COURTRENT_FAILURE:
            return {
                ...state,
                isRenting: false,
                isRented: false,
                errorRenting: true
            };

        //cancelación arriendo de cancha
        case CANCEL_COURTRENT_REQUEST:
            return {
                ...state,
                isCancelingRenting: true,
                errorCancelingRent: false
            };
        case CANCEL_COURTRENT_SUCCESS:
            return {
                ...state,
                isCancelingRenting: false,
                isRentingCanceled: true,
            };
        case CANCEL_COURTRENT_FAILURE:
            return {
                ...state,
                isCancelingRenting: false,
                isRentingCanceled: false,
                errorCancelingRent: true
            };
        //cleanup
        case CLEAN_REGISTRATION_COURTRENT:
            return {
                ...state,
                isRenting: false,
                isRented: false,
                errorRenting: false,
                isCancelingRenting: false,
                isRentingCanceled: false,
                errorCancelingRent: false
            }
        default:
            return state;
    }
}

export default rentState;
