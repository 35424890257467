import { requestMachPayment } from '../services/mach/Mach';

export const PAYMENT_REQUEST = "LOGIN_REQUEST";
export const PAYMENT_SUCCESS = "LOGIN_SUCCESS";
export const PAYMENT_FAILURE = "LOGIN_FAILURE";

const requestPayment = () => {
  return {
    type: PAYMENT_REQUEST
  };
};

const receivePayment = payment => {
  return {
    type: PAYMENT_SUCCESS,
    payment
  };
};

const paymentError = () => {
  return {
    type: PAYMENT_FAILURE
  };
};

export const payWithMach = () => dispatch => {
  dispatch(requestPayment());
  requestMachPayment()
  .then(response => dispatch(receivePayment(response)))
  .catch(() => dispatch(paymentError));
};
