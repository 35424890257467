import { useMemo, useState, useEffect, Fragment, useCallback } from "react";
import { inscription, isString } from "../../services/transactions/Transaction.service";
import { useNavigate, useParams } from "react-router";

import "../../styles/Inscripcion.css";
// import Thumbnails from "../../assets/tournament.png";
// import perfil from "../../assets/perfil.png";
import CashButton from "./CashButton";
import MachButton from "./MachButton";

// import { keyframes } from "styled-components";

import LogoTennisUp from "../../assets/logo-tenisup.png";

//redux
import { connect } from "react-redux";

//services
import { Navigate } from "react-router-dom";
import MachDetails from "./MachDetails";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { validatePrivacidad } from "../../actions/events";
import toast from "react-hot-toast";
import { useUser } from "../../context/UserContext";

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    event: state.events.event,

    singleEventFetched: state.events.singleEventFetched,
    fetchSingleEventError: state.events.fetchSingleEventError,

    isRegistering: state.register.isRegistering,
    isRegistered: state.register.isRegistered,
    errorRegistering: state.register.errorRegistering,
    eventReplace: state.register.eventReplace,

    singlePlayer: state.jugador.singlePlayer,
  };
}

const Inscripcion = (props) => {
  const { eventId } = useParams("eventId");
  const { event, eventReplace, singlePlayer } = props;
  const { precio } = event || {};
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [waitRegister, setWaitRegister] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const { tipo_usuario, user, settings } = useUser();

  const [transaction] = useState({
    amount: precio || 0,
    key_torneo: event.id,
    uid: user?.uid,
  });
  const total = useMemo(() => {
    const multiplicador = singlePlayer ? 2 : 1;
    return precio * multiplicador;
  }, [singlePlayer, precio]);

  const handlePay = async () => {
    if (waitRegister) return;
    const isAmountANumber = isString(total);
    if (isAmountANumber && (eventReplace || settings.categoria)) {
      const { label, value, key, isDisabled, ...data } = singlePlayer || {};
      const responseCreate = await inscription(
        { ...transaction, amount: total },
        {
          categoria: eventReplace || settings.categoria,
          singlePlayer: data,
        }
      );
      if (!responseCreate) {
        toast.error("Ha ocurrido un problema!");
        return;
      }
      const url = responseCreate.url;
      window.open(url, "_self");
    } else {
      console.log(["Ha ocurrido un problema!", "Sólo se permiten números.", "error"]);
      return;
    }
  };

  useEffect(() => {
    if (!props.singleEventFetched) {
      setLoading(true);
    }
  }, [props.singleEventFetched]);

  const renderMachMethod = () => {
    return <MachDetails event={event} />;
  };

  const sendRegister = async () => {
    if (waitRegister) return;
    const isAmountANumber = isString(total);
    if (isAmountANumber && (eventReplace || settings.categoria)) {
      const { label, value, key, isDisabled, ...data } = singlePlayer || {};
      setPaymentMethod("efectivo");
      setWaitRegister(true);
      const responseCreate = await inscription(
        { ...transaction, amount: total },
        {
          payment_method: "efectivo",
          categoria: eventReplace || settings.categoria,
          singlePlayer: data,
        }
      );
      if (responseCreate) {
        navigate("/explore");
        toast.success("Se ha inscrito con Éxito", { duration: 2000 });
      } else {
        toast.error("No se ha podido completar la Inscripción", { duration: 2000 });
      }
      setWaitRegister(false);
    } else {
      console.log(["Ha ocurrido un problema!", "Sólo se permiten números.", "error"]);
      return;
    }
  };

  useEffect(() => {
    if (props.errorRegistering) {
      alert("Error al registrarse");
    }
  }, [props.errorRegistering]);

  const renderContent = () => {
    switch (paymentMethod) {
      case "mach":
        return renderMachMethod();
      default:
        return (
          <Fragment>
            <div className="pago-container">
              <MachButton onPress={() => handlePay()} amount={total} />
              {/* <PagoOnline /> */}
              <CashButton onPress={() => sendRegister()} amount={total} />
            </div>
          </Fragment>
        );
    }
  };

  const goBack = useCallback(() => {
    if (eventId) {
      navigate("/event/" + eventId);
    }
  }, [navigate, eventId]);

  useEffect(() => {
    if (event && !validatePrivacidad(event, tipo_usuario)) goBack();
  }, [tipo_usuario, event, goBack]);

  if (loading) {
    return (
      <>
        {eventId ? (
          <Navigate to={"/event/" + eventId} replace />
        ) : (
          <Navigate to="/explore" replace />
        )}
      </>
    );
  } else {
    return (
      <div className="shadow-lg">
        <div className="container-info-event">
          <div className="info-user">
            <div onClick={() => goBack()} style={{ width: "fit-content" }}>
              <FaArrowAltCircleLeft style={{ cursor: "pointer" }} size="40" />
            </div>
            <h1>Inscripción a torneo</h1>
            <div className="user-profile">
              <img className="perfil-img" src={settings.imagen} alt="Imagen de Perfil" />
              <h3>{settings.nombre}</h3>
              <p>¿No eres tú?</p>
            </div>
          </div>
          <div className="info-container">
            <div className="info-content">
              <h2>Detalle de pago</h2>
              <p>
                Nombre del producto:{" "}
                <b>
                  {event.nombre} {event.tipo} Inscripción
                </b>
              </p>
              <p>
                Fecha Inicio de inscripción: <b>{event.fechaInicio}</b>
              </p>
              <p>
                Fecha Limite de inscripción: <b>{event.fechaLimite}</b>
              </p>
              <p>
                Precio inscripción: <b>${precio}</b>
              </p>
              {singlePlayer && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ marginRight: 20 }}>Compañero de equipo:</p>{" "}
                  <div style={{ backgroundColor: "#dcec001f", padding: 16 }}>
                    {singlePlayer.label}
                  </div>
                </div>
              )}
              <p>
                Total: <b>${total}</b>
              </p>
            </div>
          </div>
          {renderContent()}
          <div className="logo-end">
            <img src={LogoTennisUp} alt="logo" />
          </div>
          {props.isRegistered && <Navigate to="/myevents" replace/>}
        </div>
      </div>
    );
  }
};

export default connect(mapStateToProps)(Inscripcion);

// const LoadingContainer = styled.div`
//   display: flex;
//   flex: 1;
//   height: 50vh;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
// `;

// const Title = styled.h2`
//   font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
//   font-weight: bold;
//   font-size: 24px;
//   text-align: center;
//   margin-top: 24px;
// `;

// const LoadingKeyframes = keyframes`
//     0% {
//       transform: rotate(0deg);
//     }

//     100% {
//       transform: rotate(360deg);
//     }
// `;

// const Loading = styled.div`
//   display: inline-block;
//   position: relative;
//   width: 48px;
//   height: 48px;
//   margin-top: 8px;

//   div {
//     box-sizing: border-box;
//     display: block;
//     position: absolute;
//     width: 48px;
//     height: 48px;
//     margin: 8px;
//     border: 6px solid #000;
//     border-radius: 50%;
//     animation: ${LoadingKeyframes} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
//     border-color: #000 transparent transparent transparent;
//   }

//   div:nth-child(1) {
//     animation-delay: -0.45s;
//   }

//   div:nth-child(2) {
//     animation-delay: -0.3s;
//   }
//   div:nth-child(3) {
//     animation-delay: -0.15s;
//   }
// `;
