import { Component } from 'react'


import disp from '../../../assets/instrucciones/disp.jpeg'


import '../../../styles/Disp.css'




export default class Disp extends Component {
    render() {
        return (
            <div className='disp-content'>
                <div className='disp-screenshots-container'>
                    <img alt="disponibilidad" className='disp-screenshots' src={disp} />
                </div>
                <div className='disp-text-container'>
                    <h1>Agrega tu disponibilidad</h1>
                    <p>Agregando esta información, podrás encontrar match con otros tenistas en tus horarios libres!</p>
                </div>
            </div>
        )
    }
}