import { ref, update } from "firebase/database";
import { addCourt, addService } from "../../actions/clubs";
import { db } from "../../firebase/firebase";
import { uploadImage } from "../../services/images/images.service";

export const updateClub = async (clubuid, { club, logo } = {}) => {
  if (clubuid) {
    try {
      if (logo?.blob) {
        const { blob } = logo;
        delete club.settings.logo;
        uploadImage(`clubes/${club.uid}.jpg`, blob, `clubes/${clubuid}/settings/logo`);
      }

      if (club?.courts) {
        let databaseRef = ref(db, `clubes/${club.uid}/courts`);
        await Promise.all(
          club.courts.map(async (court) => {
            await addCourt(databaseRef, court, club.uid, club.settings);
          })
        );
        delete club.courts;
      }
      if (club?.services) {
        let databaseRef = ref(db, `clubes/${club.uid}/services`);
        await Promise.all(
          club.services.map(async (service) => {
            await addService(databaseRef, service, club.uid);
          })
        );
        delete club.services;
      }
      await update(ref(db, `clubes/${clubuid}/`), club);
      console.log("success");
    } catch (error) {
      console.log(error);
      // dispatch(errorUpdateClub());
    }
  }
  //dispatch(requestUpdateClub());
};
