import { useEffect, useState } from 'react';
import perfil from '../../assets/perfil.png';

const SimpleUser = ({ imagen, nombre, uid, defaultImg }) => {
  const [imgError, setImgError] = useState(false);
  useEffect(() => {
    if (imagen) {
      setImgError(false);
    }
  }, [imagen]);

  return (
    <div className="tw-flex tw-items-center">
        <img
          style={{
            borderRadius: '100%',
            width: 30,
            height: 30,
            marginInlineEnd: 20,
          }}
          src={imgError ? defaultImg || perfil : imagen}
          alt="flag"
          onError={() => {
            setImgError(true);
          }}
        />
      <span>{nombre}</span>
    </div>
  );
};

export default SimpleUser;
