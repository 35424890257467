import { useUser } from "../../../context/UserContext";
import { checkImg } from "../../../services/Utils";

function SingleService({ service, handleClick, singleClubFetched, botonera }) {
  const { image, description, name, price } = service;
  const { isPlayer } = useUser();
  return (
    <div
      className="map-events-cards"
      onClick={() => handleClick?.(service)}
      style={{ cursor: handleClick ? "pointer" : "auto", padding: 0 }}
    >
      <div className="court-card perfilClub-court-card">
        <div className="court-info" style={{ margin: 5, position: "relative" }}>
          <img alt="court" src={checkImg(image)}></img>
        </div>
        <div className="court-info" style={{ padding: "6px 0 6px 52px" }}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 style={{ color: "#5b5b5b", margin: 0 }}>{name}</h3>
              {botonera && botonera}
            </div>
            <div className="court-info-column">
              <p style={{ margin: 0, marginLeft: 8, color: "#999999" }}>{description}</p>
            </div>
          </div>

          {singleClubFetched && isPlayer ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto max-content min-content",
                alignItems: "end",
              }}
            >
              <div
                style={{
                  margin: 0,
                  placeSelf: "auto",
                  fontSize: "1rem",

                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontFamily: '"Airbnb Cereal App", Arial',
                }}
              >{`$ ${price} / hrs`}</div>
            </div>
          ) : (
            <h5
              style={{ margin: 0, color: "#002688", fontSize: 14, width: "100%", textAlign: "end" }}
            >{`$ ${price} / hrs`}</h5>
          )}
        </div>
      </div>
    </div>
  );
}

export default SingleService;
