import { useEffect, useState, useMemo } from "react";

//Styles
import "../../styles/Clubes.css";

//Redux
import { connect } from "react-redux";

import { getAllClubs } from "../../actions/clubs";
// import ClubRowItems from "./ClubRowItems";

// import { changeMenuSelected } from "../../actions/menu";

// Calendario
import "../../styles/Calendar.css";
import RenderClub from "../mapa/mapEvents/RenderClub";
import { useNavigate } from "react-router-dom";

function mapStateToProps(state) {
  return {
    //menu
    mode: state.menu.mode,

    club: state.club.club,
    clubes: state.club.clubs,
    clubsFetched: state.club.clubsFetched,
  };
}

const Clubes = (props) => {
  const { dispatch, clubes } = props;
  const [calendarDate] = useState(new Date());
  // const [mostrar, setMostrarCalendar] = useState("");
  const [, setWeek] = useState([]);
  // const [showCalendar, setShowCalendar] = useState(false);
  const navigate = useNavigate();
  const registeredClubs = useMemo(() => clubes?.filter((club) => club?.settings), [clubes]);
  useEffect(() => {
    dispatch(getAllClubs());
    window.addEventListener("wheel", function (e) {
      let container = document.getElementById("clubs-container");
      if (container != null) {
        if (e.deltaY > 0) container.scrollLeft += 100;
        else container.scrollLeft -= 100;
      }
    });
    return () => {
      window.removeEventListener("wheel", () => {});
    };
  }, [dispatch]);

  useEffect(() => {
    getWeekDays(calendarDate);
  }, [calendarDate]);

  // const returnClubes = () => {
  //   let clubs = props.clubes;
  //   let today = `${calendarDate.getDay()}-${
  //     calendarDate.getMonth() + 1
  //   }-${calendarDate.getFullYear()}`;

  //   switch (estado_filtro) {
  //     case "clubes":
  //       let clubs_ = clubs?.filter((club) => club.tipo === "club");

  //       return clubs_?.map((item, i) => {
  //         let todayRents = Object.values(item.arriendos).filter(
  //           (arriendo) => arriendo.date === today
  //         );
  //         let hasRents = todayRents.length > 0;
  //         return (
  //           <ClubRowItems
  //             hasRents={hasRents}
  //             rents={todayRents}
  //             uid={item.uid}
  //             key={i}
  //             club={item}
  //           />
  //         );
  //       });

  //     case "academias":
  //       let academias = clubs.filter((club) => club.tipo === "academia");
  //       return academias?.map((item, i) => {
  //         let todayRents = Object.values(item.arriendos).find(
  //           (arriendo) => arriendo.date === today
  //         );

  //         let hasRents = todayRents?.am !== undefined || todayRents?.pm !== undefined;
  //         return (
  //           <ClubRowItems
  //             hasRents={hasRents}
  //             rents={todayRents}
  //             uid={item.uid}
  //             key={i}
  //             club={item}
  //           />
  //         );
  //       });
  //     case "recintos":
  //       let recintos = clubs.filter((club) => club.tipo === "academia");
  //       return recintos?.map((item, i) => {
  //         let todayRents = Object.values(item.arriendos).filter(
  //           (arriendo) => arriendo.date === today
  //         );
  //         let hasRents = todayRents.length > 0;
  //         return (
  //           <ClubRowItems
  //             hasRents={hasRents}
  //             rents={todayRents}
  //             uid={item.uid}
  //             key={i}
  //             club={item}
  //           />
  //         );
  //       });
  //     default:
  //   }
  // };

  const getWeekDays = (current) => {
    current = new Date(current);
    var weekdays = [];
    // Validacion día Lunes y fines de semana
    current.getDay() === 0
      ? current.setDate(current.getDate() + current.getDay() - 6)
      : current.setDate(current.getDate() - current.getDay() + 1);
    for (var i = 0; i < 7; i++) {
      weekdays.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
    setWeek(weekdays);
  };

  return (
    <div className={"main-container-activado"} style={{ marginLeft: 0 }}>
      <div
        id="clubs-container"
        className="scroll tw-flex tw-flex-wrap tw-gap-5 tw-p-3 tw-justify-center"
      >
        {registeredClubs?.map((club, i) => (
          <RenderClub
            item={club}
            key={club.uid}
            handleClick={() => {
              navigate("/club/" + club.uid);
            }}
            hideCardBtn
          />
        ))}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Clubes);
