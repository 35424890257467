import {
  //Registrar Usuario
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,

  //Actualizar Usuario
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,

  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  SET_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_CLUB_SUCCESS,
  LOGIN_CLUB_FAILURE,
  EDIT_EVENT_PASIVE,
  EDIT_EVENT_ACTIVE,
  EDIT_CLUB_PASIVE,
  EDIT_CLUB_ACTIVE,
} from "../actions/auth";

const authReducer = (
  state = {
    //Registro de Usuario
    isRegisteringUser: false,
    userRegisted: false,
    registerUserError: false,

    //Actualizar Usuario
    isUpdatingUser: false,
    updateUserError: false,
    userUpdated: false,

    isLoggingIn: false,
    isLoggingOut: false,
    isVerifying: false,
    loginError: false,
    logoutError: false,
    isAuthenticated: false,
    isEditingEvent: false,
    isEditingClub: false,

    existingUser: false,
    existingClub: false,

    //Tipo de usuario
    userLoginSuccess: false,
    clubLoginSuccess: false,

    user: {},
    error: "",
    info: null,
  },
  action
) => {
  switch (action.type) {
    //Registro de Usuarios
    case REGISTER_USER_REQUEST:
      return {
        ...state,
        isRegisteringUser: true,
        registerUserError: false,
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        isRegisteringUser: false,
        userRegisted: true,
        registerUserError: false,
      };
    case REGISTER_USER_FAILURE:
      return {
        ...state, 
        isRegisteringUser: false,
        registerUserError: true,
        error: action.error
      };
    
    //Actualizar usuario
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        isUpdatingUser: true,
        userUpdated: false,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isUpdatingUser: false,
        userUpdated: true,
        updateUserError: false,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        userUpdated: false,
        updateUserError: true
      };
      
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        loginError: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        user: action.user,
        auth_type: action.auth_type,
        existingUser: true,
        existingClub: true,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        loginError: true,
        error: action.error,
        existingUser: false,
        existingClub: false,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        logoutError: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
        isAuthenticated: false,
        existingUser: false,
        existingClub:false,
        user: {},
        info:null
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        logoutError: true,
      };
    case VERIFY_REQUEST:
      return {
        ...state,
        isVerifying: true,
        verifyingError: false,
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        isVerifying: false,
      };

    // Tipos de Usuarios  
    case SET_USER:
      return {
        ...state,
        info: action.info,
        auth_type: action.auth_type,
      };
    case LOGIN_CLUB_SUCCESS:
      return {
        ...state,
        clubLoginSuccess: true,
        userLoginSuccess: false,
        existingUser: false,
        existingClub: true,
        error: ''
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        userLoginSuccess: true,
        clubLoginSuccess: false,
        existingUser: true,
        existingClub: false,
        error: ''
      };
      case LOGIN_CLUB_FAILURE:
      return {
          ...state,
          clubLoginSuccess: false,
          existingClub: false,
        };
      case LOGIN_USER_FAILURE:
      return {
          ...state,
          userLoginSuccess: false,
          existingUser: false,
          };

    //Editando eventos
    case EDIT_EVENT_PASIVE:
      return {
        ...state,
        isEditingEvent: false,
      };
    case EDIT_EVENT_ACTIVE:
      return {
        ...state,
        isEditingEvent: true,
      };
    //Editando Clubes
    case EDIT_CLUB_PASIVE:
      return {
        ...state,
        isEditingClub: false,
      };
    case EDIT_CLUB_ACTIVE:
      return {
        ...state,
        isEditingClub: true,
      };

    default:
      return state;
  }
};

export default authReducer;
