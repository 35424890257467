// import classNames from "classnames";
import { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
// import { Hours } from "../../costants/Time";
import classNames from 'classnames';
import { useResizeDetector } from 'react-resize-detector';

const BodyWeekNew = ({ days, UpdateData, price }) => {
  const { hours: realHours, date } = days || {};
  const { width, ref: containerRef } = useResizeDetector();
  const maxCounter = useMemo(() => {
    let maxCounter = Math.floor(width / 64);
    return Math.floor((width - (maxCounter - 1) * 8) / 64);
  }, [width]);
  const [padding, setPadding] = useState(0);

  useEffect(() => {
    const requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame;
    window.requestAnimationFrame = requestAnimationFrame;

    window.requestAnimationFrame(() => {
      setPadding((width - maxCounter * 64 - (maxCounter - 1) * 8) / 2);
    });
  }, [maxCounter, width]);

  const hoursTemplate = useMemo(() => {
    let position = -1;
    let prevValue = null;
    let prevHour = null;
    let nextHour = null;
    let counter = 0;
    const hoursPrev = {};
    return Object.entries(realHours || {})
      .filter(([, value]) => value !== 0)
      .map(([hour, value]) => [hour, value === 3 ? 2 : value])
      .reduce((a, [hour, value], index) => {
        if (value === 1 || prevValue !== value || counter === maxCounter)
          position++;
        prevHour = moment(moment(hour, 'HH:mm'))
          .subtract(30, 'minutes')
          .format('HH:mm');
        if (prevValue === 1 && value === 2 && hoursPrev[prevHour]) {
          if (counter === maxCounter) counter = 0;
          a[position] = a[position] ?? {
            hour,
            value: 1,
            hours: [],
            dumy: true,
          };

          nextHour = moment(moment(hour, 'HH:mm'))
            .add(30, 'minutes')
            .format('HH:mm');
          a[position].hours.push({ hour, value: 1, prevHour, nextHour });

          counter++;
          position++;
        }
        if (counter === maxCounter) counter = 0;
        prevValue = value;
        a[position] = a[position] ?? { hour, value, hours: [] };
        prevHour = moment(moment(hour, 'HH:mm'))
          .subtract(30, 'minutes')
          .format('HH:mm');
        nextHour = moment(moment(hour, 'HH:mm'))
          .add(30, 'minutes')
          .format('HH:mm');
        a[position].hours.push({ hour, value, prevHour, nextHour, counter });
        counter++;
        hoursPrev[hour] = value;
        return a;
      }, []);
  }, [realHours, maxCounter]);
  const [hoursSelected, setHoursSelected] = useState({});
  useEffect(() => {
    setHoursSelected({});
  }, [hoursTemplate]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const updateHoursSelected = (hour, value, restData) => {
    let newHoursSelected = hoursSelected;
    let hasHoursSelected = Object.values(hoursSelected || {}).length > 0;
    let newStartD = startDate;
    let newEndDate = endDate;
    if (!hasHoursSelected) {
      newStartD = newEndDate = hour;
    }
    let isSelectable = value === 1;
    if (isSelectable) {
      if (hasHoursSelected) {
        if (
          !hoursSelected[hour] &&
          !hoursSelected[restData.prevHour] &&
          !hoursSelected[restData.nextHour]
        ) {
          newHoursSelected = {};
          newStartD = newEndDate = hour;
          // isSelectable =
          //   isSelectable && (hoursSelected[restData.prevHour] || hoursSelected[restData.nextHour]);
        }
      }
      hasHoursSelected = Object.values(newHoursSelected || {}).length > 0;

      if (!hasHoursSelected) {
        let nextHour = hoursTemplate.find(
          (value) => value.hour === restData.nextHour
        );
        if (nextHour?.value === 1) {
          nextHour = nextHour.hours[0];
          newEndDate = nextHour.hour;
          newHoursSelected[nextHour.hour] = { ...nextHour };
          nextHour = hoursTemplate.find(
            (value) => value.hour === nextHour.nextHour
          );
          if (nextHour?.value === 1) {
            newEndDate = nextHour.hour;
            nextHour = nextHour.hours[0];
            newHoursSelected[nextHour.hour] = { ...nextHour };
          }
        }
      }
      let newValue = newHoursSelected[hour] || {};
      newValue = { ...newValue, ...restData };

      newValue.selected = !newValue.selected;
      newValue = { ...newHoursSelected, [hour]: newValue };
      if (newHoursSelected[hour]) {
        let nextHour = hour;
        if (newValue[restData.prevHour]) {
          while (nextHour) {
            const newNextHour = newValue[nextHour]?.nextHour;
            delete newValue[nextHour];
            nextHour = newNextHour;
          }
          newEndDate = restData.prevHour;
        } else {
          delete newValue[nextHour];
          newStartD = restData.nextHour;
        }
      }
      if (!newValue[restData.prevHour] && newValue[hour]) newStartD = hour;
      if (!newValue[restData.nextHour] && newValue[hour]) newEndDate = hour;
      if (Object.values(newValue || {}).length === 0) {
        newStartD = newEndDate = null;
      }
      setHoursSelected(newValue);
    }
    const dStart = moment(
      `${moment(date).format('YYYY-MM-DD')}T${newStartD}`,
      'YYYY-MM-DDTHH:mm'
    );
    const dateEnd = moment(
      `${moment(date).format('YYYY-MM-DD')}T${newEndDate}`,
      'YYYY-MM-DDTHH:mm'
    )
      .toDate()
      .toISOString();
    const diferencia = moment(dateEnd).diff(dStart, 'minutes') / 60;
    UpdateData({
      day: { start: dStart, end: dateEnd },
      duracion: diferencia,
      total: Math.round(diferencia * price),
    });
    setStartDate(newStartD);
    setEndDate(newEndDate);
  };
  return (
    <div
      className="tw-flex tw-gap-2 tw-flex-wrap tw-max-w-2xl tw-w-fit tw-items-center tw-justify-start"
      ref={containerRef}
    >
      <div
        className="tw-flex tw-gap-2 tw-flex-wrap tw-max-w-2xl tw-w-fit tw-items-center tw-justify-start"
        style={{ paddingInline: padding }}
      >
        {hoursTemplate.map(({ hour, value, hours, dumy }) => (
          <div
            key={hour + (dumy ? 'dumy' : '')}
            className={classNames(
              'tw-flex tw-rounded-md tw-gap-2 tw-outline tw-outline-2',
              {
                'tw-outline-red-300 tw-bg-red-300 tw-bg-opacity-10':
                  value === 2,
                'tw-outline-blue-400 tw-bg-blue-400 tw-bg-opacity-10 hover:tw-bg-blue-300 hover:tw-bg-opacity-50':
                  value === 1 && !hoursSelected[hour],
                'tw-outline-Turquesa tw-bg-Turquesa hover:tw-bg-Turquesa hover:tw-bg-opacity-90':
                  value === 1 && !!hoursSelected[hour],
              }
            )}
          >
            {hours?.map(({ hour: key, ...restData }, index) => (
              <div
                key={`${key} - ${hour + (dumy ? 'dumy' : '')}`}
                className={classNames(
                  'tw-w-16 tw-h-9 tw-flex tw-items-center tw-justify-center',
                  {
                    'tw-font-semibold tw-cursor-pointer': value === 1,
                    'tw-line-through tw-opacity-30': value === 2,
                    'tw-text-Turquesa': value === 1 && !hoursSelected[hour],
                    'tw-text-white': value === 1 && hoursSelected[hour],
                  }
                )}
                onClick={() => updateHoursSelected(hour, value, restData)}
              >
                {key}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
export default BodyWeekNew;
