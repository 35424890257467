import { useEffect, useCallback } from "react";

//components
import EventsRow from "./EventsRow";

//styles

//Redux
import { connect, useDispatch } from "react-redux";
import { getAllEvents, getSingleEvent } from "../../actions/events";
import { changeMenuSelected } from "../../actions/menu";

import { useParams } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import TitleEvents from "./TitleEvents";

const mapStateToProps = (state) => ({
  events: state.events.events,
});

const EventsPage = (props) => {
  const { events } = props;
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const { tipo_usuario } = useUser();

  //Navegar a evento mediante el click en un evento (uid)
  const goToEvent = useCallback(
    (uid) => {
      dispatch(getSingleEvent(uid));
    },
    [dispatch]
  );

  const getUrlEvent = useCallback(() => {
    if (eventId) {
      dispatch(changeMenuSelected("explore"));
      goToEvent(eventId);
    }
  }, [eventId, dispatch, goToEvent]);

  //Obtener todos los eventos
  useEffect(() => {
    dispatch(getAllEvents(tipo_usuario));
    getUrlEvent();
  }, [dispatch, getUrlEvent, tipo_usuario]);

  return (
    <div className={"main-container-activado"} style={{ marginLeft: 0 }}>
      <TitleEvents title="Eventos disponibles"/>
      <EventsRow events={events} showActions allEvents />
    </div>
  );
};

export default connect(mapStateToProps)(EventsPage);
