import { db, storage, auth } from '../firebase/firebase';
import { deleteObject, ref as refStorage } from 'firebase/storage';
import {
  equalTo,
  off,
  onValue,
  orderByChild,
  push,
  query,
  ref,
  remove,
  update,
} from 'firebase/database';
import { uploadImage } from '../services/images/images.service';

export const RESET_EVENTS = 'RESET_EVENTS';
//Creacion de eventos
export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

//Eliminar Eventos
export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';

//Obtencion de mis eventos
export const GET_MY_EVENTS_REQUEST = 'GET_MY_EVENTS_REQUEST';
export const GET_MY_EVENTS_SUCCESS = 'GET_MY_EVENTS_SUCCESS';
export const GET_MY_EVENTS_FAILURE = 'GET_MY_EVENTS_FAILURE';

//Obtencion de transacciones de eventos
export const GET_TRANSACTIONS_EVENTS_REQUEST =
  'GET_TRANSACTIONS_EVENTS_REQUEST';
export const GET_TRANSACTIONS_EVENTS_SUCCESS =
  'GET_TRANSACTIONS_EVENTS_SUCCESS';
export const GET_TRANSACTIONS_EVENTS_FAILURE =
  'GET_TRANSACTIONS_EVENTS_FAILURE';

//Obtencion de todos los eventos
export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE';

//Obtencion de un solo evento
export const GET_SINGLE_EVENT_REQUEST = 'GET_SINGLE_EVENT_REQUEST';
export const GET_SINGLE_EVENT_SUCCESS = 'GET_SINGLE_EVENT_SUCCESS';
export const GET_SINGLE_EVENT_FAILURE = 'GET_SINGLE_EVENT_FAILURE';
export const CLEAR_SINGLE_EVENT = 'CLEAR_SINGLE_EVENT';

//Actualizar Eventos
export const UPDATE_EVENT_DEFAULT = 'UPDATE_EVENT_DEFAULT';
export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE';

//Imagenes del torneo

export const GET_IMAGES_REQUEST = 'GET_IMAGES_REQUEST';
export const GET_IMAGES_SUCCESS = 'GET_IMAGES_SUCCESS';
export const GET_IMAGES_FAILURE = 'GET_IMAGES_FAILURE';

export const DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const DELETE_IMAGE_FAILURE = 'DELETE_IMAGE_FAILURE'


export const resetStatusEvent = () => {
  return {
    type: RESET_EVENTS,
  };
};

//Obtener imagenes torneo
const requestUploadImage = () => {
  return {
    type: GET_IMAGES_REQUEST,
  };
};

const receiveImage = () => {
  return {
    type: GET_IMAGES_SUCCESS,
  };
};

const errorUploadImage = () => {
  return {
    type: GET_EVENTS_FAILURE,
  };
};

//Eliminar imagen del torneo

const requestDeleteImage = () => {
  return {
    type: DELETE_IMAGE_REQUEST,
  };
};

const receiveDeleteImage = () => {
  return {
    type: DELETE_IMAGE_SUCCESS,
  };
};

const errorDeleteImage = () => {
  return {
    type: DELETE_IMAGE_FAILURE,
  };
};

//Creacion de eventos
const requestCreateEvent = () => {
  return {
    type: CREATE_EVENT_REQUEST,
  };
};

const receiveEvent = () => {
  return {
    type: CREATE_EVENT_SUCCESS,
  };
};

const errorCreateEvent = () => {
  return {
    type: CREATE_EVENT_FAILURE,
  };
};

//RD CRUD ELIMINAR

const requestDeleteEvent = () => {
  return {
    type: DELETE_EVENT_REQUEST,
  };
};

const receiveDeleteEvent = () => {
  return {
    type: DELETE_EVENT_SUCCESS,
  };
};

const errorDeleteEvent = () => {
  return {
    type: DELETE_EVENT_FAILURE,
  };
};

//Obtencion de las transacciones de un evento
const requestGetTransactionsEvents = () => {
  return {
    type: GET_TRANSACTIONS_EVENTS_REQUEST,
  };
};

const receiveTransactionsEvents = (events) => {
  return {
    type: GET_TRANSACTIONS_EVENTS_SUCCESS,
    events,
  };
};

const errorGetTransactionsEvents = () => {
  return {
    type: GET_TRANSACTIONS_EVENTS_FAILURE,
  };
};

//Obtencion de mis eventos
const requestGetMyEvents = () => {
  return {
    type: GET_MY_EVENTS_REQUEST,
  };
};

const receiveMyEvents = (events) => {
  return {
    type: GET_MY_EVENTS_SUCCESS,
    events,
  };
};

const errorGetMyEvents = () => {
  return {
    type: GET_MY_EVENTS_FAILURE,
  };
};

//Obtencion de todos los eventos
const requestGetAllEvents = () => {
  return {
    type: GET_EVENTS_REQUEST,
  };
};

const receiveAllEvents = (events) => {
  return {
    type: GET_EVENTS_SUCCESS,
    events,
  };
};

const errorGetAllEvents = () => {
  return {
    type: GET_EVENTS_FAILURE,
  };
};

//Obtencion de un solo evento
const requestGetSingleEvent = () => {
  return {
    type: GET_SINGLE_EVENT_REQUEST,
  };
};

const receiveSingleEvent = (event) => {
  return {
    type: GET_SINGLE_EVENT_SUCCESS,
    event,
  };
};

const errorGetSingleEvent = () => {
  return {
    type: GET_SINGLE_EVENT_FAILURE,
  };
};

const clearSEvent = () => {
  return {
    type: CLEAR_SINGLE_EVENT,
  };
};

//ACTUALIZAR
export const defaultUpdateEvent = () => {
  return {
    type: UPDATE_EVENT_DEFAULT,
  };
};

const requestUpdateEvent = () => {
  return {
    type: UPDATE_EVENT_REQUEST,
  };
};

const receiveUpdateEvent = () => {
  return {
    type: UPDATE_EVENT_SUCCESS,
  };
};

const errorUpdateEvent = () => {
  return {
    type: UPDATE_EVENT_FAILURE,
  };
};

//
export const clearSingleEvent = () => (dispatch) => {
  dispatch(clearSEvent());
};

export const errorEvent = () => (dispatch) => {
  dispatch(errorGetSingleEvent());
};

/**
 * FUNCIONES DE FIREBASE (CRUD)
 */

export const uploadEventImage = (data, selectType, selectOption) => async (dispatch) => {
  dispatch(requestUploadImage);
  let key_torneo = selectType;
  let databaseRef = '';
  let key_image = '';
  let path = '';
  let storagePath = '';
  if (selectOption === 'Sponsor') {
    storagePath = `eventos/sponsors/`;
    path = `eventos/${key_torneo}/sponsors/`;
  } else if (selectOption === 'Fotos') {
    storagePath = `eventos/galeria/`;
    path = `eventos/${key_torneo}/galeria/`;
  } else if (selectOption === 'Ganadores') {
    storagePath = `eventos/ganadores/`;
    path = `eventos/${key_torneo}/ganadores/`;
  }
  const newData = data;
  if (
    path &&
    storagePath &&
    newData.imagen.blob &&
    selectType &&
    ((newData.categoria && newData.nombre) || selectOption !== 'Ganadores')
  ) {
    databaseRef = ref(db, path);
    key_image = push(databaseRef).key;
    if (newData.imagen.blob) {
      const { blob } = newData.imagen;
      delete newData.imagen;
      await uploadImage(
        `${storagePath}${key_image}.jpg`,
        blob,
        `${path}/${key_image}/imagen`
      );
    }
    databaseRef = ref(db, `${path}/${key_image}`);

    try {
      await update(databaseRef, { ...newData, key_image });
      dispatch(receiveImage()); //si hubo exito se establece el estado como exitoso
      return { error: false };
    } catch (error) {
      dispatch(errorUploadImage()); //si hubo exito se establece el estado como falla
      return { error: 'Se ha producido un error al subir la imagen' };
    }
  } else {
    dispatch(errorUploadImage()); //si no hubo exito se establece el estado como falla
    return { error: 'Complete los campos' };
  }
};

//Editar Datos Jugador
export const editIMG = (selectType, id, mode, data) => async (dispatch) => {
  dispatch(requestUploadImage);
  let key_torneo = selectType.key
  let path = `eventos/${key_torneo}/${mode}/`
  let storagePath = `eventos/${mode}/`
  let databaseRef = ''

  const newData = data

  if (
    path &&
    storagePath &&
    (newData.imagen.blob || newData.nombre || newData.categoria)
  ) {

    if (newData.imagen.blob) {
      const { blob } = newData.imagen;
      delete newData.imagen;
      await uploadImage(
        `${storagePath}${id}.jpg`,
        blob,
        `${path}/${id}/imagen`
      );
    }

    databaseRef = ref(db, `eventos/${key_torneo}/${mode}/${id}/`);
    try {
      await update(databaseRef, (newData));
      dispatch(receiveImage());
      return { error: false };
    } catch (error) {
      dispatch(errorUploadImage());
      return { error: 'Se ha producido un error al editar la imagen' };
    }
  } else {
    dispatch(errorUploadImage());
    return { error: 'Complete los campos' };
  }
};

//ELIMINAR IMAGEN
export const deleteIMG = (selectType, id, mode) => async (dispatch) => {
  //Se establece el estado como 'evento eliminado'
  dispatch(requestDeleteImage());
  let key_torneo = selectType.key
  //Eliminar evento mediante firebase
  await remove(ref(db, `eventos/${key_torneo}/${mode}/${id}`))
    .then(() => {
      dispatch(receiveDeleteImage()); //si hubo exito se establece el estado como exitoso
    })
    .catch(() => {
      dispatch(errorDeleteImage()); //si hubo exito se establece el estado como falla
    });
};

export const getEventsTransaction = (key_torneo) => (dispatch) => {
  dispatch(requestGetTransactionsEvents());
  try {
    onValue(
      query(
        ref(db, `transactions/eventos/`),
        equalTo(key_torneo),
        orderByChild('key_torneo')
      ),
      (snapshot) => {
        var transactions = snapshot.val();
        if (transactions) {
          dispatch(
            receiveTransactionsEvents(
              Object.keys(transactions).map((t) => {
                return { ...transactions[t], idTransaction: t };
              })
            )
          );
        }
      }
    );
  } catch (e) {
    console.log(e);
    dispatch(errorGetTransactionsEvents());
  }
};
export const createEvent = (eventData) => async (dispatch) => {
  //Se establece el estado como 'Creando evento'
  dispatch(requestCreateEvent());
  var databaseRef = ref(db, 'eventos/');
  var key_torneo = push(databaseRef).key;

  if (eventData.logo.blob) {
    const { blob } = eventData.logo;
    delete eventData.logo;
    uploadImage(
      `eventos/${key_torneo}.jpg`,
      blob,
      `eventos/${key_torneo}/logo`
    );
  } else {
    if (typeof eventData.logo !== 'string') eventData.logo = null;
  }
  eventData.id = key_torneo;
  update(ref(db, 'eventos/' + key_torneo), eventData)
    .then(() => {
      dispatch(receiveEvent()); //si hubo exito se establece el estado como exitoso
    })
    .catch(() => {
      dispatch(errorCreateEvent()); //si hubo exito se establece el estado como falla
    });
};

const updateEventFunction = (id, eventData, dispatch) => {
  update(ref(db, `eventos/${id}/`), eventData)
    .then(() => {
      dispatch(receiveUpdateEvent());
    })
    .catch(() => {
      dispatch(errorUpdateEvent());
    });
};
//UPDATE
export const updateEvent = (eventData) => async (dispatch) => {
  dispatch(requestUpdateEvent());
  let key_torneo = eventData.id;

  if (eventData.logo.blob) {
    const { blob } = eventData.logo;
    delete eventData.logo;
    uploadImage(
      `eventos/${key_torneo}.jpg`,
      blob,
      `eventos/${key_torneo}/logo`
    );
  } else {
    if (typeof eventData.logo !== 'string') eventData.logo = null;
  }
  onValue(
    ref(db, `eventos/${eventData.id}/cupos`),
    async (snapshot) => {
      if (snapshot.val()?.Abierta)
        eventData.cupos.Abierta = snapshot.val()?.Abierta;
      updateEventFunction(eventData.id, eventData, dispatch);
    },
    async () => {
      updateEventFunction(eventData.id, eventData, dispatch);
    },
    { onlyOnce: true }
  );
};
export const updateCuadroEvent =
  (cuadroImg, key_torneo, atributo) => async (dispatch) => {
    // dispatch(requestUpdateEvent());

    if (cuadroImg?.blob) {
      const { blob } = cuadroImg;
      uploadImage(
        `eventos/${atributo}/${key_torneo}.pdf`,
        blob,
        `eventos/${key_torneo}/${atributo}`
      );
    }
  };
export const deleteCuadroEvent = (key_torneo, atributo) => async (dispatch) => {
  dispatch(requestUpdateEvent());

  deleteObject(refStorage(storage, `eventos/${atributo}/${key_torneo}.pdf`));
  remove(ref(db, `eventos/${key_torneo}/${atributo}`))
    .then(() => {
      dispatch(receiveUpdateEvent());
    })
    .catch(() => {
      dispatch(errorUpdateEvent());
    });
};

export const getMyEvents = () => (dispatch) => {
  dispatch(requestGetMyEvents());

  const USER_ID = auth.currentUser.uid;
  let listeners = {};
  let main;
  try {
    main = onValue(ref(db, `users/${USER_ID}/torneos`), (snapshot) => {
      Object.values(listeners).forEach((eventOff) => eventOff?.());
      listeners = {};
      let myEvents = {};
      if (snapshot.exists()) {
        Object.values(snapshot.val())
          .filter(({ id }) => id)
          .forEach(({ id }) => {
            listeners[id] = onValue(ref(db, `eventos/${id}`), (snapshot) => {
              if (snapshot.exists()) {
                myEvents[id] = snapshot.val();
              } else {
                delete myEvents[id];
              }
              dispatch(receiveMyEvents(Object.values(myEvents || {})));
            });
          });
      } else {
        dispatch(receiveMyEvents([]));
      }
    });
  } catch (error) {
    dispatch(errorGetMyEvents());
  }
  return { main, ...listeners };
};
export const privacidad = {
  public: () => true,
  admin: (admin) => admin === 'admin',
};
export const validatePrivacidad = (ev, admin) => {
  return (
    !ev.privacidad ||
    (privacidad[ev.privacidad] && privacidad[ev.privacidad](admin))
  );
};

export const getAllEventsOn = (admin) => (dispatch) => {
  //Se establece el estado como 'obteniendo todos los eventos'
  dispatch(requestGetAllEvents());

  let events = [];
  //obtener todos los eventos mediante firebase
  const getAllEventsListener = onValue(
    ref(db, `eventos`),
    (snapshot) => {
      events = snapshot.val() ? Object.values(snapshot.val()) : [];
      events = events.filter((ev) => validatePrivacidad(ev, admin));
      dispatch(receiveAllEvents(events));
    },
    () => {
      dispatch(errorGetAllEvents());
    }
  );
  return getAllEventsListener;
};
export const getAllEvents = (admin) => (dispatch) => {
  //Se establece el estado como 'obteniendo todos los eventos'
  dispatch(requestGetAllEvents());

  let events = [];
  //obtener todos los eventos mediante firebase
  const getAllEventsListener = onValue(
    ref(db, `eventos`),
    (snapshot) => {
      events = snapshot.val() ? Object.values(snapshot.val()) : [];
      events = events.filter((ev) => validatePrivacidad(ev, admin));
      dispatch(receiveAllEvents(events));
    },
    () => {
      dispatch(errorGetAllEvents());
    },
    { onlyOnce: true }
  );
  return getAllEventsListener;
};
let lastevent = null;
export const getSingleEvent = (uid) => (dispatch) => {
  if (!uid) return;
  dispatch(clearSingleEvent());
  dispatch(requestGetSingleEvent());
  try {
    if (lastevent) off(lastevent);
    lastevent = ref(db, `eventos/${uid}`);
    onValue(
      lastevent,
      (snapshot) => {
        if (!snapshot.exists()) {
          dispatch(errorGetSingleEvent());
        }
        dispatch(receiveSingleEvent(snapshot.val() || {}));
      },
      () => {
        dispatch(errorGetSingleEvent());
      }
    );
  } catch (error) {
    console.log(error);
    dispatch(errorGetSingleEvent());
  }
};

//ELIMINAR
export const deleteEvent = (id) => (dispatch) => {
  //Se establece el estado como 'evento eliminado'
  dispatch(requestDeleteEvent());

  //Eliminar evento mediante firebase
  remove(ref(db, `eventos/${id}`))
    .then(() => {
      dispatch(receiveDeleteEvent()); //si hubo exito se establece el estado como exitoso
    })
    .catch(() => {
      dispatch(errorDeleteEvent()); //si hubo exito se establece el estado como falla
    });
};
