import { useEffect, useMemo, useRef, useState } from 'react';
import { FaCloudUploadAlt, FaDownload, FaTrash } from 'react-icons/fa';
import IsAdmin from '../../components/IsAdmin';
import { Document, Page, pdfjs } from 'react-pdf';
import { connect } from 'react-redux';
import {
  deleteCuadroEvent,
  getSingleEvent,
  updateCuadroEvent,
} from '../../actions/events';
import { useNavigate, useParams } from 'react-router-dom';
import { changeMenuSelected } from '../../actions/menu';
// import { colors } from "../../costants/colors";
import { handleChangePicture } from '../../utils/FileUpload';
import { useMediaQuery } from '@material-ui/core';
import classNames from 'classnames';
import Title from '../../components/ui/Title';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BasesDetails = ({ event, dispatch, ...props }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageWidth, setPageWidth] = useState(0);
  const navigate = useNavigate();
  const [, setCuadroTorneo] = useState({
    file: null,
    filename: null,
    blob: null,
  });
  const mobile = useMediaQuery('(max-width: 800px)');

  const ref = useRef();
  const pdfWrapper = useRef();

  var url = event.basesTorneoImg;
  const { eventId } = useParams('eventId');

  useEffect(() => {
    const resize = () => {
      if (pdfWrapper.current) {
        setPageWidth(pdfWrapper.current.getBoundingClientRect().width);
      }
    };
    window.addEventListener('resize', resize);
    resize();
    return () => window.removeEventListener('resize', resize);
  }, [numPages]);

  useEffect(() => {
    if (eventId) {
      dispatch(getSingleEvent(eventId));
    }
  }, [dispatch, eventId]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleRemoveCuadro = async (atributo) => {
    if (eventId) dispatch(deleteCuadroEvent(eventId, atributo));
    ref.current.value = '';
  };

  const handleChangeP = async (event) => {
    var Name = event.target?.name;
    event.preventDefault();
    handleChangePicture({
      Name,
      files: event.target?.files,
      handleOnload: (auxCuadroTorneo, Name) => {
        setCuadroTorneo(auxCuadroTorneo);
        if (auxCuadroTorneo.filename && auxCuadroTorneo.blob && eventId)
          dispatch(updateCuadroEvent(auxCuadroTorneo, eventId, Name));
      },
    });
  };

  const styles = useMemo(() => {
    return {
      position: 'absolute',
      right: mobile ? 0 : 32,
      width: 'fit-content',
      zIndex: 2,
      bottom: mobile ? 50 : null,
      display: 'flex',
      margin: mobile ? 'auto' : null,
      left: mobile ? 0 : null,
      gap: 12,
    };
  }, [mobile]);

  const goBack = () => {
    navigate('/event/' + eventId);
    dispatch(changeMenuSelected('/explore'));
  };
  return (
    <div className="main-container-activado tw-bg-white tw-overflow-hidden tw-relative">
      <Title goBack={goBack} title="Bases del Torneo" />
      <div style={styles}>
        {event.basesTorneoImg && (
          <a
            href={url}
            download={true}
            style={{ width: 'fit-content', padding: 12 }}
            target="_blank"
            className="detailEvent-toolsButtons"
            rel="noreferrer"
          >
            <FaDownload style={{ margin: 5 }} size="20" />
          </a>
        )}
        <IsAdmin>
          <div
            onClick={() => handleRemoveCuadro('basesTorneoImg')}
            className={classNames('detailEvent-toolsButtons', {
              'detailEvent-toolsButtons-disabled': !event.basesTorneoImg,
            })}
            style={{ padding: 12 }}
          >
            <FaTrash style={{ margin: 5 }} size="20" />
          </div>
          <input
            type="file"
            name="basesTorneoImg"
            id="cuadros-torneo"
            className="detailEvent-toolsButtons inputfile"
            required
            accept="application/pdf"
            ref={ref}
            onChange={handleChangeP}
          />
          <label htmlFor="cuadros-torneo" style={{ padding: 12 }}>
            <FaCloudUploadAlt style={{ margin: 5 }} size="20" />
          </label>
        </IsAdmin>
      </div>
      <div
        id="modalTorneo"
        ref={pdfWrapper}
        className="scroll tw-grid tw-grid-cols-1"
        style={{
          height: '80vh',
        }}
      >
        <PDF
          onload={onDocumentLoadSuccess}
          numPages={numPages}
          url={url}
          width={pageWidth - 10}
        />
      </div>
    </div>
  );
};

export const PDF = ({ onload, numPages, width, url }) => {
  if (!url)
    return (
      <label
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 500,
          margin: 0,
          width: '100%',
        }}
      >
        No disponible
      </label>
    );
  return (
    <div className="pdf-style-cuadro">
      <Document
        className={'pdf-style-2'}
        file={{
          url: url,
        }}
        onLoadSuccess={onload}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            className={'pdf-style-3'}
            width={width}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ))}
      </Document>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isFetchingSingleEvent: state.events.isFetchingSingleEvent,
    singleEventFetched: state.events.singleEventFetched,
    fetchSingleEventError: state.events.fetchSingleEventError,
    event: state.events.event,
  };
}

export default connect(mapStateToProps)(BasesDetails);
