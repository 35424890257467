import '../../styles/Pago.css';
import MACH from '../../assets/socios/webpay2.png'

const MachButton = ({ amount, onPress }) => {
    return (
        <div className="shadow" onClick={onPress}>
            <div className='online-content'>
                <img alt="trofeo" className='trofeo-img' src={MACH} />
                <h4>{`$${amount}`}</h4>
            </div>
        </div>
    )
}

export default (MachButton)

