import { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../styles/EditCancha.css";
import "../../../styles/Court.css";
import { deleteService, defaultService } from "../../../actions/clubs";
import Modal from "react-modal";
import { colors } from "../../../costants/colors";

import styled from "styled-components";
import NewService from "./NewService";
import ListService from "./ListService";
import toast from "react-hot-toast";

// import club from "../../configuration/club";

function mapStateToProps(state) {
  return {
    events: state.events,
    event: state.events.event,
    serviceDeleted: state.club.serviceDeleted,
    serviceUpdated: state.club.serviceUpdated,
    serviceCreated: state.club.serviceCreated,
    info: state.auth.info,
  };
}

const ServiceContainer = (props) => {
  const {
    serviceDeleted,
    serviceUpdated,
    serviceCreated,
    updateServiceError,
    createServiceError,
    services,
    welcomeClubSetup: welcomeClub,
    dispatch,
  } = props;
  const [addService, setAddService] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [change, setChange] = useState(false);
  const [service, setService] = useState([]);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    setService(services);
    dispatch(defaultService());
  }, [services, dispatch]);

  const handleDelete = async (service, index) => {
    if (service.key) {
      props.dispatch(deleteService(service.key, props.info.uid));
      let allService = props.services;
      allService.splice(index, 1);
      setService(allService);
      setChange(!change);
    } else {
      let allService = props.services;
      allService.splice(index, 1);
      setService(allService);
      setChange(!change);
      setAddService(false);
      toast.success("Se ha eliminado el servicio correctamente");
    }
    setShowDelete(false);
  };

  useEffect(() => {
    if (
      serviceUpdated ||
      serviceDeleted ||
      serviceCreated ||
      createServiceError ||
      updateServiceError
    ) {
      setAddService(false);
      setSelectedService("");
    }
  }, [serviceUpdated, serviceDeleted, serviceCreated, createServiceError, updateServiceError]);
  useEffect(() => {
    if (serviceUpdated) {
      toast.success("El servicio se ha editado correctamente");
    }
  }, [serviceUpdated]);
  useEffect(() => {
    if (serviceDeleted) {
      toast.success("Se ha eliminado el servicio correctamente");
    }
  }, [serviceDeleted]);
  useEffect(() => {
    if (serviceCreated) {
      toast.success("El servicio se agregó correctamente");
    }
  }, [serviceCreated]);
  useEffect(() => {
    if (createServiceError || updateServiceError) {
      toast.error("Ha ocurrido un error");
    }
  }, [createServiceError, updateServiceError]);

  const handleOpenDeleteModal = (service, index) => {
    setSelectedService({ service, index });
    setShowDelete(true);
  };

  const returnModalDelete = () => (
    <>
      <Modal
        isOpen={showDelete}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setShowDelete(false)}
      >
        <div className="mensaje">
          <h5> ¿Estás seguro de eliminar la cancha? </h5>
        </div>

        <div className="modal-body">
          <Button1 className="modal-button" onClick={() => setShowDelete(false)}>
            <span>Cancelar</span>
          </Button1>

          <Button1
            onClick={() => handleDelete(selectedService.service, selectedService.index)}
            className="modal-button"
            alertDeleted
          >
            <span>Eliminar</span>
          </Button1>
        </div>
      </Modal>
    </>
  );

  return (
    <div className={"edit-court-club-content"}>
      {returnModalDelete()}
      <div className="edit-form-columns xs:tw-px-6">
        <button
          className="add-new-court-btn"
          onClick={() => {
            setAddService(!addService);
            setSelectedService("");
          }}
          style={{ backgroundColor: colors.Verde.TenisCTA }}
        >
          {addService ? "Cancelar" : "Agregar Servicio"}
        </button>
      </div>
      <div className="xs:tw-mt-11 tw-px-3 xs:tw-px-7 lg:tw-mt-0 court-column" id="newCanchascroll">
        {addService ? (
          <NewService
            adding={(state) => setAddService(state)}
            selectedService={selectedService}
            welcomeClub={welcomeClub}
            service={props.services}
            setServices={(service) => props.setServices(service)}
            handleaddService={() => setAddService(!addService)}
          ></NewService>
        ) : (
          <ListService
            service={service}
            handleOpenDeleteModal={handleOpenDeleteModal}
            setSelectedService={setSelectedService}
            setAddService={setAddService}
          />
        )}
      </div>
    </div>
  );
};

// Estilos modal

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
  },
};

const Button1 = styled.button`
  position: relative;
  margin: 6px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  border: none;
  background-color: #000022;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  &:hover {
    background: #000022;
    opacity: 0.6;
  }
  cursor: pointer;
`;

export default connect(mapStateToProps)(ServiceContainer);
