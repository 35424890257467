import { useCallback, useEffect, useState } from 'react';

import clubPhoto from '../../assets/tennis-ball.png';
import { useResizeDetector } from 'react-resize-detector';
import Map from '../mapa/Map';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaPhone, FaMapMarkerAlt, FaHome } from 'react-icons/fa';
import Modal from 'react-modal';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Title from '../ui/Title';
import { CreatePaymentChange } from '../../actions/payment';
import baseDesplegable from '../../assets/base-desplegable.png';
import moment from 'moment';

import {
  CLEAR_SINGLE_CLUB,
  getSingleCourt,
  defaultSingleCourt,
  UpdateSingleCourtWeek,
  getSingleClub,
} from '../../actions/clubs';
// import SingleWeekCalendar from "../calendar/SingleWeekCalendar";
import Loading from '../../pages/Loading';
import SingleCourt from '../SingleCourt';
import RentCourtModal from '../inscripcion/RentCourtModal';
import TabsHeader from '../ui/TabsHeader';
import SingleService from '../clubes/config/SingleService';

import '../../styles/PerfilClub.css';
import '../../styles/Clubes.css';
import DespleableBtn from '../../icons/DespleableBtn';
import classNames from 'classnames';
import { useUser } from '../../context/UserContext';

Modal.setAppElement('#root');
function mapStateToProps(state) {
  return {
    mode: state.menu.mode,
    club: state.club.club,
    court: state.club.court,
    payment: state.payment,
    singleCourtFetched: state.club.singleCourtFetched,
    //Club
    isFetchingSingleClub: state.club.isFetchingClub,
    singleClubFetched: state.club.singleClubFetched,
    fetchingSingleClubError: state.club.fetchingSingleClubError,
  };
}
const PerfilClub = (props) => {
  const mobile = useMediaQuery('(max-width: 420px)');
  const sm = useMediaQuery('(max-width: 639px)');
  const { clubUid } = useParams('eventId');
  const { dispatch, singleClubFetched, club, handleClose, handleOpenModals } =
    props;
  const [menuActive, setMenuActive] = useState('Canchas');
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [about, setAbout] = useState();
  const [ciudad, setCiudad] = useState();
  const [region, setRegion] = useState();
  const [direccion, setDireccion] = useState();
  const [logo, setLogo] = useState();
  const [lat, setLat] = useState(0.0);
  const [lng, setLng] = useState(0.0);
  const [courts, setCourts] = useState([]);
  const [services, setServices] = useState([]);
  const menu = ['Canchas', 'Servicios'];
  const navigate = useNavigate();
  const [showCourt, setShowCourt] = useState(false);
  // const [hourCourt, setHourCourt] = useState("");
  // const [minCourt, setMinCourt] = useState("");
  const { isClub, tipo_usuario, uid, user, settings, isNotPlayer } = useUser();

  useEffect(() => {
    if (showCourt === false) {
      dispatch(defaultSingleCourt());
    }
  }, [showCourt, dispatch]);

  useEffect(() => {
    if (clubUid) dispatch(getSingleClub(clubUid));
    else if (isClub && uid) dispatch(getSingleClub(uid));
  }, [clubUid, isClub, uid, dispatch]);

  useEffect(() => {
    if (club?.courts) {
      setCourts(Object.values(club.courts));
    } else setCourts([]);
    if (club?.services) {
      setServices(Object.values(club.services));
    } else setServices([]);
  }, [club?.courts, club?.services]);

  useEffect(() => {
    let data = settings || {};
    let courtsData = user?.courts || {};
    let servicesData = user?.services || {};
    if (club?.uid) {
      data = club.settings;
      courtsData = club.courts;
      servicesData = club.services;
    }
    const { name, phone, about, ciudad, region, direccion, lat, lng } = data;
    const { logo_200x200, logo_400x400, logo_800x800, logo } = data;
    setName(name);
    setPhone(phone);
    setAbout(about);
    setCiudad(ciudad);
    setRegion(region);
    setDireccion(direccion);
    setLogo(logo_200x200 || logo_400x400 || logo_800x800 || logo);
    setLat(lat);
    setLng(lng);
    setCourts(Object.values(courtsData || {}));
    setServices(Object.values(servicesData || {}));
  }, [club, user?.courts, user?.services, settings, isNotPlayer]);

  const handleOpenCourtModal = (court) => {
    if (handleOpenModals) {
      handleOpenModals?.(court);
    } else {
      dispatch(getSingleCourt(club.uid, court.key));
      setShowCourt(true);
    }
  };
  const [selectedDay, setselectedDay] = useState();
  const SetPaymentData = useCallback(
    (data) => {
      dispatch(CreatePaymentChange(data));
    },
    [dispatch]
  );

  useEffect(() => {
    if (props?.court?.week) {
      setselectedDay(
        props.court.week?.[moment().format('dddd')] ||
          Object.values(props.court.week)?.[0]
      );
    }
  }, [props?.court?.week]);
  useEffect(() => {
    if (showCourt && props.singleCourtFetched)
      SetPaymentData({
        duracion: null,
        day: { start: null, end: null },
        total: null,
        clubID: clubUid,
        courtKey: props.court?.key,
      });
  }, [
    showCourt,
    props.singleCourtFetched,
    SetPaymentData,
    clubUid,
    props.court?.key,
  ]);
  const ModalCourt = () => {
    return (
      <Modal
        className="Modal-form-courts"
        isOpen={showCourt && props.singleCourtFetched}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setShowCourt(false)}
      >
        <RentCourtModal
          handleClose={setShowCourt}
          handleCloseConfirm={() => {
            if (props.payment?.total >= props.court?.price) {
              navigate(`/arriendo/${props.court.key}`);
            }
          }}
          tipo_usuario={tipo_usuario}
          court={props.court}
          payment={props.payment}
          updateWeek={(week) => {
            dispatch(UpdateSingleCourtWeek({ ...props.court, week: week }));
          }}
          selectedDay={selectedDay}
          setselectedDay={setselectedDay}
          SetPaymentData={SetPaymentData}
        />
      </Modal>
    );
  };
  const renderContent = () => {
    switch (menuActive) {
      case 'Canchas':
        return (
          <div
            id="court-service-profile"
            className={classNames('scroll', {
              'tw-flex tw-items-center tw-justify-center': courts.length === 0,
            })}
            style={{ minHeight: 400 }}
          >
            {courts.length === 0 ? (
              <div>Este club no tiene canchas agregadas</div>
            ) : (
              courts?.map((court, index) => (
                <SingleCourt
                  key={court.key}
                  mobile={mobile}
                  singleClubFetched={singleClubFetched}
                  court={court}
                  handleClick={() => handleOpenCourtModal(court)}
                  tipo_usuario={tipo_usuario}
                />
              ))
            )}
          </div>
        );

      case 'Servicios':
        return (
          <div
            id="court-service-profile"
            className={classNames('scroll', {
              'tw-flex tw-items-center tw-justify-center': courts.length === 0,
            })}
            style={{ minHeight: 400 }}
          >
            {courts.length === 0 ? (
              <div style={{ margin: '3%' }}>
                Este club no tiene servicios agregados
              </div>
            ) : (
              services?.map((service) => (
                <SingleService key={service.key} service={service} />
              ))
            )}
          </div>
        );
      default:
        return;
    }
  };
  const clearState = () => {
    dispatch({
      type: CLEAR_SINGLE_CLUB,
    });
  };
  const goBack = () => {
    if (handleClose) {
      handleClose?.();
    } else {
      clearState();
      navigate(clubUid ? '/clubs' : '/explore');
    }
  };
  const { width: widthContainer, ref: containerRef } = useResizeDetector();
  const [isOpen, setisOpen] = useState(true);
  const [top, setTop] = useState(40);

  useEffect(() => {
    if (sm || showCourt) {
      setisOpen(false);
    }
  }, [sm, showCourt]);
  return (
    <div className={'perfil-container-' + props.mode} style={{ width: '100%' }}>
      {(singleClubFetched || club) && <Loading />}
      {singleClubFetched && club && (
        <div className="tw-grid tw-h-full tw-w-full tw-rounded tw-shadow tw-text-left tw-bg-white tw-grid-rows-2-auto-fr tw-overflow-hidden tw-relative">
          <div className="tw-w-full tw-h-fit">
            <Title
              goBack={singleClubFetched && goBack}
              title="Detalles del Club"
              containerStyles={{ marginBottom: 0, marginInline: 14 }}
              size="2xl"
            />
          </div>
          {sm && !showCourt && (
            <>
              <img
                src={baseDesplegable}
                alt="desplegableImg"
                className="tw-z-10 tw-absolute"
                style={{ left: (widthContainer || 0) - 5 }}
              ></img>
              <div
                className="tw-absolute tw-w-0 tw-z-10 tw-transition-all"
                onClick={() => setisOpen((value) => !value)}
                style={{
                  left: (widthContainer || 0) + 2,
                  top,
                  transitionProperty: 'top',
                }}
              >
                <DespleableBtn
                  classNameContainer="tw-absolute tw-right-0 tw-top-0 tw-translate-x-9"
                  right={!isOpen}
                />
              </div>
              {isOpen && (
                <div
                  className="tw-absolute tw-right-0 tw-top-0 tw-w-9 tw-h-full"
                  onClick={() => setisOpen(false)}
                ></div>
              )}
            </>
          )}
          <div className="tw-flex tw-flex-wrap tw-h-full tw-w-full tw-grid-rows-1 tw-overflow-auto tw-overflow-x-hidden">
            <div
              ref={containerRef}
              className={classNames(
                `tw-h-full tw-flex tw-flex-col tw-items-center sm:tw-pt-0 tw-pt-9
            sm:tw-mr-0 tw-mr-14 sm:tw-bg-gray-50 tw-bg-Verde-TenisCTA tw-overflow-auto 
            tw-overflow-y-hidden sm:tw-px-4 tw-pb-4 sm:tw-w-60 tw-flex-1 sm:tw-flex-none tw-max-w-xs
            sm:tw-relative sm:tw-z-auto tw-z-10 scroll tw-absolute tw-top-0 tw-transition-all tw-content-start`,
                { 'tw-w-full': isOpen, 'tw-w-0': !isOpen }
              )}
              style={{ width: isOpen && sm && 'calc(100% - 37px)' }}
              onMouseMove={(e) => {
                let top = e.clientY - 125;
                if (top < 40) top = 40;
                setTop(top);
              }}
            >
              {sm && (
                <div className="tw-flex tw-bg-blueGray-100 tw-h-24 tw-shadow-md tw-absolute tw-top-0 tw-w-full ">
                  <div className="tw-absolute tw-z-30">
                    <Title
                      goBack={singleClubFetched && goBack}
                      containerStyles={{ marginBottom: 0, marginInline: 14 }}
                      size="2xl"
                    />
                  </div>
                  <Map disable lat={lat} lng={lng} zoom={17} />
                </div>
              )}
              <div className="tw-flex tw-items-start tw-w-full sm:tw-flex-col">
                <img
                  src={logo || clubPhoto}
                  className="tw-aspect-square sm:tw-w-36 sm:tw-h-36 tw-rounded-full tw-object-cover tw-my-4 tw-w-28 tw-h-28 sm:tw-border-0 tw-border-8 sm:tw-border-white tw-border-Verde-TenisCTA tw-border-solid tw-mx-2 tw-z-0 tw-bg-[white]"
                  alt="Imagen de perfil"
                />
                <h1 className="tw-col-span-2 tw-font-bold tw-text-ellipsis tw-text-base tw-mt-15 sm:tw-mt-0">
                  {name}
                </h1>
              </div>

              <div className="tw-grid tw-grid-cols-2-auto-fr tw-gap-3 tw-w-full tw-text-black tw-opacity-60 tw-px-3 sm:tw-px-0 tw-mt-3 tw-mb-3">
                <FaPhone />
                <h3>{phone}</h3>
                <FaHome />
                <div>
                  <h3>
                    {ciudad}, {region}
                  </h3>
                </div>
                <FaMapMarkerAlt />
                <h3>{direccion}</h3>
              </div>
              <div className="scroll tw-min-h-[100px] tw-px-3  sm:tw-px-0 tw-w-full">
                <h2 className="tw-text-Turquesa tw-font-bold tw-text-base tw-sticky tw-top-0 sm:tw-bg-gray-50 tw-bg-Verde-TenisCTA tw-w-full">
                  Sobre Nosotros
                </h2>
                <p>{about}</p>
              </div>
            </div>
            <div className="tw-flex tw-flex-col tw-flex-1 tw-h-full tw-basis-96">
              {!sm && (
                <div className="tw-flex tw-bg-blueGray-100 tw-h-52 tw-shadow-md">
                  <Map disable lat={lat} lng={lng} zoom={17} />
                </div>
              )}
              <div
                style={{ width: '100%', marginTop: '1rem', marginLeft: '1rem' }}
              >
                <TabsHeader
                  menuOptions={menu}
                  onChange={setMenuActive}
                  value={menuActive}
                />
              </div>
              {props.court && ModalCourt()}
              {renderContent()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
// Estilos modal

const customStyles = {
  content: {
    maxWidth: '100vw',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.75)',
  },
};
export default connect(mapStateToProps)(PerfilClub);
