import { memo } from "react";
import InstagramFeed from "react-ig-feed";

import "react-ig-feed/dist/index.css";

import "../../styles/LandingImg.css";

const LandingImg = () => {
  return (
    <div className="container-imagenes">
      <InstagramFeed
        token="IGQVJWZAl9HanZAPNTAyNXh3a0JtRnpiSGNCakZA6cnJaRVN1ZAjgyMExIUkw3cXk3UGJDTlkyTC0tR2ttZAzJCdVRYQjE2TDl3SktlbWhDakJpRXE0c0lXZA1BzcWNBV2c1b1Jxa294MEVZAdVhvbmpKWVV2NwZDZD"
        counter="6"
      />
    </div>
  );
};

export default memo(LandingImg);
