import { useEffect } from "react";

//components
import EventsRow from "./EventsRow";

//Redux
import { connect, useDispatch } from "react-redux";
import { getMyEvents } from "../../actions/events";
import TitleEvents from "./TitleEvents";

function mapStateToProps(state) {
  return {
    //all-events
    isFetchingMyEvents: state.events.isFetchingMyEvents,
    myEventsFetched: state.events.myEventsFetched,
    fetchMyEventsError: state.events.fetchMyEventsError,
    myEvents: state.events.myEvents,

    //
    isFetchingSingleEvent: state.events.isFetchingSingleEvent,
    singleEventFetched: state.events.singleEventFetched,
    fetchSingleEventError: state.events.fetchSingleEventError,

    //nenu
    mode: state.menu.mode,
  };
}

const MyEventsPage = (props) => {
  const dispatch = useDispatch();
  const { myEvents, mode = "activado" } = props;

  // Obtener todos los eventos
  useEffect(() => {
    const offs = dispatch(getMyEvents());
    return () => {
      Object.values(offs || {}).forEach((off) => off?.());
    };
  }, [dispatch]);

  return (
    <div className={`main-container-${mode}`}>
      <TitleEvents title="Mis eventos" />
      <EventsRow events={myEvents} isEventUp allEvents />
    </div>
  );
};

export default connect(mapStateToProps)(MyEventsPage);
