import classNames from 'classnames';
import InterpretaSet from './InterpretaSet';

const RoundRobind = ({ cuadro, handleClick }) => {
  const round = cuadro.rounds?.[0] || [];
  return (
    <div className="tw-z-20 tw-bg-white tw-bg-opacity-90 tw-w-full tw-p-5 tw-px-7 scroll tw-absolute tw-h-full">
      {round?.seeds?.map((seed, index) => (
        <CuadroRoundrobin
          group={seed?.teams || []}
          sets={round.sets}
          key={seed.id}
          SeedId={seed.id}
          index={index + 1}
          handleClick={(team) =>
            handleClick(round, {
              ...team,
              id: `${seed.id}-${team.id}`,
            })
          }
        />
      ))}
    </div>
  );
};

export default RoundRobind;

const CuadroRoundrobin = ({ group, index, handleClick, sets, SeedId }) => {
  const size = (group?.length || 0) + 1;
  return (
    <div className="tw-text-Turquesa tw-mb-4">
      <h1 className="tw-font-bold tw-text-xl tw-pb-2 tw-bg-Turquesa tw-bg-opacity-5 tw-w-fit tw-p-3 tw-py-1 tw-rounded-md">
        Grupo {index}
      </h1>
      <table className="tw-w-full tw-pb-3 ">
        <thead className="tw-w-full">
          <tr className="tw-border-Turquesa tw-border-solid tw-border-b-2 tw-border-opacity-40">
            <th
              className="tw-border-Turquesa tw-border-solid tw-border-r-2 tw-border-opacity-40"
              style={{ width: `${100 / size}%`, minWidth: 100 }}
            >
              <div className="tw-font-bold tw-flex tw-items-center tw-justify-center">
                Jugadores
              </div>
            </th>
            {group?.map((seed, index) => (
              <th
                key={'header-' + index}
                className="tw-border-l-2 tw-border-Turquesa tw-border-opacity-20"
                style={{ width: `${100 / size}%`, minWidth: 100 }}
              >
                <div
                  key={seed.id + 'column'}
                  className="tw-flex tw-items-center tw-justify-center"
                >
                  {seed.name}
                </div>
              </th>
            ))}
            <th
              className="tw-border-Turquesa tw-border-solid tw-border-l-2 tw-border-opacity-40"
              style={{ width: `${100 / size}%`, minWidth: 100 }}
            >
              <div className="tw-font-bold tw-flex tw-items-center tw-justify-center">
                PG - PP
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {group?.map((seed, indexG) => (
            <CuadroRoundrobinRow
              group={group}
              index={indexG}
              seed={seed}
              key={seed.id + 'row'}
              handleClick={handleClick}
              sets={sets}
              SeedId={SeedId}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
const CuadroRoundrobinRow = ({
  seed,
  group,
  index,
  handleClick,
  sets,
  SeedId,
}) => {
  const clickHandle = (seed) => {
    seed.teams = seed.teams?.sort((a, b) => a.teamPosition - b.teamPosition);
    handleClick?.(seed);
  };
  return (
    <tr>
      <td className="tw-border-Turquesa tw-h-7 tw-border-solid tw-border-r-2 tw-border-opacity-40">
        <div className="tw-overflow-ellipsis tw-h-7 tw-overflow-hidden tw-whitespace-nowrap tw-w-60">
          {seed.name}
        </div>
      </td>
      {group?.map((seed, row) => {
        const t1 = group[index];
        const t2 = group[row];
        const teamKey =
          t1?.teamPosition < t2?.teamPosition
            ? `${index}:${row}`
            : `${row}:${index}`;
        return (
          <td
            key={seed.id + 'cell'}
            className="tw-h-7 tw-border-l-2 tw-border-Turquesa tw-border-opacity-20"
          >
            <div className="tw-h-full">
              <div
                className={classNames(
                  'tw-m-1 tw-h-full tw-flex tw-justify-center',
                  {
                    'tw-bg-gray-500 tw-bg-opacity-10': t1?.isBye || t2?.isBye,
                    'tw-bg-Turquesa tw-bg-opacity-25': index === row,
                    'tw-cursor-pointer hover:tw-bg-Turquesa hover:tw-bg-opacity-10':
                      index !== row && !t1?.isBye && !t2?.isBye,
                  }
                )}
                onClick={() =>
                  index !== row &&
                  !t1?.isBye &&
                  !t2?.isBye &&
                  clickHandle?.({
                    teams: [t1, t2],
                    id: teamKey,
                  })
                }
              >
                {index !== row && !t1?.isBye && !t2?.isBye && (
                  <InterpretaSet
                    set={sets?.[`${SeedId}-${teamKey}`]}
                    group={group}
                    index={index}
                    row={row}
                  />
                )}
              </div>
            </div>
          </td>
        );
      })}
      <td className="tw-border-Turquesa tw-border-solid tw-border-l-2 tw-border-opacity-40 tw-h-7">
        <div className="tw-flex tw-justify-center">
          {seed.ganados || 0}-{seed.perdidos || 0}
        </div>
      </td>
    </tr>
  );
};
