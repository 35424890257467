import { useEffect, useState, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { uploadEventImage, getAllEventsOn } from "../actions/events";
import "../styles/UploadImages.css";
import defaultImage from "../assets/tennis-ball.png";
import toast from "react-hot-toast";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";
import UploadImg from "../components/upload-images/UploadImg";

//Alerta
import swal from "@sweetalert/with-react";
import Title from "../components/ui/Title";
import TabsHeader from "../components/ui/TabsHeader";
import SimpleUser from "../components/ui/SimpleUser";

function mapStateToProps(state) {
  return {
    events: state.events.events,
    event: state.events.event,
  };
}

const UploadImages = (props) => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const mobile = useMediaQuery("(max-width: 420px)");
  const [nombre, setNombre] = useState("");
  const [categoria, setCategoria] = useState("");
  const [imagen, setImagen] = useState({ file: "", filename: "" });
  const [events, setEvents] = useState([]);
  const [selectType, setSelectType] = useState("");
  const [selectOption, setSelectOption] = useState("Sponsor");
  const configOptions = ["Sponsor", "Fotos", "Ganadores"];
  const categorias = useMemo(() => {
    if (selectType?.categorias?.Total) delete selectType.categorias.Total;
    return Object.keys(selectType?.categorias || {}).map((categoria) => ({
      value: categoria,
      key: categoria,
      label: categoria,
    }));
  }, [selectType]);

  const images = useMemo(() => {
    let options = []
    const Tipo = {
      Sponsor: Object.entries(selectType?.sponsor || {}),
      Fotos: Object.entries(selectType?.fotos || {}),
      Ganadores: Object.entries(selectType?.ganadores || {})
    }
    options = Tipo[selectOption]
    return options
  }, [selectType, selectOption])

  const SuccessChange = () => {
    swal({
      title: "Se subió la imagen correctamente",
      text: "Se almacenó la imagen en la galeria de imagenes del torneo",
      icon: "success",
      button: {
        text: "Continuar",
        value: "done",
      },
    });
  };

  const Upload = async () => {
    const { dispatch } = props;
    let data = {
      imagen: imagen,
    };
    if (selectOption === "Ganadores") {
      data = {
        imagen: imagen,
        nombre: nombre || null,
        categoria: categoria || null,
      };
    }
    const { error } = await dispatch(uploadEventImage(data, selectType.value, selectOption));
    if (!error) {
      SuccessChange();
      setImagen({ file: "", filename: "" })
      if (selectOption === "Ganadores") {
        setNombre("")
        setCategoria("")
      }
    }
    else toast.error(error);
  }

  const handleChangePicture = async (event) => {
    event.preventDefault();
    if (event.target?.files.length === 1) {
      const file = event.target?.files[0];
      const fileReader = new window.FileReader();
      const filename = file?.name;
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setImagen({
          file: fileReader.result,
          filename: filename,
          blob: file,
        });
      };
    }
  };

  useEffect(() => {
    const listener = dispatch(getAllEventsOn());
    return () => listener?.();
  }, [dispatch]);

  useEffect(() => {
    const events =
      props.events?.map((event) => ({
        value: event.id,
        key: event.id,
        label: <SimpleUser imagen={event.logo} nombre={event.nombre} defaultImg={defaultImage} />,
        categorias: event.cupos,
        sponsor: event.sponsors,
        fotos: event.galeria,
        ganadores: event.ganadores,
      })) || [];
    setEvents(events);
    setSelectType((selectType) =>
      selectType ? events.filter((event) => event.value === selectType.value)?.[0] || "" : ""
    );
  }, [props.events]);

  function goBack() {
    navigate("/explore");
  }

  const OptionsEvent = () => {
    let options = []
    const Opciones = {
      Sponsor:
        <UploadImg
          events={events}
          setSelectType={setSelectType}
          selectType={selectType}
          handleChangePicture={handleChangePicture}
          imagen={imagen}
          images={images}
          selectOption={selectOption}
          onClick={() => Upload()}
          imgName="Sponsor"
        />,
      Fotos:
        <UploadImg
          events={events}
          setSelectType={setSelectType}
          selectType={selectType}
          handleChangePicture={handleChangePicture}
          imagen={imagen}
          images={images}
          selectOption={selectOption}
          onClick={() => Upload()}
          imgName="Foto"
        />,
      Ganadores:
        <UploadImg
          events={events}
          setSelectType={setSelectType}
          selectType={selectType}
          handleChangePicture={handleChangePicture}
          imagen={imagen}
          images={images}
          selectOption={selectOption}
          onClick={() => Upload()}
          winnerOption
          nombre={nombre}
          setNombre={setNombre}
          categoria={categoria}
          setCategoria={({ value }) => setCategoria(value)}
          categorias={categorias}
          imgName="Ganador"
        />
    }
    options = Opciones[selectOption]
    return options
  }
  
  return (
    <div
      className="main-container-activado"
      style={{ backgroundColor: "white", overflow: "hidden", paddingRight: 0 }}
    >
      <div
        style={{
          paddingInline: mobile && 12,
          width: "100%",
        }}
      >
        <Title
          goBack={goBack}
          title="Añadir fotos del torneo"
          containerStyles={{
            fontSize: 32,
            zIndex: mobile && 10,
            backgroundColor: "white",
            height: mobile ? 70 : 60,
            position: mobile && "sticky",
            top: mobile && 0,
            paddingBottom: mobile && 10,
          }}
        />
      </div>
      <div className="container-upload">
        <div className="container-selectOption">
          <TabsHeader menuOptions={configOptions} onChange={setSelectOption} value={selectOption} />
        </div>
        {OptionsEvent()}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(UploadImages);
