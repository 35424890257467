import { useState } from "react";
//Card
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Link, useSearchParams, Navigate } from "react-router-dom";

//image
import logo from "../assets/logo-blanco.png";

import { restore as restablecerContraseña } from "../services/login/restore.service";

//Alerta
import swal from "@sweetalert/with-react";

const RestorePassword = () => {
  const [params] = useSearchParams();
  //Expresión regular de validación contraseña
  var PassReg = /(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/;

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorPasswordConfirmation, setErrorPasswordConfirmation] = useState("");
  const [restore, setRestore] = useState(null);

  const classes = useStyles();
  const oobCode = params.get("oobCode");
  const apiKey = params.get("apiKey");
  const mode = params.get("mode");
  
  const validateGeneralInformation = () => {
    if (password === "" || passwordConfirmation === "") {
      return false;
    } else {
      if (PassReg.exec(password) && PassReg.exec(passwordConfirmation)) {
        return true;
      } else {
        return false;
      }
    }
  };

  function handleChangeRestore(e) {
    const { name, value } = e.target;
    switch (name) {
      case "password":
        setPassword(value);
        PassReg.exec(value) === false || PassReg.exec(value) === null
          ? setErrorPassword("Ingresa una contraseña con 8 caracteres minimos y una mayúscula")
          : setErrorPassword("");
        break;
      case "password-confirmation":
        setPasswordConfirmation(value);
        if (value !== password) {
          setErrorPasswordConfirmation("Las contrasenas no coinciden");
        } else {
          setErrorPasswordConfirmation("");
        }
        break;
      default:
        break;
    }
  }

  const SuccessChange = () => {
    swal({
      title: "Se ha cambiado la contraseña correctamente",
      text: "Ya puedes ingresar a tu cuenta con la nueva contraseña",
      icon: "success",
      button: {
        text: "Entendido",
        value: "done",
      },
    }).then((value) => {
      switch (value) {
        case "done":
          <Link to="/" />;
          break;
        default:
          break;
      }
    });
  };

  const restorePassword = async () => {
    var res_restore = await restablecerContraseña(apiKey, oobCode, passwordConfirmation);
    if (res_restore?.data?.email) {
      setRestore(res_restore);
      SuccessChange();
    } else if (res_restore?.error) {
      setRestore(res_restore.error);
    }
  };

  // mode puede tener los siguientes valores : resetPassword, recoverEmail, verifyEmail

  const renderContent = () => {
    if (!mode) {
      return <Navigate to="/" />;
    }
    if (mode === "resetPassword") {
      return (
        <div className={classes.content}>
          <Card className={classes.root}>
            <CardMedia
              style={{ objectFit: "contain", backgroundColor: "#234591", height: 100 }}
              component="img"
              image={logo}
              alt="Contemplative Reptile"
              title="Contemplative Reptile"
            />
            <CardContent className={classes.cardcontent}>
              <Typography gutterBottom variant="h5" component="h2">
                Restablecer Contraseña
              </Typography>
              <Typography className={classes.cardtypography} component="h2">
                Escribe la nueva contraseña
              </Typography>
              <div className="restore-password-input">
                <input
                  className="restore-password-intext"
                  name="password"
                  type="password"
                  placeholder="********"
                  value={password}
                  onChange={handleChangeRestore}
                />
                {errorPassword !== "" && (
                  <span className="perfil-club-errorMessage">{errorPassword}</span>
                )}
              </div>
              <Typography className={classes.cardtypography} component="h2">
                Escribe la confirmación de la contraseña
              </Typography>
              <div className="restore-password-input">
                <input
                  className="restore-password-intext"
                  name="password-confirmation"
                  type="password"
                  placeholder="********"
                  value={passwordConfirmation}
                  onChange={handleChangeRestore}
                />
                {errorPasswordConfirmation !== "" && (
                  <span className="perfil-club-errorMessage">{errorPasswordConfirmation}</span>
                )}
              </div>
            </CardContent>
            <CardActions>
              <button
                className="recover-button"
                style={
                  !validateGeneralInformation() || restore
                    ? { opacity: 0.5, transform: "none" }
                    : { opacity: 1 }
                }
                disabled={!validateGeneralInformation()}
                onClick={() => restorePassword()}
              >
                Restablecer Contraseña
              </button>
            </CardActions>
          </Card>
        </div>
      );
    }
    if (mode === "verifyEmail") {
      return <div>Se muestra pantalla que diga que tu usuario ya está verificado</div>;
    }
  };
  return <div>{renderContent()}</div>;
};

export default RestorePassword;

const useStyles = makeStyles({
  root: {
    width: "60%",
    boxShadow: "10px 10px 22px 6px #928D8D",
    height: "65%",
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "-webkit-center",
    backgroundColor: "#f5f6fa",
    height: "100vh",
  },
  cardcontent: {
    height: "60%",
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
    alignItems: "center",
  },
  "@media (max-width: 480px)": {
    root: {
      width: "100%",
      height: "100%",
      borderRadius: "0px",
    },
    cardcontent: {
      height: "75%",
      display: "flex",
      flexDirection: "column",
      placeContent: "center",
      alignItems: "center",
    },
    cardtypography: {
      alignSelf: "flex-start",
      margin: "5px 0px 10px 10px",
    },
  },
});
