import axios from 'axios'
const content = {
    "Content-Type": "application/json"
};

export const restore = async (apiKey, oobCode, newPassword) => {
    const ENDPOINT = `https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${apiKey}`
    try {
    const reset = await axios.post(
        ENDPOINT,
        {
            "oobCode": oobCode,
            "newPassword": newPassword
        },
        {
            headers: content,
        }
    ).then((response) => response).catch((err) => err)
    return reset

    } catch (err) {
        console.error(
            "No se pudo pa :(",
            err
        );
        return (
            err
        )
    }
};