import { useEffect, useState, useCallback } from 'react';
import '../../styles/Recintos.css'
import { connect } from 'react-redux';

import { getAllClubs } from "../../actions/clubs";
import Map from '../mapa/Map';

function mapStateToProps(state) {
    return {
        clubes: state.club.clubs,
        clubsFetched: state.club.clubsFetched,
    }
}

const Recintos = props => {

    var div = document.getElementById("home-recintos-container");
    const [height, setHeight] = useState(false);
    const rect = div !== null ? div.getBoundingClientRect() : 'cargando';
    const width = rect !== 'cargando' ? rect.width : 'asd';

    const isMobile = useCallback(() => {
        if (width < 640) {
            setHeight('150px')
        } else {
            setHeight('300px')
        }
    },[width])
    
    useEffect(() => {
        //Al iniciar consulta
        if (rect == null) { //Valicación para no recibir información nula. Évita errores
        } else {
            isMobile();
        }

    }, [width, rect, isMobile]);

    const { dispatch } = props;
    useEffect(() => {
        dispatch(getAllClubs());
    }, [props.clubsFetched, dispatch])

    const renderClubes = () => {
        if (props.clubsFetched === true) {
            var arr = props.clubes;
            var clubes = arr.map((val) => {
                return (
                    <div className='recinto-container'>
                        <div className='home-recinto-parent-normal'>
                            <div className='home-recinto'>
                                <div className='home-detalle-recinto'>
                                    <div>
                                        <h5>{val.nombre}</h5>
                                        <h5>{val.ciudad}</h5>
                                        <h5>{val.telefono}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='home-mapa-container'>
                            <div style={{ height: height, width: "100%", position: 'relative' }}>
                                <Map lat={val.lat} lng={val.lng} zoom={15} disable/>
                            </div>
                        </div>
                    </div>

                )
            })
            return clubes
        }
    }

    return (
        <div id='home-recintos-container' className='home-recintos-container'>
            <div className='home-recintos-content'>
                {renderClubes()}
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(Recintos)
