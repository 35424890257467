import axios from "axios";
import moment from "moment";
import { API } from "../../config/Config";

export const getWeek = async (clubId, keyCourt) => {
  const headers = { "Content-Type": "application/json" };
  const url = `${API}/club/getweek`;
  try {
    const result = await axios.post(
      url,
      {
        clubId,
        keyCourt,
        diffutc: moment().format("Z")
      },
      {
        headers: headers,
      }
    );
    return result.data;
  } catch (err) {
    console.error("error al obtener la semana", err);
  }
};

