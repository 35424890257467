import {
    //Obtener rankings
    GET_TYPERANK_REQUEST,
    GET_TYPERANK_SUCCESS,
    GET_TYPERANK_FAILURE,
} from '../actions/tipoRanking'

const tipoRankingState = (
    state = {
        //Traer rankings 
        isFetchingTypeRank: false,
        typeRankingFetched: false,
        fetchTypeRankingError: false,
        typeRank: [],
    },
    action
) => {
    switch (action.type) {

        case GET_TYPERANK_REQUEST:
            return {
                isFetchingTypeRank: true,
                typeRankingFetched: false,
                fetchTypeRankingError: false,
            }
        case GET_TYPERANK_SUCCESS:
            return {
                isFetchingTypeRank: false,
                typeRankingFetched: true,
                fetchTypeRankingError: false,
                typeRank: action.typeRank
            }
        case GET_TYPERANK_FAILURE:
            return {
                isFetchingTypeRank: false,
                typeRankingFetched: false,
                fetchTypeRankingError: true,
            }
            default:
                return state
    }
}

export default tipoRankingState;