import { useMemo } from "react";
import Delete from "../../../icons/Delete";
import Edit from "../../../icons/Edit";
// import { FaTrash, FaEdit } from "react-icons/fa";
// import { IoIosTrash } from "react-icons/io";
import SingleCourt from "../../SingleCourt";

function ListCanchas({ courts: courtsData, handleOpenDeleteModal, setSelectedCourt, setAddCourt }) {
  const courts = useMemo(() => courtsData || [], [courtsData]);
  if (courts.length === 0) return <div>No tienes agregada ninguna cancha</div>;
  const selectedCourt = (court, index) => {
    setSelectedCourt({
      court: { ...(court || {}), week: JSON.parse(JSON.stringify(court.week || [])) },
      index,
    });
    setAddCourt(true);
  };
  const Botonera = ({ court, index }) => (
    <div className="fa-courts">
      <button
        style={{ marginRight: 8 }}
        onClick={(e) => {
          e.preventDefault();
          handleOpenDeleteModal(court, index);
        }}
      >
        <Delete />
      </button>
      <button onClick={() => selectedCourt(court, index)}>
        <Edit />
      </button>
    </div>
  );
  return (
    <>
      {courts?.map((court, index) => (
        <SingleCourt
          key={"SingleCourt-" + index}
          court={court}
          handleClick={() => selectedCourt(court, index)}
          botonera={<Botonera court={court} index={index} />}
        />
      ))}
    </>
  );
}

export default ListCanchas;
