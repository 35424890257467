import classNames from "classnames";
import Phone from "react-phone-input-2";
import "../../styles/Input.css";

const PhoneInput = ({
  name,
  placeholder = "",
  value,
  label,
  error,
  onChange,
  onKeyPress,
  onPaste,
  onBlurCapture,
  type = "text",
  full = true,
  containerClass = "",
  containerStyles = {},
  inputStyles = {},
  labelStyles = {},
  containerStylesOff = false,
  enableSearch,
  localization = "es",
  regions = "america",
  country = "cl",
  ...props
}) => {
  return (
    <div
      className={classNames(
        {
          "custom-input-container": !containerStylesOff,
          "w-full": full,
          "tw-w-auto": !full,
          "tw-pb-3": !error,
        },
        containerClass
      )}
      style={{
        ...containerStyles,
      }}
    >
      <label className="custom-input-label" style={labelStyles}>
        {label}
        <Phone
          enableAreaCodes={true}
          placeholder={placeholder}
          containerStyle={{
            marginTop: label ? "0.5rem" : 0,
          }}
          inputStyle={{
            width: "100%",
            paddingTop: 12,
            paddingBottom: 12,
            height: 45,
            border: `1.5px solid ${error ? "red" : "#202020"}`,
            ...inputStyles,
          }}
          buttonStyle={{ border: `1.5px solid ${error ? "red" : "#202020"}` }}
          enableSearch={enableSearch}
          localization={localization}
          regions={regions}
          country={country}
          value={value}
          inputClass={error ? "custom-input-error" : "custom-input"}
          inputProps={{
            name: name,
            required: true,
          }}
          onChange={onChange}
          enableLongNumbers={false}
        />
      </label>
      {error && <span className="custom-input-errorMessage">{error}</span>}
    </div>
  );
};

export default PhoneInput;
