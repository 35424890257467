import classNames from "classnames";
import "../../styles/Check.css";
const Check = ({ label, checked, value, name, onChange, size, square, full }) => {
  const setClassSize = (size, square) => {
    let classN = "checkmark-check";
    if (size === "medium") classN += " checkmark-check-medium";
    if (square) classN += " checkmark-check-square";
    return classN;
  };
  const className = classNames("container-check", { "container-check-medium": size === "medium" });
  return (
    <label className={className}>
      {label}
      <input type="radio" checked={checked} value={value} name={name} onChange={onChange} />
      <span className={setClassSize(size, square)}></span>
    </label>
  );
};

export default Check;
