import {  
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    CANCEL_REGISTER_REQUEST,
    CANCEL_REGISTER_SUCCESS,
    CANCEL_REGISTER_FAILURE,
    CLEAN_REGISTRATION,
    SET_STATUS_REGISTRATION,
    EVENT_REPLACE,

  } from '../actions/inscription';

  const inscripcionState = (
    state = {
      //inscripcion a eventos
      isRegistering: false,
      isRegistered: false,
      errorRegistering: false,

      //cancelacion de inscripcion
      isCancelingRegister: false,
      isRegisterCanceled: false,
      errorCancelingRegister: false,

      //Manejar el estado especial categoria "Abierta"
      eventReplace: ""
    },
    action
    ) => {
    switch (action.type) {
      //inscripcion a eventos
      case REGISTER_REQUEST:
        return {
          ...state,
          isRegistering: true,
          errorRegistering: false
        };
      case REGISTER_SUCCESS:
        return {
          ...state,
          isRegistering: false,
          isRegistered: true,
        };
      case REGISTER_FAILURE:
        return {
          ...state,
          isRegistering: false,
          isRegistered: false,
          errorRegistering: true
        };

      //cancelacion registro a eventos
      case CANCEL_REGISTER_REQUEST:
        return {
          ...state,
          isCancelingRegister: true,
          errorCancelingRegister: false
        };
      case CANCEL_REGISTER_SUCCESS:
        return {
          ...state,
          isCancelingRegister: false,
          isRegisterCanceled: true,
        };
      case CANCEL_REGISTER_FAILURE:
        return {
          ...state,
          isCancelingRegister: false,
          isRegisterCanceled: false,
          errorCancelingRegister: true
        };
      //cleanup
      case CLEAN_REGISTRATION:
        return{
          ...state,
          isRegistering: false,
          isRegistered: false,
          errorRegistering: false,
          isCancelingRegister: false,
          isRegisterCanceled: false,
          errorCancelingRegister: false
        };
        case SET_STATUS_REGISTRATION:
          /**
           * isRegistering
           * isRegistered
           * errorRegistering
           */
          return { ...state, ...action.data }
        case EVENT_REPLACE:
          return { 
            ...state,
            eventReplace: action.data
          }
      default:
        return state;
    }
  };

  export default inscripcionState;