import { useEffect, useRef, useState } from "react";

import "../../styles/SingleWeekCalendar.css";
import moment from "moment";
import { useMemo } from "react";
import { HeaderWeeks } from "./HeaderWeek";
import BodyWeekNew from "./BodyWeekNew";

const SingleWeekCalendarNew = ({
  week,
  selectedDay,
  setWeek,
  setselectedDay,
  UpdateData,
  price,
  isClub,
  numberDay = 7,
  start = moment(new Date()),
  containerScrollID,
  staticWeek = false,
}) => {
  
  const days = useMemo(() => {
    let startDay = staticWeek ? moment(start).startOf("isoWeek") : moment(start);
    const tempDays = [];
    for (let index = 0; index < numberDay; index++) {
      let name = startDay.format("dddd");
      if (startDay.isSameOrBefore(start)) {
        const date = moment(start).format("YYYY-MM-DD");
        Object.entries(week?.[name]?.hour || {}).forEach(([hKey, hValue]) => {
          const date1 = moment(
            moment(date + " " + hKey, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm")
          );
          const date2 = moment(start).format("YYYY-MM-DD HH:mm");
          if (!isClub && moment(date1).isSameOrBefore(date2)) {
            week[name].hour[hKey] = 0;
          }
        });
      }
      tempDays.push({
        name,
        disponibility: week?.[name]?.disponibility ?? true,
        date: !staticWeek && startDay.toDate(),
        selectables: isClub,
        hours: week?.[name]?.hour || {},
      });
      startDay.add(1, "day");
    }
    return tempDays;
  }, [numberDay, start, isClub, week, staticWeek]);
  const bodyDays = useMemo(() => {
    const day = days?.find((day) => day?.name === selectedDay?.name) || [];
    return day;
  }, [days, selectedDay]);

  const calculeRef = useRef(UpdateData);
  calculeRef.current = UpdateData;
  const ChangeDay = (data) => {
    if (isClub) {
      var auxWeek = { ...week };
      if (auxWeek[data.name]) {
        auxWeek[data.name] = {
          ...auxWeek[data.name],
          disponibility: !auxWeek[data.name].disponibility,
        };

        setWeek({ ...auxWeek });
        setselectedDay(auxWeek[data.name]);
      }
    } else {
      setselectedDay(data);
      calculeRef.current({
        day: { start: null, end: null },
        duracion: 0,
        total: 0,
      });
    }
  };

  const [, setReloadData] = useState(true);

  useEffect(() => {
    let timer;
    let newCanchascrollDOM;
    const handleScrolltimer = () => {
      if (timer) clearTimeout(timer);
      setReloadData(false);
      timer = setTimeout(() => setReloadData(true), 100);
    };
    if (containerScrollID) {
      newCanchascrollDOM = document.querySelector(containerScrollID);
      if (newCanchascrollDOM) {
        newCanchascrollDOM.addEventListener("scroll", handleScrolltimer);
      }
    }

    return () => {
      if (newCanchascrollDOM) newCanchascrollDOM.removeEventListener("scroll", handleScrolltimer);
      if (timer) clearTimeout(timer);
    };
  }, [containerScrollID]);

  return (
    <div className="Scroll tw-select-none tw-flex tw-flex-col tw-items-center tw-gap-4 md:tw-px-4">
      <HeaderWeeks
        days={days}
        selectedDay={selectedDay}
        showNumberDay={!isClub}
        SetselectedDay={ChangeDay}
        staticWeek={staticWeek}
      />
      <BodyWeekNew days={bodyDays} UpdateData={UpdateData} price={price} />
    </div>
  );
};

export default SingleWeekCalendarNew;
