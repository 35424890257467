import {  
    PAYMENT_REQUEST,
    PAYMENT_SUCCESS,
    PAYMENT_FAILURE,
  } from '../actions/mach';
  
  const machState = (
    state = {
      isProcessingPayment: false,
      paymentReceived: false,
      paymentError: false,
      payment: {}
    },
    action
    ) => {
    switch (action.type) {
      case PAYMENT_REQUEST:
        return {
          ...state,
          isProcessingPayment: true,
          paymentError: false
        };
      case PAYMENT_SUCCESS:
        return {
          ...state,
          isProcessingPayment: false,
          paymentReceived: true,
          payment: action.payment
        };
      case PAYMENT_FAILURE:
        return {
          ...state,
          isProcessingPayment: false,
          paymentReceived: false,
          paymentError: true
        };
      default:
        return state;
    }
  };

  export default machState;